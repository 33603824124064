import React, { FC, useState, useEffect, useContext } from "react";
import { fadeInBottom } from "src/lib/framer-motion/fade-in-bottom";
import { motion } from "framer-motion";
import routes from "src/config/routes";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";

const UserAgreementPage: FC = () => {
  const { language } = useContext(GlobalContext);
  console.log("current language is ", language);

  return (
    <motion.div
      variants={fadeInBottom()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto p-4 sm:p-11 static-content-type2 aml-content">
        <div className="flex pb-[180px] font-manrope text-primary">
          <div className="flex flex-col gap-4">
            <h3 className="text-[25px] sm:text-[36px] font-bold text-primary">
              {String(L.translate("UserAgreement.title"))}
            </h3>
            <div className="flex flex-col gap-2">
              <h2 className="font-manrope text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("UserAgreement.par_content_1"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">1.1</span>
                  {String(L.translate("UserAgreement.par_content_1_1"))}
                </li>
                <li>
                  <span className="mr-3">1.2</span>
                  {String(L.translate("UserAgreement.par_content_1_2"))}
                </li>
                <li>
                  <span className="mr-3">1.3</span>
                  {String(L.translate("UserAgreement.par_content_1_3"))}
                </li>
              </ul>

              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("UserAgreement.par_content_2"))}
              </h2>
              {/* <li>
                <a className="underline" href="fastoo.com" target="_blank">
                  www.Fastoo.com
                </a>{" "}
              </li> */}
              <ul className="text-[14px] sm:text-[16px]">
                {" "}
                <li>
                  {" "}
                  <span className="mr-3">2.1</span>
                  {String(L.translate("UserAgreement.par_content_2_2"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">2.2</span>
                  {String(L.translate("UserAgreement.par_content_2_3"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">2.3</span>
                  {String(L.translate("UserAgreement.par_content_2_4"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">2.4</span>
                  {String(L.translate("UserAgreement.par_content_2_5"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">2.5</span>
                  {String(L.translate("UserAgreement.par_content_2_6"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">2.6</span>
                  {String(L.translate("UserAgreement.par_content_2_7"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">2.7</span>
                  {String(L.translate("UserAgreement.par_content_2_8"))}
                </li>
              </ul>
              {/* <li>
                {String(L.translate("UserAgreement.par_content_2_18"))}{" "}
                <strong>
                  {String(L.translate("UserAgreement.par_content_2_19"))}{" "}
                </strong>
                {String(L.translate("UserAgreement.par_content_2_20"))}{" "}
                <a
                  className="underline"
                  href={routes.privacyPolicy}
                  target="_blank"
                  rel="noreferrer"
                >
                  {String(L.translate("UserAgreement.par_content_2_21"))}{" "}
                </a>
                {String(L.translate("UserAgreement.par_content_2_22"))}
              </li> */}

              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("UserAgreement.par_content_3"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">3.1</span>
                  {String(L.translate("UserAgreement.par_content_3_1"))}
                </li>
                <li>
                  <span className="mr-3">3.2</span>
                  {String(L.translate("UserAgreement.par_content_3_2"))}
                </li>
                <li>
                  <span className="mr-3">3.3</span>
                  {String(L.translate("UserAgreement.par_content_3_3"))}
                </li>
                <ul>
                  <li>
                    <span className="mr-3">3.3.1</span>
                    {String(L.translate("UserAgreement.par_content_3_4"))}
                  </li>
                  <li>
                    <span className="mr-3">3.3.2</span>
                    {String(L.translate("UserAgreement.par_content_3_5"))}
                  </li>
                  <li>
                    <span className="mr-3">3.3.3</span>
                    {String(L.translate("UserAgreement.par_content_3_6"))}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">3.4</span>
                  {String(L.translate("UserAgreement.par_content_3_7"))}
                </li>
                <li>
                  <span className="mr-3">3.5</span>
                  {String(L.translate("UserAgreement.par_content_3_8"))}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("UserAgreement.par_content_4"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">4.1</span>
                  {String(L.translate("UserAgreement.par_content_4_1"))}
                </li>
                <li>
                  <span className="mr-3">4.2</span>
                  {String(L.translate("UserAgreement.par_content_4_2"))}
                </li>
                <li>
                  <span className="mr-3">4.3</span>
                  {String(L.translate("UserAgreement.par_content_4_3"))}
                </li>
                <li>
                  <span className="mr-3">4.4</span>
                  {String(L.translate("UserAgreement.par_content_4_4"))}
                </li>
                <li>
                  <span className="mr-3">4.5</span>
                  {String(L.translate("UserAgreement.par_content_4_5"))}
                </li>
              </ul>

              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("UserAgreement.par_content_5"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">5.1</span>
                  {String(L.translate("UserAgreement.par_content_5_1"))}
                </li>
                <li>
                  <span className="mr-3">5.2</span>
                  {String(L.translate("UserAgreement.par_content_5_2"))}
                </li>
                <li>
                  <span className="mr-3">5.3</span>
                  {String(L.translate("UserAgreement.par_content_5_3"))}
                </li>
                <li>
                  <span className="mr-3">5.4</span>
                  {String(L.translate("UserAgreement.par_content_5_4"))}
                </li>
                <li>
                  <span className="mr-3">5.5</span>
                  {String(L.translate("UserAgreement.par_content_5_5"))}
                </li>
                <li>
                  <span className="mr-3">5.6</span>
                  {String(L.translate("UserAgreement.par_content_5_6"))}
                </li>
              </ul>
              {/* <li>
                {String(L.translate("UserAgreement.par_content_5_6"))}{" "}
                <a className="underline" href="mailto:info@fastoo.com">
                  info@fastoo.com{" "}
                </a>
                {String(L.translate("UserAgreement.par_content_5_7"))}
              </li> */}

              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("UserAgreement.par_content_6"))}{" "}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">6.1</span>
                  {String(L.translate("UserAgreement.par_content_6_1"))}{" "}
                </li>
                <li>
                  <span className="mr-3">6.2</span>
                  {String(L.translate("UserAgreement.par_content_6_2"))}{" "}
                </li>
                <li>
                  <span className="mr-3">6.3</span>
                  {String(L.translate("UserAgreement.par_content_6_3"))}{" "}
                </li>
                <li>
                  <span className="mr-3">6.4</span>
                  {String(L.translate("UserAgreement.par_content_6_4"))}{" "}
                </li>
                <li>
                  <span className="mr-3">6.5</span>
                  {String(L.translate("UserAgreement.par_content_6_5"))}{" "}
                </li>
                <li>
                  <span className="mr-3">6.6</span>
                  {String(L.translate("UserAgreement.par_content_6_6"))}{" "}
                </li>
                <li>
                  <span className="mr-3">6.7</span>
                  {String(L.translate("UserAgreement.par_content_6_7"))}{" "}
                </li>
                <li>
                  <span className="mr-3">6.8</span>
                  {String(L.translate("UserAgreement.par_content_6_8"))}{" "}
                </li>
                <li>
                  <span className="mr-3">6.9</span>
                  {String(L.translate("UserAgreement.par_content_6_9"))}{" "}
                </li>
                <li>
                  <span className="mr-3">6.10</span>
                  {String(L.translate("UserAgreement.par_content_6_10"))}{" "}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("UserAgreement.par_content_7"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  {" "}
                  <span className="mr-3">7.1</span>
                  {String(L.translate("UserAgreement.par_content_7_1"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">7.2</span>
                  {String(L.translate("UserAgreement.par_content_7_2"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">7.3</span>
                  {String(L.translate("UserAgreement.par_content_7_3"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">7.4</span>
                  {String(L.translate("UserAgreement.par_content_7_4"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">7.5</span>
                  {String(L.translate("UserAgreement.par_content_7_5"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">7.6</span>
                  {String(L.translate("UserAgreement.par_content_7_6"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">7.7</span>
                  {String(L.translate("UserAgreement.par_content_7_7"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">7.8</span>
                  {String(L.translate("UserAgreement.par_content_7_8"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">7.9</span>
                  {String(L.translate("UserAgreement.par_content_7_9"))}{" "}
                </li>
                <ul>
                  <li>
                    <span className="mr-3">7.9.1</span>
                    {String(L.translate("UserAgreement.par_content_7_10"))}{" "}
                  </li>
                  <li>
                    <span className="mr-3">7.9.2</span>
                    {String(L.translate("UserAgreement.par_content_7_11"))}{" "}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">7.10</span>
                  {String(L.translate("UserAgreement.par_content_7_12"))}{" "}
                </li>
                <li>
                  <span className="mr-3">7.11</span>
                  {String(L.translate("UserAgreement.par_content_7_13"))}{" "}
                </li>
                <li>
                  <span className="mr-3">7.12</span>
                  {String(L.translate("UserAgreement.par_content_7_14"))}{" "}
                </li>
                <li>
                  <span className="mr-3">7.13</span>
                  {String(L.translate("UserAgreement.par_content_7_15"))}{" "}
                </li>
                <li>
                  <span className="mr-3">7.14</span>
                  {String(L.translate("UserAgreement.par_content_7_16"))}{" "}
                </li>
                <li>
                  <span className="mr-3">7.15</span>
                  {String(L.translate("UserAgreement.par_content_7_17"))}{" "}
                </li>
                <li>
                  <span className="mr-3">7.16</span>
                  {String(L.translate("UserAgreement.par_content_7_18"))}{" "}
                </li>
                <li>
                  <span className="mr-3">7.17</span>
                  {String(L.translate("UserAgreement.par_content_7_19"))}{" "}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("UserAgreement.par_content_8"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  {" "}
                  <span className="mr-3">8.1</span>
                  {String(L.translate("UserAgreement.par_content_8_1"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">8.2</span>
                  {String(L.translate("UserAgreement.par_content_8_2"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">8.3</span>
                  {String(L.translate("UserAgreement.par_content_8_3"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">8.4</span>
                  {String(L.translate("UserAgreement.par_content_8_4"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">8.5</span>
                  {String(L.translate("UserAgreement.par_content_8_5"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">8.6</span>
                  {String(L.translate("UserAgreement.par_content_8_6"))}{" "}
                </li>
                <ul>
                  {" "}
                  <li>
                    <span className="mr-3">8.6.1</span>{" "}
                    {String(L.translate("UserAgreement.par_content_8_7"))}{" "}
                  </li>
                  <li>
                    <span className="mr-3">8.6.2</span>
                    {String(L.translate("UserAgreement.par_content_8_8"))}{" "}
                  </li>
                </ul>
                <li>
                  {" "}
                  <span className="mr-3">8.7</span>
                  {String(L.translate("UserAgreement.par_content_8_9"))}{" "}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("UserAgreement.par_content_9"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  {" "}
                  <span className="mr-3">9.1</span>
                  {String(L.translate("UserAgreement.par_content_9_1"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.2</span>
                  {String(L.translate("UserAgreement.par_content_9_2"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.3</span>
                  {String(L.translate("UserAgreement.par_content_9_3"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.4</span>
                  {String(L.translate("UserAgreement.par_content_9_4"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.5</span>
                  {String(L.translate("UserAgreement.par_content_9_5"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.6</span>
                  {String(L.translate("UserAgreement.par_content_9_6"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.7</span>
                  {String(L.translate("UserAgreement.par_content_9_7"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.8</span>
                  {String(L.translate("UserAgreement.par_content_9_8"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.9</span>
                  {String(L.translate("UserAgreement.par_content_9_9"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.10</span>
                  {String(L.translate("UserAgreement.par_content_9_10"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.11</span>
                  {String(L.translate("UserAgreement.par_content_9_11"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.12</span>
                  {String(L.translate("UserAgreement.par_content_9_12"))}{" "}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">9.13</span>
                  {String(L.translate("UserAgreement.par_content_9_13"))}{" "}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("UserAgreement.par_content_10"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">10.1</span>
                  {String(L.translate("UserAgreement.par_content_10_1"))}{" "}
                </li>
                <ul>
                  <li>
                    <span className="mr-3">10.1.1</span>
                    {String(L.translate("UserAgreement.par_content_10_2"))}{" "}
                  </li>
                  <li>
                    <span className="mr-3">10.1.2</span>
                    {String(L.translate("UserAgreement.par_content_10_3"))}{" "}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">10.2</span>
                  {String(L.translate("UserAgreement.par_content_10_4"))}{" "}
                </li>
                <li>
                  <span className="mr-3">10.3</span>
                  {String(L.translate("UserAgreement.par_content_10_5"))}{" "}
                </li>
                <li>
                  <span className="mr-3">10.4</span>
                  {String(L.translate("UserAgreement.par_content_10_6"))}{" "}
                </li>
                <li>
                  <span className="mr-3">10.5</span>
                  {String(L.translate("UserAgreement.par_content_10_7"))}{" "}
                </li>
                <li>
                  <span className="mr-3">10.6</span>
                  {String(L.translate("UserAgreement.par_content_10_8"))}{" "}
                </li>
                <li>
                  <span className="mr-3">10.7</span>
                  {String(L.translate("UserAgreement.par_content_10_9"))}{" "}
                </li>
              </ul>
              <strong>{L.translate("UserAgreement.llc")}</strong>
              <p className="text-[14px] sm:text-[16px]">
                {L.translate("UserAgreement.legal_address")}
              </p>
              <p className="text-[14px] sm:text-[16px]">
                {L.translate("UserAgreement.actual_address")}
              </p>
              <p className="text-[14px] sm:text-[16px]">
                {L.translate("UserAgreement.contuct_information")}
              </p>
              <p className="text-[14px] sm:text-[16px]">
                {L.translate("UserAgreement.website")}{" "}
                <a
                  className="underline"
                  href="http://www.fastoo.com/"
                  target="_blank"
                >
                  www.fastoo.com
                </a>{" "}
              </p>
              <strong className="text-[14px] sm:text-[16px]">
                {L.translate("UserAgreement.bank")}{" "}
                <a
                  className="underline"
                  href="http://www.nbg.gov.ge"
                  target="_blank"
                >
                  www.nbg.gov.ge
                </a>{" "}
              </strong>
              <p className="text-[14px] sm:text-[16px]">
                <i>{L.translate("UserAgreement.supervisury")}</i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default UserAgreementPage;
