import L from "i18n-react";

export const companyTypes = [
  {
    id: 0,
    code: "ie",
    name: "IE",
  },
  {
    id: 1,
    code: "gkc",
    name: "GKC",
  },
  {
    id: 2,
    code: "ltd",
    name: "LTD",
  },
];

export const employmentStatuses = [
  {
    id: 0,
    code: "working",
    name: `${String(L.translate("KYC.working"))}`,
  },
  {
    id: 1,
    code: "unemployed",
    name: `${String(L.translate("KYC.unemployed"))}`,
  },
  {
    id: 2,
    code: "entrepreneur ",
    name: `${String(L.translate("KYC.entrepreneur"))}`,
  },
  {
    id: 3,
    code: "student",
    name: `${String(L.translate("KYC.student"))}`,
  },
  {
    id: 4,
    code: "citizen",
    name: `${String(L.translate("KYC.citizen"))}`,
  },
];

export const yesNo = [
  {
    id: 0,
    code: "yes",
    name: `${String(L.translate("KYC.yes"))}`,
  },
  {
    id: 1,
    code: "no",
    name: `${String(L.translate("KYC.no"))}`,
  },
];

export const salaryIncoms = [
  {
    id: 0,
    code: "salary",
    name: `${String(L.translate("KYC.salary"))}`,
  },
  {
    id: 1,
    code: "dividends",
    name: `${String(L.translate("KYC.dividends"))}`,
  },
  {
    id: 2,
    code: "other",
    name: `${String(L.translate("KYC.other"))}`,
  },
];

export const incomeAmounts = [
  {
    id: 0,
    code: "tiny",
    name: "0-1000",
  },
  {
    id: 1,
    code: "small",
    name: "1001-5000",
  },
  {
    id: 2,
    code: "medium",
    name: "5001-10000",
  },
  {
    id: 3,
    code: "large",
    name: "> 10000",
  },
];

export const reasons = [
  {
    id: 0,
    code: "utility_bills",
    name: `${String(L.translate("KYC.utility"))}`,
  },
  {
    id: 1,
    code: "deposits",
    name: `${String(L.translate("KYC.deposits"))}`,
  },
  {
    id: 2,
    code: "other",
    name: `${String(L.translate("KYC.other"))}`,
  },
];

export const frequencies = [
  {
    id: 0,
    code: "one",
    name: `${String(L.translate("KYC.oneTime"))}`,
  },
  {
    id: 1,
    code: "month",
    name: `${String(L.translate("KYC.severalTimeM"))}`,
  },
  {
    id: 2,
    code: "year",
    name: `${String(L.translate("KYC.severalTimeY"))}`,
  },
];

export const annualTurnovers = [
  {
    id: 0,
    code: "tiny",
    name: "< 10000",
  },
  {
    id: 1,
    code: "small",
    name: "10 000 - 100 000",
  },
  {
    id: 2,
    code: "medium",
    name: "100 000 - 500 000",
  },
  {
    id: 3,
    code: "large",
    name: "> 500 000",
  },
];

export const genderTypes = [
  {
    id: 1,
    code: "Male",
    name: "Male",
  },
  {
    id: 2,
    code: "Female",
    name: "Female",
  },
];
