import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/datatable.css";
import "./assets/css/scrollbar.css";
import "./assets/css/other.css";
import "./assets/css/temp/kyc.css";
import App from "./pages";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
