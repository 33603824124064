import {
  FC,
  useRef,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";
import useAuth from "src/hooks/use-auth";
import { useNavigate } from "react-router-dom";
import Button from "../ui/button";
import { QuestionIcon } from "../icons/question-icon";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";
import routes from "src/config/routes";

export const VerificationBanner: FC = () => {
  const navigate = useNavigate();
  const { language } = useContext(GlobalContext);
  const { getCurrrentUser } = useAuth();

  const onStartVerification = () => {
    navigate(
      !!getCurrrentUser()?.is_business ? routes.kycBuisiness : routes.kyc
    );
  };
  return (
    <div className="hidden sm:flex top-[70px] z-30 h-[51px] w-full items-center justify-center gap-2 bg-[#FFF5E7]">
      <QuestionIcon className="w-[19px] h-[19px] text-primary" />
      <p className="text-[14px] font-manrope font-medium leading-[21px] text-primary">
        {getCurrrentUser()?.is_business
          ? String(L.translate("Deposit.protect_your_funds_business"))
          : String(L.translate("Deposit.protect_your_funds"))}
      </p>
      <Button
        onClick={onStartVerification}
        variant="solidDanger"
        type="submit"
        className={`${
          language === "EN" ? "w-[101px]" : "w-[136px]"
        } rounded-[8px] font-manrope text-[14px] font-medium leading-[21px]`}
      >
        {String(L.translate("Deposit.verify_now"))}
      </Button>
    </div>
  );
};

export const MobileVerificationBanner: FC = () => {
  const navigate = useNavigate();
  const { language } = useContext(GlobalContext);
  const { getCurrrentUser } = useAuth();

  const onStartVerification = () => {
    navigate(
      !!getCurrrentUser()?.is_business ? routes.kycBuisiness : routes.kyc
    );
  };
  return (
    <div className="flex sticky top-16 sm:top-[70px] z-10 sm:hidden w-full items-center justify-center gap-2 bg-[#FFF5E7] p-2">
      <QuestionIcon className="w-[15px] h-[15px] text-primary flex-shrink-0" />
      <p className="text-[12px] sm:text-[14px] font-manrope font-medium leading-[21px] text-primary">
        {String(L.translate("Deposit.protect_your_funds"))}
      </p>
      <Button
        onClick={onStartVerification}
        variant="solidDanger"
        type="submit"
        className={`${
          language === "EN" ? "w-[101px]" : "w-[136px]"
        } rounded-[8px] font-manrope text-[14px] font-medium leading-[21px] flex-shrink-0`}
      >
        {String(L.translate("Deposit.verify_now"))}
      </Button>
    </div>
  );
};
