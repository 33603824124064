import { FC } from "react";
import L from "i18n-react";

interface IOurPricesTabs {
  isBuisiness?: boolean;
  onChange: (isBuisiness: boolean) => void;
}

const OurPricesTabs: FC<IOurPricesTabs> = ({ isBuisiness, onChange }) => {
  return (
    <div className="flex gap-[8px]">
      <button
        type="button"
        className={`${
          !isBuisiness ? "text-primary bg-light" : "text-secondary"
        } p-4 rounded-t-[8px] text-base hover:text-primary duration-200 outline-none`}
        onClick={() => onChange(false)}
      >
        {L.translate("OurPrices.private")}
      </button>
      <button
        type="button"
        className={`${
          isBuisiness ? "text-primary bg-light" : "text-secondary"
        } p-4 rounded-t-[8px] text-base hover:text-primary duration-200 outline-none`}
        onClick={() => onChange(true)}
      >
        {L.translate("OurPrices.buisiness")}
      </button>
    </div>
  );
};

export default OurPricesTabs;
