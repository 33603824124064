export const BankCardIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M2.25 3.5625C2.14645 3.5625 2.0625 3.64645 2.0625 3.75V14.25C2.0625 14.3536 2.14644 14.4375 2.25 14.4375H15.75C15.8536 14.4375 15.9375 14.3536 15.9375 14.25V3.75C15.9375 3.64644 15.8536 3.5625 15.75 3.5625H2.25ZM0.9375 3.75C0.9375 3.02513 1.52513 2.4375 2.25 2.4375H15.75C16.4749 2.4375 17.0625 3.02513 17.0625 3.75V14.25C17.0625 14.9749 16.4749 15.5625 15.75 15.5625H2.25C1.52513 15.5625 0.9375 14.9749 0.9375 14.25V3.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M0.9375 6C0.9375 5.68934 1.18934 5.4375 1.5 5.4375H16.5C16.8107 5.4375 17.0625 5.68934 17.0625 6C17.0625 6.31066 16.8107 6.5625 16.5 6.5625H1.5C1.18934 6.5625 0.9375 6.31066 0.9375 6Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M9.5625 12C9.5625 11.6893 9.81434 11.4375 10.125 11.4375H13.5C13.8107 11.4375 14.0625 11.6893 14.0625 12C14.0625 12.3107 13.8107 12.5625 13.5 12.5625H10.125C9.81434 12.5625 9.5625 12.3107 9.5625 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M16.5 3.1875C16.8107 3.1875 17.0625 3.43934 17.0625 3.75V9.75C17.0625 10.0607 16.8107 10.3125 16.5 10.3125C16.1893 10.3125 15.9375 10.0607 15.9375 9.75V3.75C15.9375 3.43934 16.1893 3.1875 16.5 3.1875Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M1.5 3.1875C1.81066 3.1875 2.0625 3.43934 2.0625 3.75V9.75C2.0625 10.0607 1.81066 10.3125 1.5 10.3125C1.18934 10.3125 0.9375 10.0607 0.9375 9.75V3.75C0.9375 3.43934 1.18934 3.1875 1.5 3.1875Z"
      />
    </svg>
  );
};
