export const PhoneVerificationIcon: React.FC<React.SVGAttributes<{}>> = (
  props
) => {
  return (
    <svg fill="none" viewBox="0 0 18 23" stroke="currentColor" {...props}>
      <path
        d="M17 6.5V16.5C17 20.5 16 21.5 12 21.5H6C2 21.5 1 20.5 1 16.5V6.5C1 2.5 2 1.5 6 1.5H12C16 1.5 17 2.5 17 6.5Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 5H7"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99995 18.6C9.85599 18.6 10.55 17.906 10.55 17.05C10.55 16.194 9.85599 15.5 8.99995 15.5C8.14391 15.5 7.44995 16.194 7.44995 17.05C7.44995 17.906 8.14391 18.6 8.99995 18.6Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
