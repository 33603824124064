import { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import Button from "../ui/button";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import useAuth from "src/hooks/use-auth";
import useKYCStatus from "src/hooks/use-kyc-status";
import GlobalContext from "src/context/global-context";

type FastTransfersActionsProps = {};

const FastTransfersActions: FC<FastTransfersActionsProps> = ({}) => {
    const navigate = useNavigate();
    const { setAlertText, setIsAlertOpened, setAlertTitle } =
        useContext(GlobalContext);
    const { getCurrent2FAInfo } = useAuth();
    const { isVerifed } = useKYCStatus();

    const onWithdraw = () => {
        setAlertTitle(String(L.translate("Alert.Information")));

        if (!isVerifed) {
            setAlertText(String(L.translate("Errors.withdraw_account")));
            setIsAlertOpened(true);
            return;
        }

        navigate(routes.withdraw);
    };

    return (
        <div className="w-full flex flex-row items-center justify-between">
            <p className="hidden sm:block text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
                {L.translate("FastTransfers.title")}
            </p>
        </div>
    );
};

export default FastTransfersActions;
