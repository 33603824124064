import { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import { Link } from "react-router-dom";
import routes from "../../config/routes";

const FastTransfersTermAndConditionPage: FC = () => {
  console.log("THEHE");

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-0 sm:p-4 sm:pr-12">
        <div className="w-full flex flex-row items-center mb-[20px] justify-between">
          <p className="hidden sm:block text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
            {L.translate("FastTransfersTermCondition.title")}
          </p>
        </div>

        <div className="bg-white p-6 rounded-xl">
          <p className="mb-[20px]">
            {L.translate("FastTransfersTermCondition.descriptionTxt1")}
          </p>
          <p>{L.translate("FastTransfersTermCondition.descriptionTxt2")}</p>
        </div>
      </div>
    </div>
  );
};

export default FastTransfersTermAndConditionPage;
