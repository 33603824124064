import { useState, forwardRef, useEffect } from "react";
import L from "i18n-react";
import cn from "classnames";
import { EyeIcon } from "../icons/eye-icon";
import { EyeCloseIcon } from "../icons/eye-close-icon";
import { makeNumberWithFloatingDigits } from "src/lib/utils";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  max_limit: number;
  real_max_limit: number;
  isDeposit: boolean;
  value: number;
  onChange: any;
  showBalance?: boolean;
  currency?: string;
};

const InputWithMax = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      className,
      inputClassName = "bg-transparent",
      type = "number",
      max_limit,
      real_max_limit,
      isDeposit,
      onChange,
      showBalance = false,
      currency = "USD",
      value,
      ...props
    },
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState(value);

    const handleChange = (event: any) => {
      let max = 0;
      let current = 0;
      const inputValue = event.target.value;

      if (isDeposit) max = max_limit;
      else max = real_max_limit;

      if (inputValue === "") current = 0;
      else current = parseFloat(inputValue);

      if (current <= max) {
        if (inputValue === "") {
          setCurrentValue(inputValue);
          onChange(-1);
          return;
        }
        if (inputValue === "0" && inputValue.length === 1) {
          setCurrentValue(inputValue);
          onChange(inputValue);
        } else if (
          inputValue.startsWith("0.") ||
          (!inputValue.startsWith("0") && inputValue.includes("."))
        ) {
          setCurrentValue(inputValue);
          onChange(inputValue);
        }
        if (!inputValue.startsWith("0")) {
          setCurrentValue(inputValue);
          onChange(inputValue);
        }
      } else {
        setCurrentValue(inputValue);
        onChange(inputValue);
      }
    };

    console.log(`balance ${real_max_limit}`);

    return (
      <div className={className}>
        <label className="block text-[16px] font-manrope font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-[16px] font-manrope font-medium text-secondary dark:text-light/70">
              {label}
            </span>
          )}

          <span className="relative block">
            <input
              type={type}
              value={currentValue >= 0 ? currentValue : ""}
              step="any"
              onChange={handleChange}
              ref={ref}
              {...props}
              onWheel={(e) => e.currentTarget.blur()}
              className={cn(
                "h-[50px] sm:h-[64px] placeholder-other w-full appearance-none text-[16px] font-manrope font-medium rounded-[12px] border border-border bg-transparent pl-4 pr-12 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] dark:border-dark-600 dark:text-light dark:ring-dark-600 lg:pl-5 lg:pr-12",
                error
                  ? "focus:border-[#F44336] !border-[#F44336] focus:ring-[#F44336]"
                  : "focus:border-active focus:ring-active dark:focus:border-active dark:focus:ring-active",
                inputClassName
              )}
            />
            <span
              onClick={() => {
                setCurrentValue(isDeposit ? max_limit : real_max_limit);
                onChange(isDeposit ? max_limit : real_max_limit);
              }}
              className="absolute top-0 flex h-full w-12 cursor-pointer items-center justify-start text-dark-900 hover:text-dark-700 right-2 dark:text-dark-800 hover:dark:text-light-900"
            >
              {String(L.translate("Base.Modals.max_button"))}
            </span>
          </span>
        </label>
        {showBalance && (
          <span className="block cursor-pointer pt-2.5 text-16px font-manrope font-medium text-secondary dark:text-light/70">
            {L.translate("DepositFiat.balance")}:{" "}
            <span className="text-primary font-semibold">{`${makeNumberWithFloatingDigits(
              real_max_limit,
              2
            )} ${currency.toUpperCase()}`}</span>
          </span>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[16px] text-[#F44336] font-manrope"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

InputWithMax.displayName = "InputWithMax";
export default InputWithMax;
