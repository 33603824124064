import { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import { BankCard, IbanAccount } from "../../api/types";
import Button from "../../components/ui/button";
import GlobalContext from "src/context/global-context";
import { PaymentIcon } from "src/components/icons/payment-icon";
import { BankCardIcon } from "src/components/icons/bank-card-icon";
import { DeleteIcon } from "src/components/icons/delete-icon";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Spin } from "antd";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";
import useKYCStatus from "src/hooks/use-kyc-status";
import CardsTable from "src/components/payment/cards-table";
import IbanTable from "src/components/payment/iban-table";

type TTabs = "card" | "iban";

const tabs: { code: TTabs; name: string }[] = [
  {
    code: "card",
    name: "Account.PaymentMethods.card",
  },
  {
    code: "iban",
    name: "IBAN",
  },
];

const PaymentPage: FC = () => {
  const { openModal } = useModalAction();
  const { isVerifed } = useKYCStatus();

  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [cards, setCards] = useState<BankCard[]>([]);
  const [ibans, setIbans] = useState<IbanAccount[]>([]);
  const [currentTab, setCurrentTab] = useState<TTabs>("card");

  useEffect(() => {
    if (!isMounted) {
      return;
    }
    switch (currentTab) {
      case "card": {
        getCards();
        return;
      }
      case "iban": {
        getIbans();
        return;
      }
      default: {
        return;
      }
    }
  }, [isMounted, currentTab]);
  useEffect(() => {
    if (!isMounted) {
      return;
    }
    getIbans();
  }, [isMounted]);

  const { mutate: getCards, isLoading: isCardsLoading } = useMutation(
    client.bankCards.all,
    {
      onSuccess: (data) => {
        setCards(data);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getIbans, isLoading: isIbanLoading } = useMutation(
    client.iban.all,
    {
      onSuccess: (data) => {
        setIbans(data);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onAddPaymentMethod = () => {
    setAlertTitle(String(L.translate("Alert.Information")));

    if (!isVerifed) {
      setAlertText(String(L.translate("Errors.need_verification")));
      setIsAlertOpened(true);
      return;
    }
    openModal("CHOOSE_NEW_PAYMENT_METHOD", {
      getCards,
      getIbans,
    });
  };

  const renderTabs = () => (
    <div className="flex gap-4">
      {tabs.map((item) => (
        <button
          key={item.code}
          className={
            item.code === currentTab
              ? "relative text-primary after:absolute after:w-full after:bottom-[-5px] after:bg-[#232800] after:h-[2px] after:left-0 after:transition-opacity transition-colors"
              : "relative text-secondary transition-colors hover:text-primary"
          }
          onClick={() => setCurrentTab(item.code)}
        >
          {L.translate(item.name)}
        </button>
      ))}
    </div>
  );

  const makeCardList = () => {
    if (cards.length === 0 && ibans.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center py-16 gap-4">
          <PaymentIcon className="w-[60px] h-[60px] text-secondary" />
          <p className="font-manrope text-secondary text-[14px] font-light leading-[21px]">
            {String(L.translate("Account.PaymentMethods.you_payment"))}
          </p>
        </div>
      );
    } else {
      return (
        <div className="w-full flex-col mt-4">
          {renderTabs()}
          {currentTab === "card" && (
            <CardsTable
              data={cards}
              onRefetch={getCards}
              isLoading={isCardsLoading}
            />
          )}
          {currentTab === "iban" && (
            <IbanTable
              data={ibans}
              isLoading={isIbanLoading}
              onRefetch={getIbans}
            />
          )}
        </div>
      );
    }
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col py-2 px-4 sm:p-4 sm:pr-11">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          {String(L.translate("Account.PaymentMethods.title"))}
        </p>

        <div className="flex flex-col bg-white rounded-[12px] mt-2 sm:mt-4 p-4 gap-4 relative">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-8">
            <p className="font-manrope text-secondary text-[16px] font-medium leading-[24px]">
              {String(L.translate("Account.PaymentMethods.page_description"))}
            </p>
            <Button
              variant="solid"
              onClick={onAddPaymentMethod}
              className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] w-full bg-active hover:bg-main sm:max-w-[294px]"
            >
              {String(L.translate("Account.PaymentMethods.add_payment_method"))}
            </Button>
          </div>
          <div className="w-full">
            <hr className="h-[1px] border-0 bg-[#E6E8EC] opacity-50 dark:opacity-50" />
          </div>
          {makeCardList()}
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
