import React, { FC, useState, useEffect, useContext } from "react";
import Input from "../../components/ui/input";
import Button from "../../components/ui/button";
import L from "i18n-react";
import { BackIcon } from "../../components/icons/back-icon";
import { useNavigate, Navigate } from "react-router-dom";
import { fadeInOut } from "../../lib/framer-motion/fade-in-out";
import { motion } from "framer-motion";
import routes from "../../config/routes";
import { useMutation } from "react-query";
import client from "../../api";
import useAuth from "../../hooks/use-auth";
import { getErrorMessage } from "../../lib/utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useModalAction } from "../../components/modal-views/context";
import GlobalContext from "src/context/global-context";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";

const ForgotPasswordPage: FC = () => {
  const { openModal } = useModalAction();
  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .required(String(L.translate("Errors.google2fa")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          String(L.translate("Errors.email_format"))
        ),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { setIsAlertOpened, setAlertTitle, setAlertText } =
    useContext(GlobalContext);

  const { authorize } = useAuth();
  const { mutate: forgotPassword, isLoading } = useMutation(
    client.users.forgotPassword,
    {
      onSuccess: (data) => {
        openModal("EMAIL_VERIFICATION", {
          type: "password",
          title: "Your request has been successfully submitted.",
        });
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "email_not_confirmed"
            ) {
              setAlertText(String(L.translate("Errors.email_not_confirmed")));
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { isAuthorized } = useAuth();
  if (isAuthorized) return <Navigate to={routes.balance} />;

  const onSubmit = (data: FormData) => {
    forgotPassword(data);
  };

  return (
    <motion.div
      variants={fadeInOut()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto">
        <form
          className="flex h-full w-full sm:w-[421px] py-8 flex-col px-4 sm:px-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-2 sm:gap-0">
            <Button
              variant="icon"
              type="button"
              onClick={() => navigate(-1)}
              className="bg-[#F4F4F4] rounded-[5px] px-4 py-2 w-[101px] mb-10 hover:bg-light-600"
            >
              <BackIcon className="w-[20px] h-[20px] text-primary" />
              <p className="font-manrope text-[18px] font-medium text-primary">
                {String(
                  L.translate("Forms.ForgotPassword.forgot_password_link")
                )}
              </p>
            </Button>
            <img
              src={"/assets/logo.png"}
              className="w-24 h-24 object-contain m-auto sm:hidden"
            />
            <p className="text-[34px] font-medium font-manrope text-primary text-center sm:text-left">
              {String(
                L.translate("Forms.ForgotPassword.forgot_password_title")
              )}
            </p>
            <p className="text-[18px] font-light font-manrope text-secondary text-center sm:text-left">
              {String(L.translate("Forms.ForgotPassword.forgot_password_text"))}
            </p>
          </div>
          <div className="flex flex-col mt-10 gap-4">
            <Input
              label={String(
                L.translate("Forms.ForgotPassword.forgot_password_field_email")
              )}
              className="w-full"
              {...register("email")}
              error={errors.email && (errors.email.message as string)}
            />
          </div>
          <div className="flex flex-col gap-8 mt-10">
            <Button
              type="submit"
              variant="validate"
              className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px]"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
            >
              {String(
                L.translate("Forms.ForgotPassword.forgot_password_btn_submit")
              )}
            </Button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default ForgotPasswordPage;
