export function CurrencyEURIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 128 128"
    >
      <rect width="128" height="128" fill="#253F99" rx="64"></rect>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M77.514 22.632c-7.323-1.632-14.88-.693-21.68 2.64-6.782 3.326-12.435 8.842-16.353 15.722a44.59 44.59 0 00-3.4 7.534H23.533v10h10.288a48.162 48.162 0 000 10.944H23.534v10H36.08a44.594 44.594 0 003.4 7.534c3.92 6.88 9.572 12.396 16.354 15.721 6.8 3.334 14.357 4.273 21.68 2.641 7.309-1.628 13.91-5.709 19.036-11.568l3.622-4.14-8.28-7.242-3.62 4.14c-3.713 4.243-8.307 6.995-13.15 8.074-4.83 1.076-9.84.476-14.446-1.782-4.624-2.266-8.708-6.146-11.637-11.288a32.72 32.72 0 01-1.092-2.09H75.42v-10H44.915a37.108 37.108 0 010-10.944H75.42v-10H47.947c.34-.712.704-1.41 1.092-2.09 2.929-5.142 7.013-9.022 11.637-11.288 4.606-2.258 9.615-2.858 14.445-1.782 4.844 1.08 9.438 3.831 13.15 8.074l3.621 4.14 8.28-7.243-3.622-4.14c-5.125-5.858-11.727-9.939-19.036-11.567z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
