import React, { FC, useState, useEffect, useContext } from "react";
import { fadeInBottom } from "src/lib/framer-motion/fade-in-bottom";
import { motion } from "framer-motion";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";

const CookiesPolicyPage: FC = () => {
  const { language } = useContext(GlobalContext);
  console.log("current language is ", language);

  return (
    <motion.div
      variants={fadeInBottom()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto p-4 sm:p-11 cookie-policy-content">
        <div className="flex pb-[180px] font-manrope text-primary">
          <div className="flex flex-col gap-4">
            <h3 className="text-[25px] sm:text-[36px] font-bold text-primary">
              {String(L.translate("CookiePolicy.title"))}
            </h3>
            <div className="flex flex-col gap-2">
              <ul className="list-decimal">
                <li className="font-manrope text-[16px] sm:text-[22px] text-primary">
                  {String(L.translate("CookiePolicy.paragraph_1"))}
                </li>
                <ul className="list-none text-[14px] sm:text-[16px]">
                  <li>
                    <span className="mr-3">1.1</span>{" "}
                    {String(L.translate("CookiePolicy.par_content_1"))}
                  </li>
                  <li>
                    <span className="mr-3">1.2</span>{" "}
                    {String(L.translate("CookiePolicy.par_content_2"))}
                  </li>
                </ul>
                <li className="font-manrope mt-2 text-[16px] sm:text-[22px]  text-primary">
                  {String(L.translate("CookiePolicy.paragraph_2"))}
                </li>
                <ul className="list-none  text-[14px] sm:text-[16px]">
                  <li>
                    <span className="mr-3">2.1</span>{" "}
                    {String(L.translate("CookiePolicy.par_con_2_1"))};
                  </li>
                  <li>
                    <span className="mr-3">2.2</span>
                    {String(L.translate("CookiePolicy.par_con_2_2"))};
                  </li>
                  <li>
                    <span className="mr-3">2.3</span>{" "}
                    {String(L.translate("CookiePolicy.par_con_2_3"))};
                  </li>
                  <li>
                    <span className="mr-3">2.4</span>{" "}
                    {String(L.translate("CookiePolicy.par_con_2_4"))};
                  </li>
                  <li>
                    <span className="mr-3">2.5</span>{" "}
                    {String(L.translate("CookiePolicy.par_con_2_5"))};
                  </li>
                </ul>

                <li className="font-manrope mt-2 text-[16px] sm:text-[22px] text-primary">
                  {String(L.translate("CookiePolicy.paragraph_3"))}
                </li>
                <ul className="list-none text-[14px] sm:text-[16px]">
                  <li>
                    <span className="mr-3">3.1</span>{" "}
                    {String(L.translate("CookiePolicy.par_content_3"))}
                  </li>
                  <li>
                    <span className="mr-3">3.2</span>{" "}
                    {String(L.translate("CookiePolicy.par_content_31"))}
                  </li>
                  <li>
                    <span className="mr-3">3.3</span>{" "}
                    {String(L.translate("CookiePolicy.par_content_32"))}
                  </li>
                  <li>
                    <span className="mr-3">3.4</span>{" "}
                    {String(L.translate("CookiePolicy.par_content_33"))}
                  </li>
                </ul>
                <li className="font-manrope mt-2 text-[16px] sm:text-[22px] text-primary">
                  {String(L.translate("CookiePolicy.paragraph_4"))}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CookiesPolicyPage;
