import { FC, useContext, useEffect, useState, useRef } from "react";
import L from "i18n-react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage, makeSenderSecret } from "../../lib/utils";
import Button from "../ui/button";
import { PhoneVerificationIcon } from "src/components/icons/phone-verification-icon";
import ToggleSwitch, { SwitchElement } from "src/components/ui/switch";
import { CheckIcon } from "src/components/icons/check-icon";
import { EmailVerificationIcon } from "../icons/email-verification-icon";
import { CloseCircleIcon } from "../icons/close-circle-icon";
import GlobalContext from "src/context/global-context";
import useAuth from "src/hooks/use-auth";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";

type TwoAuthSettingsCardProps = {
  type: string; //phone & email
  sender: string | undefined;
  initialValue: number;
  onCallback: any;
};

const TwoAuthSettingsCard: FC<TwoAuthSettingsCardProps> = ({
  type,
  sender,
  initialValue,
  onCallback,
}) => {
  const switchRef = useRef<SwitchElement>(null);
  const { openModal } = useModalAction();
  const { setIsAlertOpened, setAlertText, setAlertTitle, language } =
    useContext(GlobalContext);

  const [isChecked, setIsChecked] = useState(Boolean(initialValue));

  const { getCurrent2FAInfo, setCurrent2FAInfo } = useAuth();

  useEffect(() => {
    setIsChecked(Boolean(initialValue));
  }, [initialValue]);

  const onValueChanged = (type: string, value: boolean) => {
    if (type === "email") {
      isChecked ? disableEmail() : enableEmail();
    } else {
      if (!sender) {
        setAlertText(
          String(L.translate("Account.TwoFactorAuth.please_add_phone_number"))
        );
        setIsAlertOpened(true);
        return;
      } else {
        isChecked ? disablePhone() : enablePhone();
      }
    }
  };

  const { mutate: enableEmail, isLoading: isLoadingEnableEmail } = useMutation(
    client.twoAuthFactor.enableEmail,
    {
      onSuccess: (data) => {
        console.log(data);
        setCurrent2FAInfo({ ...getCurrent2FAInfo(), email_2fa_enabled: 1 });
        setIsChecked(true);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "email_2fa_already_enabled"
            ) {
              setAlertText(
                String(L.translate("Errors.email_2fa_already_enabled"))
              );
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
        setIsChecked(false);
      },
    }
  );

  const { mutate: disableEmail, isLoading: isLoadingDisableEmail } =
    useMutation(client.twoAuthFactor.disableEmail, {
      onSuccess: (data) => {
        console.log(data);
        setCurrent2FAInfo({ ...getCurrent2FAInfo(), email_2fa_enabled: 0 });
        setIsChecked(false);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "email_2fa_already_disabled"
            ) {
              setAlertText(
                String(L.translate("Errors.email_2fa_already_disabled"))
              );
              setIsAlertOpened(true);
              return;
            }
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "can_not_disable_last_2fa"
            ) {
              setAlertText(
                "You can't turn OFF all options. At least one should be turned ON"
              );
              console.log(isChecked);

              setIsChecked(isChecked);
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
        setIsChecked(false);
      },
    });

  const { mutate: enablePhone, isLoading: isLoadingEnablePhone } = useMutation(
    client.twoAuthFactor.enablePhone,
    {
      onSuccess: (data) => {
        console.log(data);
        setCurrent2FAInfo({ ...getCurrent2FAInfo(), phone_2fa_enabled: 1 });
        setIsChecked(true);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "phone_2fa_already_enabled"
            ) {
              setAlertText(
                String(L.translate("Errors.phone_2fa_already_enabled"))
              );
              setIsAlertOpened(true);
              return;
            }

            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
        setIsChecked(false);
      },
    }
  );

  const { mutate: disablePhone, isLoading: isLoadingDisablePhone } =
    useMutation(client.twoAuthFactor.disablePhone, {
      onSuccess: (data) => {
        console.log(data);
        setCurrent2FAInfo({ ...getCurrent2FAInfo(), phone_2fa_enabled: 0 });
        setIsChecked(false);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "phone_2fa_already_enabled"
            ) {
              setAlertText(
                String(L.translate("Errors.phone_2fa_already_enabled"))
              );
              setIsAlertOpened(true);
              return;
            }
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "can_not_disable_last_2fa"
            ) {
              setAlertText(
                "You can't turn OFF all options. At least one should be turned ON"
              );
              console.log(isChecked);

              setIsChecked(isChecked);
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
        setIsChecked(false);
      },
    });

  const makeConfiguration = () => {
    if (sender) {
      //justify-between
      return (
        <div
          className={`${
            type === "email" ? "invisible" : ""
          } flex flex-row items-center justify-end gap-4 w-full sm:w-[230px] flex-shrink-0`}
        >
          <Button
            variant="solid"
            onClick={() =>
              openModal("ADD_PHONE_NUMBER", {
                isNew: false,
                sender,
                callback: onCallback,
              })
            }
            className={`${
              language === "EN" ? "sm:w-[108px]" : "sm:w-[140px]"
            } rounded-[12px] font-manrope text-[14px] font-bold leading-[24px] w-full bg-active hover:bg-main h-[41px]`}
          >
            {String(L.translate("Buttons.buttonEdit"))}
          </Button>
          {/* 
          <Button
            variant="outline"
            className="rounded-[12px] font-manrope text-[14px] font-bold leading-[24px] w-full border-opacity-20 sm:w-[108px] h-[41px]"
          >
            Delete
          </Button>
          */}
        </div>
      );
    } else {
      return (
        <div
          className={`${
            type === "email" ? "invisible" : ""
          } flex flex-row items-center justify-end w-full sm:w-[230px] flex-shrink-0`}
        >
          <Button
            variant="solid"
            onClick={() =>
              openModal("ADD_PHONE_NUMBER", {
                isNew: true,
                sender: null,
                callback: onCallback,
              })
            }
            className={`${
              language === "EN" ? "sm:w-[128px]" : "sm:w-[188px]"
            } rounded-[12px] font-manrope text-[14px] font-bold leading-[24px] w-full bg-active hover:bg-main h-[41px]`}
          >
            {String(L.translate("Account.AccountSettings.add_phone"))}
          </Button>
        </div>
      );
    }
  };

  return (
    <div className="w-full flex flex-col bg-white rounded-[12px] mt-4 p-4 gap-4">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="w-full flex flex-row gap-2 items-center">
          {type === "email" ? (
            <EmailVerificationIcon className="w-[24px] h-[24px] text-primary" />
          ) : (
            <PhoneVerificationIcon className="w-[24px] h-[24px] text-primary" />
          )}
          <p className="font-manrope text-[18px] font-medium text-primary leading-[24px]">
            {`${
              type === "email"
                ? String(L.translate("Account.AccountSettings.email_adress"))
                : String(L.translate("Account.AccountSettings.phone_num"))
            } ${String(L.translate("Account.AccountSettings.verificaton"))}`}
          </p>
        </div>

        <ToggleSwitch
          type={type}
          value={isChecked}
          ref={switchRef}
          onValueChanged={onValueChanged}
        />
      </div>
      <div className="w-full">
        <hr className="h-[1px] border-0 bg-[#E6E8EC] opacity-50 dark:opacity-50" />
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-8 md:gap-16 lg:gap-40">
        <div className="flex flex-row items-center justify-between gap-2 w-full">
          <p className="font-manrope text-secondary text-[14px] font-medium leading-[24px]">
            {String(L.translate("Account.AccountSettings.project_you"))}
          </p>
          <div className="flex flex-row items-center justify-center gap-1 flex-shrink-0">
            {!sender ? (
              <CloseCircleIcon className="w-[14px] h-[14px] text-primary" />
            ) : (
              <div className="w-[14px] h-[14px] flex items-center justify-center bg-active rounded-full">
                <CheckIcon className="text-dark w-[8px] h-[8px]" />
              </div>
            )}

            <p className="text-[14px] font-manrope text-primary font-medium leading-[24px]">
              {!sender ? "Not linked" : makeSenderSecret(sender)}
            </p>
          </div>
        </div>
        {makeConfiguration()}
      </div>
    </div>
  );
};

export default TwoAuthSettingsCard;
