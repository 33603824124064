// TODO: Should be rewriten to Tailwind CSS
import { useState, useEffect } from "react";
import L from "i18n-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { loadCountriesFromLocal, saveCountriesIntoLocal } from "src/lib/utils";
import { useMutation } from "react-query";
import client from "../../../api";
import { CountryResponse } from "../../../api/types";
import Button from "../../ui/button";
import Dropdown from "../../ui/dropdown";
import DropdownWithSearch from "../../ui/dropdown-search";
import Input from "../../ui/input";
import { reasons, frequencies, yesNo } from "./data";

export type TFinalData = Omit<
  FormData,
  | "reason_for_using"
  | "utilize_frequency"
  | "dual_citizenship"
  | "has_green_card"
  | "dual_citizenship_country_id"
> & {
  reason_for_using: string;
  utilize_frequency: string;
  dual_citizenship: 0 | 1;
  has_green_card: 0 | 1;
  dual_citizenship_country_id?: number;
};

interface IKycBuisinessFormStep3 {
  onSubmit: (state: TFinalData) => void;
}

const yesId = yesNo.find((item) => item.code === "yes")?.id;
const reasonOtherId = reasons.find((item) => item.code === "other")?.id;

const schema = yup
  .object({
    reason_for_using: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    reason_for_using_details: yup
      .string()
      .when("reason_for_using", (reason_for_using, schema) => {
        if (reason_for_using[0] === reasonOtherId) {
          return schema.required(`${String(L.translate("Errors.fieldErr"))}`);
        }
        return schema;
      }),
    utilize_frequency: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    dual_citizenship: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    dual_citizenship_country_id: yup
      .number()
      .when("dual_citizenship", (dual_citizenship, schema) => {
        if (dual_citizenship[0] === yesId) {
          return schema.required(`${String(L.translate("Errors.fieldErr"))}`);
        }
        return schema;
      }),
    has_green_card: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
  })
  .required();
export type FormData = yup.InferType<typeof schema>;

export default function KycBuisinessFormStep3({
  onSubmit,
}: IKycBuisinessFormStep3) {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
    watch,
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      dual_citizenship: 1,
    },
  });

  const watchReason = watch("reason_for_using");
  const watchDual = watch("dual_citizenship");

  const showReasonDetails =
    reasons.find((item) => item.id === watchReason)?.code === "other";

  const [countries, setCountries] = useState<CountryResponse[]>([]);

  const { mutate: getCountries, isLoading } = useMutation(
    client.kyc.getCountries,
    {
      onSuccess: (data) => {
        console.log(data);
        saveCountriesIntoLocal(data);
        setCountries(data);
      },
      onError: (error: any) => {
        setCountries([]);
      },
    }
  );

  const loadCountries = async () => {
    let data = await loadCountriesFromLocal();
    if (data.length === 0) {
      getCountries();
    } else {
      setCountries(data);
    }
  };

  useEffect(() => {
    loadCountries();
  }, []);

  return (
    <form
      className="h-full w-full flex flex-col gap-4"
      onSubmit={handleSubmit((data: FormData) => {
        onSubmit({
          ...data,
          reason_for_using:
            reasons.find((item) => item.id === data.reason_for_using)?.code ||
            "",
          utilize_frequency:
            frequencies.find((item) => item.id === data.utilize_frequency)
              ?.code || "",
          dual_citizenship:
            yesNo.find((item) => item.id === data.dual_citizenship)?.code ===
            "yes"
              ? 1
              : 0,
          has_green_card:
            yesNo.find((item) => item.id === data.has_green_card)?.code ===
            "yes"
              ? 1
              : 0,
          dual_citizenship_country_id:
            data.dual_citizenship_country_id !== -1
              ? data.dual_citizenship_country_id
              : undefined,
          reason_for_using_details: showReasonDetails
            ? data.reason_for_using_details
            : undefined,
        });
      })}
    >
      <div className="w-full flex flex-col gap-8 mt-2">
        <Controller
          name="reason_for_using"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.reason_for_using"))}
              placeholder={String(L.translate("KYC.enter_reasons"))}
              className="w-full"
              data={reasons}
              required
              error={String(
                L.translate(errors.reason_for_using?.message || "")
              )}
              {...field}
            />
          )}
        />
        {showReasonDetails && (
          <Input
            label={String(L.translate("KYC.your_reason"))}
            placeholder={String(L.translate("KYC.enter_reason"))}
            className="w-full"
            {...register("reason_for_using_details")}
            required
            error={String(
              L.translate(errors.reason_for_using_details?.message || "")
            )}
          />
        )}
        <Controller
          name="utilize_frequency"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.utilize_frequency"))}
              className="w-full"
              data={frequencies}
              required
              error={String(
                L.translate(errors.utilize_frequency?.message || "")
              )}
              {...field}
            />
          )}
        />
        <Controller
          name="dual_citizenship"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.dual_citizenship"))}
              placeholder="Enter status"
              className="w-full"
              data={yesNo}
              required
              error={String(
                L.translate(errors.dual_citizenship?.message || "")
              )}
              {...field}
            />
          )}
        />
        {watchDual === 0 && (
          <Controller
            name="dual_citizenship_country_id"
            control={control}
            render={({ field }) => (
              <DropdownWithSearch
                label={String(L.translate("Account.AccountSettings.country"))}
                placeholder={String(
                  L.translate("Account.AccountSettings.enter_country")
                )}
                className="w-full"
                data={countries}
                required
                error={String(
                  L.translate(errors.dual_citizenship_country_id?.message || "")
                )}
                {...field}
                value={field.value ?? -1}
              />
            )}
          />
        )}
        <Controller
          name="has_green_card"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.has_green_card"))}
              className="w-full"
              data={yesNo}
              required
              error={String(L.translate(errors.has_green_card?.message || ""))}
              {...field}
            />
          )}
        />
      </div>
      <div className="flex flex-row items-center justify-center mt-4">
        <Button
          type="submit"
          variant="validate"
          className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] w-full h-[64px]"
          disabled={!isValid}
        >
          {L.translate("KYC.continue")}
        </Button>
      </div>
    </form>
  );
}
