import { FC, useEffect, useState, useContext, useMemo } from "react";
import L from "i18n-react";
import Button from "../../components/ui/button";
// import visaIcon from "assets/images/general/visa.webp";
import { useModalAction } from "../../components/modal-views/context";
import InputWithMax from "../../components/ui/input-max";
import DropdownCurrency from "../../components/ui/dropdown-currency";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import RadioGroup from "../../components/ui/radio-group";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "../../context/global-context";
import {
  getCurrencyInfo,
  getCurrencySymbol,
  getErrorMessage,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import {
  APISomethingWrongMsg,
  AssetDataType,
  NetworkDisconnectMsg,
  PAYMENT_OPTIONS,
  PAYMENT_TYPE,
} from "src/lib/constants";
import { Spin } from "antd";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import DropdownCard from "src/components/ui/dropdown-card";
import { BankCard, FiatLimitation } from "src/api/types";
import routes from "src/config/routes";
import useAuth from "src/hooks/use-auth";

function convertExponentialToDecimal(
  exponentialNumber: number
): number | string {
  const str = exponentialNumber?.toString();
  if (str.indexOf("e") !== -1) {
    const exponent = parseInt(str.split("-")[1], 10);

    return exponentialNumber.toFixed(exponent);
  }
  return exponentialNumber;
}

const DepositPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const isMounted = useIsMounted();
  const { getCurrrentUser } = useAuth();

  const { closeModal, openModal } = useModalAction();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [maxAmount, setMaxAmount] = useState(0);
  const [selectedCurrencyIdx, setSelectedCurrencyIdx] = useState(-1);
  const [currencyList, setCurrencyList] = useState<AssetDataType[]>([]);
  const [transferBanks, setTransferBanks] = useState<any[]>([]);
  const [step, setStep] = useState(0);

  const [payOptions, setPayOptions] = useState<string[]>([]);
  const [cardList, setCardList] = useState<BankCard[]>([]);
  const [selectBank, setSelectBank] = useState<string>("");
  const [selectedBank, setSelctedBank] = useState<any>(null);
  const [fee, setFee] = useState(0);
  const [continueBtn, setContinueBtn] = useState(false);
  const [amountValue, setAmountValue] = useState(0);

  const [limitations, setLimitations] = useState<FiatLimitation[]>([]);
  const [checkCard, setCheckCard] = useState(false);

  const [dailyAmount, setDailyAmount] = useState(0);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const schema = yup
    .object()
    .shape({
      currency: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`),
      payType: yup
        .string()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .min(1),
      card: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .min(0),
      min_amount: yup
        .number()
        .nullable()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .positive(),
      max_amount: yup
        .number()
        .nullable()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .positive(),
      amount: yup
        .number()
        .nullable()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .positive()
        .min(
          yup.ref("min_amount"),
          `${
            checkCard
              ? String(L.translate("Errors.small_amount_for_a_deposit"))
              : String(L.translate("Errors.choose_the_card"))
          }`
        )
        .max(
          yup.ref("max_amount"),
          `${
            checkCard
              ? String(L.translate("Errors.much_amount_for_a_deposit"))
              : String(L.translate("Errors.choose_the_card"))
          }`
        )
        .test(
          "is-card-selected",
          `${String(L.translate("Errors.choose_the_card"))}`,
          function (value) {
            const { card } = this.parent;
            if (card === undefined || card === null || card < 0) {
              return false;
            }
            return true;
          }
        ),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  useEffect(() => {
    if (isMounted) {
      getWallets();
      getLimitations();
    }
  }, [isMounted]);

  useEffect(() => {
    if (location.state) {
      setSelectedCurrencyIdx(location.state.selectedCurrencyIdx);
      setCurrencyList(location.state.currencies);
      setValue("currency", location.state.selectedCurrencyIdx);
    }
  }, [location.state]);

  const {
    register,
    watch,
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<any>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      currency: selectedCurrencyIdx,
      payType: "",
      card: -1,
      amount: -1,
      min_amount: 1,
      max_amount: 1,
    },
  });

  const [currency, payType, amount, card] = watch([
    "currency",
    "payType",
    "amount",
    "card",
  ]);

  const recive =
    (amount &&
      String(fee) &&
      Number(amount) > Number(fee) &&
      Number(amount) - Number(fee)) ||
    0;

  const currencyItem = currencyList?.find((item) => item.key === currency);
  useEffect(() => {
    setMaxAmount(
      currency >= 0 && currencyList.length > 0
        ? Number(currencyItem?.amount)
        : 0
    );
    setValue("amount", -1);

    if (currencyList.length > 0 && currency >= 0 && currencyItem?.currency) {
      getAvailablePaymentMethods({
        code: currencyItem?.currency,
        type: PAYMENT_TYPE.Deposit,
      });
    }
  }, [currency, currencyList]);

  const amountLimits = useMemo(() => {
    if (!limitations) {
      return null;
    }

    const code = currencyList?.[currency]?.currency;
    const cardChannel = cardList?.[card]?.channel;

    if (payType === PAYMENT_OPTIONS.Card && !cardChannel) {
      return null;
    }

    const channelName = () => {
      switch (payType) {
        case PAYMENT_OPTIONS.Card:
          return cardChannel;
        case PAYMENT_OPTIONS.Bank:
          return selectedBank.is_partner ? payType : "bank_transfer_other";
        default:
          return payType;
      }
    };

    // const channelName =
    //   payType === PAYMENT_OPTIONS.Card ? cardChannel : payType;

    const result = limitations.find(
      (item) => item.asset_code === code && item.channel_name === channelName()
    );

    if (!result) {
      return null;
    }

    const isVip = getCurrrentUser().is_vip;

    const { deposit_min, vip_deposit_min, deposit_max, vip_deposit_max } =
      result;
    return {
      min: isVip ? vip_deposit_min : deposit_min,
      max: isVip ? vip_deposit_max : deposit_max,
    };
  }, [
    limitations,
    currencyList,
    currency,
    cardList,
    selectedBank,
    card,
    payType,
    getCurrrentUser,
  ]);

  useEffect(() => {
    if (!amountLimits) {
      return;
    }
    setCheckCard(true);
    const { min, max } = amountLimits;
    setValue("min_amount", min);
    setValue("max_amount", max);
  }, [amountLimits, setValue]);

  useEffect(() => {
    const assetCode = currencyList?.[currency]?.currency;
    const selectedCard = cardList[card];
    const cardNumber = selectedCard?.card_number;
    if (
      !assetCode ||
      !payType ||
      (payType === PAYMENT_OPTIONS.Bank && !selectedBank.swift) ||
      (payType === PAYMENT_OPTIONS.Card && !cardNumber)
    ) {
      return;
    }

    getDailyAmount({
      asset_code: assetCode,
      channel_name: payType,
      card_number: PAYMENT_OPTIONS.Card ? cardNumber : undefined,
      swift: PAYMENT_OPTIONS.Bank ? selectedBank.swift : undefined,
    });

    let result = 0;
    if (amountLimits?.min) result = amount - amountLimits?.min;
    else result = amount;

    if (result > 0) {
      setError("amount", {
        type: "validate",
        message: "",
      });
    }
  }, [card, cardList, payOptions, currencyList, payType, selectedBank]);

  useEffect(() => {
    setValue("card", payType === PAYMENT_OPTIONS.Card ? -1 : 0);
    // setValue("amount", -1);
    if (payType === PAYMENT_OPTIONS.Card) {
      getCards();
    }
    if (payType === PAYMENT_OPTIONS.Bank && currencyItem) {
      getPlaformRequisites({
        asset_id: currencyItem?.currency_id,
      });
      setStep(1);
    } else {
      setSelctedBank({});
    }
  }, [payType]);

  useEffect(() => {
    if (payType === PAYMENT_OPTIONS.Card && card < 0) {
      if (amount || !amount) {
        setError("card", {
          type: "custom",
          message: `${String(L.translate("Errors.choose_the_card"))}`,
        });
      }
    }
    if (!amount && Number(amount) < 0) return;
    // const assetCode = selectWallet?.asset?.code;
    // const fiatCode = assetCode?.endsWith('b')
    // 	? assetCode.slice(0, assetCode.length - 1)
    // 	: assetCode;
    // const fiatWallet = walletsList?.find((w) => w.asset.code === fiatCode);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const params: any = {
      amount,
      asset_id: String(currencyItem?.currency_id),
      channel_name: String(payType),
    };

    if (payType === PAYMENT_OPTIONS.Bank) {
      params.swift = selectedBank?.swift;
    }
    if (selectedBank?.id) {
      const newTypingTimeout: NodeJS.Timeout = setTimeout(() => {
        getDepositFee(params);
      }, 700);

      setTypingTimeout(newTypingTimeout);
    }
  }, [amount]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const gotNewCard = (cardData: any) => {
    /*
    const newCard = {
      id: -1,
      bank_name: cardData.bank_name,
      card_number: cardData.card_number,
      card_number_last4: cardData.card_number.substr(
        cardData.card_number.length - 4
      ),
      holder_name: cardData.holder_name,
      expiry_month: cardData.expiry_month,
      expiry_year: cardData.expiry_year,
    };
    */

    setCardList([...cardList, cardData]);
    setValue("card", [...cardList, cardData].length - 1);
  };

  const onAddCard = () => {
    openModal("ADD_PAYMENT_CARD", { type: "online", callback: gotNewCard });
  };

  const { mutate: getWallets, isLoading } = useMutation(
    client.balance.getWallets,
    {
      onSuccess: (data) => {
        const dataSource = data.wallets.map((item, index) => ({
          key: index,
          wallet_id: item.id,
          currency: item.asset.code,
          amount: item.total,
          currency_id: item.asset.id,
          name: item.asset.name,
          img_path: item.asset.img_path,
          vip_deposit_max: item.asset.vip_deposit_max,
          vip_deposit_min: item.asset.vip_deposit_min,
          vip_withdraw_max: item.asset.vip_withdraw_max,
          vip_withdraw_min: item.asset.vip_withdraw_min,
          deposit_max: item.asset.deposit_max,
          deposit_min: item.asset.deposit_min,
          withdraw_max: item.asset.withdraw_max,
          withdraw_min: item.asset.withdraw_min,
          depositable: item.asset.depositable,
          withdrawable: item.asset.withdrawable,
        }));
        setCurrencyList(dataSource.filter((item) => item.depositable === 1));

        if (dataSource.length > 0) {
          if (selectedCurrencyIdx < 0) {
            setSelectedCurrencyIdx(0);
            setValue("currency", 0);

            getAvailablePaymentMethods({
              code: dataSource[0].currency,
              type: PAYMENT_TYPE.Deposit,
            });

            setMaxAmount(Number(dataSource[0].amount));
          } else {
            setMaxAmount(Number(dataSource[selectedCurrencyIdx].amount));

            getAvailablePaymentMethods({
              code: dataSource[selectedCurrencyIdx].currency,
              type: PAYMENT_TYPE.Deposit,
            });
          }
        }
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getDepositFee } = useMutation(
    client.payment.getPaymentDepositFee,
    {
      onSuccess: (data) => {
        setFee(data.deposit_fee);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const {
    mutate: getAvailablePaymentMethods,
    isLoading: isLoadingPaymentMethods,
  } = useMutation(client.payment.getAvailablePaymentMethods, {
    onSuccess: (data) => {
      data.length > 0 && setValue("payType", "");
      setPayOptions(data);
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: getCards, isLoading: isLoadingCards } = useMutation(
    client.bankCards.all,
    {
      onSuccess: (data) => {
        setCardList(data);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getPlaformRequisites, isLoading: isLoadingRequisites } =
    useMutation(client.payment.getPaymentPlatformRequisites, {
      onSuccess: (data) => {
        const sortBank = data?.sort(
          (a: any, b: any) => b.is_partner - a.is_partner
        );

        setTransferBanks(sortBank);
        // openModal("DEPOSIT_BANK_CONFIRMATION", {
        //   amount: amount,
        //   currency: currencyItem?.currency,
        //   info: [
        //     {
        //       name: String(L.translate("Bank.bank_name")),
        //       value: data.beneficiary_bank,
        //     },
        //     {
        //       name: String(L.translate("Bank.beneficiary_name")),
        //       value: data.beneficiary_name,
        //     },
        //     {
        //       name: String(L.translate("Bank.beneficiary_address")),
        //       value: data.beneficiary_bank_address,
        //     },
        //     {
        //       name: "SWIFT",
        //       value: data.swift,
        //     },
        //     {
        //       name: String(L.translate("Bank.iban")),
        //       value: data.iban,
        //     },
        //     {
        //       name: String(L.translate("Bank.reference_code")),
        //       value: getCurrrentUser().payment_reference,
        //     },
        //     {
        //       name: String(L.translate("Bank.invoice_id")),
        //       value: data.invoice_id,
        //     },
        //     {
        //       name: String(L.translate("Bank.note")),
        //       value: data.note,
        //     },
        //   ],
        // });
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  const { mutate: depositPaypal, isLoading: isLoadingPaypal } = useMutation(
    client.paypal.deposit,
    {
      onSuccess: (data) => {
        debugger;
        //navigate(routes.proceed, { state: { url: data.url.ready } });
        // window.open(data.url.ready, "_blank");
        const newWindow = window.open(data.url.ready, "_blank");
        if (!newWindow) {
          window.location.href = data.url.ready;
        }
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "unsupported_paypal_currency"
            ) {
              setAlertText(
                String(L.translate("Errors.unsupported_paypal_currency"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "overload_daily_limit"
            ) {
              setAlertText(String(L.translate("Errors.overload_daily_limit")));
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "too_small_amount_for_a_deposit"
            ) {
              setAlertText(
                String(L.translate("Errors.too_small_amount_for_a_deposit"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "too_high_amount_for_a_deposit"
            ) {
              setAlertText(
                String(L.translate("Errors.too_high_amount_for_a_deposit"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "server_exception"
            ) {
              setAlertText(String(L.translate("Errors.server_exception")));
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: depositBankTransfer, isLoading: isLoadingBankTransfer } =
    useMutation(client.bankTransfer.deposit, {
      onSuccess: (data) => {
        if (currencyItem?.currency_id) {
          openModal("DEPOSIT_BANK_CONFIRMATION", {
            amount: amount,
            currency: currencyItem?.currency,
            info: [
              {
                name: String(L.translate("Bank.bank_name")),
                value: selectedBank.beneficiary_bank,
              },
              {
                name: String(L.translate("Bank.beneficiary_name")),
                value: selectedBank.beneficiary_name,
              },
              {
                name: String(L.translate("Bank.beneficiary_address")),
                value: selectedBank.beneficiary_bank_address,
              },
              {
                name: "SWIFT",
                value: selectedBank.swift,
              },
              {
                name: String(L.translate("Bank.iban")),
                value: selectedBank.iban,
              },
              {
                name: String(L.translate("Bank.reference_code")),
                value: getCurrrentUser().payment_reference,
              },
              {
                name: String(L.translate("Bank.invoice_id")),
                value: selectedBank.invoice_id,
              },
              {
                name: String(L.translate("Bank.note")),
                value: selectedBank.note,
              },
            ],
          });
        }

        // &&
        // getPlaformRequisites({
        //   asset_id: currencyItem?.currency_id,
        //   invoice_id: data.id,
        // });
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] ===
                "platform_bank_account_not_found"
            ) {
              setAlertText(
                String(L.translate("Errors.platform_bank_account_not_found"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] ===
                "unsupported_bank_transfer_currency"
            ) {
              setAlertText(
                String(L.translate("Errors.unsupported_bank_transfer_currency"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "overload_daily_limit"
            ) {
              setAlertText(String(L.translate("Errors.overload_daily_limit")));
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "too_small_amount_for_a_deposit"
            ) {
              setAlertText(
                String(L.translate("Errors.too_small_amount_for_a_deposit"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "too_high_amount_for_a_deposit"
            ) {
              setAlertText(
                String(L.translate("Errors.too_high_amount_for_a_deposit"))
              );
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  const { mutate: depositCardOnline, isLoading: isLoadingCardOnline } =
    useMutation(client.cartuBank.depositWithCardOnline, {
      onSuccess: (data) => {
        //navigate(routes.proceed, { state: { url: data.proceedUrl } });
        const newWindow = window.open(data.proceedUrl, "_blank");
        if (!newWindow) {
          window.location.href = data.proceedUrl;
        }
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "unsupported_bank_currency"
            ) {
              setAlertText(
                String(L.translate("Errors.unsupported_bank_currency"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "too_small_amount_for_a_deposit"
            ) {
              setAlertText(
                String(L.translate("Errors.too_small_amount_for_a_deposit"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "too_high_amount_for_a_deposit"
            ) {
              setAlertText(
                String(L.translate("Errors.too_high_amount_for_a_deposit"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "overload_daily_limit"
            ) {
              setAlertText(String(L.translate("Errors.overload_daily_limit")));
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0].includes("curl_error28")
            ) {
              setAlertText(String(L.translate("Errors.invalid_cvv_code")));
              setIsAlertOpened(true);
              return;
            }

            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  const { mutate: depositCardSaved, isLoading: isLoadingCardSaved } =
    useMutation(client.cartuBank.depositWithCardSaved, {
      onSuccess: (data) => {
        const newWindow = window.open(data.proceedUrl, "_blank");
        if (!newWindow) {
          window.location.href = data.proceedUrl;
        }
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "unsupported_bank_currency"
            ) {
              setAlertText(
                String(L.translate("Errors.unsupported_bank_currency"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "too_small_amount_for_a_deposit"
            ) {
              setAlertText(
                String(L.translate("Errors.too_small_amount_for_a_deposit"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "too_high_amount_for_a_deposit"
            ) {
              setAlertText(
                String(L.translate("Errors.too_high_amount_for_a_deposit"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "overload_daily_limit"
            ) {
              setAlertText(String(L.translate("Errors.overload_daily_limit")));
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0].includes("curl_error28")
            ) {
              setAlertText(String(L.translate("Errors.invalid_cvv_code")));
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  const gotBankCardVCC = (
    cvv: string,
    proceedCardInfo: BankCard,
    asset_id: number,
    amount: number
  ) => {
    debugger;
    const settingsBrowser = {
      browserJavaEnabled: false,
      browserLanguage: "en-US",
      browserColorDepth: "24",
      browserScreenHeight: 1080,
      browserScreenWidth: 1920,
      browserTZ: "-180",
      browserUserAgent:
        "Mozilla/5.0+(Windows+NT+10.0;+Win64;+x64;+rv:132.0)+Gecko/20100101+Firefox/132.0",
      challengeWindowSize: "03",
    };
    if (proceedCardInfo.id === -1) {
      //deposit with online card
      depositCardOnline({
        asset_id,
        amount,
        card_number: proceedCardInfo.card_number,
        expiry_month: proceedCardInfo.expiry_month!,
        expiry_year: proceedCardInfo.expiry_year!,
        cvc: cvv,
      });
    } else {
      //deposit with saved card
      depositCardSaved({
        asset_id,
        amount,
        card_id: proceedCardInfo.id,
        cvc: cvv,
        ...settingsBrowser,
      });
    }
  };

  const { mutate: getDailyAmount, isLoading: isLoadingDailyAmount } =
    useMutation(client.payment.calculateDailyDepositAmount, {
      onSuccess: (data) => {
        setDailyAmount(data.residual_amount ?? 0);
        /*
        if (data.residual_amount) {
          setValue("max_amount", data.residual_amount);
        }
        */
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "overload_daily_limit"
            ) {
              setAlertText(String(L.translate("Errors.overload_daily_limit")));
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  const { mutate: getLimitations } = useMutation(
    client.payment.getLimitations,
    {
      onSuccess: (data) => {
        setLimitations(data);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onConfirmedPaypalDeposit = (asset_id: number, amount: number) => {
    depositPaypal({ amount, asset_id });
  };

  const onSubmit = (data: FormData) => {
    if (amountLimits && data.amount < amountLimits.min) {
      setError("amount", {
        type: "custom",
        message: String(L.translate("less_than_min_amount")),
      });
      return;
    }

    const asset_id = currencyList[data.currency].currency_id;
    const cardNumber = cardList[data.card]?.card_number;

    if (data.payType === PAYMENT_OPTIONS.Bank) {
      depositBankTransfer({
        amount: data.amount,
        asset_id,
        platform_bank_account_id: selectedBank?.id,
      });
    } else if (data.payType === PAYMENT_OPTIONS.Paypal) {
      openModal("DEPOSIT_PAYPAL_CONFIRMATION", {
        asset_id,
        amount: data.amount,
        currency: currencyItem?.currency.toUpperCase(),
        channel_name: data.payType,
        callback: onConfirmedPaypalDeposit,
      });
    } else if (data.payType === PAYMENT_OPTIONS.Card) {
      const cardInfo = cardList[data.card];
      openModal("DEPOSIT_CARD_CONFIRMATION", {
        asset_id,
        holder_name: cardInfo.holder_name,
        amount: data.amount,
        card_no: cardList[data.card].card_number,
        currency: currencyItem?.currency.toUpperCase(),
        channel_name: payType,
        card_number: cardNumber,
        callback: gotBankCardVCC,
        card_info: cardInfo,
      });
    }
  };

  const GoBack = () => {
    if (!selectedBank || (selectedBank && !continueBtn)) {
      setValue("payType", "");
    }
    setStep(0);
    // if (selectedBank) {
    // 	setActiveView('currency');
    // 	return;
    // }
    // setSelectedPayment('');
    // setTimeout(() => {
    // 	setActiveView('currency');
    // }, 100);
  };

  const isValidBank = selectedBank?.id ? true : false;

  const handleContinue = () => {
    if (!selectedBank) {
      return;
    }
    setContinueBtn(true);
    setStep(0);
    setAmountValue(0);
    // onChange(selectedBank);
    // setActiveView('currency');
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4 sm:pr-11">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          {L.translate("DepositFiat.deposit_fiat_page_title")}
        </p>
        {payType === PAYMENT_OPTIONS.Bank && step === 1 ? (
          <div className="relative w-full gap-2 mt-2 sm:mt-4 bg-white rounded-[12px] items-center justify-center content-item">
            <button onClick={GoBack} className="back-step">
              <span className="back-step__arrow">
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 7.27393L16.25 7.27393"
                    stroke="#011E26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M7.29883 13.2985L1.24883 7.27451L7.29883 1.24951"
                    stroke="#011E26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
              <span className="back-step__text">Back </span>
            </button>
            <div className="operation-form operation-form--wider">
              <div className="operation-group">
                <div className="add-card-list add-card-list--type2">
                  {transferBanks?.map((item, index) => (
                    <div key={index} className="add-card-item">
                      <div className="radio radio--type2">
                        <label className="radio__label">
                          <input
                            type="radio"
                            className="hidden_btn"
                            name="tokens-group"
                            checked={item.id === selectedBank?.id}
                            onChange={() => setSelctedBank(item)}
                          />
                          <span className="radio__item" />
                          <span className="radio__text-group">
                            <span className="radio__text radio__text--regular">
                              {item.is_partner
                                ? item.beneficiary_bank
                                : L.translate("DepositFiat.other_bank")}
                            </span>
                            {item.is_partner ? (
                              <span className="radio__text radio__text--extra">
                                {" "}
                                Swift: {item.swift}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </label>
                      </div>
                      {!!item.is_partner && item?.bank_icon && (
                        <div className="add-card-item__logo add-card-item__logo--bank ">
                          <img src={item?.bank_icon || ""} alt="visa" />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-submit">
                <Button
                  onClick={handleContinue}
                  variant="validate"
                  type="submit"
                  className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] w-full"
                  disabled={!isValidBank}
                >
                  {String(L.translate("DepositFiat.continue"))}
                </Button>
              </div>
              {/* <button className="button button--full-width">Continue</button> */}
            </div>
          </div>
        ) : (
          <div className="relative w-full flex flex-col gap-2 mt-2 sm:mt-4 bg-white rounded-[12px] items-center justify-center">
            <button
              onClick={() => navigate(-1)}
              className="back-step left-4 top-4 start ml-10 mt-6 mr-auto"
            >
              <span className="back-step__arrow">
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 7.27393L16.25 7.27393"
                    stroke="#011E26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M7.29883 13.2985L1.24883 7.27451L7.29883 1.24951"
                    stroke="#011E26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
              <span className="back-step__text text-[20px]">Back </span>
            </button>{" "}
            {(isLoading || isLoadingPaymentMethods || isLoadingCards) && (
              <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
            )}
            <div className="flex flex-col w-full sm:w-[548px] p-4">
              <form
                className="h-full w-full flex flex-col gap-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <p className="font-manrope text-[20px] font-medium text-primary">
                  1. {L.translate("DepositFiat.select_currency_title")}
                </p>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <DropdownCurrency
                      label={String(
                        L.translate("DepositFiat.currency_to_deposit")
                      )}
                      placeholder="Currency"
                      className="w-full"
                      data={currencyList}
                      error={
                        errors.currency && (errors.currency.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                {payOptions.length > 0 ? (
                  <div className="flex flex-col w-full gap-8">
                    <div className="w-full flex flex-row justify-between">
                      <Controller
                        name="payType"
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            label={String(
                              L.translate("DepositFiat.deposit_method")
                            )}
                            options={payOptions}
                            className="w-full"
                            error={
                              errors.payType &&
                              (errors.payType.message as string)
                            }
                            {...field}
                          />
                        )}
                      />
                      <div className="w-full flex flex-col items-start">
                        <p className="font-manrope text-[20px] text-primary font-medium">
                          {String(L.translate("DepositFiat.limits"))}
                        </p>
                        <p className="font-manrope text-[14px] text-secondary font-light mt-2">
                          {String(L.translate("DepositFiat.dep_max_min"))}
                        </p>
                        <p className="font-manrope text-[16px] text-primary font-medium">
                          {amountLimits &&
                            currencyItem &&
                            `${getCurrencySymbol(
                              currencyItem?.currency,
                              amountLimits.min
                            )} / ${getCurrencySymbol(
                              currencyItem?.currency,
                              amountLimits.max
                            )}`}
                        </p>
                      </div>
                    </div>
                    {payType ? (
                      <>
                        {" "}
                        {payType === PAYMENT_OPTIONS.Card && (
                          <Controller
                            name="card"
                            control={control}
                            render={({ field }) => (
                              <DropdownCard
                                label={String(L.translate("Bank.choose_card"))}
                                placeholder={String(
                                  L.translate("Bank.choose_card")
                                )}
                                className="w-full"
                                data={cardList}
                                onAddCard={onAddCard}
                                setError={() => {
                                  setError("card", {
                                    type: "custom",
                                    message: `${String(
                                      L.translate("Errors.choose_the_card")
                                    )}`,
                                  });
                                }}
                                error={
                                  errors.card && (errors.card.message as string)
                                }
                                {...field}
                              />
                            )}
                          />
                        )}
                        <p className="font-manrope text-[20px] font-medium text-primary">
                          2. {String(L.translate("DepositFiat.enter_amount"))}
                        </p>
                        <Controller
                          name="amount"
                          control={control}
                          render={({ field }) => (
                            <InputWithMax
                              label={String(L.translate("DepositFiat.amount"))}
                              placeholder={String(
                                L.translate("DepositFiat.enter_amount")
                              )}
                              className="w-full"
                              max_limit={amountLimits?.max || 0}
                              real_max_limit={maxAmount}
                              isDeposit={true}
                              showBalance={true}
                              currency={currencyItem?.currency}
                              error={
                                errors.amount &&
                                (errors.amount.message as string)
                              }
                              {...field}
                            />
                          )}
                        />
                        {payType === PAYMENT_OPTIONS.Bank ? (
                          <div className="form-block">
                            <div className="operation-info">
                              <span className="input-name">
                                {L.translate("DepositFiat.you_receive")}:
                              </span>
                              <span className="operation-info__sum">
                                {" "}
                                {(Number(recive) <= 0
                                  ? 0
                                  : convertExponentialToDecimal(
                                      Number(recive)
                                    )) || "0"}{" "}
                                {currencyItem?.currency?.toUpperCase()}
                              </span>
                              <div className="operation-info">
                                <div className="operation-info__line operation-info__line--flex ">
                                  <span className="operation-info__label">
                                    {" "}
                                    {L.translate("DepositFiat.transaction_fee")}
                                    :
                                  </span>
                                  <span className="operation-info__value operation-info__value--type2">
                                    {!amount ? "0" : fee}{" "}
                                    {currencyItem?.currency?.toUpperCase()}
                                  </span>
                                </div>
                                <div className="operation-info__line operation-info__line--flex operation-info__line--mob-column">
                                  <span className="operation-info__label">
                                    {L.translate("DepositFiat.payment_method")}:
                                  </span>
                                  <span className="operation-payment operation-payment--full">
                                    <span className="operation-payment__name operation-payment__name--type2">
                                      {selectedBank?.beneficiary_bank}
                                    </span>
                                    <button
                                      type="button"
                                      onClick={() => setStep(1)}
                                      className="d-flex operation-payment__link"
                                    >
                                      {L.translate("DepositFiat.change_bank")}
                                      <span className="d-flex operation-payment__link-arrow">
                                        <svg
                                          width="12"
                                          height="10"
                                          viewBox="0 0 12 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11.166 5.18282L1.16602 5.18282"
                                            stroke="#232800"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M7.13281 9.19911L11.1661 5.18311L7.13281 1.16644"
                                            stroke="#232800"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p className="font-manrope text-[20px] font-medium text-secondary py-8">
                    {String(L.translate("DepositFiat.message"))}
                  </p>
                )}
                {payOptions.length > 0 && (
                  <div className="flex flex-row items-center justify-center">
                    <Button
                      variant="validate"
                      type="submit"
                      className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] w-full"
                      disabled={
                        !isValid ||
                        isLoadingPaypal ||
                        isLoadingBankTransfer ||
                        isLoadingCardOnline ||
                        isLoadingCardSaved ||
                        isLoadingRequisites
                      }
                      isLoading={
                        isLoadingPaypal ||
                        isLoadingBankTransfer ||
                        isLoadingCardOnline ||
                        isLoadingCardSaved ||
                        isLoadingRequisites
                      }
                    >
                      {String(L.translate("DepositFiat.continue"))}
                    </Button>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DepositPage;
