export default function HeaderLogo() {
  return (
    <>
      <svg
        width="113"
        // height="512"
        viewBox="0 0 1616 512"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="256" cy="256" r="201" fill="#C3E600" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M477 256C477 378.055 378.055 477 256 477C133.945 477 35 378.055 35 256C35 133.945 133.945 35 256 35C378.055 35 477 133.945 477 256ZM256 449.705C362.98 449.705 449.705 362.98 449.705 256C449.705 149.02 362.98 62.2948 256 62.2948C149.02 62.2948 62.2948 149.02 62.2948 256C62.2948 362.98 149.02 449.705 256 449.705Z"
          fill="#232800"
        />
        <path
          d="M239.271 143.519L112.922 242.352C105.218 217.919 106.142 178.914 136.255 152.323C166.367 125.733 208.234 124.149 239.271 143.519Z"
          fill="#232800"
        />
        <path
          d="M214.841 313.671V181.379C204.936 184.681 171.037 200.97 168.176 245.654C166.16 277.131 190.184 305.967 214.841 313.671Z"
          fill="#232800"
        />
        <path
          d="M273.609 181.38L237.51 259.522C226.724 249.837 217.391 228.573 228.485 206.033C239.579 183.493 263.19 180.206 273.609 181.38Z"
          fill="#232800"
        />
        <path
          d="M306.187 171.915H230.466C234.061 162.816 246.843 144.752 269.207 145.28C291.571 145.808 303.179 163.257 306.187 171.915Z"
          fill="#232800"
        />
        <path
          d="M366.06 216.379H267.006C270.822 204.859 285.98 181.688 316.093 181.16C346.205 180.631 361.951 204.419 366.06 216.379Z"
          fill="#232800"
        />
        <path
          d="M318.074 285.496L249.617 253.578C259.962 241.472 281.05 237.686 298.483 247.195C315.917 256.704 318.808 276.691 318.074 285.496Z"
          fill="#232800"
        />
        <path
          d="M334.362 304.866L245.214 263.044C241.692 273.389 249.396 302.885 270.968 313.891C302.489 328.859 326.364 314.111 334.362 304.866Z"
          fill="#232800"
        />
        <path
          d="M215.278 392.254L141.978 235.309C126.056 250.791 100.023 293.552 123.268 340.746C146.513 387.94 194.293 394.749 215.278 392.254Z"
          fill="#232800"
        />
        <path
          d="M380.808 345.589L197.228 320.055C200.75 345.809 231.347 395.756 286.817 398.638C341.054 401.455 372.077 364.446 380.808 345.589Z"
          fill="#232800"
        />
        <path
          d="M334.583 267.886L325.117 288.578L339.865 295.622C345.677 283.471 338.765 272.069 334.583 267.886Z"
          fill="#232800"
        />
        <path
          d="M349.771 247.856V224.743C366.06 224.743 349.771 224.743 366.06 224.743C365.884 239.183 355.127 246.168 349.771 247.856Z"
          fill="#232800"
        />
        <path
          d="M398.857 192.165L366.279 187.543C365.252 197.228 368.481 207.354 379.707 212.857C392.914 209.863 397.977 197.815 398.857 192.165Z"
          fill="#232800"
        />
        <path
          d="M399.958 183.801L325.117 173.235C329.74 164.577 344.62 148.23 367.16 152.104C389.7 155.978 398.417 174.849 399.958 183.801Z"
          fill="#232800"
        />
        <path
          d="M356.375 137.576L287.918 105.218C287.037 113.803 288.798 132.953 306.628 143.299C329.199 156.396 349.037 145.207 356.375 137.576Z"
          fill="#232800"
        />
        <path
          d="M251.598 106.979L215.498 101.696C219.548 123.532 239.491 132.073 248.956 133.614L286.597 138.676C281.314 115.256 261.063 107.786 251.598 106.979Z"
          fill="#232800"
        />
        <path
          d="M661.821 230.807H627.709V383.975H589.25V230.807H567.512V199.705H589.25V186.663C589.25 165.482 594.823 150.098 605.971 140.511C617.342 130.702 635.066 125.797 659.145 125.797V157.567C647.552 157.567 639.414 159.797 634.732 164.256C630.05 168.492 627.709 175.961 627.709 186.663V199.705H661.821V230.807Z"
          fill="#232800"
        />
        <path
          d="M676.211 291.004C676.211 272.499 680.002 256.112 687.582 241.843C695.385 227.574 705.864 216.538 719.018 208.735C732.395 200.709 747.11 196.695 763.163 196.695C777.655 196.695 790.252 199.594 800.953 205.391C811.878 210.964 820.573 217.987 827.039 226.46V199.705H865.498V383.975H827.039V356.552C820.573 365.247 811.766 372.493 800.619 378.29C789.471 384.087 776.763 386.985 762.494 386.985C746.664 386.985 732.172 382.972 719.018 374.946C705.864 366.697 695.385 355.326 687.582 340.834C680.002 326.119 676.211 309.509 676.211 291.004ZM827.039 291.673C827.039 278.965 824.363 267.929 819.012 258.565C813.885 249.201 807.084 242.066 798.612 237.161C790.14 232.256 780.999 229.804 771.189 229.804C761.379 229.804 752.238 232.256 743.766 237.161C735.294 241.843 728.382 248.866 723.031 258.23C717.904 267.371 715.34 278.296 715.34 291.004C715.34 303.713 717.904 314.86 723.031 324.447C728.382 334.034 735.294 341.392 743.766 346.519C752.461 351.424 761.602 353.877 771.189 353.877C780.999 353.877 790.14 351.424 798.612 346.519C807.084 341.615 813.885 334.48 819.012 325.116C824.363 315.529 827.039 304.381 827.039 291.673Z"
          fill="#232800"
        />
        <path
          d="M975.771 386.985C961.279 386.985 948.236 384.421 936.642 379.293C925.272 373.943 916.242 366.808 909.554 357.89C902.865 348.749 899.298 338.605 898.852 327.457H938.315C938.983 335.26 942.662 341.837 949.351 347.188C956.262 352.316 964.846 354.88 975.102 354.88C985.803 354.88 994.053 352.874 999.849 348.86C1005.87 344.624 1008.88 339.273 1008.88 332.808C1008.88 325.896 1005.53 320.768 998.846 317.424C992.381 314.08 982.013 310.401 967.744 306.388C953.921 302.598 942.662 298.919 933.967 295.352C925.272 291.785 917.691 286.322 911.226 278.965C904.983 271.607 901.862 261.909 901.862 249.87C901.862 240.06 904.76 231.142 910.557 223.115C916.354 214.866 924.603 208.4 935.305 203.718C946.229 199.036 958.715 196.695 972.761 196.695C993.718 196.695 1010.55 202.046 1023.26 212.748C1036.19 223.227 1043.1 237.607 1043.99 255.889H1005.87C1005.2 247.64 1001.86 241.063 995.836 236.158C989.817 231.253 981.679 228.801 971.423 228.801C961.39 228.801 953.698 230.696 948.347 234.486C942.997 238.276 940.321 243.293 940.321 249.535C940.321 254.44 942.105 258.565 945.672 261.909C949.239 265.253 953.587 267.929 958.715 269.935C963.843 271.719 971.423 274.06 981.456 276.958C994.833 280.526 1005.76 284.204 1014.23 287.994C1022.92 291.562 1030.39 296.913 1036.64 304.047C1042.88 311.182 1046.11 320.657 1046.34 332.473C1046.34 342.952 1043.44 352.316 1037.64 360.565C1031.84 368.815 1023.59 375.28 1012.89 379.962C1002.41 384.644 990.04 386.985 975.771 386.985Z"
          fill="#232800"
        />
        <path
          d="M1125.32 230.807V332.808C1125.32 339.719 1126.88 344.736 1130 347.857C1133.35 350.756 1138.92 352.205 1146.72 352.205H1170.13V383.975H1140.04C1122.87 383.975 1109.71 379.962 1100.57 371.936C1091.43 363.91 1086.86 350.867 1086.86 332.808V230.807H1065.12V199.705H1086.86V153.889H1125.32V199.705H1170.13V230.807H1125.32Z"
          fill="#232800"
        />
        <path
          d="M1278.51 386.985C1261.12 386.985 1245.4 383.084 1231.36 375.28C1217.31 367.254 1206.28 356.106 1198.25 341.837C1190.22 327.346 1186.21 310.624 1186.21 291.673C1186.21 272.945 1190.33 256.335 1198.58 241.843C1206.83 227.351 1218.09 216.204 1232.36 208.4C1246.63 200.597 1262.57 196.695 1280.18 196.695C1297.8 196.695 1313.74 200.597 1328.01 208.4C1342.28 216.204 1353.54 227.351 1361.78 241.843C1370.03 256.335 1374.16 272.945 1374.16 291.673C1374.16 310.401 1369.92 327.011 1361.45 341.503C1352.98 355.995 1341.38 367.254 1326.67 375.28C1312.18 383.084 1296.13 386.985 1278.51 386.985ZM1278.51 353.877C1288.32 353.877 1297.46 351.536 1305.94 346.854C1314.63 342.172 1321.65 335.149 1327 325.785C1332.35 316.421 1335.03 305.05 1335.03 291.673C1335.03 278.296 1332.47 267.037 1327.34 257.896C1322.21 248.532 1315.41 241.509 1306.94 236.827C1298.47 232.145 1289.33 229.804 1279.52 229.804C1269.71 229.804 1260.56 232.145 1252.09 236.827C1243.84 241.509 1237.27 248.532 1232.36 257.896C1227.46 267.037 1225 278.296 1225 291.673C1225 311.516 1230.02 326.9 1240.05 337.824C1250.31 348.526 1263.13 353.877 1278.51 353.877Z"
          fill="#232800"
        />
        <path
          d="M1485.35 387C1467.96 387 1452.25 383.098 1438.2 375.295C1424.15 367.269 1413.12 356.121 1405.09 341.852C1397.06 327.36 1393.05 310.639 1393.05 291.688C1393.05 272.96 1397.18 256.35 1405.43 241.858C1413.67 227.366 1424.93 216.219 1439.2 208.415C1453.47 200.612 1469.41 196.71 1487.03 196.71C1504.64 196.71 1520.58 200.612 1534.85 208.415C1549.12 216.219 1560.38 227.366 1568.63 241.858C1576.88 256.35 1581 272.96 1581 291.688C1581 310.416 1576.76 327.026 1568.29 341.518C1559.82 356.01 1548.23 367.269 1533.51 375.295C1519.02 383.098 1502.97 387 1485.35 387ZM1485.35 353.892C1495.16 353.892 1504.3 351.551 1512.78 346.869C1521.47 342.187 1528.49 335.164 1533.85 325.8C1539.2 316.436 1541.87 305.065 1541.87 291.688C1541.87 278.311 1539.31 267.052 1534.18 257.911C1529.05 248.547 1522.25 241.524 1513.78 236.842C1505.31 232.16 1496.17 229.819 1486.36 229.819C1476.55 229.819 1467.41 232.16 1458.93 236.842C1450.68 241.524 1444.11 248.547 1439.2 257.911C1434.3 267.052 1431.84 278.311 1431.84 291.688C1431.84 311.531 1436.86 326.914 1446.89 337.839C1457.15 348.541 1469.97 353.892 1485.35 353.892Z"
          fill="#232800"
        />
      </svg>
    </>
  );
}
