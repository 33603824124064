import { createContext } from "react";

interface IGlobalContext {
  isAlertOpened: boolean;
  alertTitle: string;
  alertText: string;
  language: string;
  setLanguage: (text: string) => void;
  setIsAlertOpened: (opened: boolean) => void;
  setAlertText: (text: string) => void;
  setAlertTitle: (text: string) => void;
}

const defaultState = {
  isAlertOpened: false,
  alertText: "",
  alertTitle: "",
  language: "EN",
  setLanguage: (text: string) => {},
  setIsAlertOpened: (opened: boolean) => {},
  setAlertText: (text: string) => {},
  setAlertTitle: (text: string) => {},
};

const GlobalContext = createContext<IGlobalContext>(defaultState);
export default GlobalContext;
