export const InfoCircle: React.FC<React.SVGAttributes<{}>> = (props) => {
  const { stroke } = props;

  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50065 17.4163C13.8548 17.4163 17.4173 13.8538 17.4173 9.49967C17.4173 5.14551 13.8548 1.58301 9.50065 1.58301C5.14648 1.58301 1.58398 5.14551 1.58398 9.49967C1.58398 13.8538 5.14648 17.4163 9.50065 17.4163Z"
        stroke={stroke || "#F9A000"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 6.33301V10.2913"
        stroke={stroke || "#F9A000"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.49609 12.667H9.5032"
        stroke={stroke || "#F9A000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
