import { Menu } from "@headlessui/react";
import { forwardRef, useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Transition } from "./transition";
import { Fragment } from "react";
import { DropdownIcon } from "../icons/dropdown-icon";
import { GreenTickIcon } from "../icons/green-tick-icon";
import { animate } from "framer-motion";
import { CountryResponse } from "src/api/types";
import { getCurrencyInfo } from "src/lib/utils";
import L from "i18n-react";

type DropdownCurrencyProps = {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  data: any[];
  value: number;
  onChange: any;
  showBalance?: boolean;
};

const DropdownCurrency = forwardRef<HTMLDivElement, DropdownCurrencyProps>(
  (
    {
      label,
      error,
      className,
      placeholder = "",
      inputClassName = "bg-transparent",
      data = [],
      value,
      onChange,
      showBalance = false,
      ...props
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const selectedIDRef = useRef(-1);

    const [selectedId, setSelectedId] = useState(value);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isContainerOpened, setIsContainerOpened] = useState(false);

    useEffect(() => {
      setSelectedId(value);
    }, [value]);

    useEffect(() => {
      updateWindowSize();
      window.addEventListener("resize", updateWindowSize);
      btnRef.current?.addEventListener("click", cickedMenuButton);
      window.addEventListener("click", handler);

      return () => {
        window.removeEventListener("click", handler);
        window.removeEventListener("resize", updateWindowSize);
        btnRef.current?.removeEventListener("click", cickedMenuButton);
      };
    }, []);

    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsContainerOpened(false);
      }
    };

    function updateWindowSize() {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }

    useEffect(() => {
      console.log(isContainerOpened, selectedId);
      setTimeout(() => menuRef.current?.scrollTo(0, selectedId * 40), 100);
    }, [selectedId, isContainerOpened]);

    function cickedMenuButton() {
      btnRef.current &&
        btnRef.current.ariaExpanded &&
        btnRef.current.ariaExpanded === "false" &&
        setIsContainerOpened(true);

      setTimeout(
        () => menuRef.current?.scrollTo(0, selectedIDRef.current * 40),
        100
      );
    }

    useEffect(() => {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }, [containerRef.current]);

    const selectedItem = data.find((item) => item.key === selectedId);

    return (
      <div className={className} ref={containerRef}>
        <label className="block text-[16px] font-manrope font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-16px font-manrope font-medium text-secondary dark:text-light/70">
              {label}
            </span>
          )}
          <Menu>
            <Menu.Button
              ref={btnRef}
              className={classNames(
                "relative h-[50px] sm:h-[64px] placeholder-other w-full appearance-none text-16px font-manrope font-medium rounded-[12px] border border-border bg-transparent px-4 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] dark:border-dark-600 dark:text-light dark:ring-dark-600 lg:px-5",
                error
                  ? "focus:border-[#F44336] !border-[#F44336] focus:ring-[#F44336]"
                  : "focus:border-active focus:ring-active dark:focus:border-active dark:focus:ring-active",
                inputClassName
              )}
            >
              {({ open }) => (
                <>
                  <div className="flex flex-row items-center gap-2">
                    {selectedId >= 0 &&
                      selectedItem?.currency &&
                      getCurrencyInfo(selectedItem?.currency, 34).icon}

                    <p
                      className={`${
                        selectedId < 0 ? "text-other" : "text-primary"
                      } text-left`}
                    >
                      {selectedId < 0
                        ? placeholder
                        : data?.[selectedId]?.currency?.toUpperCase()}
                    </p>
                    {selectedId >= 0 && (
                      <p className="hidden sm:block text-[16px] font-medium text-other">
                        {selectedItem?.currency &&
                          getCurrencyInfo(selectedItem?.currency, 34).descr}
                      </p>
                    )}
                  </div>
                  <span className="absolute top-0 flex h-full w-[34px] cursor-pointer items-center justify-start text-dark-900 hover:text-dark-700 right-0 dark:text-dark-800 hover:dark:text-light-900 lg:w-9">
                    <DropdownIcon
                      className={`h-auto w-4 ${
                        open ? "rotate-0" : "rotate-180"
                      }`}
                    />
                  </span>
                </>
              )}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                ref={menuRef}
                className={`max-h-[216px] scrollbar-hide overflow-y-auto flex flex-col absolute z-30 mt-2 rounded-[12px] bg-light py-1.5 text-dark shadow-card origin-top-left dark:bg-dark-250 dark:text-light`}
                style={{ width: containerWidth }}
              >
                {data.map((item) => (
                  /* Use the `active` state to conditionally style the active item. */
                  <Menu.Item key={item.key}>
                    {({ active, close }) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (item.key == selectedId) {
                            close();
                            return;
                          }

                          setSelectedId(item.key);
                          onChange(item.key);
                          selectedIDRef.current = item.key;

                          close();
                        }}
                        className="flex transition-fill-colors px-4  flex-row items-center justify-between py-2 hover:bg-light-400"
                      >
                        <div className="flex flex-row gap-4 items-center">
                          {item?.currency &&
                            getCurrencyInfo(item?.currency, 34).icon}

                          <p className="text-primary text-[16px] font-medium font-manrope">
                            {item?.currency?.toUpperCase()}
                          </p>
                        </div>

                        <GreenTickIcon
                          className={`${
                            active || selectedId === item.key
                              ? "block"
                              : "hidden"
                          } w-[20px] h-[20px]`}
                        />
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          {selectedId >= 0 && showBalance && (
            <span className="block cursor-pointer pt-2.5 text-16px font-manrope font-medium text-secondary dark:text-light/70">
              {`${String(L.translate("Balance.title"))}: `}
              <span className="text-primary font-semibold">
                {selectedItem?.amount}
              </span>
            </span>
          )}
        </label>
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[16px] text-[#F44336] font-manrope"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

DropdownCurrency.displayName = "DropdownCurrency";
export default DropdownCurrency;
