// TODO: Should be rewriten to Tailwind CSS
import { useState, useContext } from "react";
import { useMutation } from "react-query";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import { getErrorMessage } from "../../lib/utils";
import client from "../../api";
import { IBuisinessKycInput } from "../../api/types";
import Steps from "../../components/kyc/kyc-steps";
import FormStep1, {
  TFinalData as FormData1,
} from "../../components/kyc/buisiness/form-step-1";
import FormStep2, {
  TFinalData as FormData2,
} from "../../components/kyc/buisiness/form-step-2";
import FormStep3, {
  TFinalData as FormData3,
} from "../../components/kyc/buisiness/form-step-3";

const KycBuisiness = () => {
  const [step, setStep] = useState(1);
  const [formData1, setFormData1] = useState<FormData1>();
  const [formData2, setFormData2] = useState<FormData2>();

  const navigate = useNavigate();

  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);

  const handleForm1Submit = (data: FormData1) => {
    setFormData1(data);
    setStep((prev) => prev + 1);
  };

  const handleForm2Submit = (data: FormData2) => {
    setFormData2(data);
    setStep((prev) => prev + 1);
  };

  const handleForm3Submit = (formData3: FormData3) => {
    if (!formData1 || !formData2 || !formData3) {
      return;
    }
    const state: IBuisinessKycInput = {
      ...formData1,
      ...formData2,
      ...formData3,
    };
    navigate(routes.settings, {
      state,
    });
  };

  return (
    <div className="form form--kyc">
      <div className="form-header">
        <h2 className="form-title">{L.translate("KYC.buisiness_title")}</h2>
        <div className="form-text">
          <p>{L.translate("KYC.buisiness_subtitle")}</p>
        </div>
      </div>
      <div className="form-body">
        <Steps step={step} />
      </div>
      {step === 1 && <FormStep1 onSubmit={handleForm1Submit} />}
      {step === 2 && <FormStep2 onSubmit={handleForm2Submit} />}
      {step === 3 && <FormStep3 onSubmit={handleForm3Submit} />}
    </div>
  );
};

export default KycBuisiness;
