import { Fragment } from "react";

interface IKycSteps {
  step: number;
}

const steps = [1, 2, 3];

export default function KycSteps({ step }: IKycSteps) {
  return (
    <div className="kyc-step">
      {steps.map((item, idx) => (
        <Fragment key={idx}>
          <div className={`kyc-step__item ${step === item ? "active" : ""}`}>
            {item}
          </div>
          {idx !== steps.length - 1 && <div className="kyc-step__line"></div>}
        </Fragment>
      ))}
    </div>
  );
}
