import { forwardRef, useEffect, useState, useContext } from "react";
import classNames from "classnames";
import { makePlaceholder, makeSenderSecret } from "src/lib/utils";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage, convertSeconds } from "../../lib/utils";
import { SpinnerIcon } from "../icons/spinner-icon";
import GlobalContext from "src/context/global-context";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";
import L from "i18n-react";

type InputWithTimerProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  error?: string;
  className?: string;
  sender: string;
  inputClassName?: string;
  inputType: string;
  timer: number;
  value: string;
  onChange: any;
  setCode?: (value: string) => void;
  maxLength: number;
  setIsExpiredTime: (bool: boolean) => void;
};

const InputWithTimer = forwardRef<HTMLInputElement, InputWithTimerProps>(
  (
    {
      setIsExpiredTime,
      timer,
      label,
      error,
      type = "text",
      className,
      sender,
      inputType,
      inputClassName = "bg-transparent",
      value = "",
      onChange,
      maxLength,
      setCode,
      ...props
    },
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState(value ?? "");
    const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);

    const [sentCode, setSentCode] = useState(false);
    const [counter, setCounter] = useState(0);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
      setCurrentValue(value ?? "");
    }, [value]);

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (val.length > maxLength) {
        return;
      }

      const re = /^[0-9\b]+$/;
      if (val === "" || re.test(val)) {
        setCurrentValue(val);
        onChange(val);
      }
      if (inputType === "email_login" || inputType === "email") {
        if (maxLength === val.length) {
          checkEmailCode({ email: sender, code: val });
        }
      }
      if (inputType === "sms_login" || inputType === "phone") {
        if (maxLength === val.length) {
          checkSmsCode({ phone: sender, code: val });
        }
      }
    };

    useEffect(() => {
      setTimeout(() => {
        if (!sentCode) return;
        if (counter === 0) {
          setSentCode(false);
          setIsExpiredTime(true);
          return;
        }

        setCounter(counter - 1);
      }, 1000);
    }, [counter, sentCode]);

    const { mutate: checkEmailCode, isLoading: isLoadingCheckEmail } =
      useMutation(client.users.checkEmailCode, {
        onSuccess: (data) => {
          console.log(data);

          setChecked(true);
          setSentCode(false);
          setCode?.("email");
        },
        onError: (error: any) => {
          if (error.code === "ERR_NETWORK") {
            setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
          } else {
            if (error.response) {
              setAlertText(getErrorMessage(error.response.data));
            } else {
              setAlertText(
                String(L.translate("GlobalErrors.APISomethingWrong"))
              );
            }
          }
          setIsAlertOpened(true);
        },
      });

    const { mutate: checkSmsCode, isLoading: isLoadingCheckSms } = useMutation(
      client.users.checkSmsCode,
      {
        onSuccess: (data) => {
          console.log(data);

          setChecked(true);
          setSentCode(false);
          setCode?.("sms");
        },
        onError: (error: any) => {
          if (error.code === "ERR_NETWORK") {
            setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
          } else {
            if (error.response) {
              if (
                error.response.data.errors &&
                error.response.data.errors[0]
                  ?.split("_")
                  .slice(0, -1)
                  .join("_") === "seconds_left_for_next_code"
              ) {
                const currenTime = error.response.data.errors[0]
                  ?.split("_")
                  .pop();
                if (Number(currenTime) > 0) {
                  setCounter(Number(currenTime));
                  setSentCode(true);
                }
              }
              setAlertText(getErrorMessage(error.response.data));
            } else {
              setAlertText(
                String(L.translate("GlobalErrors.APISomethingWrong"))
              );
            }
          }
          setIsAlertOpened(true);
        },
      }
    );

    const { mutate: sendSMSCode, isLoading: isLoadingSMS } = useMutation(
      client.settings.sendPhoneCode,
      {
        onSuccess: (data) => {
          console.log(data);

          setCounter(timer);
          setSentCode(true);
        },
        onError: (error: any) => {
          if (error.code === "ERR_NETWORK") {
            setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
          } else {
            if (error.response) {
              if (
                error.response.data.errors &&
                error.response.data.errors[0]
                  ?.split("_")
                  .slice(0, -1)
                  .join("_") === "seconds_left_for_next_code"
              ) {
                const currenTime = error.response.data.errors[0]
                  ?.split("_")
                  .pop();
                if (Number(currenTime) > 0) {
                  setCounter(Number(currenTime));
                  setSentCode(true);
                }
              }
              setAlertText(getErrorMessage(error.response.data));
            } else {
              setAlertText(
                String(L.translate("GlobalErrors.APISomethingWrong"))
              );
            }
          }
          setIsAlertOpened(true);
        },
      }
    );

    const { mutate: sendEmailCode, isLoading: isLoadingEmail } = useMutation(
      client.settings.sendEmailCode,
      {
        onSuccess: (data) => {
          console.log(data);
          setCounter(timer);
          setSentCode(true);
        },
        onError: (error: any) => {
          if (error.code === "ERR_NETWORK") {
            setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
          } else {
            if (error.response) {
              if (error.response) {
              }
              setAlertText(getErrorMessage(error.response.data));
            } else {
              setAlertText(
                String(L.translate("GlobalErrors.APISomethingWrong"))
              );
            }
          }
          setIsAlertOpened(true);
        },
      }
    );

    const { mutate: sendEmailCodeLogin, isLoading: isLoadingEmailLogin } =
      useMutation(client.users.sendEmailCodeLogin, {
        onSuccess: (data) => {
          console.log(data);
          setCounter(timer);
          setSentCode(true);
        },
        onError: (error: any) => {
          if (error.code === "ERR_NETWORK") {
            setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
          } else {
            if (error.response) {
              if (
                error.response.data.errors &&
                error.response.data.errors[0]
                  ?.split("_")
                  .slice(0, -1)
                  .join("_") === "seconds_left_for_next_code"
              ) {
                const currenTime = error.response.data.errors[0]
                  ?.split("_")
                  .pop();
                if (Number(currenTime) > 0) {
                  setCounter(Number(currenTime));
                  setSentCode(true);
                }
              }
              setAlertText(getErrorMessage(error.response.data));
            } else {
              setAlertText(
                String(L.translate("GlobalErrors.APISomethingWrong"))
              );
            }
          }
          setIsAlertOpened(true);
        },
      });

    const { mutate: sendSmsCodeLogin, isLoading: isLoadingSmsLogin } =
      useMutation(client.users.sendSmsCodeLogin, {
        onSuccess: (data) => {
          console.log(data);
          setCounter(timer);
          setSentCode(true);
        },
        onError: (error: any) => {
          if (error.code === "ERR_NETWORK") {
            setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
          } else {
            if (error.response) {
              if (
                error.response.data.errors &&
                error.response.data.errors[0]
                  ?.split("_")
                  .slice(0, -1)
                  .join("_") === "seconds_left_for_next_code"
              ) {
                const currenTime = error.response.data.errors[0]
                  ?.split("_")
                  .pop();
                if (Number(currenTime) > 0) {
                  setCounter(Number(currenTime));
                  setSentCode(true);
                }
              }
              setAlertText(getErrorMessage(error.response.data));
            } else {
              setAlertText(
                String(L.translate("GlobalErrors.APISomethingWrong"))
              );
            }
          }
          setIsAlertOpened(true);
        },
      });

    const onSendCode = (e: any) => {
      if (checked) {
        return;
      }
      if (sentCode) {
        return;
      }

      if (inputType === "email") {
        sendEmailCode({ email: sender, event: "withdrawal" });
      } else if (inputType === "email_login") {
        sendEmailCodeLogin({ email: sender, event: "login" });
      } else if (inputType === "sms_login") {
        if (sender.substring(0, 1) === "+") {
          sendSmsCodeLogin({ phone: sender, event: "login" });
        } else {
          sendSmsCodeLogin({ phone: `+${sender}`, event: "login" });
        }
      } else {
        if (sender.substring(0, 1) === "+") {
          sendSMSCode({ phone: sender, event: "withdrawal" });
        } else {
          sendSMSCode({ phone: `+${sender}`, event: "withdrawal" });
        }
      }
    };

    return (
      <div className={className}>
        <span
          role="info"
          className="block pt-2 text-secondary font-manrope text-[16px] font-medium mb-2"
        >
          {(inputType === "email_login" || inputType === "email") && "Email"}{" "}
          {(inputType === "sms_login" || inputType === "phone") && "Phone"}{" "}
          {`${String(
            L.translate("Forms.LoginForm.title_code")
          )} ${makeSenderSecret(sender)}`}
        </span>
        <label className="block text-16px font-manrope font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-16px font-manrope font-medium text-secondary dark:text-light/70">
              {label}
            </span>
          )}
          <div
            className={classNames(
              "flex flex-row items-center justify-end gap-2",
              inputClassName
            )}
          >
            <input
              type={type}
              ref={ref}
              pattern="[0-9]*"
              value={currentValue}
              onChange={onTextChange}
              {...props}
              // placeholder={makePlaceholder(sender)}
              placeholder={`${String(
                L.translate("Forms.LoginForm.placeholder_2fa")
              )}`}
              className={classNames(
                "h-[50px] sm:h-[64px] placeholder-other w-full appearance-none text-[16px] font-manrope font-medium rounded-[12px] border border-border bg-transparent px-4 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] dark:border-dark-600 dark:text-light dark:ring-dark-600 lg:px-5",
                error
                  ? "focus:border-[#F44336] !border-[#F44336] focus:ring-[#F44336]"
                  : "focus:border-active focus:ring-active dark:focus:border-active dark:focus:ring-active",
                inputClassName
              )}
              disabled={checked}
            />
            <span
              onClick={onSendCode}
              className={`${
                !sentCode
                  ? "hover:bg-main bg-active cursor-pointer"
                  : "bg-[#BCBEAD] cursor-default"
              } flex-shrink-0 appearance-none h-[50px] sm:h-[64px] w-[115px] rounded-[12px] text-primary flex font-manrope text-[16px] font-bold items-center justify-center ${inputClassName}`}
            >
              {/* {inputType === "email_login" || inputType === "sms_login" ? (
                <>
                  {isLoadingSmsLogin || isLoadingEmailLogin ? (
                    <SpinnerIcon className="h-auto w-5 animate-spin" />
                  ) : checked ? (
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 27.5C21.875 27.5 27.5 21.875 27.5 15C27.5 8.125 21.875 2.5 15 2.5C8.125 2.5 2.5 8.125 2.5 15C2.5 21.875 8.125 27.5 15 27.5Z"
                        stroke="#232800"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.6875 15.0004L13.225 18.5379L20.3125 11.4629"
                        stroke="#232800"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : sentCode ? (
                    `${convertSeconds(counter)}`
                  ) : (
                    "Send Code"
                  )}
                </>
              ) : (
                <>
                  {isLoadingSMS || isLoadingEmail ? (
                    <SpinnerIcon className="h-auto w-5 animate-spin" />
                  ) : sentCode ? (
                    `${counter}s`
                  ) : (
                    "Send Code"
                  )}
                </>
              )} */}
              {/* {inputType === "email_login" || inputType === "sms_login" ? ( */}
              <>
                {isLoadingSmsLogin ||
                isLoadingEmailLogin ||
                isLoadingSMS ||
                isLoadingEmail ? (
                  <SpinnerIcon className="h-auto w-5 animate-spin" />
                ) : checked ? (
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 27.5C21.875 27.5 27.5 21.875 27.5 15C27.5 8.125 21.875 2.5 15 2.5C8.125 2.5 2.5 8.125 2.5 15C2.5 21.875 8.125 27.5 15 27.5Z"
                      stroke="#232800"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.6875 15.0004L13.225 18.5379L20.3125 11.4629"
                      stroke="#232800"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : sentCode ? (
                  `${convertSeconds(counter)}`
                ) : (
                  "Send Code"
                )}
              </>
              {/* ) : (
                <>
                  {isLoadingSMS || isLoadingEmail ? (
                    <SpinnerIcon className="h-auto w-5 animate-spin" />
                  ) : sentCode ? (
                    `${counter}s`
                  ) : (
                    "Send Code"
                  )}
                </>
              )} */}
            </span>
          </div>
        </label>

        {error && (
          <span
            role="alert"
            className="block pt-2 text-[16px] text-[#F44336] font-manrope"
          >
            {error}
          </span>
        )}
      </div>
    );
  }
);

InputWithTimer.displayName = "InputWithTimer";
export default InputWithTimer;
