import { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import { BankCard, User } from "../../api/types";
import Button from "../../components/ui/button";
import GlobalContext from "src/context/global-context";
import { PaymentIcon } from "src/components/icons/payment-icon";
import { BankCardIcon } from "src/components/icons/bank-card-icon";
import { DeleteIcon } from "src/components/icons/delete-icon";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Spin } from "antd";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";
import Tooltip from "src/components/ui/tooltip";
import { InfoCircle } from "../icons/info-circle";

interface ICardsTable {
  data: BankCard[];
  isLoading?: boolean;
  onRefetch: () => void;
}

const CardsTable: FC<ICardsTable> = ({ data: cards, onRefetch, isLoading }) => {
  const { openModal } = useModalAction();

  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const isMounted = useIsMounted();

  const onDeleteCard = (cardIdx: number) => {
    openModal("DELETE_CARD_CONFIRM", {
      card_no: cards[cardIdx].id,
      card_number: cards[cardIdx].card_number,
      callback: onRefetch,
    });
  };

  return (
    <>
      <div className="w-full mt-8">
        <hr className="h-[1px] border-0 bg-[#E6E8EC] opacity-50 dark:opacity-50" />
      </div>
      <div className="w-ful flex flex-row border-b-[1px] border-[#E6E8EC] border-opacity-50 py-4 gap-2">
        <p className="text-[14px] w-[40%] font-manrope text-secondary text-left font-light">
          {String(L.translate("Account.PaymentMethods.Cardholder"))}
        </p>
        <p className="text-[14px] w-[40%]  font-manrope text-secondary text-left font-light">
          {String(L.translate("Account.PaymentMethods.card_number"))}
        </p>
        <p className="text-[14px] w-[40%]  font-manrope text-secondary text-left font-light">
          {String(L.translate("Account.PaymentMethods.status"))}
        </p>
        <p className="text-[14px] w-[20%]  font-manrope text-secondary text-left font-light"></p>
      </div>
      {isLoading ? (
        <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
      ) : (
        cards.map((item, index) => (
          <div
            key={index}
            className="w-ful flex flex-row border-b-[1px] border-[#E6E8EC] border-opacity-50 py-4 gap-2 items-center"
          >
            <p className="text-[14px] w-[40%] font-manrope text-primary text-left font-light leading-[24px]">
              {item.holder_name}
            </p>
            <p className="text-[14px] w-[40%]  font-manrope text-primary text-left font-light leading-[24px">
              {item.card_number
                .replace(/.(?=.{4})/g, "x")
                .match(/.{1,4}/g)!
                .join(" ")}
            </p>
            {item.is_verified ? (
              <p className="text-[14px] w-[40%]  font-manrope text-success text-left font-medium leading-[24px]">
                {String(L.translate("Account.PaymentMethods.verified"))}
              </p>
            ) : (
              <div className="w-[40%] flex items-center gap-[8px]">
                <p className="text-[14px] font-manrope text-primary text-left font-light leading-[24px">
                  {String(L.translate("Account.PaymentMethods.unverified"))}
                </p>
                <Tooltip
                  text={String(
                    L.translate("Account.PaymentMethods.tooltip_text")
                  )}
                />
              </div>
            )}
            <div className="w-[20%]">
              <Button
                variant="icon"
                className="ml-auto"
                onClick={() => onDeleteCard(index)}
              >
                <DeleteIcon className="w-[20px] h-[20px] text-primary hover:text-light-600" />
              </Button>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default CardsTable;
