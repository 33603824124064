export function CurrencyGELIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 128 128"
    >
      <rect width="128" height="128" fill="#93A7EE" rx="64"></rect>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="2"
        d="M61.248 61.573V40.9a26.768 26.768 0 016.335.053V69.32l1.696-1.643 5.557-5.386.304-.295V43.101c9.091 4.137 15.403 13.266 15.403 23.857v2.302l1.682-1.57 5.457-5.095.388-.361-.082-.524C96.02 49.05 87.047 38.7 75.14 34.698V20.594l-1.705 1.697-5.557 5.531-.295.294v4.965a34.757 34.757 0 00-6.335-.04V20.595l-1.706 1.697-5.557 5.531-.294.294v6.438C39.948 38.966 30 51.802 30 66.958c0 10.758 5.014 20.349 12.836 26.586H36.14l-.293.295-5.41 5.457-1.69 1.704h58.225l.29-.277 5.703-5.457 1.8-1.722H63.466c-14.503 0-25.616-12.056-25.616-26.586 0-10.76 6.516-20.012 15.842-24.052V69.32l1.695-1.643 5.558-5.386.304-.295v-.423z"
      ></path>
    </svg>
  );
}
