export function CurrencyCNYIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 128 128"
    >
      <rect width="128" height="128" fill="#002FD7" rx="64"></rect>
      <mask
        id="mask0_6331_84170"
        style={{ maskType: "alpha" }}
        width="63"
        height="80"
        x="33"
        y="24"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M33 24H95.22200000000001V104H33z"></path>
      </mask>
      <g mask="url(#mask0_6331_84170)">
        <path
          stroke="#fff"
          strokeWidth="11"
          d="M37.444 15.111L64.111 64m0 0l26.667-48.889M64.11 64H37.444m26.667 0h26.667M64.11 64v17.778m0 0V104m0-22.222H37.444m26.667 0h26.667"
        ></path>
      </g>
    </svg>
  );
}
