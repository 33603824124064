import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { loadKYCStatus } from "src/lib/utils";

const useKYCStatus = () => {
  const location = useLocation();

  const [isNotVerified, setIsNotVerified] = useState(false);
  const [isVerifed, setIsVerified] = useState(false);

  useEffect(() => {
    loadKYCStatus().then((kycStatus) => {
      if (kycStatus) {
        setIsNotVerified(
          kycStatus.name == "unverified" || kycStatus.name == "rejected"
            ? true
            : false
        );
        setIsVerified(
          kycStatus.name === "verified" || kycStatus.name === "approved"
            ? true
            : false
        );
      }
    });
  }, [location.pathname]);

  return { isNotVerified, isVerifed };
};

export default useKYCStatus;
