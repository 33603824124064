import { Menu } from "@headlessui/react";
import { forwardRef, useState, useRef, useEffect, ChangeEvent } from "react";
import classNames from "classnames";
import { Transition } from "./transition";
import { Fragment } from "react";
import { DropdownIcon } from "../icons/dropdown-icon";
import { GreenTickIcon } from "../icons/green-tick-icon";
import { animate } from "framer-motion";
import { CountryResponse } from "src/api/types";
import MenuItem from "antd/es/menu/MenuItem";
import { textSearchValid } from "../../lib/utils";
import { SearchIcon } from "../icons/search-icon";
import L from "i18n-react";

type DropdownWithSearchProps = {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  data: CountryResponse[];
  value: number;
  onChange: any;
  required?: boolean;
};

const DropdownWithSearch = forwardRef<HTMLDivElement, DropdownWithSearchProps>(
  (
    {
      label,
      error,
      className,
      placeholder = "",
      inputClassName = "bg-transparent",
      data = [],
      value,
      onChange,
      required,
      ...props
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const selectedIDRef = useRef(-1);
    const [selectedId, setSelectedId] = useState(value);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isContainerOpened, setIsContainerOpened] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState<CountryResponse[]>(data);

    useEffect(() => {
      setSearchText("");
      setSelectedId(value);
      setFilteredData(data);
    }, [value, data]);

    useEffect(() => {
      updateWindowSize();
      window.addEventListener("resize", updateWindowSize);
      btnRef.current?.addEventListener("click", cickedMenuButton);
      window.addEventListener("click", handler);

      return () => {
        window.removeEventListener("click", handler);
        window.removeEventListener("resize", updateWindowSize);
        btnRef.current?.removeEventListener("click", cickedMenuButton);
      };
    }, []);

    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsContainerOpened(false);
      }
    };

    function updateWindowSize() {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }

    useEffect(() => {
      setTimeout(() => menuRef.current?.scrollTo(0, selectedId * 40), 100);
    }, [selectedId, isContainerOpened]);

    function cickedMenuButton() {
      btnRef.current &&
        btnRef.current.ariaExpanded &&
        btnRef.current.ariaExpanded === "false" &&
        setIsContainerOpened(true);

      setTimeout(
        () => menuRef.current?.scrollTo(0, selectedIDRef.current * 40),
        100
      );
    }

    useEffect(() => {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }, [containerRef.current]);

    const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
      const currentText = e.target.value;
      setSearchText(currentText);

      if (currentText.length === 0) {
        setFilteredData(data);
      } else {
        setFilteredData(
          data.filter((item) =>
            label.includes(
              String(L.translate("Account.AccountSettings.country"))
            )
              ? textSearchValid(
                  item.name.toLowerCase(),
                  currentText.toLowerCase(),
                  "0"
                )
              : item.name.toUpperCase().includes(currentText.toUpperCase())
          )
        );
      }
    };

    return (
      <div className={className} ref={containerRef}>
        <label className="block text-[16px] font-manrope font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-16px font-manrope font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[16px] text-[#F44336] font-manrope">
                  *{" "}
                </span>
              )}
              {label}
            </span>
          )}
          <Menu>
            <Menu.Button
              ref={btnRef}
              className={classNames(
                "relative h-[50px] sm:h-[64px] placeholder-other w-full appearance-none text-16px font-manrope font-medium rounded-[12px] border border-border bg-transparent px-4 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] dark:border-dark-600 dark:text-light dark:ring-dark-600 lg:px-5",
                error
                  ? "focus:border-[#F44336] !border-[#F44336] focus:ring-[#F44336]"
                  : "focus:border-active focus:ring-active dark:focus:border-active dark:focus:ring-active",
                inputClassName
              )}
            >
              {({ open }) => (
                <>
                  <p
                    className={`${
                      selectedId < 0 ? "text-other" : "text-primary"
                    } text-left`}
                  >
                    {selectedId < 0 ? placeholder : data[selectedId]?.name}
                  </p>
                  <span className="absolute top-0 flex h-full w-[34px] cursor-pointer items-center justify-start text-dark-900 hover:text-dark-700 right-0 dark:text-dark-800 hover:dark:text-light-900 lg:w-9">
                    <DropdownIcon
                      className={`h-auto w-4 ${
                        open ? "rotate-0" : "rotate-180"
                      }`}
                    />
                  </span>
                </>
              )}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                ref={menuRef}
                className={`max-h-[230px] scrollbar-hide overflow-y-auto flex flex-col absolute z-30 mt-2 rounded-[12px] bg-light py-0 text-dark shadow-card origin-top-left dark:bg-dark-250 dark:text-light`}
                style={{ width: containerWidth }}
              >
                <div className="w-full px-4 py-2 sticky top-0 bg-white">
                  <input
                    className="focus:border-active focus:ring-active h-[44px] mt-2 placeholder-other w-full appearance-none text-[16px] font-manrope font-medium rounded-[10px] border-[1px] border-opacity-50 border-border bg-transparent px-8 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] dark:border-dark-600 dark:text-light dark:ring-dark-600"
                    type="text"
                    placeholder="Enter country name"
                    value={searchText}
                    onChange={onSearchTextChange}
                  />
                  <SearchIcon className="absolute left-[24px] top-[30px] w-[16px] h-[16px] text-secondary" />
                </div>
                {filteredData.map((item, index) => (
                  /* Use the `active` state to conditionally style the active item. */
                  <Menu.Item key={index}>
                    {({ active, close }) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (item.id == selectedId + 1) {
                            close();
                            return;
                          }

                          setSelectedId(item.id - 1);
                          onChange(item.id - 1);
                          selectedIDRef.current = item.id - 1;

                          close();
                        }}
                        className="flex transition-fill-colors px-4  flex-row items-start justify-between py-2 hover:bg-light-400"
                      >
                        <p className="text-primary text-[16px] font-medium font-manrope">
                          {item.name}
                        </p>
                        <GreenTickIcon
                          className={`${
                            selectedId === item.id - 1 ? "block" : "hidden"
                          } w-[20px] h-[20px]`}
                        />
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </label>
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[16px] text-[#F44336] font-manrope"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

DropdownWithSearch.displayName = "DropdownWithSearch";
export default DropdownWithSearch;
