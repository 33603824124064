import React, { FC, useState, useEffect, useContext } from "react";
import L from "i18n-react";
import { Wallet } from "src/api/types";
import { getCurrencyInfo, makeNumberWithFloatingDigits } from "../../lib/utils";
import { AssetDataType } from "src/lib/constants";
import {
  Dropdown,
  MenuProps,
  Space,
  Table,
  Divider,
  Button,
  theme,
} from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { ThreeDotsIcon } from "../icons/three-dots-icon";
import { DropdownIcon } from "../icons/dropdown-icon";
import { useModalAction } from "../modal-views/context";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import GlobalContext from "src/context/global-context";
import useAuth from "src/hooks/use-auth";
import useKYCStatus from "src/hooks/use-kyc-status";

type BalanceListProps = {
  isLoading: boolean;
  wallets: Wallet[];
};

const { useToken } = theme;

const BalanceList: FC<BalanceListProps> = ({ isLoading, wallets }) => {
  const navigate = useNavigate();
  const { openModal } = useModalAction();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { setAlertText, setIsAlertOpened, setAlertTitle } =
    useContext(GlobalContext);
  const { getCurrent2FAInfo } = useAuth();
  const { isVerifed } = useKYCStatus();
  const { token } = useToken();

  const { language } = useContext(GlobalContext);

  const makeItemsForMobile = () => {};

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a className="text-success text-[13px] font-manrope font-light">
          {String(L.translate("Buttons.deposit"))}
        </a>
      ),
    },
    { type: "divider" },
    {
      key: "2",
      label: (
        <a className="text-success text-[13px] font-manrope font-light">
          {String(L.translate("Buttons.withdraw"))}
        </a>
      ),
    },
  ];

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const dataSource = wallets.map((item, index) => ({
    key: index,
    wallet_id: item.id,
    currency: item.asset.code,
    amount: item.balance,
    currency_id: item.asset.id,
    name: item.asset.name,
    img_path: item.asset.img_path,
    vip_deposit_max: item.asset.vip_deposit_max,
    vip_deposit_min: item.asset.vip_deposit_min,
    vip_withdraw_max: item.asset.vip_withdraw_max,
    vip_withdraw_min: item.asset.vip_withdraw_min,
    deposit_max: item.asset.deposit_max,
    deposit_min: item.asset.deposit_min,
    withdraw_max: item.asset.withdraw_max,
    withdraw_min: item.asset.withdraw_min,
    depositable: item.asset.depositable,
    withdrawable: item.asset.withdrawable,
  }));

  const columns: ColumnsType<AssetDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("Balance.currency")}
        </p>
      ),
      dataIndex: "currency",
      className: "w-auto",
      key: "currency",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <div className="w-[24px] h-[24px]">{getCurrencyInfo(value).icon}</div>
          <div className="flex flex-col flex-shrink">
            <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
              {value.toUpperCase()}
            </p>
            <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
              {getCurrencyInfo(value).descr}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "amount"
        );
        return (
          <div className="items-center gap-2 hidden sm:flex">
            <p className="font-manrope text-secondary text-[14px] font-medium">
              {L.translate("Balance.amount")}
            </p>
            <div className="flex flex-col items-center">
              <DropdownIcon
                className={`w-[8px] h-[8px] ${
                  sortedColumn?.order === "ascend"
                    ? "text-secondary"
                    : "text-light-600"
                }`}
              />
              <DropdownIcon
                className={`w-[8px] h-[4px] rotate-180 ${
                  sortedColumn?.order === "descend"
                    ? "text-secondary"
                    : "text-light-600"
                }`}
              />
            </div>
          </div>
        );
      },
      dataIndex: "amount",
      key: "amount",
      className: "w-auto",
      sorter: (a, b) => a.amount - b.amount,
      render: (value: number) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="hidden sm:block font-manrope text-secondary text-[14px] font-medium">
          {L.translate("Balance.action")}
        </p>
      ),
      dataIndex: "action",
      className: `w-[50px] ${
        language === "GE" ? "sm:w-[240px]" : "sm:w-[140px]"
      }`,
      render: (
        _,
        record: { key: React.Key; depositable: number; withdrawable: number }
      ) => {
        const onClick: MenuProps["onClick"] = ({ key }) => {
          if (key === "1") {
            if (record.depositable == 1) {
              onDeposit(record.key as number);
            }
          } else {
            if (record.withdrawable == 1) {
              onWithdraw(record.key as number);
            }
          }
        };

        return dataSource.length >= 1 ? (
          <>
            <Space direction="vertical" className="block sm:hidden">
              <Dropdown
                menu={{ items }}
                dropdownRender={(menu) => (
                  <div
                    style={{
                      backgroundColor: token.colorBgElevated,
                      borderRadius: token.borderRadiusLG,
                      boxShadow: token.boxShadowSecondary,
                    }}
                  >
                    <Space style={{ padding: 8 }}>
                      <Button
                        className={`${
                          record.depositable == 1
                            ? "bg-success"
                            : "bg-secondary"
                        } text-white hover:!text-black hover:!border-black w-32 text-[13px] font-manrope font-light`}
                        onClick={() =>
                          record.depositable == 1 &&
                          onDeposit(record.key as number)
                        }
                      >
                        {String(L.translate("Buttons.deposit"))}
                      </Button>
                    </Space>
                    <Divider style={{ margin: 0 }} />
                    <Space style={{ padding: 8 }}>
                      <Button
                        className={`${
                          record.withdrawable == 1
                            ? "bg-success"
                            : "bg-secondary"
                        } text-white hover:!text-black hover:!border-black w-32 text-[13px] font-manrope font-light`}
                        onClick={() =>
                          record.withdrawable == 1 &&
                          onWithdraw(record.key as number)
                        }
                      >
                        {String(L.translate("Buttons.withdraw"))}
                      </Button>
                    </Space>
                  </div>
                )}
              >
                <a>
                  <ThreeDotsIcon className="w-[20px] h-[20px] text-primary" />
                </a>
              </Dropdown>
            </Space>
            <div className="hidden sm:flex flex-row gap-4">
              <a
                className={`${
                  record.depositable == 1 ? "text-success" : "text-secondary"
                } text-[14px] font-manrope font-medium`}
                onClick={() =>
                  record.depositable == 1 && onDeposit(record.key as number)
                }
              >
                {L.translate("Buttons.deposit")}
              </a>
              <a
                className={`${
                  record.withdrawable == 1 ? "text-success" : "text-secondary"
                } text-[14px] font-manrope font-medium`}
                onClick={() =>
                  record.withdrawable == 1 && onWithdraw(record.key as number)
                }
              >
                {L.translate("Buttons.withdraw")}
              </a>
              {/*
                <a
                  className="text-success text-[14px] font-manrope font-medium"
                  onClick={() => onTransfer(record.key as number)}
                >
                  {L.translate("Buttons.transfer")}
                </a>
                */}
            </div>
          </>
        ) : null;
      },
    },
  ];

  const onDeposit = (nIdx: number) => {
    setAlertTitle(String(L.translate("Alert.Information")));

    if (!isVerifed) {
      setAlertText(String(L.translate("Errors.deposit_account")));
      setIsAlertOpened(true);
      return;
    }
    navigate(routes.deposit, {
      state: { selectedCurrencyIdx: nIdx, currencies: dataSource },
    });
  };
  const onWithdraw = (nIdx: number) => {
    setAlertTitle(String(L.translate("Alert.Information")));

    if (!isVerifed) {
      setAlertText(String(L.translate("Errors.withdraw_account")));
      setIsAlertOpened(true);
      return;
    }

    if (!getCurrent2FAInfo().google2fa_enabled) {
      setAlertText(String(L.translate("Errors.google_2fa_account")));
      setIsAlertOpened(true);
      return;
    }

    navigate(routes.withdraw, {
      state: { selectedCurrencyIdx: nIdx, currencies: dataSource },
    });
  };

  const onTransfer = (nIdx: number) => {
    openModal("TRANSFER", { curIdx: nIdx, currencies: dataSource });
  };

  return (
    <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4 mb-2 sm:mb-0">
      <Table
        loading={isLoading}
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
};

export default BalanceList;
