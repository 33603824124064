import { useEffect, useState } from "react";
import { Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { BackIcon } from "src/components/icons/back-icon";
import L from "i18n-react";

const ProceedFramePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [url, setURL] = useState("");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (location.state) {
      setURL(location.state.url);
    }
  }, [location.state]);

  const onBack = () => {
    navigate(-1);
  };

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col py-2 px-4 sm:p-4 sm:pr-11">
        <div className="flex flex-col bg-white rounded-[12px] w-full h-[578px] items-start p-4">
          <div
            className="flex flex-row gap-2 sm:gap-4 h-[40px] sm:h-[44px] items-center cursor-pointer hover:opacity-80"
            onClick={onBack}
          >
            <BackIcon className="w-[16px] h-[16px] sm:w-[20px] sm:h-[20px] text-primary" />
            <p className="text-primary font-manrope text-[16px] sm:text-[24px] font-medium leading-[45px]">
              {String(L.translate("Forms.ForgotPassword.forgot_password_link"))}
            </p>
          </div>
          <div className="w-full h-full flex items-center justify-center relative">
            <iframe className="w-full h-full" src={url} onLoad={onLoad} />
            {isLoading && (
              <Spin className="absolute inset-0 z-40 flex items-center justify-center bg-opacity-80 backdrop-blur-sm" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProceedFramePage;
