export const MailboxIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 98 103" stroke="none" {...props}>
      <path
        d="M49.4 102.8C76.2411 102.8 98.0001 81.0406 98.0001 54.1996C98.0001 27.3586 76.2411 5.59961 49.4 5.59961C22.559 5.59961 0.800049 27.3586 0.800049 54.1996C0.800049 81.0406 22.559 102.8 49.4 102.8Z"
        fill="#F2F6FF"
      />
      <path
        d="M45.2313 4.84841L6.80005 35.7746V85.7988C6.80005 90.2171 10.3818 93.7988 14.8 93.7988H85.4364C89.8547 93.7988 93.4364 90.2171 93.4364 85.7988V35.7746L55.2826 4.86491C52.3543 2.49257 48.1674 2.4857 45.2313 4.84841Z"
        fill="#011625"
      />
      <rect
        x="19.3011"
        y="14.377"
        width="61.9246"
        height="77.0863"
        rx="9.6"
        fill="url(#paint0_linear_1040_21780)"
      />
      <path
        d="M24.6293 25.9984C24.6293 25.1148 25.3456 24.3984 26.2293 24.3984H72.8122C73.6959 24.3984 74.4122 25.1148 74.4122 25.9984C74.4122 26.8821 73.6959 27.5984 72.8122 27.5984H26.2293C25.3456 27.5984 24.6293 26.8821 24.6293 25.9984Z"
        fill="#C3E600"
      />
      <path
        d="M24.6293 36.3988C24.6293 35.5152 25.3456 34.7988 26.2293 34.7988H72.8122C73.6959 34.7988 74.4122 35.5152 74.4122 36.3988C74.4122 37.2825 73.6959 37.9988 72.8122 37.9988H26.2293C25.3456 37.9988 24.6293 37.2825 24.6293 36.3988Z"
        fill="#D5D5D5"
      />
      <path
        d="M24.6293 46.7992C24.6293 45.9156 25.3456 45.1992 26.2293 45.1992H72.8122C73.6959 45.1992 74.4122 45.9156 74.4122 46.7992C74.4122 47.6829 73.6959 48.3992 72.8122 48.3992H26.2293C25.3456 48.3992 24.6293 47.6829 24.6293 46.7992Z"
        fill="#D5D5D5"
      />
      <path
        d="M24.6293 57.1996C24.6293 56.316 25.3456 55.5996 26.2293 55.5996H72.8122C73.6959 55.5996 74.4122 56.316 74.4122 57.1996C74.4122 58.0833 73.6959 58.7996 72.8122 58.7996H26.2293C25.3456 58.7996 24.6293 58.0833 24.6293 57.1996Z"
        fill="#D5D5D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.80005 35.6914V85.7982C6.80005 90.2164 10.3818 93.7982 14.8 93.7982H63.2262C71.0102 93.7982 74.2095 83.8092 67.874 79.2868L6.80005 35.6914Z"
        fill="url(#paint1_linear_1040_21780)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.4364 35.6914V85.7982C93.4364 90.2164 89.8547 93.7982 85.4364 93.7982H14.2745L93.4364 35.6914Z"
        fill="#E1DCEB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.6001 36.4451L17.2615 93.7987H14.0754L92.6614 35.1523L93.6001 36.4451Z"
        fill="#011625"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1040_21780"
          x1="50.2635"
          y1="14.377"
          x2="50.2635"
          y2="91.4633"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.71875" stopColor="#EAEAEA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1040_21780"
          x1="6.80005"
          y1="35.5981"
          x2="66.4"
          y2="102.398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="E1DCEB" />
          <stop offset="1" stopColor="#B3AFBA" />
        </linearGradient>
      </defs>{" "}
    </svg>
  );
};
