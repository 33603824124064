export default function CardIcon() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.83334 7.7959H20.1667"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.5 15.1299H7.33333"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.625 15.1299H13.2917"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.90334 3.21289H16.0875C19.3508 3.21289 20.1667 4.01956 20.1667 7.23706V14.7629C20.1667 17.9804 19.3508 18.7871 16.0967 18.7871H5.90334C2.64917 18.7962 1.83334 17.9896 1.83334 14.7721V7.23706C1.83334 4.01956 2.64917 3.21289 5.90334 3.21289Z"
          stroke="#232800"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
