import { FC, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../layouts/_layout";
import LayoutKyc from "../layouts/_kyc-layout";
import routes from "../config/routes";
import PrivateRoute from "../lib/private-route";
import WelcomePage from "../pages/welcome";
import SignInPage from "../pages/authentication/sign-in";
import SignInAuth2FAPage from "../pages/authentication/sing-in-auth";
import SignIn2FAPage from "../pages/authentication/sign-in-2fa";
import SignUpPage from "../pages/authentication/sign-up";
import EmailConfirmationPage from "../pages/authentication/email-confirmation";
import ForgotPasswordPage from "../pages/authentication/forgot-password";
import CreateNewPasswordPage from "../pages/authentication/create-new-password";
import BalancePage from "../pages/dashboard/balance";
import TransactionsPage from "../pages/dashboard/transactions";
import GeneralSettingsPage from "../pages/dashboard/general-settings";
import TwoFactorAuthSettingsPage from "../pages/dashboard/two-factor-auth-settings";
import NotificationsPage from "../pages/dashboard/notifications";
import PaymentPage from "../pages/dashboard/payment";
import DepositPage from "../pages/dashboard/deposit";
import WithdrawPage from "../pages/dashboard/withdraw";
import NotFound from "../pages/404";
import RequestResultPage from "src/pages/dashboard/request-result";
import ProceedFramePage from "src/pages/dashboard/proceed-frame";
import PrivacyPolicyPage from "src/pages/policy/privacy";
import UserAgreementPage from "src/pages/policy/user-agreement";
import InternalAMLPolicyPage from "src/pages/policy/aml";
import DepositSuccessPage from "src/pages/dashboard/deposit-success";
import DepositFailPage from "src/pages/dashboard/deposit-fail";
import DepositCardSuccessPage from "src/pages/dashboard/deposit-card-success";
import WithdrawFiatPage from "src/pages/dashboard/withdraw-fiat";
import CookiesPolicyPage from "src/pages/policy/cookie";
import FastTransfersPage from "../pages/dashboard/fast-transfers";
import FastTransfersTermAndConditionPage from "../pages/dashboard/fast-transfers-term-condition";
import FastTransfersKwikPayPage from "../pages/dashboard/fast-transfers-kwik";
import FastTransfersKwikPayMethodsPage from "../pages/dashboard/fast-transfers-kwik-methods";
import OfferConfirmFramePage from "../pages/dashboard/offer-confirm";
import Kyc from "../pages/kyc/kyc-individual";
import KycBuisiness from "../pages/kyc/kyc-buisiness";
import Prices from "src/pages/prices";
import FAQ from "../pages/faq";
import AboutUs from "../pages/about-us";
import ContactUs from "../pages/contact-us";

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <Layout>
            <WelcomePage />
          </Layout>
        }
      />
      <Route
        path="*"
        element={
          <Layout>
            <NotFound />
          </Layout>
        }
      />
      <Route
        path={routes.login}
        element={
          <Layout>
            <SignInPage />
          </Layout>
        }
      />
      <Route
        path={routes.login2FA}
        element={
          <Layout>
            <SignInAuth2FAPage />
          </Layout>
        }
      />
      <Route
        path={routes.register}
        element={
          <Layout>
            <SignUpPage />
          </Layout>
        }
      />
      <Route
        path={routes.emailConfirmation}
        element={
          <Layout>
            <EmailConfirmationPage />
          </Layout>
        }
      />
      <Route
        path={routes.forgot}
        element={
          <Layout>
            <ForgotPasswordPage />
          </Layout>
        }
      />
      <Route
        path={routes.createNewPassword}
        element={
          <Layout>
            <CreateNewPasswordPage />
          </Layout>
        }
      />
      <Route
        path={routes.privacyPolicy}
        element={
          <Layout>
            <PrivacyPolicyPage />
          </Layout>
        }
      />
      <Route
        path={routes.userAgreement}
        element={
          <Layout>
            <UserAgreementPage />
          </Layout>
        }
      />
      <Route
        path={routes.privacyCookies}
        element={
          <Layout>
            <CookiesPolicyPage />
          </Layout>
        }
      />
      <Route
        path={routes.internalAMLPolicy}
        element={
          <Layout>
            <InternalAMLPolicyPage />
          </Layout>
        }
      />

      <Route
        path={routes.prices}
        element={
          <Layout>
            <Prices />
          </Layout>
        }
      />

      <Route
        path={routes.faq}
        element={
          <Layout>
            <FAQ />
          </Layout>
        }
      />

      <Route
        path={routes.aboutUs}
        element={
          <Layout>
            <AboutUs />
          </Layout>
        }
      />
      <Route
        path={routes.contactUs}
        element={
          <Layout>
            <ContactUs />
          </Layout>
        }
      />

      <Route
        path={routes.balance}
        element={
          <PrivateRoute>
            <Layout>
              <BalancePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.fastTransfers}
        element={
          <PrivateRoute>
            <Layout>
              <FastTransfersPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.fastTransfersTermAndCondition}
        element={
          <PrivateRoute>
            <Layout>
              <FastTransfersTermAndConditionPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.fastTransferKwikPay}
        element={
          <PrivateRoute>
            <Layout>
              <FastTransfersKwikPayPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.fastTransferKwikPayMethods}
        element={
          <PrivateRoute>
            <Layout>
              <FastTransfersKwikPayMethodsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.transactionHistory}
        element={
          <PrivateRoute>
            <Layout>
              <TransactionsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.settings}
        element={
          <PrivateRoute>
            <Layout>
              <GeneralSettingsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.twoFactorAuth}
        element={
          <PrivateRoute>
            <Layout>
              <TwoFactorAuthSettingsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.notifications}
        element={
          <PrivateRoute>
            <Layout>
              <NotificationsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.paymentMethod}
        element={
          <PrivateRoute>
            <Layout>
              <PaymentPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.deposit}
        element={
          <PrivateRoute>
            <Layout>
              <DepositPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.withdraw}
        element={
          <PrivateRoute>
            <Layout>
              <WithdrawPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.withdrawResult}
        element={
          <PrivateRoute>
            <Layout>
              <RequestResultPage type="withdraw" />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.depositResult}
        element={
          <PrivateRoute>
            <Layout>
              <RequestResultPage type="deposit" />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.proceed}
        element={
          <PrivateRoute>
            <Layout>
              <ProceedFramePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.offerConfirm}
        element={
          <PrivateRoute>
            <Layout>
              <OfferConfirmFramePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.depositSuccess}
        element={
          <PrivateRoute>
            <Layout>
              <DepositSuccessPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.depositFail}
        element={
          <PrivateRoute>
            <Layout>
              <DepositFailPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.depositCardSuccess}
        element={
          <PrivateRoute>
            <Layout>
              <DepositCardSuccessPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.verifyCardResult}
        element={
          <PrivateRoute>
            <Layout>
              <WithdrawFiatPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.kyc}
        element={
          <PrivateRoute>
            <LayoutKyc>
              <Kyc />
            </LayoutKyc>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.kycBuisiness}
        element={
          <PrivateRoute>
            <LayoutKyc>
              <KycBuisiness />
            </LayoutKyc>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
