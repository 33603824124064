export const ConfigValue = {
  PORT: process.env.PORT || 3000,
  PUBLIC_REST_API_ENDPOINT: process.env.REACT_APP_BASE_URL,
  CAPTCHA: process.env.REACT_APP_CAPTCHA,
  AUTH_TOKEN_KEY: "fastoo-auth-token",
  AUTH_USER_INFO: "fastoo-auth-user",
  AUTH_USER_GOOGLE_2FA: "fastoo-auth-user-google-2fa",
  AUTH_USER_PHONE_2FA: "fastoo-auth-user-phone-2fa",
  AUTH_USER_EMAIL_2FA: "fastoo-auth-user-email-2fa",
  CRYPTO_SECRET_PASS: process.env.REACT_APP_SECRET_PASS || "XkhZG4fW2t2W",
  COUNTRIES: "fastoo-countries",
  KYC_STATUS: "fastoo-kyc-status",
  SMS_TIMESTAMPS: "fastoo-sms-timestamps",
  KYC_MESSAGE: "fastoo-kyc-message",
  LANGUAGE: "fastoo-language",
  AUTH_USER_TELEPHONE_2FA:"fastoo-auth-user-phone"
};
