import { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  checkKYCMessage,
  getErrorMessage,
  saveKYCMessage,
  saveKYCStatus,
} from "../../lib/utils";
import { User, WalletResponse } from "../../api/types";
import { MobileVerificationBanner } from "../../components/kyc/verification-banner";
import FastTransfersActions from "../../components/fast-transfers/actions";
import BalanceList from "../../components/balance/list";
import useKYCStatus from "src/hooks/use-kyc-status";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import Info from "../../components/fast-transfers/info";
import Button from "../../components/fast-transfers/button";
import Methods from "../../components/fast-transfers/methods";
import { Spin } from "antd";

const FastTransfersPage: FC = () => {
  const { openModal } = useModalAction();
  const isMounted = useIsMounted();
  const [user, setUser] = useState<User | null>(null);
  const [showTermCondition, setShowTermCondition] = useState(true);

  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const [pageData, setPageData] = useState<WalletResponse | null>(null);

  const { mutate: getUserData, isLoading } = useMutation(client.users.me, {
    onSuccess: async (data) => {
      setUser(data.user);
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: confirmTermCondition, isLoading: isLoadingEnableEmail } =
    useMutation(client.users.confirmTermCondition, {
      onSuccess: (data) => {
        console.log(data);
        setUser(data);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        setIsAlertOpened(true);
      },
    });
  const { mutate: rejectTermCondition, isLoading: isLoadingEnableReject } =
    useMutation(client.users.confirmTermCondition, {
      onSuccess: (data) => {
        setUser(data);
        openModal("FAST_TRANSFER_TERM_CONDITION_REJECT");
        setShowTermCondition(false);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        setIsAlertOpened(true);
      },
    });

  const onOpenTermConditionModal = () => {
    console.log("added new card");
    openModal("FAST_TRANSFER_TERM_CONDITION");
  };

  const handleConfirmTermCondition = () => {
    confirmTermCondition();
  };
  const handleCloseTermCondition = () => {
    setShowTermCondition(false);
  };

  const handleRejectTermCondition = () => {
    rejectTermCondition();
  };

  useEffect(() => {
    isMounted && getUserData();
  }, [isMounted]);

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-0 sm:p-4 sm:pr-12">
        <FastTransfersActions />

        {user?.isNeedConfirm && showTermCondition && (
          <div className="relative border border-[#F9A000] p-6 rounded rounded-xl my-[25px] bg-[#FFF5E7]">
            <Info
              onOpenTermConditionModal={onOpenTermConditionModal}
              handleCloseTermCondition={handleCloseTermCondition}
              user={user}
              loading={isLoading}
            />
            <div className="flex gap-[10px] mt-[25px]">
              <Button
                onClick={handleConfirmTermCondition}
                ptop="10px"
                pbottom="10px"
                font="12px"
                text={String(
                  L.translate("FastTransfers.termCondition.confirm")
                )}
                className="font-manrope flex border border-[#C3E600] bg-[#C3E600] rounded-xl  px-[35px] "
              />
              <Button
                onClick={handleRejectTermCondition}
                ptop="10px"
                pbottom="10px"
                font="12px"
                text={String(L.translate("FastTransfers.termCondition.reject"))}
                className="font-manrope flex border border-[#7F8172] rounded-xl  px-[35px]"
              />
            </div>
            {(isLoadingEnableEmail || isLoadingEnableReject) && (
              <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
            )}
          </div>
        )}

        <div className="bg-white p-6 rounded-xl">
          <Methods />
        </div>
      </div>
    </div>
  );
};

export default FastTransfersPage;
