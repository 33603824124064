import { InfoCircle } from "../icons/info-circle";
import { Link } from "react-router-dom";
import { PopupCloseIcon } from "../icons/popup-close-icon";
import L from "i18n-react";
import { FC } from "react";
import { User } from "../../api/types";
import routes from "../../config/routes";

type UserDataCardProps = {
  user: User | null;
  loading: boolean;
  handleCloseTermCondition: any;
  onOpenTermConditionModal: any;
};

const Info: FC<UserDataCardProps> = ({
  onOpenTermConditionModal,
  user,
  handleCloseTermCondition,
  loading,
}) => {
  const ClickCloseIcon = () => {
    handleCloseTermCondition();
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex gap-[15px]">
          <InfoCircle />
          <div className="flex gap-[15px]">
            <p className="font-manrope text-sm font-medium text-primary leading-[21px]">
              {String(L.translate("FastTransfers.termCondition.txt"))}
            </p>
            <p
              onClick={onOpenTermConditionModal}
              className="font-manrope text-sm font-medium text-[#7F8172] underline"
            >
              {String(L.translate("FastTransfers.termCondition.readForm"))}
            </p>
          </div>
        </div>
        <div className="cursor-pointer">
          <svg
            onClick={ClickCloseIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M13.5 4.5L4.5 13.5"
              stroke="#232800"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.5 4.5L13.5 13.5"
              stroke="#232800"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Info;
