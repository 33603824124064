import { FC, useContext, useEffect, useState } from "react";
import {
  PaymentHistoryResponse,
  TransactionHistoryInput,
  User,
} from "src/api/types";
import L from "i18n-react";
import {
  capitalizeFirstLetter,
  convertPaymentSystem,
  getErrorMessage,
  getPaymentHistoryType,
  getPaymentOptionName,
  getPaymentStatusName,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import {
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  NetworkDisconnectMsg,
  PaymentDataType,
  TransactionType,
} from "src/lib/constants";
import { Dropdown, MenuProps, Space, Table, Tooltip } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import { DropdownIcon } from "../icons/dropdown-icon";
import moment from "moment";
import DropdownType from "../ui/dropdown";
import { useModalAction } from "../modal-views/context";

type TransactionDepositWithdrawalListProps = {};

const TransactionDepositWithdrawalList: FC<
  TransactionDepositWithdrawalListProps
> = ({}) => {
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<PaymentDataType[]>([]);
  const [searchParams, setSearchParams] = useState<TransactionHistoryInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });

  const [total, setTotal] = useState(0);
  const [paymentType, setPaymentType] = useState("all");
  const { openModal } = useModalAction();

  useEffect(() => {
    isMounted &&
      getList({
        ...searchParams,
        ...(paymentType != "all" && { type: paymentType }),
      });
  }, [isMounted, searchParams.current_page, paymentType]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({ ...searchParams, current_page: page });
  };

  const onCallbackProcess = () => {
    getList({
      ...searchParams,
      ...(paymentType != "all" && { type: paymentType }),
    });
  };

  const { mutate: getList, isLoading } = useMutation(
    client.transactions.getPaymentHistory,
    {
      onSuccess: (data: PaymentHistoryResponse) => {
        console.log(data);
        setTotal(data.total);

        setDataSource(
          data.data.map((item) => ({
            key: item.id,
            user_id: item.user_id,
            asset_id: item.asset_id,
            amount: item.amount,
            amount_fee: item.amount_fee,
            total_amount: item.total_amount,
            payment_system: item.payment_system,
            status: item.status,
            type: item.type,
            description: item.description,
            comment: item.comment,
            to: item.to,
            withdraw_payment_system: item.withdraw_payment_system,
            refund_id: item.refund_id,
            user_ip: item.user_ip,
            created_at: item.created_at,
            updated_at: item.updated_at,
            indicated_amount: item.indicated_amount,
            asset_code: item.asset.code,
            invoice: item.requisites ? true : false,
            invoice_data: item.requisites,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onTypeChange = (nTypeIdx: number) => {
    console.log(nTypeIdx);
    setPaymentType(TransactionType[nTypeIdx].code);
  };

  const onDelete = (key: number) => {
    console.log("DELETE INDEX - ", key);
    openModal("DELETE_TRANSACTION_CONFIRM", {
      id: key,
      callback: onCallbackProcess,
    });
  };

  const onViewInvoice = (key: number) => {
    const filterInvoices = dataSource.filter((item) => item.key === key);
    if (filterInvoices.length === 0) {
      return;
    }

    const selInvoice = filterInvoices[0];
    selInvoice.invoice_data &&
      openModal("DEPOSIT_BANK_CONFIRMATION", {
        amount: selInvoice.indicated_amount,
        currency: selInvoice.asset_code,
        info: [
          {
            name: String(L.translate("Bank.bank_name")),
            value: selInvoice.invoice_data.beneficiary_bank,
          },
          {
            name: "SWIFT",
            value: selInvoice.invoice_data.swift,
          },
          {
            name: String(L.translate("Bank.beneficiary_name")),
            value: selInvoice.invoice_data.beneficiary_name,
          },
          {
            name: String(L.translate("Bank.beneficiary_iban")),
            value: selInvoice.invoice_data.iban,
          },
          {
            name: String(L.translate("Bank.reference")),
            value: selInvoice.invoice_data.reference,
          },
          {
            name: String(L.translate("Bank.note")),
            value: selInvoice.invoice_data.note,
          },
        ],
      });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <button
          className="text-primary text-[13px] font-manrope font-light w-full text-left"
          onClick={() => setPaymentType("all")}
        >
          {L.translate("TransactionHistory.all")}
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className="text-primary text-[13px] font-manrope font-light w-full text-left"
          onClick={() => setPaymentType("deposit")}
        >
          {L.translate("TransactionHistory.deposit")}
        </button>
      ),
    },
    {
      key: "3",
      label: (
        <button
          className="text-primary text-[13px] font-manrope font-light w-full text-left"
          onClick={() => setPaymentType("withdraw")}
        >
          {L.translate("TransactionHistory.withdrawal")}
        </button>
      ),
    },
  ];

  const columns: ColumnsType<PaymentDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.time")}
        </p>
      ),
      dataIndex: "updated_at",
      className: "w-auto",
      key: "updated_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {moment.unix(value).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
      ),
    },
    {
      title: (titleProps) => {
        return (
          <Dropdown
            arrow={true}
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: ["1"],
            }}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <a className="font-manrope text-secondary text-[14px] font-medium">
                {L.translate("TransactionHistory.type")}
              </a>
              <DropdownIcon className="w-[8px] h-[8px] text-secondary" />
            </div>
          </Dropdown>
        );
      },
      dataIndex: "type",
      className: "w-auto",
      key: "type",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {getPaymentHistoryType(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.currency")}
        </p>
      ),
      dataIndex: "asset_code",
      className: "w-auto",
      key: "asset_code",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value.toUpperCase()}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.paymentMethod")}
        </p>
      ),
      dataIndex: "payment_system",
      className: "w-auto",
      key: "payment_system",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {getPaymentOptionName(value) /*convertPaymentSystem(value)*/}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.invoice")}
        </p>
      ),
      dataIndex: "invoice",
      className: "w-auto",
      render: (
        _,
        record: { key: React.Key; invoice: boolean; invoice_data?: any }
      ) => (
        <div
          className={`${
            !record.invoice ? "invisible" : ""
          } flex flex-row gap-4`}
        >
          <a
            className="text-secondary underline hover:underline hover:text-primary text-[12px] font-manrope font-medium"
            onClick={() => onViewInvoice(record.key as number)}
          >
            {L.translate("TransactionHistory.invoice")}
          </a>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.indicatedAmount")}
        </p>
      ),
      dataIndex: "indicated_amount",
      key: "indicated_amount",
      className: "w-auto",
      sorter: (a, b) => a.indicated_amount - b.indicated_amount,
      render: (value: number) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.fee")}
        </p>
      ),
      dataIndex: "amount_fee",
      className: "w-auto",
      key: "amount_fee",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {makeNumberWithFloatingDigits(value, 2)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.amount")}
        </p>
      ),
      dataIndex: "total_amount",
      key: "total_amount",
      className: "w-auto",
      sorter: (a, b) => a.total_amount - b.total_amount,
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.comment")}
        </p>
      ),
      dataIndex: "comment",
      key: "comment",
      className: "w-auto",
      sorter: (a, b) => a.total_amount - b.total_amount,
      render: (value: string) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {value?.replaceAll("_", " ")}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.status")}
        </p>
      ),
      dataIndex: "status",
      className: "w-auto",
      key: "status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {getPaymentStatusName(value) /*capitalizeFirstLetter(value)*/}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="hidden sm:block font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.action")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key; status: string; type: string }) => (
        <div
          className={`${record.status !== "waiting" ? "invisible" : ""} ${
            record.type !== "deposit" ? "invisible" : ""
          } flex flex-row gap-4`}
        >
          <a
            className="text-white hover:text-white bg-black rounded-full text-[12px] font-manrope font-medium px-2 hover:bg-dark-300"
            onClick={() => onDelete(record.key as number)}
          >
            {L.translate("TransactionHistory.delete")}
          </a>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<PaymentDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (
        _,
        record: { key: React.Key; invoice: boolean; invoice_data?: any }
      ) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-row gap-2 items-center justify-between">
            <div className="w-full flex flex-col items-start gap-2">
              <p className="text-primary text-[14px] font-manrope font-medium">
                {moment.unix(selData.updated_at).format("YYYY-MM-DD HH:mm:ss")}
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {
                  getPaymentHistoryType(
                    selData.type
                  ) /*capitalizeFirstLetter(selData.type)*/
                }
              </p>

              <p className="text-primary text-[14px] font-manrope font-medium">
                {
                  getPaymentOptionName(
                    selData.payment_system
                  ) /*convertPaymentSystem(selData.payment_system)*/
                }
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {
                  getPaymentStatusName(
                    selData.status
                  ) /*capitalizeFirstLetter(selData.status)*/
                }
              </p>
              <a
                className={`${
                  !record.invoice ? "invisible" : ""
                } text-secondary underline hover:underline hover:text-primary text-[12px] font-manrope font-medium`}
                onClick={() => onViewInvoice(record.key as number)}
              >
                {L.translate("TransactionHistory.invoice")}
              </a>
            </div>
            <div className="w-full flex flex-col items-start gap-2">
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium w-[117px]">
                  {L.translate("TransactionHistory.amount")}
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium flex-shrink-0">
                  {`${makeNumberWithFloatingDigits(
                    selData.total_amount,
                    2
                  )} ${selData.asset_code.toUpperCase()}`}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium w-[117px]">
                  {L.translate("TransactionHistory.comment")}
                </p>
                <Tooltip
                  title={selData.comment}
                  placement="bottom"
                  color="white"
                  overlayInnerStyle={{
                    color: "#232800",
                  }}
                >
                  <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium w-[50px] w:sm-[200px] whitespace-nowrap overflow-hidden text-ellipsis">
                    {selData.comment?.replaceAll("_", " ")}
                  </p>
                </Tooltip>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium w-[117px]">
                  {L.translate("TransactionHistory.indicatedAmount")}
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {makeNumberWithFloatingDigits(selData.indicated_amount, 2)}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium w-[117px]">
                  {L.translate("TransactionHistory.fee")}
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {makeNumberWithFloatingDigits(selData.amount_fee, 2)}
                </p>
              </div>
              <div
                className={`w-full flex flex-row items-center justify-between gap-2 ${
                  selData.status !== "waiting" ? "invisible" : ""
                }${selData.type !== "deposit" ? "invisible" : ""}`}
              >
                <p className="text-secondary text-[14px] font-manrope font-medium w-[117px]">
                  {L.translate("TransactionHistory.actions")}
                </p>
                <a
                  className="text-secondary text-[14px] font-manrope font-medium underline hover:text-primary hover:underline"
                  onClick={() => onDelete(record.key as number)}
                >
                  {L.translate("TransactionHistory.delete")}
                </a>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white rounded-b-[12px] w-full rounded-tr-[12px]">
      <div className="flex flex-col w-full p-4 gap-4">
        <DropdownType
          label={String(L.translate("TransactionHistory.type"))}
          placeholder=""
          className="block md:hidden w-[186px]"
          inputClassName="!h-[40px]"
          value={0}
          onChange={onTypeChange}
          data={TransactionType}
        />
        <Table
          loading={isLoading}
          showSorterTooltip={false}
          columns={isMobile ? mobileColumns : columns}
          dataSource={dataSource}
          pagination={{
            total,
            onChange: onPageChange,
            position: ["bottomCenter"],
            defaultPageSize: LIST_PAGE_SIZE,
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
};

export default TransactionDepositWithdrawalList;
