import L from "i18n-react";
import { CountryResponse, KYCStatus } from "src/api/types";
import { ConfigValue } from "src/config";
import { CurrencyEURIcon } from "../components/icons/currency-eur-icon";
import { CurrencyGBPIcon } from "../components/icons/currency-gbp-icon";
import { CurrencyGELIcon } from "../components/icons/currency-gel-icon";
import { CurrencyUAHIcon } from "../components/icons/currency-uah-icon";
import { CurrencyUSDIcon } from "../components/icons/currency-usd-icon";
import { CurrencyCNYIcon } from "../components/icons/currency-cny-icon";
import { APISomethingWrongMsg } from "./constants";
import Cookies from "js-cookie";
import moment from "moment";
import { AES, enc } from "crypto-js";

const ababagalamaga = "vUZFZ35VbP5R76ybm94s5jPx";

export const getPaymentStatusName = (item: string) => {
  switch (item) {
    case "canceled":
      return String(L.translate("Base.transaction_status.canceled"));
    case "completed":
      return String(L.translate("Base.transaction_status.completed"));
    case "pending":
      return String(L.translate("Base.transaction_status.pending"));
    case "rejected":
      return String(L.translate("Base.transaction_status.rejected"));
    case "waiting":
      return String(L.translate("Base.transaction_status.waiting"));
    case "processing":
      return String(L.translate("Base.transaction_status.processing"));
    case "in_progress":
      return String(L.translate("Base.transaction_status.in_progress"));
    default:
      return item?.replaceAll("_", " ");
  }
};

export const getPaymentOptionName = (item: string) => {
  switch (item) {
    case "paypal":
      return String(L.translate("WithdrawFiat.paypal"));
    case "bank_transfer":
      return String(L.translate("DepositFiat.bank_transfer"));
    case "cartubank":
      return String(L.translate("DepositFiat.card"));
    default:
      return String(L.translate("WithdrawFiat.paypal"));
  }
};

export const getPaymentHistoryType = (item: string) => {
  switch (item) {
    case "deposit":
      return String(L.translate("TransactionHistory.deposit"));
    case "withdraw":
      return String(L.translate("TransactionHistory.withdrawal"));
    default:
      return String(L.translate("TransactionHistory.deposit"));
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertPaymentSystem = (str: string): string => {
  const updatedStrs = str
    .split("_")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1));
  return updatedStrs.join(" ");
};

function removeFalsy(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => Boolean(v)));
}

export const getErrorMessage = (data: any) => {
  if (data.errors) {
    const errors = data.errors;
    if (Array.isArray(errors)) {
      const messages = errors.map((error: string) => error.replace(/_/g, " "));
      return messages.length > 0
        ? `${capitalizeFirstLetter(messages[0])}`
        : APISomethingWrongMsg;
      //return messages.join("\n");
    } else {
      const values = Object.values(errors) as any[];
      const messages = values.map((eachValue: string[]) =>
        eachValue[0].replace(/_/g, " ")
      );
      return messages.length > 0
        ? `${capitalizeFirstLetter(messages[0])}`
        : APISomethingWrongMsg;
    }
  } else {
    return `${data.message}`;
  }
};

export function formatAddress(address: any) {
  if (!address) return;
  const temp = ["street", "city", "post_code", "country"].reduce(
    (acc, k) => ({ ...acc, [k]: (address as any)[k] }),
    {}
  );
  const formattedAddress = removeFalsy(temp);
  return Object.values(formattedAddress).length === 0
    ? "-"
    : Object.values(formattedAddress).join(" ");
}

export function getCurrencySymbol(name: string, price: number) {
  let currencyValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: name.toUpperCase(),
  });
  return currencyValue.format(price);
}

export function getCurrencyInfo(name: string, size: number = 24) {
  switch (name.toUpperCase()) {
    case "USD":
      return {
        icon: (
          <CurrencyUSDIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "United States Dollar",
      };
    case "EUR":
      return {
        icon: (
          <CurrencyEURIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "Euro",
      };
    case "GEL":
      return {
        icon: (
          <CurrencyGELIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "Georgian Lari",
      };
    case "GBP":
      return {
        icon: (
          <CurrencyGBPIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "British Pound",
      };
    case "UAH":
      return {
        icon: (
          <CurrencyUAHIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "Ukraine Hryvnia",
      };
    case "CNY":
      return {
        icon: (
          <CurrencyCNYIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "Chinese Yuan",
      };
    default:
      return {
        icon: (
          <CurrencyUSDIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "United States Dollar",
      };
  }
}

export const saveCountriesIntoLocal = (data: CountryResponse[]) => {
  localStorage.setItem(ConfigValue.COUNTRIES, JSON.stringify(data));
};

export const loadCountriesFromLocal = async () => {
  const data = await localStorage.getItem(ConfigValue.COUNTRIES);
  return data ? JSON.parse(data) : [];
};

export const saveKYCStatus = (data: KYCStatus) => {
  localStorage.setItem(ConfigValue.KYC_STATUS, JSON.stringify(data));
};

export const loadKYCStatus = async () => {
  const data = await localStorage.getItem(ConfigValue.KYC_STATUS);
  return data ? JSON.parse(data) : null;
};

export const makeSenderSecret = (value: string) => {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (expression.test(value)) {
    //email
    return value.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2");
  } else {
    //phone number
    return value.slice(0, 3) + value.slice(3).replace(/.(?=....)/g, "*");
  }
};

export const makePlaceholder = (value: string) => {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (expression.test(value)) {
    //email
    return String(L.translate("Base.email_verification_code"));
  } else {
    //phone number
    return String(L.translate("Base.phone_verification_code"));
  }
};

export const makeSecretKey = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const roundTo = (num: number, decimalPlaces: number): number => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
};

export const makeCardSecretCardNumber = (cardNumber: string) => {
  return cardNumber
    .replace(/.(?=.{4})/g, "x")
    .match(/.{1,4}/g)!
    .join(" ");
};

export const makeNumberWithFloatingDigits = (
  value: string | number,
  digits: number = 8
) => {
  return Number(value).toLocaleString(undefined, {
    minimumFractionDigits: digits,
  });
};

export const AuthenticatorAppURL = {
  ios: "https://apps.apple.com/ru/app/google-authenticator/id388497605",
  android:
    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
};

export const verifyCardNumber = (cardNumber: string) => {
  const cleanedCardNumber = cardNumber.replace(/\D/g, "");
  if (
    cleanedCardNumber == "4000000000000006" ||
    cleanedCardNumber == "5000000000000005"
  ) {
    return true;
  }

  // Check if the card number is empty or contains non-digit characters
  if (!cleanedCardNumber || cleanedCardNumber.length !== 16) {
    return false;
  }

  // Apply the Luhn algorithm to validate the card number
  let sum = 0;
  let isAlternateDigit = false;

  for (let i = cleanedCardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cleanedCardNumber.charAt(i), 10);

    if (isAlternateDigit) {
      digit *= 2;

      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    isAlternateDigit = !isAlternateDigit;
  }

  return sum % 10 === 0;
};

export function setTransactionsTabIndex(nIdx: number) {
  Cookies.set("@transations_tab_index", `${nIdx}`, { expires: 1 });
}

export function removeTransactionsTabIndex() {
  Cookies.remove("@transations_tab_index");
}

export function getTransactionsTabIndex() {
  const nIdx = Cookies.get("@transations_tab_index");
  return nIdx;
}

export function removePlusFromPhone(phone: string) {
  if (phone.charAt(0) === "+") {
    return phone.substring(1);
  } else {
    return phone;
  }
}
export const textSearchValid = (
  first: string,
  search: string,
  start: string
) => {
  let fullName = first;
  const regex = new RegExp(start === "0" ? `^${search}` : `${search}`, "i");
  if (regex.test(fullName)) {
    return true;
  }
  return false;
};

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const saveKYCMessage = async (message: string) => {
  const currentTimestamp = Date.now();

  const info = { timestamp: currentTimestamp, message };
  localStorage.setItem(ConfigValue.KYC_MESSAGE, JSON.stringify(info));
};

export const checkKYCMessage = async (message: string) => {
  const currentTimestamp = Date.now();

  const data = await localStorage.getItem(ConfigValue.KYC_MESSAGE);
  if (data) {
    const info = JSON.parse(data);
    if (message == info.message) {
      if ((currentTimestamp - info.timestamp) / 1000 > 86400) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const saveTimestampSentSMS = async () => {
  const currentTimestamp = Date.now();

  const data = await localStorage.getItem(ConfigValue.SMS_TIMESTAMPS);
  let currentList = data ? JSON.parse(data) : [];
  if (currentList.length > 30) {
    currentList = currentList.slice(20);
  }

  currentList = [...currentList, currentTimestamp];
  localStorage.setItem(ConfigValue.SMS_TIMESTAMPS, JSON.stringify(currentList));
};

export const checkSMSDailyLimit = async () => {
  const currentTimestamp = Date.now();

  const data = await localStorage.getItem(ConfigValue.SMS_TIMESTAMPS);
  const currentList = data ? JSON.parse(data) : [];

  if (currentList.length < 3) {
    return true;
  } else {
    let counter = 0;
    for (let i = 0; i < 3; i++) {
      if (
        moment(currentList[currentList.length - 1 - i]).format("YYYY-MM-DD") ==
        moment(currentTimestamp).format("YYYY-MM-DD")
      ) {
        counter++;
      }
    }

    if (counter >= 3) {
      return false;
    } else {
      return true;
    }
  }
};

export const getNumberOfDays = (month: number, year?: number): number => {
  if ([4, 6, 9].includes(month)) {
    return 30;
  }
  if (month === 2) {
    if (!year || year % 4 === 0) {
      return 29;
    }
    return 28;
  }
  return 31;
};
/* eslint-disable */
const coder = (value: any) => {
  const cryptedText = AES.encrypt(JSON.stringify(value), ababagalamaga);
  return cryptedText.toString();
};
const deCoder = (value: any) => {
  const bytes = AES.decrypt(value, ababagalamaga);
  const decrypted = bytes.toString(enc.Utf8);

  return JSON.parse(decrypted);
};

export const getLS = (key: string): any => {
  try {
    const lsValue: string | null = localStorage.getItem(`fastoo_${key}`);
    if (lsValue) {
      return deCoder(lsValue);
    }
    throw new Error(`I do not find fastoo_${key}`);
  } catch (error) {
    return null;
  }
};

export const setLS = (key: string, value: any): any => {
  try {
    localStorage.setItem(`fastoo_${key}`, coder(value));
  } catch (error) {
    null;
  }
};

export const convertSeconds = (seconds: number): string => {
  const minutes: number = Math.floor(seconds / 60);
  const remainingSeconds: number = seconds % 60;

  const formattedMinutes: string = minutes.toString().padStart(2, "0");
  const formattedSeconds: string = remainingSeconds.toString().padStart(2, "0");

  return `${formattedMinutes}m ${formattedSeconds}sec`;
};

export const trimFileName = (name: string): string => {
  const trimName = name.length > 20 ? `${name.slice(0, 20)}...` : name;
  return trimName;
};

export const widthFile = (size: number | undefined) => {
  if (!size) {
    return false;
  }
  if (size / 1024 / 1024 > 3) {
    return false;
  }
  return true;
};

export const ACCEPT_TYPE_FILES = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];

/* eslint-disable @typescript-eslint/no-explicit-any */
export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
