// TODO: Should be rewriten to Tailwind CSS
import L from "i18n-react";
import { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import classNames from "classnames";
import { useMutation } from "react-query";
import {
  loadCountriesFromLocal,
  saveCountriesIntoLocal,
  getNumberOfDays,
} from "src/lib/utils";
import client from "../../../api";
import { CountryResponse } from "../../../api/types";
import months from "../../../assets/data/months.json";
import Button from "../../ui/button";
import Dropdown from "../../ui/dropdown";
import DropdownWithSearch from "../../ui/dropdown-search";
import Input from "../../ui/input";
import Steps from "../kyc-steps";
import { ReactPhone } from "../../ui/phone-input";
import { companyTypes } from "./data";

export type TFinalData = Omit<
  FormData,
  "dob_date" | "dob_month" | "dob_year"
> & { date_birth: string };

interface IKycBuisinessFormStep1 {
  onSubmit: (state: TFinalData) => void;
}

const schema = yup
  .object({
    first_name: yup
      .string()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    last_name: yup
      .string()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    dob_date: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`)
      .min(0),
    dob_month: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`)
      .min(0),
    dob_year: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`)
      .min(0),
    country_id: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    address: yup.string().required(`${String(L.translate("Errors.fieldErr"))}`),
    post_code: yup
      .string()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
  })
  .required();
export type FormData = yup.InferType<typeof schema>;

export default function KycBuisinessFormStep1({
  onSubmit,
}: IKycBuisinessFormStep1) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      dob_date: -1,
      dob_month: -1,
      dob_year: -1,
    },
  });

  const phoneRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const monthIdx = watch("dob_month");
  const yearIdx = watch("dob_year");
  const dayIdx = watch("dob_date");

  const [days, setDays] = useState<CountryResponse[]>([]);
  const [years, setYears] = useState<CountryResponse[]>([]);

  const selectedMonth = months[monthIdx];
  const selectedDay = days[dayIdx];
  const selectedYear = years[yearIdx];

  const [phoneDivWidth, setPhoneDivWidth] = useState(0);

  const [countries, setCountries] = useState<CountryResponse[]>([]);

  const { mutate: getCountries, isLoading } = useMutation(
    client.kyc.getCountries,
    {
      onSuccess: (data) => {
        console.log(data);
        saveCountriesIntoLocal(data);
        setCountries(data);
      },
      onError: (error: any) => {
        setCountries([]);
      },
    }
  );

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    const width = phoneRef.current ? phoneRef.current.offsetWidth : 0;
    setPhoneDivWidth(width);

    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  useEffect(() => {
    let tempYears = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 100; i <= currentYear; i++) {
      tempYears.push({
        id: i - currentYear + 100,
        name: `${i}`,
        code: `${i}`,
        active: 1,
      });
    }
    setYears(tempYears);
  }, []);

  useEffect(() => {
    const daysAmount = getNumberOfDays(
      +selectedMonth?.code,
      +selectedYear?.code || undefined
    );
    let tempDays = [];
    for (let i = 1; i <= daysAmount; i++) {
      tempDays.push({ id: i, name: `${i}`, code: `${i}`, active: 1 });
    }
    if (dayIdx > tempDays.length - 1) {
      resetField("dob_date");
    }
    setDays(tempDays);
  }, [selectedMonth, selectedYear]);

  const loadCountries = async () => {
    let data = await loadCountriesFromLocal();
    if (data.length === 0) {
      getCountries();
    } else {
      setCountries(data);
    }
  };

  useEffect(() => {
    loadCountries();
  }, []);

  return (
    <form
      className="h-full w-full flex flex-col gap-4"
      onSubmit={handleSubmit((data: FormData) => {
        const { dob_date, dob_month, dob_year, ...rest } = data;
        const dayFormatted =
          selectedDay?.code &&
          (selectedDay?.code.length === 1
            ? `0${selectedDay?.code}`
            : selectedDay?.code);
        onSubmit({
          ...rest,
          date_birth: `${selectedYear.code}-${selectedMonth.code}-${dayFormatted}`,
        });
      })}
    >
      <div className="w-full flex flex-col gap-8 mt-2">
        <Input
          label={String(L.translate("KYC.first_name"))}
          placeholder={String(L.translate("KYC.enter_first_name"))}
          className="w-full"
          {...register("first_name")}
          required
          error={String(L.translate(errors.first_name?.message || ""))}
        />
        <Input
          label={String(L.translate("KYC.last_name"))}
          placeholder={String(L.translate("KYC.enter_last_name"))}
          className="w-full"
          {...register("last_name")}
          required
          error={String(L.translate(errors.last_name?.message || ""))}
        />
        <div className="flex flex-col gap-2">
          <label>
            {" "}
            <span className="block text-[16px] font-manrope font-medium text-secondary relative">
              <span className="text-[16px] text-[#F44336] font-manrope">
                *{" "}
              </span>
              {String(L.translate("KYC.date_of_birth"))}
            </span>
          </label>
          <div className="grid grid-cols-3 gap-4">
            <Controller
              name="dob_date"
              control={control}
              render={({ field }) => (
                <Dropdown
                  label={String(L.translate("Account.AccountSettings.date"))}
                  placeholder={
                    isMobile
                      ? String(L.translate("KYC.date"))
                      : String(L.translate("KYC.date_of_birth"))
                  }
                  className="w-full"
                  data={days}
                  error={String(L.translate(errors.dob_date?.message || ""))}
                  required
                  {...field}
                />
              )}
            />

            <Controller
              name="dob_month"
              control={control}
              render={({ field }) => (
                <Dropdown
                  label={String(L.translate("Account.AccountSettings.month"))}
                  placeholder={
                    isMobile
                      ? String(L.translate("KYC.month"))
                      : String(L.translate("KYC.month_of_birth"))
                  }
                  className="w-full"
                  data={months}
                  error={String(L.translate(errors.dob_month?.message || ""))}
                  required
                  {...field}
                />
              )}
            />
            <Controller
              name="dob_year"
              control={control}
              render={({ field }) => (
                <Dropdown
                  label={String(L.translate("Account.AccountSettings.year"))}
                  placeholder={
                    isMobile
                      ? String(L.translate("KYC.year"))
                      : String(L.translate("KYC.year_of_birth"))
                  }
                  className="w-full"
                  data={years}
                  error={String(L.translate(errors.dob_year?.message || ""))}
                  required
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <Controller
          name="country_id"
          control={control}
          render={({ field }) => (
            <DropdownWithSearch
              label={String(L.translate("Account.AccountSettings.country"))}
              placeholder={String(
                L.translate("Account.AccountSettings.enter_country")
              )}
              className="w-full"
              data={countries}
              error={String(L.translate(errors.country_id?.message || ""))}
              required
              {...field}
            />
          )}
        />
        <Input
          label={String(L.translate("KYC.address"))}
          placeholder={String(L.translate("KYC.enter_address"))}
          className="w-full"
          {...register("address")}
          required
          error={String(L.translate(errors.address?.message || ""))}
        />
        <Input
          label={String(L.translate("KYC.post_code"))}
          placeholder={String(L.translate("KYC.enter_post_code"))}
          className="w-full"
          {...register("post_code")}
          required
          error={String(L.translate(errors.post_code?.message || ""))}
        />
      </div>
      <div className="flex flex-row items-center justify-center mt-4">
        <Button
          type="submit"
          variant="validate"
          className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] w-full h-[64px]"
          disabled={!isValid}
        >
          {L.translate("KYC.next")}
        </Button>
      </div>
    </form>
  );
}
