export const CODE_EXPIRATION_TIME: number = 300;
export const CODE_EXPIRATION_TIME_LOGIN: number = 300;
export const LIST_PAGE_SIZE: number = 10;

export const BlockMsg =
  "Your account has been blocked, please contact the support for help";
export const NetworkDisconnectMsg =
  "Network is disconnected. Please check your internet connection!";
export const APISomethingWrongMsg =
  "Something went wrong. Please try again later!";

export const TransactionType = [
  {
    id: 1,
    name: "TransactionHistory.all",
    code: "all",
    active: 1,
  },
  {
    id: 2,
    name: "TransactionHistory.deposit",
    code: "deposit",
    active: 1,
  },
  {
    id: 3,
    name: "TransactionHistory.withdrawal",
    code: "withdraw",
    active: 1,
  },
];

export enum SUCCESS_MODAL_TYPE {
  ChangePassword = "change_password",
  KYC = "kyc",
  ForgotPassword = "forgot_password",
  EmailVerification = "email_verification",
  ExternaTransfer = "external_transfer",
}

export enum PAYMENT_TYPE {
  Deposit = "deposit",
  Withdrawal = "withdrawal",
}
export interface AssetDataType {
  key: React.Key;
  currency: string;
  amount: number;
  wallet_id: number;
  currency_id: number;
  name: string;
  img_path: string;
  vip_deposit_max: number;
  vip_deposit_min: number;
  vip_withdraw_max: number;
  vip_withdraw_min: number;
  deposit_max: number;
  deposit_min: number;
  withdraw_max: number;
  withdraw_min: number;
  depositable: number;
  withdrawable: number;
}

export interface PaymentDataType {
  key: React.Key;
  user_id: number;
  asset_id: number;
  amount: number;
  amount_fee: number;
  total_amount: number;
  payment_system: string;
  status: string;
  type: string;
  description: string;
  comment: string;
  to: string;
  withdraw_payment_system: string;
  refund_id: string;
  user_ip: string;
  created_at: number;
  updated_at: number;
  indicated_amount: number;
  asset_code: string;
  invoice: boolean;
  invoice_data?: any;
}

export interface TransferDataType {
  key: React.Key;
  user_id: number;
  asset_id: number;
  amount: number;
  to: string;
  from: string;
  created_at: number;
  asset_code: string;
}

export enum PAYMENT_OPTIONS {
  Paypal = "paypal",
  Bank = "bank_transfer",
  Card = "cartubank",
}

export interface KwickpayTransferType {
  key: React.Key;
  id: number;
  indicated_amount?: number;
  fee?: number;
  amount?: number;
  currency?: string;
  sender_name?: string;
  sender_country?: string;
  status?: string;
  created_at?: string;
  updated_at?: string;
}
