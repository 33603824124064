import { FC } from "react";
import L from "i18n-react";
import { AccountIcon } from "../icons/account-icon";
import Button from "../ui/button";
import { User } from "src/api/types";
import { formatAddress } from "src/lib/utils";
import { Spin } from "antd";

type UserDataCardProps = {
  user: User | null;
  loading: boolean;
  onVerification: any;
};

const UserDataCard: FC<UserDataCardProps> = ({
  user,
  loading,
  onVerification,
}) => {
  const isVerifed = false;

  return (
    <div className="relative flex flex-col w-full md:w-[55%] bg-white rounded-[12px] p-4">
      <div className="flex flex-row items-center w-full border-b-2 border-gray border-opacity-50 gap-2 pb-4">
        <div className="flex flex-row items-center w-full justify-between">
          <div className="flex flex-row items-center gap-2">
            <AccountIcon className="w-[20px] h-[20px] text-primary" />
            <p className="text-primary font-manrope text-[18px] font-medium leading-[28px]">
              {String(
                L.translate("Account.AccountSettings.changing_user_data")
              )}
            </p>
          </div>
          <p className="text-secondary font-manrope text-[14px] font-medium w-[130px] lg:w-[250px] flex-shrink-0">
            {String(L.translate("Account.AccountSettings.type_account"))}:&nbsp;
            <span className="text-primary font-manrope text-[14px] font-medium leading-[14px] block lg:inline">
              {user && user.is_business
                ? String(L.translate("Account.AccountSettings.business"))
                : String(L.translate("Account.AccountSettings.individual"))}
            </span>
          </p>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-row items-start w-full border-b border-gray border-opacity-50 py-3 gap-2 sm:gap-0">
          <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[160px] flex-shrink-0">
            {String(L.translate("Account.AccountSettings.first_name"))}
          </p>
          <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
            {user ? user.data.first_name ?? "-" : "-"}
          </p>
        </div>
        <div className="flex flex-row items-start w-full border-b border-gray border-opacity-50 py-3 gap-2 sm:gap-0">
          <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[160px] flex-shrink-0">
            {String(L.translate("Account.AccountSettings.last_name"))}
          </p>
          <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
            {user ? user.data.last_name ?? "-" : "-"}
          </p>
        </div>
        <div className="flex flex-row items-start w-full border-b border-gray border-opacity-50 py-3 gap-2 sm:gap-0">
          <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[160px] flex-shrink-0">
            {String(L.translate("Account.AccountSettings.address"))}
          </p>
          <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
            {user ? formatAddress(user.data) : "-"}
          </p>
        </div>
        <div className="flex flex-row items-start w-full border-b border-gray border-opacity-50 py-3 gap-2 sm:gap-0">
          <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[160px] flex-shrink-0">
            {String(L.translate("Account.AccountSettings.birth_date"))}
          </p>
          <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
            {user ? user.data.date_of_birth ?? "-" : "-"}
          </p>
        </div>
        <div className="flex flex-row items-start w-full border-b border-gray border-opacity-50 py-3 gap-2 sm:gap-0">
          <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[160px] flex-shrink-0">
            {String(L.translate("Account.AccountSettings.email"))}
          </p>
          <p className="text-primary truncate font-manrope text-[14px] font-medium flex-shrink">
            {user ? user.email : "-"}
          </p>
        </div>
        <div className="flex flex-row items-start w-full border-b border-gray border-opacity-50 py-3 gap-2 sm:gap-0">
          <p className="text-secondary font-manrope text-[14px] lg:my-auto font-medium w-[100px] sm:w-[160px] flex-shrink-0">
            {String(L.translate("Account.AccountSettings.kyc"))}
          </p>
          {user &&
            (user.status.name == "pending" || user.status.name == "approved" ? (
              user.status.name == "pending" ? (
                <p className="text-error font-manrope text-[14px] font-medium flex-shrink">
                  {String(L.translate("UserKycStatus.pending"))}
                </p>
              ) : (
                <p className="text-success font-manrope text-[14px] font-medium flex-shrink">
                  {String(L.translate("UserKycStatus.approved"))}
                </p>
              )
            ) : (
              <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between md:flex-col md:items-start lg:flex-row lg:items-center lg:justify-between gap-2 w-full">
                {user.status.name == "rejected" ? (
                  <p className="text-error font-manrope text-[14px] font-medium flex-shrink">
                    {String(L.translate("UserKycStatus.rejected"))}
                  </p>
                ) : (
                  <p className="text-error font-manrope text-[14px] font-medium flex-shrink">
                    {String(L.translate("UserKycStatus.unverified"))}
                  </p>
                )}
                <Button
                  variant="outline"
                  className="border-success rounded-[8px] text-success text-[14px] font-manrope font-medium"
                  onClick={onVerification}
                >
                  {String(
                    L.translate("Account.AccountSettings.start_verification")
                  )}
                </Button>
              </div>
            ))}
        </div>
      </div>
      {loading && (
        <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
      )}
    </div>
  );
};

export default UserDataCard;
