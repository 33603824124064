import React, { useState, ChangeEvent } from "react";

interface FormState {
  beneficiarName: string;
  bankName: string;
  swift: string;
  iban: string;
  purposeOfPayment: string;
  address: string;
}

interface FormMethodsProps {
  handleAddAccountSubmit: (data: FormState) => void;
  accountFormData: FormState;
}

const FormMethods: React.FC<FormMethodsProps> = ({
  handleAddAccountSubmit,
  accountFormData,
}) => {
  const [formState, setFormState] = useState<FormState>(accountFormData);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const placeholders = {
    beneficiarName: "Beneficiar Name",
    bankName: "Bank Name",
    swift: "SWIFT",
    iban: "IBAN",
    purposeOfPayment: "Purpose of Payment",
    address: "Address",
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `Field is required`,
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }

    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleButtonClick = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      handleAddAccountSubmit(formState);
    }
  };

  const validateForm = () => {
    var result = true;

    if (!formState.beneficiarName.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["beneficiarName"]: `Field is required`,
      }));
      result = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, ["beneficiarName"]: "" }));
      setFormState((prevData) => ({
        ...prevData,
        ["beneficiarName"]: formState.beneficiarName.trim(),
      }));
    }

    if (!formState.bankName.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["bankName"]: `Field is required`,
      }));
      result = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, ["bankName"]: "" }));
      setFormState((prevData) => ({
        ...prevData,
        ["bankName"]: formState.beneficiarName.trim(),
      }));
    }

    if (!formState.swift.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["swift"]: `Field is required`,
      }));
      result = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, ["swift"]: "" }));
      setFormState((prevData) => ({
        ...prevData,
        ["swift"]: formState.swift.trim(),
      }));
    }

    if (!formState.iban.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["iban"]: `Field is required`,
      }));
      result = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, ["iban"]: "" }));
      setFormState((prevData) => ({
        ...prevData,
        ["iban"]: formState.iban.trim(),
      }));
    }

    if (!formState.purposeOfPayment.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["purposeOfPayment"]: `Field is required`,
      }));
      result = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, ["purposeOfPayment"]: "" }));
      setFormState((prevData) => ({
        ...prevData,
        ["purposeOfPayment"]: formState.iban.trim(),
      }));
    }

    if (!formState.address.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["address"]: `Field is required`,
      }));
      result = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, ["address"]: "" }));
      setFormState((prevData) => ({
        ...prevData,
        ["address"]: formState.iban.trim(),
      }));
    }

    return result;
  };

  return (
    <form onSubmit={handleButtonClick}>
      <h2 className="text-primary text-center font-manrope text-[20px] not-italic font-medium leading-[30px]">
        Add new account
      </h2>
      <p className="text-[#7F8172] text-center font-[Manrope] text-[16px] not-italic font-light leading-[24px]">
        Enter bank details information
      </p>
      <div className="flex flex-col gap-[18px] my-[45px]">
        {Object.entries(formState).map(([key, value]) => (
          <label
            key={key}
            htmlFor={key}
            className="flex flex-col gap-[10px] text-start text-[#7F8172] font-manrope text-[16px] not-italic font-medium leading-[normal]"
          >
            {key.charAt(0).toUpperCase() +
              key.slice(1).replace(/([A-Z])/g, " ")}
            <input
              placeholder={placeholders[key as keyof typeof placeholders]}
              value={value}
              name={key}
              id={key}
              type="text"
              className={`text-[#BCBEAD] p-[19px] font-manrope text-[16px] not-italic font-medium leading-[normal] rounded-[12px] border-[1px] border-[solid] border-[#BCBEAD] ${
                errors[key] && "border-red-500"
              }`}
              onChange={handleInputChange}
            />
            <span className="text-red-500 text-[12px]">{errors[key]}</span>
          </label>
        ))}
      </div>
      <div className="w-full text-center">
        <button className="rounded-[12px] w-full py-[21px] bg-[#BCBEAD]">
          Continue
        </button>
      </div>
    </form>
  );
};

export default FormMethods;
