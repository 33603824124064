import cn from "classnames";
import routes from "../config/routes";
import React, { useEffect, useState } from "react";
import { BalanceIcon } from "../components/icons/balance-icon";
import L from "i18n-react";
import { TransactionHistoryIcon } from "../components/icons/transaction-history-icon";
import { SettingsIcon } from "../components/icons/settings-icon";
import { TwoFactorAuthIcon } from "../components/icons/2fa-icon";
import { NotificationIcon } from "../components/icons/notification-icon";
import { PaymentIcon } from "../components/icons/payment-icon";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoutIcon } from "../components/icons/logout-icon";
import { useLogout } from "../hooks/user";
import useAuth from "../hooks/use-auth";
import useKYCStatus from "src/hooks/use-kyc-status";
import { FastTransfersIcon } from "../components/icons/fast-transfers";

interface NavLinkProps {
  href: string;
  setCollapse?: any;
  title: string;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  isCollapse?: boolean;
}

function NavLink({
  href,
  setCollapse,
  icon,
  activeIcon,
  title,
  isCollapse,
}: NavLinkProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { unauthorize } = useAuth();
  const { mutate: logout } = useLogout();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(false);
    if (location.pathname == "/")
      setIsMobile(window.innerWidth < 640 ? true : false);
    else setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const onClick = () => {
    isMobile && setCollapse(false);

    if (href == routes.logout) {
      logout();
      setTimeout(() => {
        navigate(routes.home);
      }, 1200);
    } else {
      navigate(href);
    }
  };
  return (
    <a
      onClick={onClick}
      className="my-0.5 flex items-center gap-1 px-4 py-4 hover:bg-light-300 hover:dark:bg-dark-300 xs:px-6 sm:my-1 sm:gap-1.5 sm:px-7 lg:gap-2 xl:my-0.5 xl:ml-4 cursor-pointer"
    >
      <span
        className={cn(
          "flex flex-shrink-0 items-center justify-start",
          isCollapse ? "w-[20px] xl:w-auto" : "w-auto xl:w-[20px]"
        )}
      >
        {location.pathname.includes(href) ? activeIcon : icon}
      </span>
      <span
        className={cn(
          "font-manrope font-medium text-[14px] dark:text-light-400 transition-all ease-out duration-300 truncate",
          isCollapse
            ? "inline-flex xl:opacity-0 opacity-100"
            : "opacity-0 xl:inline-flex xl:opacity-100",
          location.pathname.includes(href)
            ? "text-primary"
            : href == routes.logout
            ? "text-error"
            : "text-secondary"
        )}
      >
        {title}
      </span>
    </a>
  );
}

export default function Sidebar({
  isMobile,
  isCollapse,
  setCollapse,
  className = `sm:flex bottom-0 z-20 pt-16 ${isMobile ? "fixed" : "static"}`,
}: //className = "sm:flex fixed bottom-0 pb-[100px] sm:pb-[124px] 2xl:pb-[184px] z-20 pt-16",
{
  isMobile?: boolean;
  isCollapse?: boolean;
  setCollapse?: any;
  className?: string;
}) {
  const location = useLocation();
  const { isNotVerified } = useKYCStatus();

  return (
    <aside
      className={cn(
        "h-full flex-col justify-between overflow-y-auto border-r border-light-400 bg-light text-dark-900 dark:border-0 dark:bg-dark-200 transition-all duration-300 sm:pt-[11px]",
        isCollapse
          ? "sm:w-60 xl:w-[75px] w-[220px] left-0 z-40"
          : "sm:w-[75px] xl:w-80 w-[220px] -left-[250px] sm:left-0",
        className
      )}
    >
      <div className="flex h-full w-full flex-col overflow-y-auto scrollbar-hide">
        <nav className="flex flex-col mt-2 mb-12">
          <NavLink
            title={String(L.translate("Sidebar.balance"))}
            setCollapse={setCollapse}
            href={routes.balance}
            isCollapse={isCollapse}
            icon={<BalanceIcon className="h-[20px] w-[20px] text-secondary" />}
            activeIcon={
              <BalanceIcon className="h-[20px] w-[20px] text-primary" />
            }
          />
          <NavLink
            title={String(L.translate("Sidebar.fast_transfers"))}
            setCollapse={setCollapse}
            href={routes.fastTransfers}
            isCollapse={isCollapse}
            icon={
              <FastTransfersIcon className="h-[20px] w-[20px] text-secondary" />
            }
            activeIcon={
              <FastTransfersIcon className="h-[20px] w-[20px] text-primary" />
            }
          />
          <NavLink
            title={String(L.translate("Sidebar.transaction_history"))}
            setCollapse={setCollapse}
            href={routes.transactionHistory}
            isCollapse={isCollapse}
            icon={
              <TransactionHistoryIcon className="h-[20px] w-[20px] text-secondary" />
            }
            activeIcon={
              <TransactionHistoryIcon className="h-[20px] w-[20px] text-primary" />
            }
          />
          <div className="my-0.5 px-4 xs:px-6 sm:my-1 sm:px-7 xl:my-0.5">
            <hr className="h-[1px] border-t-0 bg-[#BCBEAD] opacity-50 dark:opacity-50" />
          </div>
          <NavLink
            title={String(L.translate("Sidebar.settings"))}
            setCollapse={setCollapse}
            href={routes.settings}
            isCollapse={isCollapse}
            icon={<SettingsIcon className="h-[20px] w-[20px] text-secondary" />}
            activeIcon={
              <SettingsIcon className="h-[20px] w-[20px] text-primary" />
            }
          />
          <NavLink
            title={String(L.translate("Sidebar.2FA_settings"))}
            setCollapse={setCollapse}
            href={routes.twoFactorAuth}
            isCollapse={isCollapse}
            icon={
              <TwoFactorAuthIcon className="h-[20px] w-[20px] text-secondary" />
            }
            activeIcon={
              <TwoFactorAuthIcon className="h-[20px] w-[20px] text-primary" />
            }
          />
          <NavLink
            title={String(L.translate("Sidebar.notifications"))}
            setCollapse={setCollapse}
            href={routes.notifications}
            isCollapse={isCollapse}
            icon={
              <NotificationIcon className="h-[20px] w-[20px] text-secondary" />
            }
            activeIcon={
              <NotificationIcon className="h-[20px] w-[20px] text-primary" />
            }
          />
          <NavLink
            title={String(L.translate("Sidebar.payment_method"))}
            setCollapse={setCollapse}
            href={routes.paymentMethod}
            isCollapse={isCollapse}
            icon={<PaymentIcon className="h-[20px] w-[20px] text-secondary" />}
            activeIcon={
              <PaymentIcon className="h-[20px] w-[20px] text-primary" />
            }
          />
          <div className="my-0.5 px-4 xs:px-6 sm:my-1 sm:px-7 xl:my-0.5">
            <hr className="h-[1px] border-t-0 bg-[#BCBEAD] opacity-50 dark:opacity-50" />
          </div>
          <NavLink
            title={String(L.translate("Sidebar.Logout"))}
            setCollapse={setCollapse}
            href={routes.logout}
            isCollapse={isCollapse}
            icon={<LogoutIcon className="h-[20px] w-[20px] text-error" />}
            activeIcon={
              <LogoutIcon className="h-[20px] w-[20px] text-primary" />
            }
          />
        </nav>
      </div>
    </aside>
  );
}
