import { useState, useEffect, FC, ReactNode } from "react";
import GlobalContext from "./global-context";
import { ConfigValue } from "src/config";
import L from "i18n-react";
import { languages } from "../languages";

export default function GlobalContextWrapper({
  children,
}: React.PropsWithChildren<{}>) {
  const url = window.location.href;
  const urlObject = new URL(url);
  const lang = urlObject.searchParams.get("lang");

  const [isAlertOpened, setIsAlertOpened] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [language, setLanguage] = useState(
    localStorage.getItem(ConfigValue.LANGUAGE) || "EN"
  );
  L.setTexts(languages[language]);

  const handleSetLanguage = (value: string) => {
    localStorage.setItem(ConfigValue.LANGUAGE, value);
    setLanguage(value);
    console.log(value);

    if (lang) {
      urlObject.searchParams.delete("lang");
      window.history.replaceState({}, "", urlObject.toString());
    }
  };

  useEffect(() => {
    if (lang) {
      localStorage.setItem(ConfigValue.LANGUAGE, lang);
      setLanguage(lang);
    }
  }, [lang]);

  return (
    <GlobalContext.Provider
      value={{
        isAlertOpened,
        setIsAlertOpened,
        alertText,
        setAlertText,
        alertTitle,
        setAlertTitle,
        language,
        setLanguage: handleSetLanguage,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
