import { FC, useEffect, useState } from "react";
import { CheckIcon } from "src/components/icons/check-icon";
import Button from "src/components/ui/button";
import { CloseIcon } from "src/components/icons/close-icon";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import L from "i18n-react";

type RequestResultPageProps = {
  type: string;
};

const RequestResultPage: FC<RequestResultPageProps> = ({ type }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isSuccess, setSuccess] = useState(false);
  useEffect(() => {
    if (location.state) {
      setSuccess(location.state.success);
    }
  }, [location.state]);

  const makeTitle = () => {
    if (type === "deposit") {
      return isSuccess
        ? String(L.translate("Base.Modals.deposit.success"))
        : String(L.translate("Base.Modals.deposit.failed"));
    } else {
      return isSuccess
        ? String(L.translate("Base.Modals.withdrawal.success"))
        : String(L.translate("Base.Modals.withdrawal.failed"));
    }
  };

  const makeDescr = () => {
    let description: string = "";
    if (type === "deposit") {
      description = isSuccess
        ? String(L.translate("Base.Modals.deposit.confirmed"))
        : `${String(
            L.translate("Base.Modals.deposit.something_went_wrong")
          )}.${"\n"}${String(L.translate("Base.Modals.deposit.try_again"))}`;
    } else {
      description = isSuccess
        ? String(L.translate("Base.Modals.withdrawal.confirmed"))
        : `${String(
            L.translate("Base.Modals.withdrawal.something_went_wrong")
          )}.${"\n"}${String(L.translate("Base.Modals.withdrawal.try_again"))}`;
    }

    let newText = description.split("\n").map((i) => {
      return (
        <p className="font-manrope text-[14px] font-medium text-secondary  text-center">
          {i}
        </p>
      );
    });

    return newText;
  };

  const makeActions = () => {
    if (type === "deposit") {
      return (
        <div className="w-full">
          <Button
            variant="solid"
            className="w-full rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[60px] bg-active hover:bg-main"
            onClick={() => navigate(routes.deposit)}
          >
            {String(L.translate("Base.Modals.deposit.back"))}
          </Button>
        </div>
      );
    } else {
      return isSuccess ? (
        <div className="w-full flex flex-row gap-2 items-center justify-between mt-2">
          <Button
            variant="solid"
            className="w-full rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[60px] bg-[#BCBEAD] hover:bg-light-600"
            onClick={() => navigate(routes.balance)}
          >
            {String(L.translate("Base.Modals.deposit.view_balance"))}
          </Button>
          <Button
            variant="solid"
            className="w-full rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[60px] bg-active hover:bg-main"
            onClick={() => navigate(routes.transactionHistory)}
          >
            {String(L.translate("Base.Modals.deposit.view_history"))}
          </Button>
        </div>
      ) : (
        <div className="w-full">
          <Button
            variant="solid"
            className="w-full rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[60px] bg-active hover:bg-main"
            onClick={() => navigate(routes.withdraw)}
          >
            {String(L.translate("Base.Modals.withdrawal.back"))}
          </Button>
        </div>
      );
    }
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col py-2 px-4 sm:p-4 sm:pr-11">
        <div className="flex flex-col bg-white rounded-[12px] w-full h-[578px] items-center justify-center">
          <div className="flex flex-col w-full sm:w-[498px] items-center justify-start p-2 gap-4">
            {isSuccess ? (
              <div className="w-[68px] h-[68px] rounded-full bg-active flex items-center justify-center">
                <CheckIcon className="w-[20px] h-[20px] text-primary" />
              </div>
            ) : (
              <div className="w-[68px] h-[68px] rounded-full bg-error flex items-center justify-center">
                <CloseIcon className="w-[20px] h-[20px] text-white" />
              </div>
            )}
            <div className="flex flex-col w-full px-4 sm:px-20">
              <p className="font-manrope text-[24px] font-medium text-primary text-center">
                {makeTitle()}
              </p>
              {makeDescr()}
            </div>
            {makeActions()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestResultPage;
