import { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import { BankCard } from "../../api/types";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { NotificationIcon } from "src/components/icons/notification-icon";
import NotificationCard from "src/components/notification/card";
import { Spin } from "antd";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";

const NotificationPage: FC = () => {
  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [cards, setCards] = useState<BankCard[]>([]);

  const [enabledEmail, setEnabledEmail] = useState(false);
  const [enabledSMS, setEnabledSMS] = useState(false);

  const { mutate: getNotificationStatus, isLoading } = useMutation(
    client.notification.get,
    {
      onSuccess: (data) => {
        console.log(data);
        setEnabledEmail(data.email_enabled);
        setEnabledSMS(data.sms_enabled);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: updateNotificationStatus } = useMutation(
    client.notification.update,
    {
      onSuccess: (data) => {
        console.log(data);
        setEnabledEmail(data.email_enabled);
        setEnabledSMS(data.sms_enabled);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    isMounted && getNotificationStatus();
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted) return;

    updateNotificationStatus({
      sms_enabled: enabledSMS,
      email_enabled: enabledEmail,
    });
  }, [enabledEmail, enabledSMS]);

  const onValueChanged = (type: string, value: boolean) => {
    type === "email" ? setEnabledEmail(value) : setEnabledSMS(value);
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col py-2 px-4 sm:p-4 sm:pr-11">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          {String(L.translate("Account.Notifications.notifications"))}
        </p>

        <div className="relative flex flex-col bg-white rounded-[12px] mt-2 sm:mt-4 p-4 gap-4">
          {isLoading && (
            <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
          )}
          <div className="w-full flex flex-row gap-2 items-center">
            <NotificationIcon className="w-[24px] h-[24px] text-primary" />
            <p className="font-manrope text-[18px] font-medium text-primary leading-[24px]">
              {String(L.translate("Account.Notifications.notifications"))}
            </p>
          </div>
          <div className="w-full">
            <hr className="h-[1px] border-0 bg-[#E6E8EC] opacity-50 dark:opacity-50" />
          </div>
          <NotificationCard
            type="email"
            value={enabledEmail}
            onValueChanged={onValueChanged}
          />
          <NotificationCard
            type="sms"
            value={enabledSMS}
            onValueChanged={onValueChanged}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
