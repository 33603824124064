import { FC } from "react";
import { ITableData } from "./types";

const TableRow: FC<ITableData> = ({
  account,
  rate,
  limit,
  period,
  kwikpay,
}) => {
  return (
    <div
      className={`grid ${
        kwikpay
          ? "grid-cols-[1fr_1fr_0.4fr]"
          : "grid-cols-[1.5fr_1fr_1.2fr_0.4fr]"
      } border-b-[1px] border-b-[#EEEEEE]`}
    >
      {!kwikpay && (
        <div className="py-[22px] font-medium text-[14px] text-primary min-w-[130px]">
          {account}
        </div>
      )}
      <div className="pl-2 py-[22px] font-medium text-[14px] text-primary min-w-[150px]">
        {rate}
      </div>
      <div className="pl-2 py-[22px] font-medium text-[14px] text-primary min-w-[150px]">
        {limit}
      </div>
      <div className="pl-2 pr-5 py-[22px] font-medium text-[14px] text-primary text-right">
        {period}
      </div>
    </div>
  );
};

export default TableRow;
