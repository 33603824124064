import { FC, useContext } from "react";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";
import LocationImageUrl from "../../assets/images/location.png";
const AboutUs: FC = () => {
  const { language } = useContext(GlobalContext);
  return (
    <div className="py-[100px] font-manrope">
      <div className="w-[990px] max-w-[100%] mx-auto px-[30px] lg:px-[16px]">
        <h2 className="mb-[10px] text-[34px] font-medium leading-[1.4] text-primary">
          {L.translate("ContactUs.title")}
        </h2>
        <div>
          <p className="mb-[90px] text-secondary font-medium text-lg">
            {L.translate("ContactUs.description")}
          </p>
        </div>
      </div>
      <div className="w-[990px] max-w-[100%] mx-auto px-[30px] lg:px-[16px] flex flex-col sm:flex-row sm:justify-between gap-5">
        <div>
          <h2 className="mb-[10px] text-[24px] font-semibold leading-6 text-primary">
            {L.translate("ContactUs.email")}
          </h2>
          <div>
            <p className="mb-[32px] text-secondary font-medium text-xl">
              support@fastoo.com
            </p>
          </div>
        </div>
        <div>
          <h2 className="mb-[10px] text-[24px] font-semibold leading-6 text-primary">
            {L.translate("ContactUs.address")}
          </h2>
          <div>
            <p className="mb-[100px] text-secondary font-medium text-xl">
              Georgia, Tbilisi, D.Agmashenebeli Ave. №150, Post code 0102
            </p>
          </div>
        </div>
      </div>
      <div className="w-[990px] max-w-[100%] mx-auto px-[30px] lg:px-[16px]">
        <h2 className="mb-[10px] text-[34px] font-medium leading-[51px] text-primary">
          {L.translate("ContactUs.location")}
        </h2>
        <div className="w-full h-fit">
          <iframe
            width="100%"
            height="577"
            frameBorder={0}
            scrolling="no"
            marginHeight={0}
            marginWidth={0}
            src="https://maps.google.com/maps?width=100%25&amp;height=577&amp;hl=en&amp;q=150%20D.Agmashenebeli%20Street,%20Tbilisi,%20Georgia+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.gps.ie/">gps tracker sport</a>
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
