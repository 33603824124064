import React, { useState } from "react";

const Popup = ({ onClose, content, continuebtn, classMaxW, handleAddAccountSubmit }: any) => {
    const [currentContent, setCurrentContent] = useState(content);
    const [continueButtonVisible, setContinueButtonVisible] = useState(true);

    const maxWidthStyle = classMaxW ? { maxWidth: classMaxW } : {};

    return (
        <div
            className={`popup-body rounded-[12px] p-[24px] bg-[#fff] fixed top-[0] left-[0] flex justify-center items-center w-full right-[0] bottom-[0] m-auto h-[fit-content]`}
            style={maxWidthStyle}
        >
            <div className="w-full text-center ">
                {currentContent}

                {!continuebtn ? (
                    <div className="w-full text-center">
                        <button
                            className="rounded-[12px] w-full py-[21px] bg-[#C3E600]"
                            onClick={onClose}
                        >
                            Okay
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Popup;
