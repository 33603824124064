export function CurrencyGBPIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 128 128"
    >
      <rect width="122" height="122" x="3" y="3" fill="#998625" rx="61"></rect>
      <rect
        width="122"
        height="122"
        x="3"
        y="3"
        stroke="#998625"
        strokeWidth="6"
        rx="61"
      ></rect>
      <path
        stroke="#fff"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="11"
        d="M82.173 44.017C81.308 37.311 76.462 24.473 64 26.772c-15.577 2.874-18.173 20.12-10.385 37.364 6.231 13.796-7.788 30.658-15.577 37.364 4.327-2.874 16.828-10.943 28.558-5.748 12.98 5.748 20.77.958 23.365 0"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="10"
        d="M73.81 68.04H39.193"
      ></path>
    </svg>
  );
}
