import { FC, useContext } from "react";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";

const AboutUs: FC = () => {
  const { language } = useContext(GlobalContext);
  return (
    <div className="py-[100px] font-manrope">
      <div className="w-[1022px] max-w-[100%] mx-auto px-[16px]">
        <h2 className="mb-[10px] text-[34px] font-medium leading-[1.4]">
          {L.translate("AboutUs.title")}
        </h2>
        <div>
          <p className="mb-[32px] text-secondary font-medium">
            {L.translate("AboutUs.p_1")}
          </p>
          <p className="mb-[32px] text-secondary font-medium">
            {L.translate("AboutUs.p_2")}
          </p>
          <p className="mb-[32px] text-secondary font-medium">
            {L.translate("AboutUs.p_3")}
          </p>
          {language !== "GE" && (
            <p className="mb-[32px] text-secondary font-medium">
              {L.translate("AboutUs.p_4")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
