import { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import { IbanAccount } from "../../api/types";
import Button from "../../components/ui/button";
import GlobalContext from "src/context/global-context";
import { PaymentIcon } from "src/components/icons/payment-icon";
import { BankCardIcon } from "src/components/icons/bank-card-icon";
import { DeleteIcon } from "src/components/icons/delete-icon";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Spin } from "antd";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";

interface Props {
  data: IbanAccount[];
  isLoading?: boolean;
  onRefetch: () => void;
}

const IbanTable: FC<Props> = ({ data: ibans, isLoading, onRefetch }) => {
  const { openModal } = useModalAction();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const isMounted = useIsMounted();

  const onDeleteCard = (idx: number) => {
    openModal("DELETE_IBAN_CONFIRM", {
      id: ibans[idx].id,
      iban: ibans[idx].iban,
      callback: onRefetch,
    });
  };

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  return (
    <>
      {isMobile ? (
        <>
          <div className="w-full mt-8">
            <hr className="h-[1px] border-0 bg-[#E6E8EC] opacity-50 dark:opacity-50" />
          </div>
          {isLoading ? (
            <Spin className="absolute inset-0 z-40  flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
          ) : (
            ibans.map((item, index) => (
              <div
                key={index}
                className="w-ful text-[14px] font-manrope grid grid-cols-2 border-b-[1px] border-[#E6E8EC] border-opacity-50 py-1 gap-2 items-center"
              >
                <div>
                  <p className="text-[14px] font-manrope text-secondary text-left">
                    {String(
                      L.translate("Account.PaymentMethods.beneficiary_name")
                    )}
                  </p>
                  <p className="text-[13px]">{item.beneficiary_name}</p>
                </div>
                <div>
                  <p className="text-[14px] font-manrope text-secondary text-left">
                    {String(L.translate("Account.PaymentMethods.bank_name"))}
                  </p>
                  <p className="text-[13px]">{item.bank_name}</p>
                </div>
                <div>
                  <p className="text-[14px] font-manrope text-secondary text-left">
                    {String(L.translate("Account.PaymentMethods.swift"))}
                  </p>
                  <p className="text-[13px]">{item.swift}</p>
                </div>
                <div>
                  <p className="text-[14px] font-manrope text-secondary text-left">
                    {String(L.translate("Account.PaymentMethods.iban"))}
                  </p>
                  <p className="text-[13px]">{item.iban}</p>
                </div>
                <div>
                  <p className="text-[14px] w-[40%] font-manrope text-secondary text-left">
                    {String(L.translate("Account.PaymentMethods.address"))}
                  </p>
                  <p className="text-[13px] w-[60%]"> {item.address}</p>
                </div>
                <div className="w-[20%]">
                  <p className="text-[14px] font-manrope text-secondary text-left">
                    {String(L.translate("Account.PaymentMethods.action"))}
                  </p>
                  <Button variant="icon" onClick={() => onDeleteCard(index)}>
                    <DeleteIcon className="w-[20px] h-[20px] text-primary hover:text-light-600" />
                  </Button>
                </div>
              </div>
            ))
          )}
        </>
      ) : (
        <>
          <div className="w-full mt-8">
            <hr className="h-[1px] border-0 bg-[#E6E8EC] opacity-50 dark:opacity-50" />
          </div>
          <div className="w-ful flex flex-row border-b-[1px] border-[#E6E8EC] border-opacity-50 py-4 gap-2">
            <p className="text-[14px] w-[40%] font-manrope text-secondary text-left font-light">
              {String(L.translate("Account.PaymentMethods.beneficiary_name"))}
            </p>
            <p className="text-[14px] w-[40%]  font-manrope text-secondary text-left font-light">
              {String(L.translate("Account.PaymentMethods.bank_name"))}
            </p>
            <p className="text-[14px] w-[40%]  font-manrope text-secondary text-left font-light">
              {String(L.translate("Account.PaymentMethods.currency"))}
            </p>
            <p className="text-[14px] w-[40%]  font-manrope text-secondary text-left font-light">
              {String(L.translate("Account.PaymentMethods.swift"))}
            </p>
            <p className="text-[14px] w-[40%]  font-manrope text-secondary text-left font-light">
              {String(L.translate("Account.PaymentMethods.iban"))}
            </p>
            <p className="text-[14px] w-[40%]  font-manrope text-secondary text-left font-light">
              {String(L.translate("Account.PaymentMethods.address"))}
            </p>
            <p className="text-[14px] w-[20%]  font-manrope text-secondary text-left font-light"></p>
          </div>
          {isLoading ? (
            <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
          ) : (
            ibans.map((item, index) => (
              <div
                key={index}
                className="w-ful flex flex-row border-b-[1px] border-[#E6E8EC] border-opacity-50 py-4 gap-2 items-center"
              >
                <p className="text-[14px] w-[40%] truncate hover:overflow-wrap-anywhere font-manrope text-primary text-left font-light leading-[24px]">
                  {item.beneficiary_name}
                </p>
                <p className="text-[14px] w-[40%] truncate hover:overflow-wrap-anywhere font-manrope text-primary text-left font-light leading-[24px]">
                  {item.bank_name}
                </p>
                <p className="text-[14px] w-[40%] truncate hover:overflow-wrap-anywhere font-manrope text-primary text-left font-light leading-[24px]">
                  {item.currency_code?.toUpperCase()}
                </p>
                <p className="text-[14px] w-[40%] truncate hover:overflow-wrap-anywhere font-manrope text-primary text-left font-light leading-[24px]">
                  {item.swift}
                </p>
                <p className="text-[14px] w-[40%] truncate hover:overflow-wrap-anywhere font-manrope text-primary text-left font-light leading-[24px]">
                  {item.iban}
                </p>
                <p className="text-[14px] w-[40%] truncate hover:overflow-wrap-anywhere font-manrope text-primary text-left font-light leading-[24px]">
                  {item.address}
                </p>
                <div className="w-[20%]">
                  <Button
                    variant="icon"
                    className="ml-auto"
                    onClick={() => onDeleteCard(index)}
                  >
                    <DeleteIcon className="w-[20px] h-[20px] text-primary hover:text-light-600" />
                  </Button>
                </div>
              </div>
            ))
          )}
        </>
      )}
    </>
  );
};

export default IbanTable;
