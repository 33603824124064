import { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import Button from "../ui/button";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import useAuth from "src/hooks/use-auth";
import useKYCStatus from "src/hooks/use-kyc-status";
import GlobalContext from "src/context/global-context";

type BalanceActionsProps = {};

const BalanceActions: FC<BalanceActionsProps> = ({}) => {
  const navigate = useNavigate();
  const { setAlertText, setIsAlertOpened, setAlertTitle } =
    useContext(GlobalContext);
  const { getCurrent2FAInfo } = useAuth();
  const { isVerifed } = useKYCStatus();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { language } = useContext(GlobalContext);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  const onWithdraw = () => {
    setAlertTitle(String(L.translate("Alert.Information")));

    if (!isVerifed) {
      setAlertText(String(L.translate("Errors.withdraw_account")));
      setIsAlertOpened(true);
      return;
    }

    /*if (getCurrent2FAInfo().google2fa_enabled === 0) {
      setAlertText(String(L.translate("Errors.google_2fa_account")));
      setIsAlertOpened(true);
      return;
    }*/

    navigate(routes.withdraw);
  };

  const onDeposit = () => {
    setAlertTitle(String(L.translate("Alert.Information")));

    if (!isVerifed) {
      console.log(String(L.translate("Errors.deposit_account")));

      setAlertText(String(L.translate("Errors.deposit_account")));
      setIsAlertOpened(true);
      return;
    }

    navigate(routes.deposit);
  };

  return (
    <div className="w-full flex flex-row items-center justify-between gap-2">
      <p className="hidden sm:block text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
        {L.translate("Balance.title")}
      </p>
      <div className="w-full sm:w-auto flex flex-row gap-4 sm:gap-2 lg:gap-4 items-center justify-between bg-white sm:bg-transparent p-2 px-4 sm:p-0">
        <Button
          variant="solid"
          onClick={onDeposit}
          className={`${
            language == "GE" ? "h-[56px]" : "h-[40px]"
          } rounded-[10px] font-manrope text-[16px] font-medium leading-[24px] bg-active hover:bg-main w-full sm:w-auto`}
        >
          {L.translate("Buttons.deposit")}
        </Button>
        <Button
          variant="outline"
          onClick={onWithdraw}
          className={`${
            language == "GE" ? "h-[56px]" : "h-[40px]"
          } rounded-[10px] font-manrope text-[16px] font-medium leading-[24px w-full sm:w-auto border border-secondary`}
        >
          {L.translate("Buttons.withdraw")}
        </Button>
        <Button
          variant="text"
          onClick={() => navigate(routes.transactionHistory)}
          className={`${
            language == "GE" ? "h-[56px]" : "h-[40px]"
          } hidden sm:block font-manrope text-[16px] font-medium leading-[24px] text-primary w-full sm:w-auto`}
        >
          {L.translate("Balance.tr_history")}
        </Button>
        <Button
          variant="solid"
          onClick={() => navigate(routes.transactionHistory)}
          className={`${
            language == "GE" ? "h-[56px]" : "h-[40px]"
          } block sm:hidden rounded-[10px] font-manrope text-[16px] font-medium leading-[24px] bg-white hover:bg-light-600 w-full sm:w-auto border border-secondary`}
        >
          {L.translate("Balance.history")}
        </Button>
      </div>
    </div>
  );
};

export default BalanceActions;
