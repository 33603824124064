import React, {
  FC,
  useState,
  useEffect,
  useContext,
  KeyboardEvent,
  useCallback,
  useRef,
} from "react";
import Input from "../../components/ui/input";
import Button from "../../components/ui/button";
import L from "i18n-react";
import ReCAPTCHA from "react-google-recaptcha";
import { ResetIcon } from "../../components/icons/reset-icon";
import { BackIcon } from "../../components/icons/back-icon";
import { useNavigate, Navigate } from "react-router-dom";
import { fadeInOut } from "../../lib/framer-motion/fade-in-out";
import { motion } from "framer-motion";
import routes from "../../config/routes";
import { getLS } from "src/lib/utils";
import { useMutation } from "react-query";
import client from "../../api";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import useRecaptchaObserver from "src/hooks/hook-obs";
import useAuth from "../../hooks/use-auth";
import { getErrorMessage, saveKYCStatus } from "../../lib/utils";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ConfigValue } from "src/config";
import { useModalAction } from "../../components/modal-views/context";
import GlobalContext from "src/context/global-context";
import InputWithTimer from "src/components/ui/input-timer";
import {
  APISomethingWrongMsg,
  NetworkDisconnectMsg,
  CODE_EXPIRATION_TIME_LOGIN,
} from "src/lib/constants";
import InputCode from "src/components/ui/input-code";

const CAPTCHA = "6LcJxEYqAAAAAOqLShLydrGCTpUjS-ODkkXMXcVb";

const SignIn2FAPage: FC = () => {
  const { openModal } = useModalAction();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const submitRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const [totpNeeded, setTotpNeeded] = useState(false);
  const { setCurrentUser, setCurrent2FAInfo } = useAuth();
  const [captchaValue, setCaptchaValue] = useState("");
  const [resetpCatpcha, setResetpCatpcha] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState("");
  const [smsSuccess, setSmsSuccess] = useState("");
  const [isExpiredTime, setIsExpiredTime] = useState<boolean>(false);
  const cered = getLS("lofin2FA");
  const { authorize, getCurrent2FAInfo } = useAuth();

  const enable2fa = getCurrent2FAInfo().google2fa_enabled === 1;

  // useRecaptchaObserver();

  console.log(cered);
  const schema = yup
    .object({
      // email: yup
      //   .string()
      //   .required(String(L.translate("Errors.google2fa")))
      //   .matches(
      //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      //     `${String(L.translate("Errors.email_format"))}`
      //   ),
      password: yup.string().required(String(L.translate("Errors.google2fa"))),
      /*
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        String(L.translate("Errors.password_validation"))
      )*/
      totp: yup.string().notRequired(),
      // .required(String(L.translate("Errors.google2fa")))
      // .matches(/^[0-9]{6}$/, String(L.translate("Errors.invalid_totp"))),
      captcha: yup
        .string()
        .notRequired()
        .required(String(L.translate("Errors.google2fa")))
        .min(3),
      email_code: yup.string().notRequired(),
      sms_code: yup.string().notRequired(),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  // console.log(emailSuccess);
  // console.log(smsSuccess);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    getValues,
    formState: { isValid, errors, isSubmitting },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),

    defaultValues: {
      totp: "",
      sms_code: "",
      email_code: "",
    },
  });
  const curCaptch = watch("captcha");
  const curPassword = watch("password");
  const curTotp = watch("totp");

  const { setIsAlertOpened, setAlertTitle, setAlertText } =
    useContext(GlobalContext);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!recaptchaRef.current) {
      console.error("reCAPTCHA ref не ініціалізовано");
      return;
    }

    const token = await recaptchaRef.current?.execute();
    if (String(token)) {
      setCaptchaValue(String(token));
      // setValue("captcha", String(token) ? String(token) : "", {
      //   shouldValidate: true,
      // });
      return token;
      // Додайте вашу логіку для перевірки капчі та авторизації після отримання токену
    } else {
      console.error("Не вдалося отримати токен від reCAPTCHA");
    }
  }, [recaptchaRef]);

  const { mutate: login, isLoading } = useMutation(client.users.login, {
    onSuccess: (data) => {
      window.Intercom("update", {
        email: data.user_data.email,
      });
      localStorage.removeItem("lofin2FA");

      saveKYCStatus(data.user_data.status);
      setCurrent2FAInfo({
        phone: "+0000",
        google2fa_enabled: data.user_data.google2fa_enabled,
        email_2fa_enabled: data.user_data.email_2fa_enabled,
        phone_2fa_enabled: data.user_data.phone_2fa_enabled,
      });
      authorize(data.token);
      setCurrentUser(data.user_data, curPassword);

      setTimeout(() => navigate(routes.balance), 300);
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          if (error.response.status === 403) {
            setAlertTitle(String(L.translate("Alert.Information")));
            setAlertText(String(L.translate("GlobalErrors.Block")));
            setIsAlertOpened(true);
            return;
          }
          if (
            error.response.data.errors.totp &&
            error.response.data.errors.totp[0] === "the_totp_field_is_required"
          ) {
            setTotpNeeded(true);
            setValue("totp", "");
            setAlertText(
              String(L.translate("Errors.the_totp_field_is_required"))
            );
            setIsAlertOpened(true);
            return;
          } else if (
            error.response.data.errors &&
            error.response.data.errors[0] === "invalid_totp_code"
          ) {
            setAlertText(String(L.translate("Errors.invalid_totp_code")));
            setIsAlertOpened(true);
            return;
          } else if (
            error.response.data.errors &&
            error.response.data.errors[0] === "invalid_totp_code"
          ) {
            setAlertText(String(L.translate("Errors.invalid_totp_code")));
            setIsAlertOpened(true);
            return;
          } else if (
            error.response.data.errors &&
            error.response.data.errors[0] === "invalid_credentials"
          ) {
            setAlertText(`${L.translate("Errors.invalid_credentials")}`);
            setIsAlertOpened(true);
            return;
          } else if (
            error.response.data.errors &&
            error.response.data.errors[0] === "email_not_confirmed"
          ) {
            setAlertText(String(L.translate("Errors.email_not_confirmed")));
            setIsAlertOpened(true);
            return;
          } else if (
            error.response.data.errors.captcha &&
            error.response.data.errors.captcha[0] === "validationrecaptcha"
          ) {
            setAlertText(String(L.translate("Errors.validationrecaptcha")));
            setIsAlertOpened(true);
            recaptchaRef.current?.reset();
            resetCaptcha();
            return;
          }
          // resetCaptcha();
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const emailEnabled = getCurrent2FAInfo().email_2fa_enabled === 1;
    const totpEnabled = getCurrent2FAInfo().google2fa_enabled === 1;
    const smsEnabled = getCurrent2FAInfo().phone_2fa_enabled === 1;

    const totpSucc = curTotp?.length === 6;

    const isEmailSuccess = emailEnabled ? emailSuccess : true;
    const isTotpSuccess = totpEnabled ? totpSucc : true;
    const isSmsSuccess = smsEnabled ? smsSuccess : true;

    const allMethodsSuccessful =
      isEmailSuccess && isTotpSuccess && isSmsSuccess;

    if (allMethodsSuccessful) {
      if (!captchaValue) {
        handleGenerate();
        return;
      }

      handleSubmit(onSubmit)();
    }
  }, [isMounted, emailSuccess, smsSuccess, curTotp, captchaValue]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const cered = getLS("lofin2FA");
    setValue("password", cered.password);
    // check2FA({ email: cered.email, password: cered.password });
  }, [isMounted]);

  // useEffect(() => {
  //   if (curCaptch && isValid && !isSubmitting && !isLoading) {
  //     // debugger;
  //     submitRef?.current?.click();
  //   }
  // }, [curCaptch, isSubmitting, isValid, isLoading]);

  const { isAuthorized } = useAuth();
  if (isAuthorized) return <Navigate to={routes.balance} />;

  const onSubmit = async (data: FormData) => {
    const cered = getLS("lofin2FA");
    if (cered) {
      login({
        email: String(cered.email),
        password: String(cered.password),
        ...(getCurrent2FAInfo().google2fa_enabled && { totp: data.totp }),
        ...(getCurrent2FAInfo().email_2fa_enabled && {
          email_code: data.email_code,
        }),
        ...(getCurrent2FAInfo().phone_2fa_enabled && {
          sms_code: data.sms_code,
        }),
        captcha: String(captchaValue),
      });
    }
  };

  // const onReCAPTCHAChange = (value: string | null) => {
  //   console.log("onReCAPTCHAChange triggered", value);
  //   if (value) {
  //     setCaptchaValue(value);
  //     setValue("captcha", value, { shouldValidate: true });
  //     console.log("Captcha resolved, submitting form...");
  //     handleSubmit(onSubmit)(); // Реініціалізація сабміта після підтвердження капчі
  //   } else {
  //     console.log("Captcha value is null");
  //   }
  // };
  const handleChange = (value: string | null) => {
    setCaptchaValue(value ? value : "");
    setValue("captcha", value ? value : "", { shouldValidate: true });
  };

  const asyncScriptOnLoad = () => {};

  const resetCaptcha = () => {
    // debugger;
    recaptchaRef.current?.reset();
  };

  const handleGenerate = async () => {
    await handleReCaptchaVerify();
  };

  const onKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await handleGenerate();
    }
  };

  const openReset2FA = () => {
    openModal("RESET_2FA", {
      type: "password_expired",
      title: `Forgot 2FA`,
    });
  };

  const disableTotpInput =
    (getCurrent2FAInfo().email_2fa_enabled === 1 && !emailSuccess) ||
    (getCurrent2FAInfo().phone_2fa_enabled === 1 && !smsSuccess);

  return (
    <motion.div
      variants={fadeInOut()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto">
        <form
          className="flex h-full w-full sm:w-[421px] py-8 flex-col px-4 sm:px-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-2 sm:gap-0">
            <Button
              variant="icon"
              type="button"
              onClick={() => {
                localStorage.removeItem("lofin2FA");
                navigate(-1);
              }}
              className="bg-[#F4F4F4] rounded-[5px] px-4 py-2 w-[101px] mb-10 hover:bg-light-600"
            >
              <BackIcon className="w-[20px] h-[20px] text-primary" />
              <p className="font-manrope text-[18px] font-medium text-primary">
                {String(
                  L.translate("Forms.ForgotPassword.forgot_password_link")
                )}
              </p>
            </Button>
            <img
              src={"/assets/logo.png"}
              className="w-24 h-24 object-contain m-auto sm:hidden"
            />
            <p className="text-[32px] font-medium font-manrope text-primary text-center sm:text-left">
              {String(L.translate("Forms.LoginForm.login_form_title"))}
            </p>
            <p className="text-[16px] font-medium font-manrope text-secondary text-center sm:text-left">
              {String(L.translate("Forms.LoginForm.login_form_2fa_subtitle"))}
            </p>
          </div>

          <div className="w-full flex flex-col gap-4 mt-10">
            <Controller
              name="email_code"
              control={control}
              render={({ field }) => (
                <InputWithTimer
                  setIsExpiredTime={setIsExpiredTime}
                  timer={CODE_EXPIRATION_TIME_LOGIN}
                  maxLength={6}
                  placeholder=""
                  label=""
                  inputType="email_login"
                  sender={String(cered.email)}
                  // onKeyDown={onKeyDown}
                  className={`${
                    getCurrent2FAInfo().email_2fa_enabled === 1
                      ? "block"
                      : "hidden"
                  } w-full`}
                  inputClassName="h-[50px] sm:!h-[60px]"
                  setCode={setEmailSuccess}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  error={
                    errors.email_code && (errors.email_code.message as string)
                  }
                  // {...field}
                />
              )}
            />

            {getCurrent2FAInfo().phone_2fa_enabled ? (
              <Controller
                name="sms_code"
                control={control}
                render={({ field }) => (
                  <InputWithTimer
                    setIsExpiredTime={setIsExpiredTime}
                    timer={CODE_EXPIRATION_TIME_LOGIN}
                    maxLength={6}
                    label=""
                    inputType="sms_login"
                    sender={getCurrent2FAInfo().phone ?? "+000000"}
                    className={`${
                      getCurrent2FAInfo().phone_2fa_enabled === 1
                        ? "block"
                        : "hidden"
                    } w-full`}
                    inputClassName="h-[50px] sm:!h-[60px]"
                    value={field.value ?? ""}
                    // onKeyDown={onKeyDown}
                    onChange={field.onChange}
                    setCode={setSmsSuccess}
                    error={
                      errors.sms_code && (errors.sms_code.message as string)
                    }
                    // {...field}
                  />
                )}
              />
            ) : (
              ""
            )}
            <Controller
              name="totp"
              control={control}
              render={({ field }) => (
                <InputCode
                  maxLength={6}
                  label={String(
                    L.translate("Forms.LoginForm.login_input_2fa_google_title")
                  )}
                  placeholder={String(
                    L.translate("Account.TwoFactorAuth.two_factor_placeholder")
                  )}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  className={`${
                    !getCurrent2FAInfo().google2fa_enabled && "hidden"
                  } w-full gap-4 mb-4`}
                  inputClassName="h-[50px] sm:!h-[60px]"
                  disabled={disableTotpInput}
                  // onKeyDown={onKeyDown}
                  // onClick={() => handleSubmit(onSubmit)}
                  error={errors.totp && (errors.totp.message as string)}
                  // {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-col mt-10">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={CAPTCHA}
              className="inline"
              size="invisible"
              onChange={(e) => handleChange(e)}
            />
          </div>
          {enable2fa && (
            <a
              href="javascript:;"
              onClick={() => openReset2FA()}
              className="text-[16px] font-medium font-manrope text-primary underline hover:text-active"
            >
              {String(L.translate("Forms.LoginForm.need_2fa_reset_btn"))}
            </a>
          )}

          <div className="flex flex-col gap-8 mt-10">
            <Button
              type="submit"
              variant="validate"
              className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px]"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
              onClick={handleGenerate}
            >
              {String(L.translate("Forms.LoginForm.login_submit_btn"))}
            </Button>
            {/* <button type="submit" className="hidden" ref={submitRef}>
              {L.translate("Global.submit")}
            </button> */}
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default SignIn2FAPage;
