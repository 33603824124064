// TODO: Should be rewriten to Tailwind CSS
import L from "i18n-react";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { loadCountriesFromLocal, saveCountriesIntoLocal } from "src/lib/utils";
import { useMutation } from "react-query";
import client from "../../../api";
import { CountryResponse } from "../../../api/types";
import Button from "../../ui/button";
import Dropdown from "../../ui/dropdown";
import DropdownWithSearch from "../../ui/dropdown-search";
import Input from "../../ui/input";
import { employmentStatuses, yesNo, salaryIncoms, incomeAmounts } from "./data";

export type TFinalData = Omit<
  FormData,
  | "employment_status"
  | "dual_citizenship"
  | "has_green_card"
  | "income_source"
  | "income_amount"
  | "dual_citizenship_country_id"
> & {
  employment_status: string;
  dual_citizenship: 0 | 1;
  has_green_card: 0 | 1;
  income_source: string;
  income_amount: string;
  dual_citizenship_country_id?: number;
};

interface IKycBuisinessFormStep2 {
  onSubmit: (state: TFinalData) => void;
}

const yesId = yesNo.find((item) => item.code === "yes")?.id;
const incomeSourceOtherId = salaryIncoms.find(
  (item) => item.code === "other"
)?.id;

const schema = yup
  .object({
    employment_status: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    work_name: yup.string(),
    job_title: yup.string(),
    dual_citizenship: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    dual_citizenship_country_id: yup
      .number()
      .when("dual_citizenship", (dual_citizenship, schema) => {
        if (dual_citizenship[0] === yesId) {
          return schema.required(`${String(L.translate("Errors.fieldErr"))}`);
        }
        return schema;
      }),
    has_green_card: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    income_source: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    income_source_details: yup
      .string()
      .when("income_source", (income_source, schema) => {
        if (income_source[0] === incomeSourceOtherId) {
          return schema.required(`${String(L.translate("Errors.fieldErr"))}`);
        }
        return schema;
      }),
    income_amount: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
  })
  .required();
export type FormData = yup.InferType<typeof schema>;

export default function KycBuisinessFormStep2({
  onSubmit,
}: IKycBuisinessFormStep2) {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
    watch,
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      dual_citizenship: 1,
    },
  });

  const watchDual = watch("dual_citizenship");
  const watchIncomeSource = watch("income_source");
  const watchEmploymentStatus = watch("employment_status");

  const showDual = yesNo.find((item) => item.id === watchDual)?.code === "yes";
  const showIncomeDetails =
    salaryIncoms.find((item) => item.id === watchIncomeSource)?.code ===
    "other";

  const showWorkAdditnls =
    employmentStatuses.find((item) => item.id === watchEmploymentStatus)
      ?.code === "working";

  const [countries, setCountries] = useState<CountryResponse[]>([]);

  const { mutate: getCountries, isLoading } = useMutation(
    client.kyc.getCountries,
    {
      onSuccess: (data) => {
        console.log(data);
        saveCountriesIntoLocal(data);
        setCountries(data);
      },
      onError: (error: any) => {
        setCountries([]);
      },
    }
  );

  const loadCountries = async () => {
    let data = await loadCountriesFromLocal();
    if (data.length === 0) {
      getCountries();
    } else {
      setCountries(data);
    }
  };

  useEffect(() => {
    loadCountries();
  }, []);

  return (
    <form
      className="h-full w-full flex flex-col gap-4"
      onSubmit={handleSubmit((data: FormData) => {
        onSubmit({
          ...data,
          employment_status:
            employmentStatuses.find(
              (item) => item.id === data.employment_status
            )?.code || "",
          dual_citizenship:
            yesNo.find((item) => item.id === data.dual_citizenship)?.code ===
            "yes"
              ? 1
              : 0,
          has_green_card:
            yesNo.find((item) => item.id === data.has_green_card)?.code ===
            "yes"
              ? 1
              : 0,
          income_source:
            salaryIncoms.find((item) => item.id === data.income_source)?.code ||
            "",
          income_amount:
            incomeAmounts.find((item) => item.id === data.income_amount)
              ?.code || "",
          dual_citizenship_country_id: showDual
            ? data.dual_citizenship_country_id
            : undefined,
          income_source_details: showIncomeDetails
            ? data.income_source_details
            : undefined,
          work_name: showWorkAdditnls ? data.work_name : undefined,
          job_title: showWorkAdditnls ? data.job_title : undefined,
        });
      })}
    >
      <div className="w-full flex flex-col gap-8 mt-2">
        <Controller
          name="employment_status"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.employment_status"))}
              placeholder={String(L.translate("KYC.enter_status"))}
              className="w-full"
              data={employmentStatuses}
              required
              error={String(
                L.translate(errors.employment_status?.message || "")
              )}
              {...field}
            />
          )}
        />
        {showWorkAdditnls && (
          <>
            <Input
              label={String(L.translate("KYC.name_of_work"))}
              placeholder={String(L.translate("KYC.enter_name_of_work"))}
              className="w-full"
              {...register("work_name")}
              required
              error={String(L.translate(errors.work_name?.message || ""))}
            />
            <Input
              label={String(L.translate("KYC.job_title"))}
              placeholder={String(L.translate("KYC.enter_job_title"))}
              className="w-full"
              {...register("job_title")}
              required
              error={String(L.translate(errors.job_title?.message || ""))}
            />
          </>
        )}
        <Controller
          name="dual_citizenship"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.dual_citizenship"))}
              className="w-full"
              data={yesNo}
              required
              error={String(
                L.translate(errors.dual_citizenship?.message || "")
              )}
              {...field}
            />
          )}
        />
        {showDual && (
          <Controller
            name="dual_citizenship_country_id"
            control={control}
            render={({ field }) => (
              <DropdownWithSearch
                label={String(L.translate("Account.AccountSettings.country"))}
                placeholder={String(
                  L.translate("Account.AccountSettings.enter_country")
                )}
                className="w-full"
                data={countries}
                required
                error={String(
                  L.translate(errors.dual_citizenship_country_id?.message || "")
                )}
                {...field}
                value={field.value ?? -1}
              />
            )}
          />
        )}
        <Controller
          name="has_green_card"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.has_green_card"))}
              className="w-full"
              data={yesNo}
              required
              error={String(L.translate(errors.has_green_card?.message || ""))}
              {...field}
            />
          )}
        />
        <Controller
          name="income_source"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.source_of_income"))}
              className="w-full"
              data={salaryIncoms}
              required
              error={String(L.translate(errors.income_source?.message || ""))}
              {...field}
            />
          )}
        />
        {showIncomeDetails && (
          <Input
            label={String(L.translate("KYC.source_income_details"))}
            placeholder={String(L.translate("KYC.enter_source_income_details"))}
            className="w-full"
            {...register("income_source_details")}
            required
            error={String(
              L.translate(errors.income_source_details?.message || "")
            )}
          />
        )}
        <Controller
          name="income_amount"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.income_amount"))}
              className="w-full"
              data={incomeAmounts}
              required
              error={String(L.translate(errors.income_amount?.message || ""))}
              {...field}
            />
          )}
        />
      </div>
      <div className="flex flex-row items-center justify-center mt-4">
        <Button
          type="submit"
          variant="validate"
          className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] w-full h-[64px]"
          disabled={!isValid}
        >
          {L.translate("KYC.next")}
        </Button>
      </div>
    </form>
  );
}
