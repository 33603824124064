import { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import { WalletResponse } from "../../api/types";
import { MobileVerificationBanner } from "../../components/kyc/verification-banner";
import BalanceActions from "../../components/balance/actions";
import BalanceList from "../../components/balance/list";
import useKYCStatus from "src/hooks/use-kyc-status";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";

const BalancePage: FC = () => {
  const { openModal } = useModalAction();
  const isMounted = useIsMounted();
  const { isNotVerified } = useKYCStatus();

  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const [pageData, setPageData] = useState<WalletResponse | null>(null);

  useEffect(() => {
    isMounted && getWallets();
  }, [isMounted]);

  const { mutate: getWallets, isLoading } = useMutation(
    client.balance.getWallets,
    {
      onSuccess: (data) => {
        console.log(data);
        setPageData(data);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-0 sm:p-4 sm:pr-12">
        <BalanceActions />
        {isNotVerified && <MobileVerificationBanner />}
        <div className="flex flex-col bg-white rounded-[12px] mt-4 p-4 mx-4 sm:mx-0">
          <p className="font-manrope text-secondary text-[14px] font-medium leading-[24px]">
            {L.translate("Balance.totalBalance")}
          </p>
          <p className="font-manrope text-primary text-[26px] font-medium leading-[39px]">
            {pageData
              ? `~$${Number(pageData!.total_in_usd).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}`
              : "~"}
          </p>
        </div>
        <BalanceList
          isLoading={isLoading}
          wallets={pageData ? pageData!.wallets : []}
        />
      </div>
    </div>
  );
};

export default BalancePage;
