import { FC, useContext, useState, useEffect } from "react";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import { TOurPrices } from "src/api/types";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import Section from "../../components/our-prices/section";

const Prices: FC = () => {
  const isMounted = useIsMounted();
  const { setIsAlertOpened, setAlertText, setAlertTitle, language } =
    useContext(GlobalContext);
  const [ourPrices, setOurPrices] = useState<TOurPrices>([]);

  const { mutate: getOurPrices, isLoading } = useMutation(
    client.ourPrices.all,
    {
      onSuccess: (data) => {
        setOurPrices(data);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    if (isMounted) {
      getOurPrices({ lang: language.toLowerCase() });
    }
  }, [isMounted, language]);
  console.log("our price page");
  console.log("ourPrices=>", ourPrices);

  return (
    <div className="flex-1 pt-[100px] pb-[200px] bg-[#F8F9FC] font-manrope">
      <div className="w-[1280px] max-w-[100%] mx-auto px-4">
        <h2 className="mb-[10px] text-[34px] text-primary font-medium leading-[1.1]">
          {L.translate("OurPrices.title")}
        </h2>
        <div className="pt-[50px]">
          <Section data={ourPrices} type="deposit" />
        </div>
        <div className="pt-[50px]">
          <Section data={ourPrices} type="withdrawal" />
        </div>
        {process.env.NODE_ENV !== "production" && (
          <div className="pt-[50px]">
            <Section data={ourPrices} type="kwikpay" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Prices;
