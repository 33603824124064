import { FC, useState } from "react";
import L from "i18n-react";

interface IFaqItem {
  number: number;
  details?: number;
}

const FaqItem: FC<IFaqItem> = ({ number, details }) => {
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive((prev) => !prev);
  };

  return (
    <div className="border-b-[1px] border-b-solid border-b-[#E6E8EC] pb-[30px] mt-[30px]">
      <div className="flex items-center cursor-pointer" onClick={toggleActive}>
        <p className="text-[20px] font-medium flex-1 text-primary">
          {L.translate(`FAQ.${number}.question`)}
        </p>
        <button
          type="button"
          className={`origin-center ease-linear duration-200 ${
            active ? "-rotate-180" : "rotate-0"
          }`}
        >
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.32615 0.5H0.67383C0.0747082 0.5 -0.22532 1.26637 0.198308 1.71457L4.5245 6.29165C4.78707 6.56945 5.21293 6.56945 5.4755 6.29165L9.80164 1.71457C10.2254 1.26637 9.92529 0.5 9.32615 0.5Z"
              fill="#01194D"
            ></path>
          </svg>
        </button>
      </div>
      {active && (
        <div className="mt-[10px] pt-[10px]">
          <p className="text-secondary">
            {L.translate(`FAQ.${number}.answer`)}
          </p>
          {!!details && (
            <ul className="mt-[10px] ml-[10px] list-disc list-inside">
              {Array.from(Array(details).keys()).map((item) => (
                <li key={item} className="mt-[10px] text-secondary">
                  {L.translate(`FAQ.${number}.Details.${item + 1}`)}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default FaqItem;
