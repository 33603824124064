export const FacebookIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#0866FF" rx="5"></rect>
      <g clipPath="url(#clip0_8607_1186)">
        <path
          fill="#fff"
          d="M25 15c0-5.523-4.477-10-10-10S5 9.477 5 15c0 4.69 3.229 8.625 7.584 9.706v-6.65h-2.062V15h2.062v-1.317c0-3.403 1.54-4.981 4.882-4.981.634 0 1.727.124 2.174.248v2.77a12.853 12.853 0 00-1.155-.037c-1.64 0-2.273.621-2.273 2.236V15h3.266l-.56 3.056h-2.706v6.87C21.164 24.33 25 20.114 25 15z"
        ></path>
        <path
          fill="#0866FF"
          d="M18.917 18.057l.561-3.056h-3.266v-1.08c0-1.615.633-2.237 2.273-2.237.509 0 .919.013 1.155.038v-2.77c-.447-.125-1.54-.249-2.174-.249-3.342 0-4.882 1.578-4.882 4.981v1.317h-2.062v3.056h2.062v6.65a10.018 10.018 0 003.627.22v-6.87h2.705z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_8607_1186">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(5 5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
