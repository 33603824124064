export const NavBalanceIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9167 9.39062H6.41675"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1668 11.3608V13.4208C20.1668 13.9708 19.7634 14.4208 19.2501 14.4408H17.4534C16.4634 14.4408 15.556 13.6508 15.4735 12.5708C15.4185 11.9408 15.6384 11.3508 16.0234 10.9408C16.3626 10.5608 16.8301 10.3408 17.3434 10.3408H19.2501C19.7634 10.3608 20.1668 10.8108 20.1668 11.3608Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0232 10.9406C15.6382 11.3506 15.4183 11.9406 15.4733 12.5706C15.5558 13.6506 16.4632 14.4406 17.4532 14.4406H19.2499V15.8906C19.2499 18.8906 17.4166 20.8906 14.6666 20.8906H6.41659C3.66659 20.8906 1.83325 18.8906 1.83325 15.8906V8.89062C1.83325 6.17062 3.33659 4.27062 5.67409 3.95062C5.91242 3.91062 6.15992 3.89062 6.41659 3.89062H14.6666C14.9049 3.89062 15.1341 3.90061 15.3541 3.94061C17.7191 4.24061 19.2499 6.15062 19.2499 8.89062V10.3406H17.3432C16.8299 10.3406 16.3624 10.5606 16.0232 10.9406Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
