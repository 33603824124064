import React, {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  ClipboardEvent,
} from "react";
import L from "i18n-react";
import {
  useModalAction,
  useModalState,
} from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  checkKYCMessage,
  getErrorMessage,
  loadCountriesFromLocal,
  removePlusFromPhone,
  saveCountriesIntoLocal,
  saveKYCMessage,
  saveKYCStatus,
} from "../../lib/utils";
import { CountryResponse, User, WalletResponse } from "../../api/types";
import FastTransfersActions from "../../components/fast-transfers/actions";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../components/popup";
import Button from "../../components/ui/button";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AssetDataType } from "src/lib/constants";
import routes from "../../config/routes";
import Dropdown from "../../components/ui/dropdown";
import Input from "../../components/ui/input";
import DropdownWithSearch from "../../components/ui/dropdown-search";
import DropdownCurrency from "../../components/ui/dropdown-currency";

const schema = yup
  .object({
    transfer_code: yup
      .string()
      .length(16, `${String(L.translate("Errors.transferCodeLengthErr"))}`)
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    amount: yup.string().required(`${String(L.translate("Errors.fieldErr"))}`),
    currency: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const FastTransfersKwikPayPage: FC = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { isValid, errors },
    setValue,
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      amount: "",
      currency: -1,
    },
  });

  const [countries, setCountries] = useState<CountryResponse[]>([]);
  const isMounted = useIsMounted();
  const { data: params } = useModalState();
  const [currencyList, setCurrencyList] = useState<AssetDataType[]>([]);
  const navigate = useNavigate();

  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);

  const [showPopup, setShowPopup] = useState(false);

  const onSubmit = (data: FormData) => {
    const currencyCode = currencyList
      .find((item) => item.key === data.currency)
      ?.currency?.toUpperCase();
    if (!currencyCode) {
      return;
    }
    confirmTransferData({
      transfer_code: data.transfer_code,
      amount: +data.amount,
      currency: currencyCode,
    });
  };

  useEffect(() => {
    getWallets();
  }, []);

  const checkAmountInput = (e: ChangeEvent<HTMLInputElement>): boolean => {
    const { value } = e.target;
    const regex = /^(\d+(\.\d{0,2})?)?$/;
    return regex.test(value);
  };

  const getTrancAmount = (
    e: ClipboardEvent<HTMLInputElement>
  ): number | undefined => {
    const pastedValue = e.clipboardData.getData("text/plain");
    if (!pastedValue) return;
    const valueNum = Number(pastedValue);
    if (!isNaN(valueNum)) {
      const trancValue = Math.floor(valueNum * 100) / 100;
      return trancValue;
    }
  };

  const { mutate: getWallets, isLoading } = useMutation(
    client.balance.getWallets,
    {
      onSuccess: (data) => {
        console.log(data);
        const dataSource = data.wallets.map((item, index) => ({
          key: index,
          wallet_id: item.id,
          currency: item.asset.code,
          amount: item.total,
          currency_id: item.asset.id,
          name: item.asset.name,
          img_path: item.asset.img_path,
          vip_deposit_max: item.asset.vip_deposit_max,
          vip_deposit_min: item.asset.vip_deposit_min,
          vip_withdraw_max: item.asset.vip_withdraw_max,
          vip_withdraw_min: item.asset.vip_withdraw_min,
          deposit_max: item.asset.deposit_max,
          deposit_min: item.asset.deposit_min,
          withdraw_max: item.asset.withdraw_max,
          withdraw_min: item.asset.withdraw_min,
          depositable: item.asset.depositable,
          withdrawable: item.asset.withdrawable,
        }));
        setCurrencyList(dataSource);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: confirmTransferData, isLoading: isSaveLoadingDataEnable } =
    useMutation(client.fastTransfersKwikPay.saveTransfer, {
      onSuccess: (data: any) => {
        if (data.status == false) {
          setShowPopup(true);
        } else if (data.status == true) {
          navigate(data.proceedUrl);
        }
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "email_2fa_already_enabled"
            ) {
              setAlertText(
                String(L.translate("Errors.email_2fa_already_enabled"))
              );
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });
  const setDepositMethod = () => {
    console.log("here");
    const path = routes.fastTransferKwikPayMethods.replace(":id", "2");
    navigate(path);
  };
  console.log("EFEFEFEFEFE");

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-0 sm:p-4 sm:pr-12">
        <FastTransfersActions />
        <div className="bg-white p-6 rounded-xl my-[25px]">
          <div className="px-[15px] py-[5px] rounded-[5px] bg-[#F4F4F4] w-full max-w-[101px] ">
            <Link
              to={routes.fastTransfers}
              className="flex font-manrope items-center gap-[10px]"
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.54102 10.2718H16.041"
                  stroke="#232800"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.58203 5.25116L3.54037 10.2712L8.58203 15.292"
                  stroke="#232800"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back
            </Link>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full  max-w-[421px] mx-auto mb-[50px] ">
              <div className="mb-[33px]">
                <h2 className="font-manrope text-xl not-italic font-medium leading-[30px] mb-[30px]">
                  {String(
                    L.translate(
                      "FastTransfers.KwikPay.formData.trnasferCode.title"
                    )
                  )}
                </h2>
                <Input
                  {...register("transfer_code")}
                  label={String(
                    L.translate(
                      "FastTransfers.KwikPay.formData.trnasferCode.desc"
                    )
                  )}
                  placeholder={String(
                    L.translate(
                      "FastTransfers.KwikPay.formData.trnasferCode.placeholder"
                    )
                  )}
                  className="w-full"
                  error={String(
                    L.translate(errors.transfer_code?.message || "")
                  )}
                />
              </div>
              <div className="mb-[33px]">
                <h2 className="font-manrope text-xl not-italic font-medium leading-[30px] mb-[30px]">
                  {String(
                    L.translate("FastTransfers.KwikPay.formData.amount.title")
                  )}
                </h2>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        label={String(
                          L.translate(
                            "FastTransfers.KwikPay.formData.amount.desc"
                          )
                        )}
                        placeholder={String(
                          L.translate(
                            "FastTransfers.KwikPay.formData.amount.placeholder"
                          )
                        )}
                        className="w-full"
                        onPaste={(e) => {
                          const trancValue = getTrancAmount(e);
                          if (trancValue !== undefined) {
                            // field.onChange(String(trancValue));
                          }
                        }}
                        onChange={(e) => {
                          if (checkAmountInput(e)) {
                            field.onChange(e.target.value);
                          }
                        }}
                      />
                    );
                  }}
                />
              </div>
              <div className="mb-[33px]">
                <h2 className="font-manrope text-xl not-italic font-medium leading-[30px] mb-[30px]">
                  {String(
                    L.translate("FastTransfers.KwikPay.formData.currency.title")
                  )}
                </h2>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <DropdownCurrency
                      label={String(
                        L.translate(
                          "FastTransfers.KwikPay.formData.currency.desc"
                        )
                      )}
                      placeholder={String(
                        L.translate(
                          "FastTransfers.KwikPay.formData.currency.placeholder"
                        )
                      )}
                      className="w-full"
                      data={currencyList}
                      error={
                        errors.currency && (errors.currency.message as string)
                      }
                      {...field}
                    />
                  )}
                />
              </div>
              <Button
                variant="validate"
                type="submit"
                className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] w-full"
                disabled={!isValid}
              >
                {String(L.translate("FastTransfers.KwikPay.formData.confirm"))}
              </Button>

              {showPopup && (
                <Popup
                  classMaxW="469px"
                  onClose={() => setDepositMethod()}
                  continuebtn={false}
                  content={
                    <>
                      <h2 className="text-2xl text-[#232800] font-manrope  font-medium leading-[36px]">
                        Incorrect data for accepting the transfer
                      </h2>
                      <p className="mt-[10px] mb-[50px] text-[#BCBEAD] text-xl font-manrope leading-[30px] font-medium">
                        Please try again
                      </p>
                    </>
                  }
                />
              )}

              {showPopup && (
                <div className="overlay w-full bg-[#000000cc] h-screen fixed top-[0] max-w-full left-[0]"></div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FastTransfersKwikPayPage;
