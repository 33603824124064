import { FC } from "react";
import L from "i18n-react";
import { TOurPrices } from "src/api/types";
import TableHeader from "../table/table-header";
import TableRow from "../table/table-row";

interface ITable {
  data: TOurPrices;
  type: string;
  isBusiness?: boolean;
  kwikpay?: boolean;
}

const OurPricesTable: FC<ITable> = ({ data, type, isBusiness, kwikpay }) => {
  const filteredData = data.filter(
    (item) =>
      item.type === type &&
      item.user_type === (isBusiness ? "business" : "private")
  );
  return (
    <div className="rounded-[12px] bg-light px-[24px] overflow-auto">
      <div>
        <div className="text-[14px] font-medium">
          <TableHeader
            account={String(
              L.translate("OurPrices.TableColumns.Deposit.Header.account")
            )}
            rate={String(
              L.translate("OurPrices.TableColumns.Deposit.Header.rate")
            )}
            limit={String(
              L.translate("OurPrices.TableColumns.Deposit.Header.limit")
            )}
            period={String(
              L.translate("OurPrices.TableColumns.Deposit.Header.period")
            )}
            kwikpay={kwikpay}
          />
          <div>
            {filteredData.map((item) => (
              <TableRow
                key={item.id}
                account={item.destination}
                rate={item.fee}
                limit={item.limit}
                period={item.period}
                kwikpay={kwikpay}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPricesTable;
