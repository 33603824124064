export default function PaypalIcon() {
  return (
    <>
      <svg
        width="36"
        height="24"
        viewBox="0 0 36 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.12069"
          y="0.5"
          width="33.7586"
          height="23"
          rx="3.5"
          fill="white"
          stroke="#F3F3F3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.465 12.154L16.6163 11.1889L16.2793 11.181H14.6696L15.7883 4.05932C15.7917 4.03776 15.803 4.01774 15.8195 4.0035C15.836 3.98926 15.8571 3.98145 15.8791 3.98145H18.5932C19.4944 3.98145 20.1162 4.16967 20.4408 4.54125C20.593 4.71556 20.6899 4.89777 20.7369 5.09821C20.7861 5.30857 20.7869 5.55987 20.7389 5.86642L20.7354 5.88872V6.08517L20.8876 6.17176C21.0158 6.24004 21.1177 6.31816 21.1958 6.40759C21.326 6.55668 21.4102 6.74613 21.4457 6.97066C21.4825 7.20161 21.4704 7.47651 21.4102 7.7877C21.3407 8.1456 21.2284 8.45737 21.0768 8.71241C20.9374 8.94743 20.7597 9.14241 20.5488 9.29345C20.3473 9.43701 20.1081 9.54597 19.8375 9.61571C19.5753 9.68424 19.2764 9.71882 18.9486 9.71882H18.7374C18.5864 9.71882 18.4397 9.77343 18.3245 9.87132C18.209 9.97126 18.1326 10.1078 18.1092 10.2571L18.0932 10.3441L17.8259 12.0451L17.8138 12.1075C17.8105 12.1273 17.805 12.1372 17.7969 12.1438C17.7897 12.1499 17.7793 12.154 17.7692 12.154H16.465Z"
          fill="#28356A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.0318 5.91113C21.0238 5.96313 21.0145 6.01627 21.0041 6.07088C20.6462 7.91606 19.4216 8.55351 17.8576 8.55351H17.0613C16.87 8.55351 16.7088 8.69291 16.6791 8.88236L16.1559 12.2144C16.1365 12.3388 16.232 12.4509 16.357 12.4509H17.7694C17.9366 12.4509 18.0787 12.3289 18.105 12.1633L18.1189 12.0913L18.3848 10.3969L18.4019 10.304C18.4279 10.1378 18.5703 10.0157 18.7375 10.0157H18.9487C20.3171 10.0157 21.3884 9.45795 21.7015 7.84372C21.8322 7.16941 21.7645 6.60635 21.4184 6.21036C21.3137 6.09098 21.1838 5.99186 21.0318 5.91113Z"
          fill="#298FC2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.6574 5.76082C20.6027 5.74479 20.5463 5.7303 20.4884 5.7172C20.4302 5.70443 20.3706 5.69311 20.3093 5.68319C20.0946 5.64836 19.8593 5.63184 19.6073 5.63184H17.48C17.4275 5.63184 17.3778 5.64372 17.3333 5.6652C17.2351 5.71256 17.1623 5.80582 17.1446 5.92L16.692 8.79804L16.679 8.88195C16.7088 8.6925 16.87 8.55309 17.0613 8.55309H17.8576C19.4215 8.55309 20.6461 7.91533 21.0041 6.07047C21.0148 6.01586 21.0238 5.96272 21.0318 5.91072C20.9412 5.86246 20.8432 5.8212 20.7376 5.78605C20.7115 5.77734 20.6846 5.76896 20.6574 5.76082Z"
          fill="#22284F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.1446 5.92037C17.1622 5.8062 17.2351 5.71294 17.3333 5.6659C17.3781 5.64434 17.4275 5.63246 17.48 5.63246H19.6073C19.8593 5.63246 20.0946 5.64906 20.3093 5.68389C20.3706 5.69373 20.4302 5.70513 20.4884 5.7179C20.5463 5.73092 20.6027 5.74549 20.6574 5.76144C20.6845 5.76958 20.7114 5.77804 20.7378 5.78642C20.8434 5.82158 20.9415 5.86316 21.0321 5.9111C21.1386 5.22922 21.0312 4.76496 20.664 4.34455C20.2592 3.88167 19.5286 3.68359 18.5937 3.68359H15.8795C15.6885 3.68359 15.5256 3.823 15.4961 4.01277L14.3656 11.2077C14.3433 11.3501 14.4526 11.4785 14.5956 11.4785H16.2713L17.1446 5.92037Z"
          fill="#28356A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.46811 14.1123H6.48918C6.35375 14.1123 6.23858 14.2111 6.21742 14.3454L5.41705 19.4403C5.40116 19.5409 5.47873 19.6315 5.58028 19.6315H6.52501C6.66044 19.6315 6.77562 19.5328 6.79677 19.3983L7.01261 18.024C7.03344 17.8895 7.14886 17.7907 7.28405 17.7907H7.91049C9.21402 17.7907 9.96633 17.1573 10.1629 15.9023C10.2514 15.3532 10.1666 14.9218 9.91049 14.6196C9.62925 14.2878 9.13038 14.1123 8.46811 14.1123ZM8.6963 15.9732C8.5881 16.6862 8.04555 16.6862 7.52099 16.6862H7.22232L7.43183 15.3546C7.44431 15.2742 7.51369 15.2149 7.59474 15.2149H7.73164C8.08899 15.2149 8.42608 15.2149 8.60026 15.4194C8.70416 15.5414 8.73593 15.7227 8.6963 15.9732Z"
          fill="#28356A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.434 15.9118H13.4863C13.4056 15.9118 13.3358 15.971 13.3234 16.0515L13.2814 16.3176L13.2152 16.2212C13.0101 15.9222 12.5525 15.8223 12.096 15.8223C11.0487 15.8223 10.1543 16.6186 9.98015 17.7357C9.88962 18.2929 10.0183 18.8258 10.3332 19.1974C10.622 19.539 11.0352 19.6814 11.5268 19.6814C12.3706 19.6814 12.8384 19.1367 12.8384 19.1367L12.7962 19.4011C12.7803 19.5021 12.8578 19.5928 12.9588 19.5928H13.8124C13.9481 19.5928 14.0627 19.494 14.0841 19.3595L14.5963 16.1029C14.6125 16.0027 14.5352 15.9118 14.434 15.9118ZM13.113 17.7636C13.0216 18.3072 12.5919 18.6721 12.0438 18.6721C11.7687 18.6721 11.5488 18.5835 11.4075 18.4156C11.2675 18.2488 11.2143 18.0114 11.2588 17.747C11.3442 17.2081 11.7811 16.8313 12.3208 16.8313C12.5899 16.8313 12.8086 16.9211 12.9528 17.0904C13.0971 17.2615 13.1544 17.5004 13.113 17.7636Z"
          fill="#28356A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.4081 15.9932H18.4558C18.3649 15.9932 18.2796 16.0385 18.2281 16.1143L16.9147 18.0568L16.3579 16.1901C16.3229 16.0733 16.2156 15.9932 16.094 15.9932H15.1583C15.0445 15.9932 14.9656 16.1047 15.0017 16.2122L16.0507 19.3028L15.0645 20.7006C14.987 20.8107 15.0651 20.9621 15.1991 20.9621H16.1502C16.2405 20.9621 16.3249 20.9179 16.3762 20.8436L19.5435 16.2532C19.6193 16.1434 19.5414 15.9932 19.4081 15.9932Z"
          fill="#28356A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.5827 14.1123H20.6036C20.4685 14.1123 20.3533 14.2111 20.3321 14.3454L19.5318 19.4403C19.5159 19.5409 19.5934 19.6315 19.6944 19.6315H20.7101C20.8043 19.6315 20.8851 19.5624 20.8998 19.4683L21.127 18.024C21.1478 17.8895 21.2633 17.7907 21.3984 17.7907H22.0246C23.3284 17.7907 24.0805 17.1573 24.2773 15.9023C24.3661 15.3532 24.2808 14.9218 24.0246 14.6196C23.7436 14.2878 23.2451 14.1123 22.5827 14.1123ZM22.8111 15.9732C22.7031 16.6862 22.1606 16.6862 21.6357 16.6862H21.3374L21.5472 15.3546C21.5596 15.2742 21.6285 15.2149 21.7098 15.2149H21.8467C22.2037 15.2149 22.5411 15.2149 22.7153 15.4194C22.8192 15.5414 22.8507 15.7227 22.8111 15.9732Z"
          fill="#298FC2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.5474 15.9118H27.6003C27.519 15.9118 27.4499 15.971 27.4377 16.0515L27.3958 16.3176L27.3292 16.2212C27.1241 15.9222 26.6669 15.8223 26.2102 15.8223C25.1631 15.8223 24.269 16.6186 24.0948 17.7357C24.0045 18.2929 24.1327 18.8258 24.4475 19.1974C24.7369 19.539 25.1494 19.6814 25.6411 19.6814C26.4848 19.6814 26.9527 19.1367 26.9527 19.1367L26.9105 19.4011C26.8946 19.5021 26.9721 19.5928 27.0737 19.5928H27.927C28.0621 19.5928 28.1773 19.494 28.1985 19.3595L28.7109 16.1029C28.7265 16.0027 28.649 15.9118 28.5474 15.9118ZM27.2265 17.7636C27.1356 18.3072 26.7053 18.6721 26.1572 18.6721C25.8826 18.6721 25.6622 18.5835 25.5209 18.4156C25.3809 18.2488 25.3283 18.0114 25.3722 17.747C25.4582 17.2081 25.8945 16.8313 26.4342 16.8313C26.7033 16.8313 26.922 16.9211 27.0661 17.0904C27.2111 17.2615 27.2684 17.5004 27.2265 17.7636Z"
          fill="#298FC2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.5856 14.2524L28.7734 19.4406C28.7575 19.5412 28.835 19.6317 28.936 19.6317H29.7526C29.8884 19.6317 30.0035 19.533 30.0243 19.3985L30.8252 14.3038C30.8412 14.2032 30.7636 14.1123 30.6626 14.1123H29.7483C29.6676 14.1125 29.5981 14.1719 29.5856 14.2524Z"
          fill="#298FC2"
        />
      </svg>
    </>
  );
}
