import { FC } from "react";
import { InfoCircle } from "../icons/info-circle";

interface ITooltip {
  text: string;
}

const Tooltip: FC<ITooltip> = ({ text }) => {
  return (
    <div className="relative inline-flex group">
      <span className="flex grow-0 shrink-0 basis-[18px] z-0">
        <InfoCircle stroke="#777E91" />
      </span>
      <span className="w-[250px] md:w-[320px] rounded-[12px] py-[10px] px-[16px] z-50 shadow-[0px_6px_25px_0px_rgba(0,0,0,0.2)] bg-white absolute top-[calc(100%+10px)] left-[-65%] md:left-[50%] translate-x-[-65%] md:translate-x-[-50%]  hidden group-hover:block">
        <span className="text-primary text-[14px] block text-left">{text}</span>
      </span>
    </div>
  );
};

export default Tooltip;
