export function CloseCircleIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8032 2.1968C9.87413 -0.732275 5.12522 -0.732276 2.19677 2.19686C-0.732275 5.1259 -0.732276 9.87478 2.19688 12.8032C5.12522 15.7323 9.87413 15.7323 12.8032 12.8032C15.7323 9.87478 15.7323 5.1259 12.8032 2.1968ZM11.9194 11.9193C9.47842 14.3602 5.52105 14.3602 3.08077 11.9194C0.639814 9.47897 0.639814 5.52161 3.08071 3.08071C5.52105 0.639785 9.47842 0.639785 11.9193 3.08071C14.3602 5.52164 14.3602 9.47898 11.9194 11.9193Z"
        fill="currentColor"
      />
      <path
        d="M10.5935 4.40647C10.3495 4.1624 9.95371 4.1624 9.70964 4.40647L7.49992 6.61619L5.29021 4.40647C5.04614 4.1624 4.65039 4.1624 4.40632 4.40647C4.16225 4.65055 4.16225 5.04629 4.40632 5.29036L6.61604 7.50008L4.40714 9.70897C4.16307 9.95304 4.16307 10.3488 4.40714 10.5929C4.65121 10.8369 5.04696 10.8369 5.29103 10.5929L7.49992 8.38396L9.70946 10.5935C9.95354 10.8376 10.3493 10.8376 10.5934 10.5935C10.8374 10.3494 10.8374 9.95369 10.5934 9.70962L8.38381 7.50008L10.5935 5.29036C10.8376 5.04629 10.8376 4.65057 10.5935 4.40647Z"
        fill="currentColor"
      />
    </svg>
  );
}
