import { Menu } from "@headlessui/react";
import { forwardRef, useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Transition } from "./transition";
import { Fragment } from "react";
import { DropdownIcon } from "../icons/dropdown-icon";
import { GreenTickIcon } from "../icons/green-tick-icon";
import { animate } from "framer-motion";
import { BankCard, CountryResponse } from "src/api/types";
import { makeCardSecretCardNumber } from "src/lib/utils";
import { BankCardIcon } from "../icons/bank-card-icon";
import L from "i18n-react";

type DropdownCardProps = {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  data: BankCard[];
  onAddCard: any;
  value: number;
  onChange: any;
  showAdds?: boolean;
  onBlur?: any;
  setError?: any;
};

const DropdownCard = forwardRef<HTMLDivElement, DropdownCardProps>(
  (
    {
      label,
      error,
      className,
      placeholder = "",
      showAdds = true,
      inputClassName = "bg-transparent",
      data = [],
      onAddCard,
      value,
      setError,
      // onBlur,
      // onMouseL
      onChange,
      ...props
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const selectedIDRef = useRef(-1);
    const [selectedId, setSelectedId] = useState(value);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isContainerOpened, setIsContainerOpened] = useState(false);
    const [blur, setBlur] = useState(false);
    const [activeBorder, setActiveBorder] = useState(false);

    useEffect(() => {
      setSelectedId(value);
    }, [value]);

    useEffect(() => {
      updateWindowSize();
      window.addEventListener("resize", updateWindowSize);
      btnRef.current?.addEventListener("click", cickedMenuButton);
      window.addEventListener("click", handler);
      return () => {
        window.removeEventListener("click", handler);
        window.removeEventListener("resize", updateWindowSize);
        btnRef.current?.removeEventListener("click", cickedMenuButton);
      };
    }, []);
    useEffect(() => {
      window.addEventListener("click", handleClickOutside);
      return () => {
        window.removeEventListener("click", handleClickOutside);
      };
    }, [activeBorder]);
    const handleClickOutside = (event: MouseEvent) => {
      if (btnRef.current && !btnRef.current.contains(event.target as Node)) {
        if (activeBorder) {
          setActiveBorder(false);
        } else {
          setBlur(false);
        }
        // Perform your desired action here
      }
    };
    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsContainerOpened(false);
      }
    };

    function updateWindowSize() {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }

    useEffect(() => {
      setTimeout(() => menuRef.current?.scrollTo(0, selectedId * 40), 100);
    }, [selectedId, isContainerOpened]);

    function cickedMenuButton() {
      if (data.length === 0) {
        return;
      }

      btnRef.current &&
        btnRef.current.ariaExpanded &&
        btnRef.current.ariaExpanded === "false" &&
        setIsContainerOpened(true);

      setTimeout(
        () => menuRef.current?.scrollTo(0, selectedIDRef.current * 40),
        100
      );
    }

    useEffect(() => {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }, [containerRef.current]);

    const mouseLeaveHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (blur && value < 0) {
        setError();
      } else {
        // setBlur(false);
      }
    };
    const blurHandle = (e: React.ChangeEvent<HTMLButtonElement>) => {
      setBlur(true);
    };

    return (
      <div className={className} ref={containerRef}>
        <label className="block text-[16px] font-manrope font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-16px font-manrope font-medium text-secondary dark:text-light/70">
              {label}
            </span>
          )}
          <Menu>
            <Menu.Button
              ref={btnRef}
              onBlur={blurHandle}
              onMouseLeave={mouseLeaveHandle}
              className={classNames(
                "relative h-[50px] sm:h-[64px] placeholder-other w-full appearance-none text-16px font-manrope font-medium rounded-[12px] border  bg-transparent px-4 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] dark:border-dark-600 dark:text-light dark:ring-dark-600 lg:px-5",
                error
                  ? "focus:border-[#F44336] !border-[#F44336] focus:ring-[#F44336]"
                  : "",
                blur ? "border-active ring-active" : "border-border",
                inputClassName
              )}
            >
              {({ open }) => (
                <>
                  <p
                    className={`${
                      selectedId < 0 ? "text-other" : "text-primary"
                    } text-left`}
                  >
                    {selectedId >= 0 && data.length > 0 ? (
                      <div className="flex flex-row items-center justify-start gap-4">
                        {data[selectedId]?.card_icon ? (
                          <img
                            src={data[selectedId].card_icon}
                            alt=""
                            className="max-w-[35px]"
                          />
                        ) : (
                          <BankCardIcon className="w-[35px] h-[20px] text-primary" />
                        )}
                        <p className="text-primary text-[16px] font-medium font-manrope">
                          {makeCardSecretCardNumber(
                            data[selectedId].card_number
                          )}
                        </p>
                      </div>
                    ) : (
                      placeholder
                    )}
                  </p>
                  <span className="absolute top-0 flex h-full w-[34px] cursor-pointer items-center justify-start text-dark-900 hover:text-dark-700 right-0 dark:text-dark-800 hover:dark:text-light-900 lg:w-9">
                    <DropdownIcon
                      className={`h-auto w-4 ${
                        open && data.length > 0 ? "rotate-0" : "rotate-180"
                      }`}
                    />
                  </span>
                </>
              )}
            </Menu.Button>
            {data.length > 0 && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  ref={menuRef}
                  className={`max-h-[216px] scrollbar-hide overflow-y-auto flex flex-col absolute z-30 mt-2 rounded-[12px] bg-light py-1.5 text-dark shadow-card origin-top-left dark:bg-dark-250 dark:text-light`}
                  style={{ width: containerWidth }}
                >
                  {data.map((item, index) => (
                    /* Use the `active` state to conditionally style the active item. */
                    <Menu.Item key={index}>
                      {({ active, close }) => (
                        <button
                          onClick={(e) => {
                            setActiveBorder(true);
                            setBlur(true);
                            e.preventDefault();
                            if (index == selectedId) {
                              close();
                              return;
                            }

                            setSelectedId(index);
                            onChange(index);
                            selectedIDRef.current = index;

                            close();
                          }}
                          className="flex transition-fill-colors px-4 flex-row items-start  justify-between py-2 hover:bg-light-400"
                        >
                          <div className="flex flex-row items-center justify-between gap-4">
                            {item.card_icon ? (
                              <img
                                src={item.card_icon}
                                alt=""
                                className="max-w-[35px]"
                              />
                            ) : (
                              <BankCardIcon className="w-[35px] h-[20px] text-primary" />
                            )}
                            <p className="text-primary text-[16px] font-medium font-manrope">
                              {makeCardSecretCardNumber(item.card_number)}
                            </p>
                          </div>
                          <GreenTickIcon
                            className={`${
                              active || selectedId === index
                                ? "block"
                                : "hidden"
                            } w-[20px] h-[20px]`}
                          />
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            )}
          </Menu>
        </label>
        {showAdds && (
          <a
            onClick={onAddCard}
            className="text-[14px] font-light text-secondary leading-[24px] font-manrope underline hover:text-primary cursor-pointer"
          >
            {String(L.translate("Account.PaymentMethods.add_new_card"))}
          </a>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[16px] text-[#F44336] font-manrope"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

DropdownCard.displayName = "DropdownCard";
export default DropdownCard;
