export function DropdownIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 5L9 5H10L5 0L0 5Z" fill="currentColor" />
    </svg>
  );
}
