import EN from "./EN.json";
import GE from "./GE.json";
import RU from "./RU.json";
import { ILanguages } from "./types";

export const languages: ILanguages = {
  GE,
  EN,
  RU,
};
