import { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import UserDataCard from "../../components/general-settings/user-data";
import ChangePasswordCard from "../../components/general-settings/change-password";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import client from "../../api";
import {
  checkKYCMessage,
  getErrorMessage,
  saveKYCMessage,
  saveKYCStatus,
} from "../../lib/utils";
import { User } from "src/api/types";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import useAuth from "src/hooks/use-auth";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";
import routes from "src/config/routes";

const GeneralSettingsPage: FC = () => {
  const { openModal } = useModalAction();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const location = useLocation();

  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);

  const [user, setUser] = useState<User | null>(null);
  const { setCurrent2FAInfo, getCurrrentUser, setCurrentUser } = useAuth();

  const { mutate: getUserData, isLoading } = useMutation(client.users.me, {
    onSuccess: async (data) => {
      saveKYCStatus(data.user.status);
      setCurrent2FAInfo({
        phone: "+00000",
        google2fa_enabled: data.user.google2fa_enabled,
        email_2fa_enabled: data.user.email_2fa_enabled,
        phone_2fa_enabled: data.user.phone_2fa_enabled,
      });
      setUser(data.user);
      setCurrentUser(data.user, getCurrrentUser().password);

      if (data.user.kyc_message) {
        let bAvailableShowAlert = await checkKYCMessage(data.user.kyc_message!);
        if (bAvailableShowAlert) {
          setAlertTitle(String(L.translate("Alert.KYC_rejected")));
          setAlertText(data.user.kyc_message);
          setIsAlertOpened(true);

          saveKYCMessage(data.user.kyc_message!);
        }
      }
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  useEffect(() => {
    isMounted && getUserData();
  }, [isMounted]);

  useEffect(() => {
    console.log("location=>", location);

    if (location.state && location.state) {
      openModal("KYC_CONTINUE", {
        isBuisiness: user?.is_business,
        data: location.state,
      });
    }
  }, [location.state, user]);

  const onStartVerification = () => {
    if (!user) {
      return;
    }
    navigate(user?.is_business ? routes.kycBuisiness : routes.kyc);
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col py-2 px-4 sm:p-4 sm:pr-11">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          {String(L.translate("Account.AccountSettings.settings"))}
        </p>
        <div className="w-full flex flex-col md:flex-row gap-2 mt-2 sm:mt-4">
          <UserDataCard
            user={user}
            loading={isLoading}
            onVerification={onStartVerification}
          />
          <ChangePasswordCard />
        </div>
      </div>
    </div>
  );
};

export default GeneralSettingsPage;
