import { FC, useState, useEffect, useContext, useRef } from "react";
import Input from "../../components/ui/input";
import Password from "src/components/ui/password";
import Button from "src/components/ui/button";
import L from "i18n-react";
import { setLS, getLS } from "src/lib/utils";
import { useModalAction } from "src/components/modal-views/context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import routes from "src/config/routes";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { fadeInBottom } from "src/lib/framer-motion/fade-in-bottom";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import client from "../../api";
import useAuth from "../../hooks/use-auth";
import { getErrorMessage } from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { containerCSS } from "react-select/dist/declarations/src/components/containers";

declare global {
  interface Window {
    Intercom: any;
  }
}

const SignInPage: FC = () => {
  const [val, setVal] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const submitRef = useRef<HTMLButtonElement>(null);
  const [isPageReload, setIsPageReload] = useState(false);
  const cred = getLS("lofin2FA");

  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const schema = yup
    .object({
      email: yup
        .string()
        .required(String(L.translate("Errors.google2fa")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          `${String(L.translate("Errors.email_format"))}`
        ),
      password: yup.string().required(String(L.translate("Errors.google2fa"))),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const curPassword = watch("password");
  const curEmail = watch("email");

  console.log(isPageReload);

  useEffect(() => {
    if (isValid && curPassword && curEmail) {
      const newTypingTimeout: NodeJS.Timeout = setTimeout(() => {
        submitRef?.current?.click();
      }, 1200);
      setTypingTimeout(newTypingTimeout);
    }
  }, [isValid, curPassword, curEmail]);

  useEffect(() => {
    return (): void => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  const { setAlertText, setAlertTitle, setIsAlertOpened } =
    useContext(GlobalContext);

  const { setCurrent2FAInfo } = useAuth();

  const { mutate: check2FA, isLoading: isloadData } = useMutation(
    client.users.active2FA,
    {
      onSuccess: (data: any) => {
        navigate(routes.login2FA);
        setCurrent2FAInfo({
          phone: data.phone,
          google2fa_enabled: data.google2fa_enabled,
          email_2fa_enabled: data.email_2fa_enabled,
          phone_2fa_enabled: data.phone_2fa_enabled,
        });
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (error.response.status === 403) {
              setAlertTitle(String(L.translate("Alert.Information")));
              setAlertText(String(L.translate("GlobalErrors.Block")));
              setIsAlertOpened(true);
              return;
            }
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "invalid_credentials"
            ) {
              setAlertText(`${L.translate("Errors.invalid_credentials")}`);
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "email_not_confirmed"
            ) {
              setAlertText(String(L.translate("Errors.email_not_confirmed")));
              setIsAlertOpened(true);
              return;
            }

            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { isAuthorized } = useAuth();
  if (isAuthorized) return <Navigate to={routes.balance} />;

  const onSubmit = (data: FormData) => {
    check2FA({ email: data.email, password: data.password });
    console.log(data.email, data.password);
    setLS("lofin2FA", {
      reload: true,
      email: data.email,
      password: data.password,
    });
  };

  // const focus = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log(e);
  //   debugger;
  //   console.log(curPassword);
  //   console.log(curEmail);
  // };

  return (
    <motion.div
      variants={fadeInBottom()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto">
        <form
          className="flex h-full w-full sm:w-[421px] py-8 flex-col px-4 sm:px-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-2 sm:gap-0">
            <img
              src={"/assets/logo.png"}
              className="w-24 h-24 object-contain m-auto sm:hidden"
            />
            <p className="text-[34px] font-medium font-manrope text-primary text-center sm:text-left">
              {String(L.translate("Forms.LoginForm.login_form_title"))}
            </p>
            <p className="text-[18px] font-light font-manrope text-secondary text-center sm:text-left">
              {String(L.translate("Forms.LoginForm.login_form_subtitle"))}
            </p>
          </div>
          <div className="flex flex-col mt-10 gap-4">
            <Input
              label={String(L.translate("Forms.LoginForm.login_field_email"))}
              id="new-email"
              value={val}
              autoComplete="new-email"
              placeholder={String(
                L.translate("Forms.LoginForm.placeholder_email")
              )}
              className="w-full"
              {...register("email")}
              onChange={(e: any) => {
                setVal(e.target.value.trim());
              }}
              error={errors.email && (errors.email.message as string)}
            />
            <Password
              label={String(
                L.translate("Forms.LoginForm.login_field_password")
              )}
              id="new-password"
              autoComplete="new-password"
              placeholder={String(
                L.translate("Forms.LoginForm.placeholder_password")
              )}
              className="w-full"
              {...register("password")}
              error={errors.password && (errors.password.message as string)}
            />
            <a
              href="javascript:;"
              onClick={() => navigate(routes.forgot)}
              className="text-[16px] font-medium font-manrope text-primary underline hover:text-active"
            >
              {String(L.translate("Forms.LoginForm.login_forgot_password"))}
            </a>
          </div>

          <div className="flex flex-col gap-8 mt-10">
            <Button
              variant="validate"
              type="submit"
              className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px]"
              disabled={!isValid}
              ref={submitRef}
              // isLoading={isLoading}
            >
              {isloadData ? (
                <span className="btn-load-icon">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15 0.9375C13.9645 0.9375 13.125 1.77697 13.125 2.8125V8.4375C13.125 9.47303 13.9645 10.3125 15 10.3125C16.0355 10.3125 16.875 9.47303 16.875 8.4375V2.8125C16.875 1.77697 16.0355 0.9375 15 0.9375ZM24.9437 5.05631C24.2115 4.32408 23.0243 4.32408 22.292 5.05631L18.3146 9.03379C17.5823 9.76602 17.5823 10.9532 18.3146 11.6854C19.0468 12.4177 20.234 12.4177 20.9662 11.6854L24.9437 7.70796C25.6759 6.97573 25.6759 5.78855 24.9437 5.05631ZM27.1875 13.125C28.223 13.125 29.0625 13.9645 29.0625 15C29.0625 16.0355 28.223 16.875 27.1875 16.875H21.5625C20.527 16.875 19.6875 16.0355 19.6875 15C19.6875 13.9645 20.527 13.125 21.5625 13.125H27.1875ZM24.9437 24.9437C25.6759 24.2115 25.6759 23.0243 24.9437 22.292L20.9662 18.3146C20.234 17.5823 19.0468 17.5823 18.3146 18.3146C17.5823 19.0468 17.5823 20.234 18.3146 20.9662L22.292 24.9437C23.0243 25.6759 24.2115 25.6759 24.9437 24.9437ZM13.125 21.5625C13.125 20.527 13.9645 19.6875 15 19.6875C16.0355 19.6875 16.875 20.527 16.875 21.5625V27.1875C16.875 28.223 16.0355 29.0625 15 29.0625C13.9645 29.0625 13.125 28.223 13.125 27.1875V21.5625ZM11.6854 18.3146C10.9532 17.5823 9.76603 17.5823 9.0338 18.3146L5.05632 22.2921C4.32409 23.0243 4.32409 24.2115 5.05632 24.9437C5.78855 25.6759 6.97574 25.6759 7.70797 24.9437L11.6854 20.9662C12.4177 20.234 12.4177 19.0468 11.6854 18.3146ZM8.4375 13.125C9.47303 13.125 10.3125 13.9645 10.3125 15C10.3125 16.0355 9.47303 16.875 8.4375 16.875H2.8125C1.77697 16.875 0.9375 16.0355 0.9375 15C0.9375 13.9645 1.77697 13.125 2.8125 13.125H8.4375ZM11.6854 11.6854C12.4177 10.9532 12.4177 9.766 11.6854 9.03377L7.70797 5.05629C6.97574 4.32406 5.78856 4.32406 5.05632 5.05629C4.32409 5.78852 4.32409 6.97571 5.05632 7.70794L9.0338 11.6854C9.76603 12.4177 10.9532 12.4177 11.6854 11.6854Z"
                      fill="#232800"
                    ></path>
                  </svg>{" "}
                </span>
              ) : (
                String(L.translate("Forms.LoginForm.login_submit_continue"))
              )}
            </Button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default SignInPage;
