import { Fragment, useContext, useEffect, useState } from "react";
import Avatar from "react-avatar";
import L from "i18n-react";
import { AccountIcon } from "../components/icons/account-icon";
import LangSwitcher from "../components/ui/switcher-lang";
import routes from "../config/routes";
import Button from "../components/ui/button";
import Hamburger from "../components/ui/hamburger";
import ThemeSwitcher from "../components/ui/theme-switcher";
import HeaderLogo from "../components/icons/hedaer-img";
import ThemeContext from "../lib/theme/theme-context";
import { useNavigate } from "react-router-dom";
import { NavBalanceIcon } from "src/components/icons/balance-nav-icon";
import { useLocation } from "react-router-dom";
import useAuth from "../hooks/use-auth";

function AuthorizedMenu() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex gap-[8px] sm:gap-[20px]">
      <Button
        variant="icon"
        className={`${
          location.pathname.includes(routes.balance)
            ? "text-primary"
            : "text-secondary"
        } font-manrope text-[14px] font-medium leading-[16px] gap-0 sm:gap-2 hidden sm:flex`}
        onClick={() => navigate(routes.balance)}
      >
        <NavBalanceIcon className="w-[20px] h-[20px]" />
        {String(L.translate("Menu.Balance"))}
      </Button>
      <Button
        variant="icon"
        className={`${
          location.pathname == routes.settings
            ? "text-primary"
            : "text-secondary"
        } font-manrope text-[14px] font-medium leading-[16px] gap-0 sm:gap-2`}
        onClick={() => navigate(routes.settings)}
      >
        <AccountIcon className="w-[20px] h-[20px]" />
        <p className="hidden sm:block">{String(L.translate("Menu.Account"))}</p>
      </Button>
    </div>
  );
}

function LoginMenu() {
  const navigate = useNavigate();
  return (
    <div className={`flex gap-[4px]sm gap-[10px] ml-4`}>
      <Button
        variant="outline"
        className={`text-[12px]  sm:text-[14px]
         rounded-[10px] font-manrope font-medium leading-[24px]`}
        onClick={() => navigate(routes.login)}
      >
        {String(L.translate("LoginPage.login_page_title"))}
      </Button>
      <Button
        variant="solid"
        className={` text-[12px]  sm:text-[14px]
         rounded-[10px] font-manrope font-medium leading-[24px] bg-active hover:bg-main`}
        onClick={() => navigate(routes.register)}
      >
        {String(L.translate("RegistrPage.registr_page_title"))}
      </Button>
    </div>
  );
}

interface HeaderProps {
  isCollapse?: boolean;
  showHamburger?: boolean;
  onClickHamburger?: () => void;
}

export default function Header({
  isCollapse,
  showHamburger = false,
  onClickHamburger,
}: HeaderProps) {
  const { currentTheme, changeCurrentTheme } = useContext(ThemeContext);
  const isDarkMode = currentTheme === "dark";
  const navigate = useNavigate();
  const { isAuthorized } = useAuth();

  return (
    <header className="app-header sticky sm:static top-0 z-40 flex h-16 w-full items-center border-b border-light-300 bg-light py-1 px-4 ltr:left-0 rtl:right-0 dark:border-dark-300 dark:bg-dark-250 sm:h-[70px] sm:px-6">
      <div className="flex pl-[1px] flex-1 w-full items-center justify-between">
        <div className="flex items-center gap-2 cursor-pointer flex-shrink-0">
          <div className="block sm:hidden">
            <LangSwitcher />
          </div>
          <a
            className="hidden sm:block"
            onClick={() => {
              navigate(routes.home);
            }}
          >
            <HeaderLogo />
          </a>
        </div>

        <div className="app-menu--right relative flex items-center justify-center">
          {/* <ThemeSwitcher /> */}
          {isAuthorized ? <AuthorizedMenu /> : <LoginMenu />}
          {showHamburger && (
            <Hamburger
              isToggle={isCollapse}
              onClick={onClickHamburger}
              className="flex sm:hidden"
            />
          )}
          <div className="sm:block hidden">
            <LangSwitcher />
          </div>
        </div>
      </div>
    </header>
  );
}
