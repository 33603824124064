import { Spin } from "antd";
import React from "react";

const Button = ({
  loading,
  text,
  className,
  onClick,
  font,
  ptop,
  pbottom,
  disabled,
}: any) => {
  const buttonStyle = {
    paddingTop: ptop,
    paddingBottom: pbottom,
    fontSize: font,
  };

  return (
    <div>
      <button
        style={buttonStyle}
        className={`w-full text-[#232800] text-center font-manrope  font-bold ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
      {loading && (
        <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
      )}
    </div>
  );
};

export default Button;
