import React, { FC, useContext, useEffect, useState } from "react";
import L from "i18n-react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import {
  CustomDropdown,
  FastTransferBankAccount,
  FastTransferKwikPay,
  WalletResponse,
  IbanAccount,
} from "../../api/types";
import { MobileVerificationBanner } from "../../components/kyc/verification-banner";
import FastTransfersActions from "../../components/fast-transfers/actions";
import BalanceList from "../../components/balance/list";
import routes from "../../config/routes";
import useKYCStatus from "src/hooks/use-kyc-status";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";
import { useNavigate } from "react-router-dom";
import { InfoCircle } from "src/components/icons/info-circle";
import Info from "../../components/fast-transfers/info";
import Button from "../../components/fast-transfers/button";
import Methods from "../../components/fast-transfers/methods";
import CustomSelect from "../../components/customSelect/select";
import Input from "../../components/input/input";
import Popup from "../../components/popup";
import FormMethods from "../../components/form-methods";
import { Spin } from "antd";
import { useParams } from "react-router-dom";

const FastTransfersKwikPayMethodsPage: FC = () => {
  const { id } = useParams();
  const initialState = {
    beneficiarName: "",
    bankName: "",
    swift: "",
    iban: "",
    purposeOfPayment: "",
    address: "",
  };

  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [isBalanceProcess, setIsBalanceProcess] = useState(false);
  const [accountData, setAccountData] = useState(initialState);
  const { openModal } = useModalAction();
  const isMounted = useIsMounted();
  const [ibanAccounts, setIbanAccounts] = useState<IbanAccount[]>([]);
  const [fastTransferBankAccounts, setFastTransferBankAccounts] = useState<
    CustomDropdown[]
  >([]);
  const [selectedIbanId, setSelectedIbanId] = useState<string | number>();
  const [kwikPayTransferData, setKwikPayTransferData] = useState<
    FastTransferKwikPay | undefined
  >(undefined);

  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const [pageData, setPageData] = useState<WalletResponse | null>(null);
  const [currencyList, setCurrencyList] = useState<
    { asset_id: number; code: string }[]
  >([]);

  const assetId = currencyList.find(
    (item) => item.code === kwikPayTransferData?.currency?.toLowerCase()
  )?.asset_id;

  const selectedIbanAccount = ibanAccounts?.find(
    (item) => item.id === selectedIbanId
  );

  useEffect(() => {
    if (isMounted) {
      getWallets();
      getIbans();
      getTransferData({ id: id ? parseInt(id) : "" });
    }
    isMounted && getIbans();
  }, [isMounted]);

  const { mutate: getIbans, isLoading: isLoadingEnable } = useMutation(
    client.iban.all,
    {
      onSuccess: (data) => {
        setIbanAccounts(data);
        let options: CustomDropdown[] = [];

        data.forEach((itemAccount, index) => {
          options.push({
            value: itemAccount.id,
            valueSpan: itemAccount.beneficiary_name,
            label: itemAccount.iban,
            span: itemAccount.beneficiary_name,
          });
        });
        setFastTransferBankAccounts(options);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getTransferData, isLoading: isLoadingTransferDataEnable } =
    useMutation(client.fastTransfersKwikPay.getTransferInfoById, {
      onSuccess: (data: FastTransferKwikPay) => {
        console.log(data);
        setKwikPayTransferData(data);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "email_2fa_already_enabled"
            ) {
              setAlertText(
                String(L.translate("Errors.email_2fa_already_enabled"))
              );
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  const [selectedOption, setSelectedOption] = useState("balance");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.id);
  };

  const handleAddAccountSubmit = (formData: any) => {
    setShowPopup(false);
    setShowPopupConfirm(true);
    setAccountData(formData);
  };

  const backToAddForm = () => {
    setShowPopup(true);
    setShowPopupConfirm(false);
  };

  const { mutate: addNewIban, isLoading } = useMutation(client.iban.add, {
    onSuccess: (data) => {
      /* params.callback(data);
            closeModal();*/
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });
  const { mutate: getTransferInBalance, isLoading: isLoadingProcess } =
    useMutation(client.fastTransfersKwikPay.getTransferInBalance, {
      onSuccess: (data) => {
        setIsBalanceProcess(false);
        if (data?.response?.errors) {
          const currError = Object.values(data.response.errors)?.[0];
          if (currError) {
            setAlertTitle(String(L.translate("Alert.Error")));
            setAlertText(String(currError));
            setIsAlertOpened(true);
          }
          return;
        }
        navigate(routes.fastTransfers);
        setAlertTitle(String(L.translate("Alert.Information")));
        setAlertText(
          String("Amount has been successfully added to your balance")
        );
        setIsAlertOpened(true);
      },
      onError: (error: any) => {
        setIsBalanceProcess(false);
        setAlertTitle(String(L.translate("Alert.Error")));
        console.log("error", error);
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  const { mutate: getSettings, isLoading: isLoadingSettings } = useMutation(
    client.users.me,
    {
      onSuccess: (data) => {
        openModal("WITHDRAWAL_SECURITY_VERIFICATION", {
          me: data.user,
          withdrawal_type: "bank",
          amount: kwikPayTransferData?.amount,
          asset_id: assetId,
          iban_account_id: selectedIbanAccount?.id,
        });
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getWallets } = useMutation(client.balance.getWallets, {
    onSuccess: (data) => {
      setCurrencyList(
        data.wallets.map((item) => ({
          asset_id: item.asset.id,
          code: item.asset.code,
        }))
      );
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));

      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  const onAddAccount = () => {
    console.log("added new card");
    setTimeout(() => {
      openModal("ADD_IBAN_ACCOUNT", {
        type: "save",
        callback: getIbans,
      });
    }, 100);
    openModal("ADD_PAYMENT_CARD", { type: "online" });
  };

  const handleClickContinue = () => {
    //confirmTermCondition();
    switch (selectedOption) {
      case "balance":
        setIsBalanceProcess(true);
        getTransferInBalance({ kwikpay_id: `${id}` });
        break;
      case "transfer":
        getSettings();
        /*openModal("CONFIRM_TRANSFER", {
                    type: "transfer",
                    callback: getTransferData,
                });*/
        break;
      default:
        break;
    }
  };

  const continueDisabled =
    selectedOption === "transfer"
      ? !selectedIbanId || isLoadingSettings
      : false;

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-0 sm:p-4 sm:pr-12">
        <FastTransfersActions />
        <div className="bg-white p-6 rounded-xl my-[25px] flex justify-center relative">
          <div className="w-full max-w-[421px] flex flex-col gap-[33px] pb-[26px]">
            <div className="p-[20px] border border-[#BCBEAD] rounded-[12px]">
              {kwikPayTransferData?.amount && (
                <>
                  <h2 className="font-manrope font-medium text-xl leading-[30px] text-[#232800]">
                    Transfer successful
                  </h2>

                  <div className="flex gap-[50px] mt-[15px]">
                    <div>
                      <p className="font-manrope font-medium text-base leading-[24px] text-[#7F8172]">
                        Amount:
                      </p>
                      <span className="text-xl font-manrope font-medium leading-[30px] text-[#232800]">
                        {kwikPayTransferData?.amount}
                      </span>
                    </div>
                    <div>
                      <p className="font-manrope font-medium text-base leading-[24px] text-[#7F8172]">
                        Currency:
                      </p>
                      <span className="text-xl font-manrope font-medium leading-[30px] text-[#232800]">
                        {kwikPayTransferData?.currency}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              <h2 className="mb-[30px] text-xl font-manrope text-[#232800] font-medium leading-[30px]">
                Please select a deposit method on
              </h2>

              <form action="" className="flex flex-col gap-[18px] pl-[20px]">
                <div className="flex  gap-[22px]">
                  <input
                    className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)]  focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDefault"
                    id="balance"
                    checked={selectedOption === "balance"}
                    onChange={handleRadioChange}
                  />
                  <label
                    className="hover:cursor-pointer text-[#232800] font-manrope text-[16px] not-italic font-medium leading-[150%]"
                    htmlFor="balance"
                  >
                    Your balance
                  </label>
                </div>
                <div className="flex gap-[22px]">
                  <input
                    className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="flexRadioDefault"
                    id="transfer"
                    checked={selectedOption === "transfer"}
                    onChange={handleRadioChange}
                  />
                  <label
                    className=" hover:cursor-pointer text-[#232800] font-manrope text-[16px] not-italic font-medium leading-[150%]"
                    htmlFor="transfer"
                  >
                    Transfer to the bank account using the IBAN code
                  </label>
                </div>
              </form>
              {selectedOption === "transfer" && (
                <div>
                  <CustomSelect
                    loading={isLoadingEnable}
                    title=""
                    desc="IBAN"
                    place={String(
                      L.translate(
                        "FastTransfers.KwikPay.formData.iban.placeholder"
                      )
                    )}
                    options={fastTransferBankAccounts}
                    selectedOption={selectedIbanId}
                    setSelectedOption={setSelectedIbanId}
                  />
                  <p
                    className="cursor-pointer mt-[-20px]"
                    onClick={onAddAccount}
                  >
                    Add account
                  </p>

                  {showPopup && (
                    <Popup
                      classMaxW="596px"
                      onClose={() => setShowPopup(false)}
                      continuebtn={true}
                      content={
                        <>
                          <FormMethods
                            accountFormData={accountData}
                            handleAddAccountSubmit={handleAddAccountSubmit}
                          />
                        </>
                      }
                    />
                  )}

                  {showPopupConfirm && (
                    <Popup
                      classMaxW="596px"
                      onClose={() => setShowPopup(false)}
                      continuebtn={true}
                      content={
                        <>
                          <div className="">
                            <h2 className="w-full max-w-[311px] mx-auto text-[#232800] text-center font-manrope text-[24px] not-italic font-medium leading-[36px]">
                              Do you want to save this cash method out of the
                              transfer on this account?
                            </h2>
                            <div className="flex justify-center space-x-4 mt-[50px]">
                              <button
                                className="w-full max-w-[166px] rounded-[12px] py-[21px] bg-[#BCBEAD]"
                                onClick={backToAddForm}
                              >
                                No
                              </button>
                              <button className="w-full max-w-[166px] rounded-[12px] py-[21px] bg-[#C3E600]">
                                Yes
                              </button>
                            </div>
                          </div>
                        </>
                      }
                    />
                  )}

                  {(showPopup || showPopupConfirm) && (
                    <div
                      className="overlay w-full bg-[#000000cc] h-screen fixed top-[0] max-w-full left-[0]"
                      onClick={() => {
                        setShowPopup(false);
                        setShowPopupConfirm(false);
                      }}
                    ></div>
                  )}
                </div>
              )}
            </div>

            <Button
              onClick={handleClickContinue}
              text="Continue"
              font="16px"
              ptop="19px"
              pbottom="16px"
              className={`flex justify-center border rounded-xl ${
                continueDisabled
                  ? "border-gray bg-gray"
                  : "border-active bg-active"
              }`}
              disabled={continueDisabled}
            />

            {isBalanceProcess && (
              <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
            )}
          </div>
        </div>
      </div>

      {/* {isLoadingSaveAccount && (
                <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
            )}*/}
    </div>
  );
};

export default FastTransfersKwikPayMethodsPage;
