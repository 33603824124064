import { ILanguages } from "./types";

export interface Ilangs {
  EN: string;
  RU: string;
  GE: string;
}

export const lgNames: ILanguages = {
  EN: "English",
  RU: "Russian",
  GE: "Georgian",
};
