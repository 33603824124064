import { FC, useEffect, useState } from "react";
import { CheckIcon } from "src/components/icons/check-icon";
import Button from "src/components/ui/button";
import { CloseIcon } from "src/components/icons/close-icon";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import routes from "src/config/routes";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import L from "i18n-react";

const DepositSuccessPage = () => {
  const isMounted = useIsMounted();

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    if (!isMounted) return;

    setSuccess(searchParams.get("paymentId") ? true : false);
  }, [isMounted]);

  const makeTitle = () => {
    return isSuccess
      ? String(L.translate("Base.Modals.deposit.success"))
      : String(L.translate("Base.Modals.deposit.failed"));
  };

  const makeDescr = () => {
    let description = isSuccess
      ? String(L.translate("Base.Modals.deposit.confirmed"))
      : `${String(
          L.translate("Base.Modals.deposit.something_went_wrong")
        )}.${"\n"}${String(L.translate("Base.Modals.deposit.try_again"))}`;

    let newText = description.split("\n").map((i) => {
      return (
        <p className="font-manrope text-[14px] font-medium text-secondary  text-center">
          {i}
        </p>
      );
    });

    return newText;
  };

  const makeActions = () => {
    return (
      <div className="w-full">
        <Button
          variant="solid"
          className="w-full rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[60px] bg-active hover:bg-main"
          onClick={() => navigate(routes.deposit)}
        >
          {String(L.translate("Base.Modals.deposit.back"))}
        </Button>
      </div>
    );
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4 sm:pr-11">
        <div className="flex flex-col bg-white rounded-[12px] w-full h-[578px] items-center justify-center">
          <div className="flex flex-col w-full sm:w-[498px] items-center justify-start p-2 gap-4">
            {isSuccess ? (
              <div className="w-[68px] h-[68px] rounded-full bg-active flex items-center justify-center">
                <CheckIcon className="w-[20px] h-[20px] text-primary" />
              </div>
            ) : (
              <div className="w-[68px] h-[68px] rounded-full bg-error flex items-center justify-center">
                <CloseIcon className="w-[20px] h-[20px] text-white" />
              </div>
            )}
            <div className="flex flex-col w-full px-4 sm:px-20">
              <p className="font-manrope text-[24px] font-medium text-primary text-center">
                {makeTitle()}
              </p>
              {makeDescr()}
            </div>
            {makeActions()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositSuccessPage;
