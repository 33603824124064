export const LockIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
      <path
        d="M16.4234 9.94804V7.80104C16.4234 5.28804 14.3854 3.25004 11.8724 3.25004C9.3594 3.23904 7.3134 5.26704 7.3024 7.78104V7.80104V9.94804"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6832 21.75H8.0422C5.9482 21.75 4.2502 20.053 4.2502 17.958V13.669C4.2502 11.574 5.9482 9.87695 8.0422 9.87695H15.6832C17.7772 9.87695 19.4752 11.574 19.4752 13.669V17.958C19.4752 20.053 17.7772 21.75 15.6832 21.75Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M11.8629 14.7031V16.9241"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  );
};
