import { Menu } from "@headlessui/react";
import React, {
  ReactNode,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from "react";
import classNames from "classnames";
import L from "i18n-react";
import { Transition } from "./transition";
import { Fragment } from "react";
import { DropdownIcon } from "../icons/dropdown-icon";
import { GreenTickIcon } from "../icons/green-tick-icon";
import { animate } from "framer-motion";
import { CountryResponse } from "src/api/types";
import { Spin } from "antd";

type DropdownProps = {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  data: any[];
  value: any;
  onChange: any;
  required?: boolean;
  loading?: boolean;
  displayValue?: (item: any) => string | ReactNode;
};

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      label,
      error,
      className,
      placeholder = "",
      inputClassName = "bg-transparent",
      data = [],
      value,
      onChange,
      required,
      loading,
      displayValue,
      ...props
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const selectedIDRef = useRef(-1);
    const [selectedId, setSelectedId] = useState(value);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isContainerOpened, setIsContainerOpened] = useState(false);

    useEffect(() => {
      setSelectedId(value);
    }, [value]);

    useEffect(() => {
      updateWindowSize();
      window.addEventListener("resize", updateWindowSize);
      btnRef.current?.addEventListener("click", cickedMenuButton);
      window.addEventListener("click", handler);

      return () => {
        window.removeEventListener("click", handler);
        window.removeEventListener("resize", updateWindowSize);
        btnRef.current?.removeEventListener("click", cickedMenuButton);
      };
    }, []);

    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsContainerOpened(false);
      }
    };

    function updateWindowSize() {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }

    useEffect(() => {
      setTimeout(() => menuRef.current?.scrollTo(0, selectedId * 40), 100);
    }, [selectedId, isContainerOpened]);

    function cickedMenuButton() {
      btnRef.current &&
        btnRef.current.ariaExpanded &&
        btnRef.current.ariaExpanded === "false" &&
        setIsContainerOpened(true);

      setTimeout(
        () => menuRef.current?.scrollTo(0, selectedIDRef.current * 40),
        100
      );
    }

    useEffect(() => {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }, [containerRef.current]);

    const renderSelected = () => {
      if (selectedId < 0) {
        return placeholder;
      }
      if (displayValue) {
        return displayValue(data[selectedId]);
      }
      return typeof data[selectedId] === "object"
        ? L.translate((data[selectedId] as CountryResponse).name)
        : data[selectedId];
    };

    return (
      <div className={className} ref={containerRef}>
        <label className="block text-[16px] font-manrope font-medium text-secondary relative">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-16px font-manrope font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[16px] text-[#F44336] font-manrope">
                  *{" "}
                </span>
              )}
              {label}
            </span>
          )}
          <Menu>
            <Menu.Button
              ref={btnRef}
              className={classNames(
                "relative h-[50px] sm:h-[64px] placeholder-other w-full appearance-none text-16px font-manrope font-medium rounded-[12px] border border-border bg-transparent px-4 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] dark:border-dark-600 dark:text-light dark:ring-dark-600 lg:px-5",
                error
                  ? "focus:border-[#F44336] !border-[#F44336] focus:ring-[#F44336]"
                  : "focus:border-active focus:ring-active dark:focus:border-active dark:focus:ring-active",
                inputClassName
              )}
            >
              {({ open }) => (
                <>
                  <p
                    className={`${
                      selectedId < 0 ? "text-other" : "text-primary"
                    } text-left`}
                  >
                    {renderSelected()}
                  </p>
                  <span className="absolute top-0 flex h-full w-[34px] cursor-pointer items-center justify-start text-dark-900 hover:text-dark-700 right-0 dark:text-dark-800 hover:dark:text-light-900 lg:w-9">
                    <DropdownIcon
                      className={`h-auto w-4 ${
                        open ? "rotate-0" : "rotate-180"
                      }`}
                    />
                  </span>
                </>
              )}
            </Menu.Button>
            {loading && (
              <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
            )}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                ref={menuRef}
                className={`max-h-[216px] scrollbar-hide overflow-y-auto flex flex-col absolute z-30 mt-2 rounded-[12px] bg-light py-1.5 text-dark shadow-card origin-top-left dark:bg-dark-250 dark:text-light`}
                style={{ width: containerWidth }}
              >
                {data.map((item, index) => (
                  /* Use the `active` state to conditionally style the active item. */
                  <Menu.Item key={index}>
                    {({ active, close }) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (index == selectedId) {
                            close();
                            return;
                          }

                          setSelectedId(index);
                          onChange(index);
                          selectedIDRef.current = index;

                          close();
                        }}
                        className="flex transition-fill-colors px-4  flex-row items-start justify-between py-2 hover:bg-light-400"
                      >
                        <p className="text-primary text-[16px] font-medium font-manrope">
                          {displayValue
                            ? displayValue(item)
                            : typeof item === "object"
                            ? L.translate((item as CountryResponse).name)
                            : item}
                        </p>
                        <GreenTickIcon
                          className={`${
                            selectedId === index ? "block" : "hidden"
                          } ${
                            selectedId === 2 ? "-mr-2" : ""
                          } w-[20px] h-[20px] `}
                        />
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </label>
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[16px] text-[#F44336] font-manrope"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

Dropdown.displayName = "Dropdown";
export default Dropdown;
