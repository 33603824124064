import routes from "../../config/routes";

const MethodsItem = ({handleToggle, src, title}:any) => {
    return (
        <div onClick={ () => handleToggle(routes.fastTransferKwikPay)}  className='p-[24px] cursor-pointer border border-[#BCBEAD] w-full max-w-[320px] rounded-[25px]'>
            <div className=' w-full max-w-[320px] h-[198px]'>
                {src &&
                <img src={src} alt="" className='w-full h-full'/>
                }
            </div>
            <h2 className='font-manrope text-[#232800] text-center font-[Manrope] text-[22px] font-medium leading-[33px]'>{title}</h2>
        </div>
    )
}
export default MethodsItem