import React, { useState } from "react";
import { Spin } from "antd";

interface CustomDropdownProps {
  value: string | number | null | undefined;
  valueSpan?: string | null | undefined;
  label: string | null | undefined;
  span?: string | null | undefined;
}

interface InputProps {
  selectedOption?: any;
  setSelectedOption?: (value: string | number) => void;
  title: string;
  loading?: boolean;
  desc: string;
  place: string;
  options: CustomDropdownProps[];
}

const CustomSelect: React.FC<InputProps> = ({
  selectedOption: selectedId,
  setSelectedOption: setSelectedId,
  title,
  loading,
  desc,
  place,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: string | number) => {
    setSelectedId?.(option);
    setIsOpen(false);
  };

  const selectedOption = options.find((opt) => opt.value === selectedId);

  return (
    <div className="relative inline-block text-left mb-[33px]">
      <h2 className="font-manrope  text-xl not-italic font-medium leading-[30px] mb-[30px]">
        {title}
      </h2>
      <p className="text-[#7F8172] font-manrope  text-base not-italic font-medium leading-[normal] mb-[10px]">
        {desc}
      </p>

      <div className="w-full relative">
        <span
          onClick={() => setIsOpen(!isOpen)}
          className={`font-manrope justify-between cursor-pointer w-[421px] inline-block p-[19px] transition duration-150 ease-in-out bg-white border border-[#BCBEAD] rounded-[12px] focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 flex items-center relative ${
            selectedId ? "text-black" : ""
          }`}
        >
          {selectedId ? (
            <p>
              {selectedOption?.label}{" "}
              <span
                className={`ml-[15px] ${selectedId ? "text-[#BCBEAD]" : ""}`}
              >
                {selectedOption?.valueSpan}
              </span>
            </p>
          ) : (
            <span className="text-secondary">{place}</span>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="6"
            viewBox="0 0 11 6"
            fill="none"
          >
            <path d="M10.5 0.5H1.5H0.5L5.5 5.5L10.5 0.5Z" fill="#BCBEAD" />
          </svg>
        </span>

        {isOpen && (
          <div
            className="absolute inset-0 bg-gray-200 opacity-50"
            onClick={() => setIsOpen(false)}
          ></div>
        )}
        {loading && (
          <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
        )}
      </div>

      {isOpen && (
        <div className="w-full z-10 max-w-[421px] origin-top-right absolute right-0 mt-2 rounded-md shadow-lg">
          <div className="bg-white rounded-md shadow-xs max-w-[421px]">
            <div className="py-1 max-h-[150px] overflow-auto rounded-[12px] bg-[var(--color-field-250,_#FFF)] [box-shadow:0px_8px_20px_0px_rgba(0,_0,_0,_0.25)]">
              {options.map((option, index) => (
                <>
                  <div
                    key={index}
                    onClick={() => handleOptionClick(option.value ?? "")}
                    className="flex justify-between px-4 py-2 text-sm leading-5 text-gray-700 cursor-pointer hover:bg-[#dcdcdc] focus:outline-none focus:bg-gray-100 focus:text-gray-800 "
                  >
                    <p>
                      {option.label}
                      <span className="ml-[15px]"> {option.span}</span>
                    </p>

                    {selectedId === option.value && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="ml-2"
                      >
                        <path
                          d="M16.6673 5L7.50065 14.1667L3.33398 10"
                          stroke="#03BC70"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
