import { FC, useState } from "react";
import L from "i18n-react";
import { TOurPrices } from "src/api/types";
import Tabs from "../our-prices-tabs";
import Table from "./table";

interface IOurPricesSection {
  data: TOurPrices;
  type: string;
}

const OurPricesSection: FC<IOurPricesSection> = ({ data, type }) => {
  const [isBuisiness, setIsBuisiness] = useState(false);
  return (
    <div className="mt-[50px]">
      <h3 className="mb-[20px] font-medium text-[24px] text-primary leading-[1.4]">
        {L.translate(`OurPrices.${type}`)}
      </h3>
      <Tabs isBuisiness={isBuisiness} onChange={setIsBuisiness} />
      <Table
        data={data}
        type={type}
        isBusiness={isBuisiness}
        kwikpay={type === "kwikpay"}
      />
    </div>
  );
};

export default OurPricesSection;
