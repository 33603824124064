// TODO: Should be rewriten to Tailwind CSS
import L from "i18n-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../../ui/button";
import Dropdown from "../../ui/dropdown";
import Input from "../../ui/input";
import {
  employmentStatuses,
  salaryIncoms,
  incomeAmounts,
  annualTurnovers,
} from "./data";

export type TFinalData = Omit<
  FormData,
  | "employment_status"
  | "has_green_card"
  | "income_source"
  | "income_amount"
  | "annual_turnover"
> & {
  employment_status: string;
  income_source: string;
  income_amount: string;
  annual_turnover: string;
};

interface IKycBuisinessFormStep2 {
  onSubmit: (state: TFinalData) => void;
}

const incomeSourceOtherId = salaryIncoms.find(
  (item) => item.code === "other"
)?.id;

const schema = yup
  .object({
    tin: yup.string().required(`${String(L.translate("Errors.fieldErr"))}`),
    employment_status: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    work_name: yup.string(),
    job_title: yup.string(),
    income_source: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    income_source_details: yup
      .string()
      .when("income_source", (income_source, schema) => {
        if (income_source[0] === incomeSourceOtherId) {
          return schema.required(`${String(L.translate("Errors.fieldErr"))}`);
        }
        return schema;
      }),
    income_amount: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    annual_turnover: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
  })
  .required();
export type FormData = yup.InferType<typeof schema>;

export default function KycBuisinessFormStep2({
  onSubmit,
}: IKycBuisinessFormStep2) {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
    watch,
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const watchIncomeSource = watch("income_source");
  const watchEmploymentStatus = watch("employment_status");

  const showIncomeDetails =
    salaryIncoms.find((item) => item.id === watchIncomeSource)?.code ===
    "other";

  const showWorkAdditnls =
    employmentStatuses.find((item) => item.id === watchEmploymentStatus)
      ?.code === "working";

  return (
    <form
      className="h-full w-full flex flex-col gap-4"
      onSubmit={handleSubmit((data: FormData) => {
        onSubmit({
          ...data,
          employment_status:
            employmentStatuses.find(
              (item) => item.id === data.employment_status
            )?.code || "",
          income_source:
            salaryIncoms.find((item) => item.id === data.income_source)?.code ||
            "",
          income_amount:
            incomeAmounts.find((item) => item.id === data.income_amount)
              ?.code || "",
          annual_turnover:
            annualTurnovers.find((item) => item.id === data.annual_turnover)
              ?.code || "",
          income_source_details: showIncomeDetails
            ? data.income_source_details
            : undefined,
          work_name: showWorkAdditnls ? data.work_name : undefined,
          job_title: showWorkAdditnls ? data.job_title : undefined,
        });
      })}
    >
      <div className="w-full flex flex-col gap-8 mt-2">
        <Input
          label={String(L.translate("KYC.tinNumber"))}
          placeholder={String(L.translate("KYC.enter_tin"))}
          className="w-full"
          {...register("tin")}
          required
          error={String(L.translate(errors.tin?.message || ""))}
        />
        <Controller
          name="employment_status"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.employment_status"))}
              placeholder={String(L.translate("KYC.enter_status"))}
              className="w-full"
              data={employmentStatuses}
              required
              error={String(
                L.translate(errors.employment_status?.message || "")
              )}
              {...field}
            />
          )}
        />
        {showWorkAdditnls && (
          <>
            <Input
              label={String(L.translate("name_of_work"))}
              placeholder={String(L.translate("enter_name_of_work"))}
              className="w-full"
              {...register("work_name")}
              required
              error={String(L.translate(errors.work_name?.message || ""))}
            />
            <Input
              label={String(L.translate("KYC.job_title"))}
              placeholder={String(L.translate("KYC.enter_job_title"))}
              className="w-full"
              {...register("job_title")}
              required
              error={String(L.translate(errors.job_title?.message || ""))}
            />
          </>
        )}
        <Controller
          name="income_source"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.source_of_income"))}
              className="w-full"
              data={salaryIncoms}
              required
              error={String(L.translate(errors.income_source?.message || ""))}
              {...field}
            />
          )}
        />
        {showIncomeDetails && (
          <Input
            label={String(L.translate("KYC.source_income_details"))}
            placeholder={String(L.translate("KYC.enter_source_income_details"))}
            className="w-full"
            {...register("income_source_details")}
            required
            error={String(
              L.translate(errors.income_source_details?.message || "")
            )}
          />
        )}
        <Controller
          name="income_amount"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.income_amount"))}
              className="w-full"
              data={incomeAmounts}
              required
              error={String(L.translate(errors.income_amount?.message || ""))}
              {...field}
            />
          )}
        />
        <Controller
          name="annual_turnover"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.annual_turnover"))}
              className="w-full"
              data={annualTurnovers}
              required
              error={String(L.translate(errors.annual_turnover?.message || ""))}
              {...field}
            />
          )}
        />
      </div>
      <div className="flex flex-row items-center justify-center mt-4">
        <Button
          type="submit"
          variant="validate"
          className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] w-full h-[64px]"
          disabled={!isValid}
        >
          {L.translate("KYC.next")}
        </Button>
      </div>
    </form>
  );
}
