import { useEffect, Fragment, lazy } from "react";
import { MODAL_VIEWS, useModalAction, useModalState } from "./context";
import { Dialog } from "../ui/dialog";
import { Transition } from "../ui/transition";
import { CloseCircleIcon } from "../icons/close-circle-icon";

const ActionSuccessView = lazy(() => import("../auth/success-modal"));
const EmailVerificationView = lazy(
  () => import("../auth/email-verification-modal")
);

const Reset2FAView = lazy(() => import("../auth/reset-2fa-modal"));

const IDVerificationContinueView = lazy(
  () => import("../kyc/continue-verification-modal")
);

const TransferView = lazy(() => import("../balance/transfer-modal"));
const TransferConfirmView = lazy(
  () => import("../balance/transfer-confirm-modal")
);
const BankDetailsView = lazy(() => import("../balance/bank-details"));
const WithdrawalBankConfirmationView = lazy(
  () => import("../withdraw/bank-confirmation")
);
const WithdrawalPaypalConfirmationView = lazy(
  () => import("../withdraw/paypal-confirmation")
);
const WithdrawalCardConfirmationView = lazy(
  () => import("../withdraw/card-confirmation")
);
const WithdrawalSecurityVerificationView = lazy(
  () => import("../withdraw/security-verification")
);
const WithdrawalVerifyCVVView = lazy(() => import("../withdraw/verify-cvv"));
const DepositBankConfirmationView = lazy(
  () => import("../deposit/bank-confirmation")
);
const DepositPaypalConfirmationView = lazy(
  () => import("../deposit/paypal-deposit-confirmation")
);
const DepositCardConfirmationView = lazy(
  () => import("../deposit/card-confirmation")
);
const DepositVerifyCvvView = lazy(() => import("../deposit/verify-cvv"));
const AddCardDetailsView = lazy(() => import("../payment/new-card-details"));
const AddIbanAccountView = lazy(() => import("../payment/new-iban-account"));
const AddKwikpayClient = lazy(() => import("../payment/add-kwikpay-client"));
const DeleteCardConfirmView = lazy(
  () => import("../payment/card-delete-confirm-modal")
);
const DeleteIBANConfirmView = lazy(
  () => import("../payment/delete-iban-confirm-modal")
);
const VerifyCardConfirmView = lazy(
  () => import("../payment/verify-card-confirm-modal")
);
const AddPhoneNumberView = lazy(() => import("../2fa/add-phone-number"));
const DeleteTransactionConfirmView = lazy(
  () => import("../transactions/transaction-delete-confirm-modal")
);
const WithdrawNotPermittedView = lazy(
  () => import("../withdraw/not-permitted-modal")
);
const FastTransferTermConditionView = lazy(
  () => import("../fast-transfers/term-condition-modal")
);
const FastTransferTermConditionRejectView = lazy(
  () => import("../fast-transfers/term-condition-reject-modal")
);
const ChooseNewPaymenthMethodView = lazy(
  () => import("../payment/choose-new-payment-method-modal")
);
const WithdrawalSubmittedMethodView = lazy(
  () => import("../withdraw/submitted-modal")
);

function renderModalContent(view: MODAL_VIEWS) {
  switch (view) {
    case "ACTION_SUCCESS":
      return <ActionSuccessView />;
    case "EMAIL_VERIFICATION":
      return <EmailVerificationView />;
    case "RESET_2FA":
      return <Reset2FAView />;
    case "KYC_CONTINUE":
      return <IDVerificationContinueView />;
    case "TRANSFER":
      return <TransferView />;
    case "TRANSFER_CONFIRM":
      return <TransferConfirmView />;
    case "BANK_DETAILS":
      return <BankDetailsView />;
    case "WITHDRAWAL_BANK_CONFIRMATION":
      return <WithdrawalBankConfirmationView />;
    case "WITHDRAWAL_SECURITY_VERIFICATION":
      return <WithdrawalSecurityVerificationView />;
    case "WITHDRAWAL_CARD_CONFIRMATION":
      return <WithdrawalCardConfirmationView />;
    case "WITHDRAWAL_PAYPAL_CONFIRMATION":
      return <WithdrawalPaypalConfirmationView />;
    case "WITHDRAWAL_VERIFY_CVV":
      return <WithdrawalVerifyCVVView />;
    case "DEPOSIT_BANK_CONFIRMATION":
      return <DepositBankConfirmationView />;
    case "DEPOSIT_CARD_CONFIRMATION":
      return <DepositCardConfirmationView />;
    case "DEPOSIT_PAYPAL_CONFIRMATION":
      return <DepositPaypalConfirmationView />;
    case "DEPOSIT_VERIFY_CVV":
      return <DepositVerifyCvvView />;
    case "ADD_PAYMENT_CARD":
      return <AddCardDetailsView />;
    case "ADD_IBAN_ACCOUNT":
      return <AddIbanAccountView />;
    case "DELETE_CARD_CONFIRM":
      return <DeleteCardConfirmView />;
    case "DELETE_IBAN_CONFIRM":
      return <DeleteIBANConfirmView />;
    case "VERIFY_CARD_CONFIRM":
      return <VerifyCardConfirmView />;
    case "ADD_PHONE_NUMBER":
      return <AddPhoneNumberView />;
    case "DELETE_TRANSACTION_CONFIRM":
      return <DeleteTransactionConfirmView />;
    case "WITHDRAWAL_NOT_AVAILABLE":
      return <WithdrawNotPermittedView />;
    case "FAST_TRANSFER_TERM_CONDITION":
      return <FastTransferTermConditionView />;
    case "FAST_TRANSFER_TERM_CONDITION_REJECT":
      return <FastTransferTermConditionRejectView />;
    case "ADD_KWIKPAY_CLIENT":
      return <AddKwikpayClient />;
    case "CHOOSE_NEW_PAYMENT_METHOD":
      return <ChooseNewPaymenthMethodView />;
    case "WITHDRAWAL_SUBMITTED":
      return <WithdrawalSubmittedMethodView />;
    default:
      return null;
  }
}

export default function ModalsContainer() {
  const { view, isOpen } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto overflow-x-hidden md:p-4"
        onClose={view === "WITHDRAWAL_NOT_AVAILABLE" ? () => {} : closeModal}
      >
        {/* {view && !["WITHDRAWAL_NOT_AVAILABLE"].includes(view) && (
          <button
            onClick={closeModal}
            aria-label="Close panel"
            className="hidden md:block fixed top-5 z-50 hover:text-dark right-5"
          >
            <CloseCircleIcon className="h-6 w-6 text-white" />
          </button>
        )} */}
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 z-40 cursor-pointer bg-dark bg-opacity-60 backdrop-blur dark:bg-opacity-80" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-110"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-110"
          >
            <div className="text-start relative z-50 inline-block min-h-screen w-full transform overflow-hidden align-middle transition-all md:min-h-[auto] md:w-auto">
              {view && !["WITHDRAWAL_NOT_AVAILABLE"].includes(view) && (
                <button
                  onClick={closeModal}
                  aria-label="Close panel"
                  className="hidden md:block fixed top-4 z-50 hover:text-dark right-4"
                >
                  <CloseCircleIcon className="h-6 w-6 text-[#232800]" />
                </button>
              )}
              <div className="relative min-h-screen items-center overflow-hidden md:block md:min-h-[auto]">
                <div className="h-screen md:h-full w-full flex items-center justify-center">
                  {view && renderModalContent(view)}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
