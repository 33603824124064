// TODO: Should be rewriten to Tailwind CSS
import L from "i18n-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../../ui/button";
import Dropdown from "../../ui/dropdown";
import Input from "../../ui/input";
import { reasons, frequencies } from "./data";

export type TFinalData = Omit<
  FormData,
  "reason_for_using" | "utilize_frequency"
> & {
  reason_for_using: string;
  utilize_frequency: string;
};

interface IKycBuisinessFormStep3 {
  onSubmit: (state: TFinalData) => void;
}

const reasonOtherId = reasons.find((item) => item.code === "other")?.id;

const schema = yup
  .object({
    reason_for_using: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    reason_for_using_details: yup
      .string()
      .when("reason_for_using", (reason_for_using, schema) => {
        if (reason_for_using[0] === reasonOtherId) {
          return schema.required(`${String(L.translate("Errors.fieldErr"))}`);
        }
        return schema;
      }),
    utilize_frequency: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
  })
  .required();
export type FormData = yup.InferType<typeof schema>;

export default function KycBuisinessFormStep3({
  onSubmit,
}: IKycBuisinessFormStep3) {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
    watch,
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const watchReason = watch("reason_for_using");

  const showReasonDetails =
    reasons.find((item) => item.id === watchReason)?.code === "other";

  return (
    <form
      className="h-full w-full flex flex-col gap-4"
      onSubmit={handleSubmit((data: FormData) => {
        onSubmit({
          ...data,
          reason_for_using:
            reasons.find((item) => item.id === data.reason_for_using)?.code ||
            "",
          utilize_frequency:
            frequencies.find((item) => item.id === data.utilize_frequency)
              ?.code || "",
          reason_for_using_details: showReasonDetails
            ? data.reason_for_using_details
            : undefined,
        });
      })}
    >
      <div className="w-full flex flex-col gap-8 mt-2">
        <Controller
          name="reason_for_using"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.reason_for_using"))}
              placeholder={String(L.translate("KYC.enter_reasons"))}
              className="w-full"
              data={reasons}
              required
              error={String(
                L.translate(errors.reason_for_using?.message || "")
              )}
              {...field}
            />
          )}
        />
        {showReasonDetails && (
          <Input
            label={String(L.translate("KYC.your_reason"))}
            placeholder={String(L.translate("KYC.enter_reason"))}
            className="w-full"
            {...register("reason_for_using_details")}
            required
            error={String(
              L.translate(errors.reason_for_using_details?.message || "")
            )}
          />
        )}
        <Controller
          name="utilize_frequency"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.utilize_frequency"))}
              className="w-full"
              data={frequencies}
              required
              error={String(
                L.translate(errors.utilize_frequency?.message || "")
              )}
              {...field}
            />
          )}
        />
      </div>
      <div className="flex flex-row items-center justify-center mt-4">
        <Button
          type="submit"
          variant="validate"
          className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] w-full h-[64px]"
          disabled={!isValid}
        >
          {L.translate("KYC.continue")}
        </Button>
      </div>
    </form>
  );
}
