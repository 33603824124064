import { forwardRef, useEffect, useState } from "react";
import { getPaymentOptionName } from "src/lib/utils";
import CardIcon from "src/components/icons/card-icon";
import PaypalIcon from "src/components/icons/paypal-icon";
import BankTransferIcon from "src/components/icons/bank-transfer-icon";

type RadioGroupProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  options: string[];
  value: string;
  onChange: any;
};

const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  (
    {
      label,
      error,
      type = "radio",
      className,
      options,
      inputClassName = "bg-transparent",
      value,
      onChange,
      ...props
    },
    ref
  ) => {
    const [curSelectedId, setCurSelectedId] = useState(value);

    useEffect(() => {
      setCurSelectedId(value);
    }, [value]);

    return (
      <div className={className}>
        <label className="block text-16px font-manrope font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-[16px] font-manrope font-medium text-secondary dark:text-light/70">
              {label}
            </span>
          )}
          <div className="w-full flex flex-col gap-2">
            {options.map((item, index) => (
              <label
                key={`radio_label_${index}`}
                className="flex flex-row items-center gap-4"
              >
                <input
                  key={`radio_input_${index}`}
                  value={item}
                  type={type}
                  ref={ref}
                  checked={curSelectedId === item}
                  onChange={(e) => {
                    setCurSelectedId(e.target.value);
                    onChange(e.target.value);
                  }}
                  {...props}
                  className="text-primary border-secondary focus:ring-1 focus:ring-primary"
                />
                {item === "cartubank" && <CardIcon />}
                {item === "paypal" && <PaypalIcon />}
                {item === "bank_transfer" && <BankTransferIcon />}
                <span className="leading-[24px] text-[16px] font-manrope font-medium bg-transparent text-primary">
                  {getPaymentOptionName(item)}
                </span>
              </label>
            ))}
          </div>
        </label>
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[16px] text-[#F44336] font-manrope"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

RadioGroup.displayName = "RadioGroup";
export default RadioGroup;
