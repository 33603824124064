import { FC, useContext, useEffect, useState, useRef } from "react";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import Button from "../../components/ui/button";
import routes from "../../config/routes";
import useAuth from "../../hooks/use-auth";
import ImageUrl from "../../assets/images/mobile.png";

const WelcomePage: FC = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { isAuthorized } = useAuth();
  const { language } = useContext(GlobalContext);

  if (isAuthorized) return <Navigate to={routes.balance} />;

  return (
    <div
      className={`overflow-hidden relative flex w-full justify-center min-h-[700px] h-full`}
    >
      <div
        className={`absolute w-[150%] h-full bottom-0 bg-[radial-gradient(ellipse_at_50%_100%_,_#e5fa73,_#f3f7da_,white_,_white)] `}
      ></div>
      <div
        className={`w-full h-full flex flex-col sm:flex-row sm:gap-16 gap-10 lg:gap-28 items-center px-20 lg:px-[10%] z-10`}
      >
        <div
          className={`flex flex-col w-full items-center sm:items-start sm:w-1/2 lg:w-[45%] max-w-[660px] font-manrope gap-14`}
        >
          <div className="flex flex-col gap-6">
            <div
              className={`font-medium text-4xl pt-10 text-center sm:text-start  sm:text-[40px] md:text-[50px] lg:text-[60px] leading-11 lg:leading-[66px]
              text-primary `}
            >
              {String(L.translate("HomePage.title"))}
            </div>
            <div
              className={`font-medium text-base text-center sm:text-start sm:text-lg sm:leading-7
              text-primary`}
            >
              {String(L.translate("HomePage.sub_title"))}
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full">
            <Button
              variant="solid"
              className={`rounded-[8px] font-manrope font-medium text-white text-xs sm:text-[16px]  px-6 bg-primary lg:px-10 py-3 lg:py-5 hover:bg-secondary w-[80%] h-12 mx-auto sm:mx-0 sm:w-[60%] sm:min-w-fit sm:h-[52px] `}
              onClick={() => navigate(routes.register)}
            >
              {String(L.translate("HomePage.create"))}
            </Button>
            <div
              className={`text-xs text-center sm:text-start sm:text-base 
               font-normal leading-5 text-primary`}
            >
              {String(L.translate("HomePage.isBusiness"))}
            </div>
          </div>
        </div>
        <div
          className={`w-[180%] min-[400px]:w-[100%]  sm:w-1/2 lg:w-[40%]
            h-fit`}
        >
          <img src={ImageUrl} className="w-full" alt="" />
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
