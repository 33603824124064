import { FC, useEffect, useRef, useState, useContext, useMemo } from "react";
import Button from "../../components/ui/button";
import L from "i18n-react";
import { useModalAction } from "../../components/modal-views/context";
import InputWithMax from "../../components/ui/input-max";
import DropdownCurrency from "../../components/ui/dropdown-currency";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import RadioGroup from "../../components/ui/radio-group";
import { roundTo } from "src/lib/utils";
import Dropdown from "src/components/ui/dropdown";
import Input from "src/components/ui/input";
import { ReactPhone } from "src/components/ui/phone-input";
import classNames from "classnames";
import {
  getCurrencySymbol,
  getErrorMessage,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import {
  APISomethingWrongMsg,
  AssetDataType,
  NetworkDisconnectMsg,
  PAYMENT_OPTIONS,
  PAYMENT_TYPE,
} from "src/lib/constants";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "../../context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import DropdownCard from "src/components/ui/dropdown-card";
import { BankCard, FiatLimitation, IbanAccount } from "src/api/types";
import { Spin } from "antd";
import useAuth from "src/hooks/use-auth";
import useKYCStatus from "src/hooks/use-kyc-status";
import { setWithdrawalAmount } from "src/api/token-util";

function convertExponentialToDecimal(
  exponentialNumber: number
): number | string {
  const str = exponentialNumber?.toString();
  if (str.indexOf("e") !== -1) {
    const exponent = parseInt(str.split("-")[1], 10);

    return exponentialNumber.toFixed(exponent);
  }
  return exponentialNumber;
}

const WithdrawPage: FC = () => {
  const schema = yup
    .object({
      currency: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`),
      payType: yup
        .string()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .min(0),
      card: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .min(0),
      min_amount: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .positive(),
      max_amount: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .positive(),
      amount: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .positive()
        .min(
          yup.ref("min_amount"),
          `${String(L.translate("Errors.small_amount_for_a_deposit"))}`
        )
        .max(
          yup.ref("max_amount"),
          `${String(L.translate("Errors.much_amount_then_balance"))}`
        ),
      variantId: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .min(0),
      paypalId: yup
        .string()
        .nullable()
        .when("variantId", (variantId, schema) => {
          if (Number(variantId[0]) === 0) {
            return schema.required(`${String(L.translate("Errors.fieldErr"))}`);
          }
          return schema.notRequired();
        }),
      email: yup
        .string()
        .nullable()
        .when("variantId", (variantId, schema) => {
          if (Number(variantId[0]) === 1) {
            return schema
              .required(`${String(L.translate("Errors.fieldErr"))}`)
              .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                `${String(L.translate("Errors.email_format"))}`
              );
          }
          return schema.notRequired();
        }),
      phone: yup
        .string()
        .nullable()
        .when("variantId", (variantId, schema) => {
          if (Number(variantId[0]) === 2) {
            return schema.required(`${String(L.translate("Errors.fieldErr"))}`);
          }
          return schema.notRequired();
        }),
      // ibanIdx: yup.number().when("payType", (payType, schema) => {
      //   if (String(payType) === "bank_transfer") {
      //     return schema
      //       .required(`${String(L.translate("Errors.fieldErr"))}`)
      //       .min(0);
      //   }
      //   return schema;
      // }),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  const withdrawVariants = [
    `${String(L.translate("WithdrawFiat.paypal_id"))}`,
    `${String(L.translate("WithdrawFiat.email"))}`,
    `${String(L.translate("WithdrawFiat.phone"))}`,
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const phoneRef = useRef<HTMLDivElement>(null);
  const [phoneDivWidth, setPhoneDivWidth] = useState(0);
  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const isMounted = useIsMounted();
  const { getCurrrentUser } = useAuth();

  const { closeModal, openModal } = useModalAction();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [maxAmount, setMaxAmount] = useState(0);
  const [fee, setFee] = useState(0);
  const [step, setStep] = useState(0);
  const [selectedBank, setSelctedBank] = useState<any>(null);
  const [continueBtn, setContinueBtn] = useState(false);

  const [selectedCurrencyIdx, setSelectedCurrencyIdx] = useState(-1);
  const [currencyList, setCurrencyList] = useState<AssetDataType[]>([]);

  const [payOptions, setPayOptions] = useState<string[]>([]);
  const [cardList, setCardList] = useState<BankCard[]>([]);

  const [limitations, setLimitations] = useState<FiatLimitation[]>([]);

  const [amountLimit, setAmountLimit] = useState({ min: 0, max: 0 });
  const [dailyAmount, setDailyAmount] = useState(0);

  const [ibanAccounts, setIbanAccounts] = useState<IbanAccount[]>([]);

  const [submitData, setSubmitData] = useState<any>({});
  const { isVerifed } = useKYCStatus();

  const { getCurrent2FAInfo } = useAuth();

  useEffect(() => {
    if (!isMounted) return;

    if (isVerifed) {
      getWallets();
      getLimitations();
    } else {
      openModal("WITHDRAWAL_NOT_AVAILABLE");
    }
    // getIbans();
  }, [isMounted]);

  useEffect(() => {
    if (location.state) {
      setSelectedCurrencyIdx(location.state.selectedCurrencyIdx);
      setCurrencyList(location.state.currencies);

      setValue("currency", location.state.selectedCurrencyIdx);
    }
  }, [location.state]);

  const {
    register,
    watch,
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      currency: selectedCurrencyIdx,
      payType: "",
      card: -1,
      amount: -1,
      min_amount: 1,
      max_amount: 1,
      variantId: 0,
      // ibanIdx: -1,
    },
  });

  useEffect(() => {
    const width = phoneRef.current ? phoneRef.current.offsetWidth : 0;
    setPhoneDivWidth(width);
  }, [phoneRef.current]);

  const [currency, payType, variantId, amount, card] = watch([
    "currency",
    "payType",
    "variantId",
    "amount",
    "card",
    // "balance",
    // "ibanIdx",
  ]);

  const recive = amount && String(fee) && Number(amount) - Number(fee);

  const currencyItem = currencyList?.find((item) => item.key === currency);
  // const iban = ibanIdx || ibanIdx === 0 ? ibanAccounts[ibanIdx] : null;

  useEffect(() => {
    setMaxAmount(
      currency >= 0 && currencyList.length > 0 ? currencyItem?.amount || 0 : 0
    );

    setValue("amount", -1);

    if (currencyList.length > 0 && currency >= 0 && currencyItem?.currency) {
      getAvailablePaymentMethods({
        code: currencyItem?.currency,
        type: PAYMENT_TYPE.Withdrawal,
      });
    }

    /*
      setTimeout(() => {
        getDailyAmount({ asset_code: currencyItem.currency });
      }, 3000);
      */
    setIbanAccounts([]);
  }, [currency, currencyList]);

  const amountLimits = useMemo(() => {
    if (!limitations) {
      return null;
    }

    const code = currencyItem?.currency;
    const cardChannel = cardList?.[card]?.channel;

    if (payType === PAYMENT_OPTIONS.Card && !cardChannel) {
      return null;
    }

    const channelName = () => {
      switch (payType) {
        case PAYMENT_OPTIONS.Card:
          return cardChannel;
        case PAYMENT_OPTIONS.Bank:
          return selectedBank?.channel_name === "bank_transfer_other"
            ? "bank_transfer_other"
            : payType;
        default:
          return payType;
      }
    };

    // const channelName =
    //   payType === PAYMENT_OPTIONS.Card ? cardChannel : payType;

    const result = limitations.find(
      (item) => item.asset_code === code && item.channel_name === channelName()
    );

    if (!result) {
      return null;
    }

    const isVip = getCurrrentUser().is_vip;

    const { withdraw_min, vip_withdraw_min, withdraw_max, vip_withdraw_max } =
      result;
    return {
      min: isVip ? vip_withdraw_min : withdraw_min,
      max: isVip ? vip_withdraw_max : withdraw_max,
    };
  }, [
    limitations,
    currencyItem,
    currency,
    cardList,
    card,
    selectedBank,
    payType,
    getCurrrentUser,
  ]);

  useEffect(() => {
    if (!amountLimits) {
      return;
    }
    const { min, max } = amountLimits;
    setValue("min_amount", min);

    setValue("max_amount", maxAmount);
  }, [amountLimits, setValue]);

  useEffect(() => {
    const assetCode = currencyItem?.currency;
    const selectedCard = cardList[card];
    const cardNumber = selectedCard?.card_number;

    if (
      !assetCode ||
      !payType ||
      (payType === PAYMENT_OPTIONS.Card && !cardNumber) ||
      (payType === PAYMENT_OPTIONS.Bank && !selectedBank?.swift)
    ) {
      return;
    }

    getDailyAmount({
      asset_code: assetCode,
      channel_name: payType,
      card_number: payType === PAYMENT_OPTIONS.Card ? cardNumber : undefined,
      swift: payType === PAYMENT_OPTIONS.Bank ? selectedBank?.swift : undefined,
    });
    let result = 0;
    if (amountLimits?.min) result = amount - amountLimits?.min;
    else result = amount;

    if (result >= 0) {
      setError("amount", {
        type: "validate",
        message: "",
      });
    }
    console.log("amount=>", amount);
    console.log("amountLimits=>", amountLimits);
  }, [card, cardList, currency, currencyItem, payType, selectedBank]);

  useEffect(() => {
    setValue("card", payType === PAYMENT_OPTIONS.Card ? -1 : 0);
    setValue("amount", -1);
    setValue("paypalId", "");

    if (payType === PAYMENT_OPTIONS.Card) {
      setValue("paypalId", "***");
      setValue("email", "placeholder@gmail.com");
      setValue("phone", "***");
      setSelctedBank({});
      getCards();
    } else if (payType === PAYMENT_OPTIONS.Bank) {
      setValue("paypalId", "***");
      setValue("email", "placeholder@gmail.com");
      setValue("phone", "***");
      setStep(1);
      getIbans();
    } else if (payType === PAYMENT_OPTIONS.Paypal) {
      setValue("email", "");
      setValue("phone", "");
      setSelctedBank({});
    }
  }, [payType]);

  useEffect(() => {
    console.log("amont=>", amount);

    if (payType === PAYMENT_OPTIONS.Card && card < 0) {
      if (amount || !amount) {
        setError("card", {
          type: "custom",
          message: "please chose the card",
        });
      }
    }
    if (amount <= 0) {
      return;
    }

    const timeOutId = setTimeout(
      () =>
        currencyItem?.currency_id &&
        getFee({
          asset_id: currencyItem?.currency_id,
          amount,
          channel_name: payType,
          card_number:
            payType === PAYMENT_OPTIONS.Card
              ? cardList?.[card]?.card_number
              : undefined,
          swift:
            payType === PAYMENT_OPTIONS.Bank ? selectedBank.swift : undefined,
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [amount]);

  // useEffect(() => {
  //   if (payType === PAYMENT_OPTIONS.Paypal) {
  //     if (variantId === 0) {
  //       setValue("paypalId", "");
  //       setValue("email", "");
  //       setValue("phone", "***");
  //     }

  //     if (variantId === 1) {
  //       debugger;
  //       setValue("email", "");
  //       setValue("paypalId", "***");
  //       setValue("phone", "***");
  //     }

  //     if (variantId === 2) {
  //       setValue("phone", "");
  //       setValue("paypalId", "***");
  //       setValue("email", "placeholder@gmail.com");
  //     }
  //   }
  //   setValue("amount", -1);
  // }, [variantId]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    const width = phoneRef.current ? phoneRef.current.offsetWidth : 0;
    setPhoneDivWidth(width);

    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const gotNewCard = (cardData: any) => {
    /*
    const newCard = {
      id: -1,
      bank_name: cardData.bank_name,
      card_number: cardData.card_number,
      card_number_last4: cardData.card_number.substr(
        cardData.card_number.length - 4
      ),
      holder_name: cardData.holder_name,
      expiry_month: cardData.expiry_month,
      expiry_year: cardData.expiry_year,
    };
    */

    setCardList([...cardList, cardData]);
    setValue("card", [...cardList, cardData].length - 1);
  };

  const onAddCard = () => {
    openModal("ADD_PAYMENT_CARD", { type: "online", callback: gotNewCard });
  };

  const onAddAccount = () => {
    openModal("ADD_IBAN_ACCOUNT", {
      type: "save",
      currency_id: currencyItem?.currency_id,
      callback: getIbans,
    });
  };

  const { mutate: getFee } = useMutation(
    client.payment.getPaymentWithdrawalFee,
    {
      onSuccess: (data) => {
        setFee(data.withdrawal_fee);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getDailyAmount, isLoading: isLoadingDailyAmount } =
    useMutation(client.payment.calculateDailyWithdrawalAmount, {
      onSuccess: (data) => {
        setDailyAmount(data.residual_amount ?? 0);
        /*
        if (data.residual_amount) {
          setValue("max_amount", data.residual_amount);
        }
        */
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "user_not_verified"
            ) {
              setAlertText(String(L.translate("Errors.user_not_verified")));
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "overload_daily_limit"
            ) {
              setAlertText(String(L.translate("Errors.overload_daily_limit")));
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  const loadSelectedWallet = (
    wallets: AssetDataType[],
    walletIdx: number,
    isVIP: number
  ) => {
    const code = wallets.find((item) => item.key === walletIdx)?.currency;
    code &&
      getAvailablePaymentMethods({
        code,
        type: PAYMENT_TYPE.Withdrawal,
      });
  };

  const { mutate: getWallets, isLoading } = useMutation(
    client.balance.getWallets,
    {
      onSuccess: (data) => {
        const dataSource = data.wallets.map((item, index) => ({
          key: index,
          wallet_id: item.id,
          currency: item.asset.code,
          amount: item.balance,
          // amount: item.total,
          currency_id: item.asset.id,
          name: item.asset.name,
          img_path: item.asset.img_path,
          vip_deposit_max: item.asset.vip_deposit_max,
          vip_deposit_min: item.asset.vip_deposit_min,
          vip_withdraw_max: item.asset.vip_withdraw_max,
          vip_withdraw_min: item.asset.vip_withdraw_min,
          deposit_max: item.asset.deposit_max,
          deposit_min: item.asset.deposit_min,
          withdraw_max: item.asset.withdraw_max,
          withdraw_min: item.asset.withdraw_min,
          depositable: item.asset.depositable,
          withdrawable: item.asset.withdrawable,
          balance: item.balance,
        }));
        const filteredDataSource = dataSource.filter(
          (item) => item.withdrawable === 1
        );
        setCurrencyList(filteredDataSource);

        const isVIP = getCurrrentUser().is_vip ?? 0;

        if (filteredDataSource.length > 0) {
          const key = filteredDataSource[0].key;
          if (selectedCurrencyIdx < 0) {
            setSelectedCurrencyIdx(key);
            setValue("currency", key);

            loadSelectedWallet(filteredDataSource, key, isVIP);
          } else {
            loadSelectedWallet(filteredDataSource, selectedCurrencyIdx, isVIP);
          }
        }
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const {
    mutate: getAvailablePaymentMethods,
    isLoading: isLoadingPaymentMethods,
  } = useMutation(client.payment.getAvailablePaymentMethods, {
    onSuccess: (data) => {
      data.length > 0 && setValue("payType", "");
      setPayOptions(data);
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));

      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: getCards, isLoading: isLoadingCards } = useMutation(
    client.bankCards.all,
    {
      onSuccess: (data) => {
        setCardList(data);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getSettings, isLoading: isLoadingSettings } = useMutation(
    client.users.me,
    {
      onSuccess: (data) => {
        if (payType === PAYMENT_OPTIONS.Bank) {
          const confirmParams = [
            {
              id: "beneficiary_name",
              name: "Beneficiary Name",
              value: selectedBank?.beneficiary_name,
            },
            {
              id: "bank_name",
              name: "Bank Name",
              value: selectedBank?.bank_name,
            },
            {
              id: "indicated_amount",
              name: "Indicated Amount",
              value: `${makeNumberWithFloatingDigits(
                submitData?.amount,
                2
              )} ${submitData?.currency.toUpperCase()}`,
            },
            { id: "swift", name: "SWIFT", value: selectedBank?.swift },
            { id: "iban", name: "IBAN", value: selectedBank?.iban },
            {
              id: "reference",
              name: "Reference",
              value: selectedBank?.payment_purpose,
            },
            { id: "address", name: "Address", value: selectedBank?.address },
          ];
          openModal("WITHDRAWAL_BANK_CONFIRMATION", {
            me: data.user,
            withdrawal_type: "bank",
            amount: submitData?.amount,
            asset_id: submitData?.asset_id,
            iban_account_id: selectedBank?.id,
            info: confirmParams,
          });
        } else if (payType === PAYMENT_OPTIONS.Paypal) {
          openModal("WITHDRAWAL_PAYPAL_CONFIRMATION", {
            ...submitData,
            me: data.user,
          });
        } else if (payType === PAYMENT_OPTIONS.Card) {
          openModal("WITHDRAWAL_VERIFY_CVV", {
            ...submitData,
            payType,
            me: data.user,
          });
        }

        /*
        if (data.user.email_2fa_enabled && data.user.phone_2fa_enabled) {
          if (payType === PAYMENT_OPTIONS.Bank) {
            openModal("BANK_DETAILS", { ...submitData, me: data.user });
          } else if (payType === PAYMENT_OPTIONS.Paypal) {
            openModal("WITHDRAWAL_PAYPAL_CONFIRMATION", {
              ...submitData,
              me: data.user,
            });
          } else if (payType === PAYMENT_OPTIONS.Card) {
            openModal("WITHDRAWAL_VERIFY_CVV", {
              ...submitData,
              me: data.user,
            });
          }
        } else {
          setAlertText("You need to enable 2FA settings. Please check");
          setIsAlertOpened(true);
        }
        */
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getLimitations } = useMutation(
    client.payment.getLimitations,
    {
      onSuccess: (data) => {
        setLimitations(data);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getIbans, isLoading: isLoadingEnable } = useMutation(
    client.iban.all,
    {
      onSuccess: (data) => {
        const filterIbans = data.filter(
          (code) =>
            code.currency_code?.toLowerCase() ===
            currencyItem?.currency.toLowerCase()
        );
        setIbanAccounts(filterIbans);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onSubmit = (data: FormData) => {
    setWithdrawalAmount(data.amount);

    const currencyItem = currencyList.find(
      (item) => item.key === data.currency
    );
    const asset_id = currencyItem?.currency_id;

    if (data.payType === PAYMENT_OPTIONS.Bank) {
      setSubmitData({
        withdrawal_type: "bank",
        asset_id,
        amount: data.amount,
        currency: currencyItem?.currency,
        iban_account_id: selectedBank?.id,
      });
    } else if (data.payType === PAYMENT_OPTIONS.Paypal) {
      let targetAddress = data.phone;
      let targetType = "phone";

      switch (data.variantId) {
        case 0:
          targetAddress = data.paypalId;
          targetType = "PAYPAL_ID";
          break;
        case 1:
          targetAddress = data.email;
          targetType = "EMAIL";
          break;
        case 2:
          targetAddress = data.phone;
          targetType = "PHONE";
          break;
      }

      setSubmitData({
        withdrawal_type: "paypal",
        asset_id,
        amount: data.amount,
        currency: currencyItem?.currency,
        variant: withdrawVariants[data.variantId],
        targetAddress,
        targetType,
      });
    } else if (data.payType === PAYMENT_OPTIONS.Card) {
      const cardInfo = cardList[data.card];

      setSubmitData({
        withdrawal_type: "card",
        asset_id,
        holder_name: cardInfo.holder_name,
        amount: data.amount,
        card_no: cardList[data.card].card_number,
        currency: currencyItem?.currency,
        card_info: cardInfo,
      });
    }

    setTimeout(() => getSettings(), 300);
  };

  const GoBack = () => {
    if (!selectedBank || (selectedBank && !continueBtn)) {
      setValue("payType", "");
    }
    setStep(0);

    // if (selectedBank) {
    // 	setActiveView('currency');
    // 	return;
    // }
    // setSelectedPayment('');
    // setTimeout(() => {
    // 	setActiveView('currency');
    // }, 100);
  };

  const isValidBank = selectedBank ? true : false;

  const handleContinue = () => {
    if (!selectedBank) {
      return;
    }
    setContinueBtn(true);
    setStep(0);
    // onChange(selectedBank);
    // setActiveView('currency');
  };

  const makePaypalVariantField = () => {
    if (variantId == 2) {
      return (
        <div className="flex flex-col w-full">
          <span className="block cursor-pointer pb-2.5 font-normal text-secondary font-manrope text-[16px]">
            {String(L.translate("WithdrawFiat.phone"))}
          </span>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <ReactPhone
                placeholder=""
                dropdownStyle={{ width: phoneDivWidth }}
                inputClass={classNames(
                  "!w-full !border !border-border !ring-[0.5px] !ring-light-500 !focus:ring-[0.5px] !rounded-[12px]",
                  errors.phone
                    ? "focus:!border-[#F44336] focus:!ring-[#F44336]"
                    : "focus:!border-active focus:!ring-active"
                )}
                country="us"
                {...field}
              />
            )}
          />

          {errors.phone?.message && (
            <span
              role="alert"
              className="block pt-2 text-[16px] text-[#F44336] font-manrope"
            >
              {errors.phone?.message}
            </span>
          )}
        </div>
      );
    } else if (variantId == 0) {
      return (
        <Input
          label={withdrawVariants[variantId]}
          className="w-full"
          {...register("paypalId")}
          error={errors.paypalId && (errors.paypalId.message as string)}
        />
      );
    } else {
      return (
        <Input
          label={withdrawVariants[variantId]}
          placeholder={String(L.translate("WithdrawFiat.enter_email"))}
          className="w-full"
          {...register("email")}
          error={errors.email && (errors.email.message as string)}
        />
      );
    }
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col py-2 px-4 sm:p-4 sm:pr-11">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          {String(L.translate("WithdrawFiat.title"))}
        </p>
        {payType === PAYMENT_OPTIONS.Bank && step === 1 ? (
          <div className="relative w-full gap-2 mt-2 sm:mt-4 bg-white rounded-[12px] items-center justify-center content-item">
            <button onClick={GoBack} className="back-step">
              <span className="back-step__arrow">
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 7.27393L16.25 7.27393"
                    stroke="#011E26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M7.29883 13.2985L1.24883 7.27451L7.29883 1.24951"
                    stroke="#011E26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
              <span className="back-step__text">Back </span>
            </button>
            <div className="operation-form operation-form--wider">
              <div className="operation-group">
                <div className="add-card-list add-card-list--type2">
                  {ibanAccounts?.map((item, index) => (
                    <div key={index} className="add-card-item">
                      <div className="radio radio--type2">
                        <label className="radio__label">
                          <input
                            type="radio"
                            className="hidden_btn"
                            name="tokens-group"
                            checked={item.id === selectedBank?.id}
                            onChange={() => setSelctedBank(item)}
                          />
                          <span className="radio__item" />
                          <span className="radio__text-group">
                            <span className="radio__text radio__text--regular">
                              {item?.bank_name}
                            </span>
                            <span className="radio__text radio__text--extra">
                              {" "}
                              Swift: {item.swift}
                            </span>
                          </span>
                        </label>
                      </div>
                      {item?.bank_icon ? (
                        <div className="add-card-item__logo add-card-item__logo--bank ">
                          <img src={item?.bank_icon || ""} alt="visa" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                  <div className="add-card-item">
                    <button
                      onClick={() => onAddAccount()}
                      type="button"
                      className="add-card-new"
                    >
                      <span className="add-card-new__icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 5V19"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5 12H19"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      Add Bank Account
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-submit">
                <Button
                  onClick={handleContinue}
                  variant="validate"
                  type="submit"
                  className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] w-full"
                  disabled={!isValidBank}
                >
                  {String(L.translate("DepositFiat.continue"))}
                </Button>
              </div>
              {/* <button className="button button--full-width">Continue</button> */}
            </div>
          </div>
        ) : (
          <div className="relative w-full flex flex-col gap-2 mt-2 sm:mt-4 bg-white rounded-[12px] items-center justify-center">
            <button
              onClick={() => navigate(-1)}
              className="back-step left-4 top-4 start ml-10 mt-6 mr-auto"
            >
              <span className="back-step__arrow">
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 7.27393L16.25 7.27393"
                    stroke="#011E26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M7.29883 13.2985L1.24883 7.27451L7.29883 1.24951"
                    stroke="#011E26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
              <span className="back-step__text text-[20px]">Back </span>
            </button>{" "}
            {(isLoading || isLoadingPaymentMethods || isLoadingCards) && (
              <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
            )}
            <div className="flex flex-col w-full sm:w-[548px] p-4">
              <form
                className="h-full w-full flex flex-col gap-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <p className="font-manrope text-[20px] font-medium text-primary">
                  1. {String(L.translate("WithdrawFiat.select_currency"))}
                </p>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <DropdownCurrency
                      label={String(
                        L.translate("WithdrawFiat.currency_to_withdraw")
                      )}
                      placeholder={String(L.translate("WithdrawFiat.currency"))}
                      className="w-full"
                      data={currencyList}
                      error={
                        errors.currency && (errors.currency.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                {payOptions.length > 0 ? (
                  <div className="w-full flex flex-col gap-8">
                    <div className="w-full flex flex-row justify-between">
                      <Controller
                        name="payType"
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            label={String(
                              L.translate("WithdrawFiat.withdraw_crypto")
                            )}
                            options={payOptions}
                            className="w-full"
                            error={
                              errors.payType &&
                              (errors.payType.message as string)
                            }
                            {...field}
                          />
                        )}
                      />
                      <div className="w-full flex flex-col items-start">
                        <p className="font-manrope text-[20px] text-primary font-medium">
                          {String(L.translate("WithdrawFiat.limits"))}
                        </p>
                        <p className="font-manrope text-[14px] text-secondary font-light mt-2">
                          {String(L.translate("WithdrawFiat.withdraw_min_max"))}
                        </p>
                        <p className="font-manrope text-[16px] text-primary font-medium">
                          {amountLimits &&
                            currencyItem?.currency &&
                            `${getCurrencySymbol(
                              currencyItem?.currency,
                              amountLimits.min
                            )} / ${getCurrencySymbol(
                              currencyItem?.currency,
                              amountLimits.max
                            )}`}
                        </p>
                      </div>
                    </div>
                    {payType === PAYMENT_OPTIONS.Card && (
                      <>
                        <Controller
                          name="card"
                          control={control}
                          render={({ field }) => (
                            <DropdownCard
                              label={String(L.translate("Bank.choose_card"))}
                              placeholder={String(
                                L.translate("Bank.choose_card")
                              )}
                              className="w-full"
                              data={cardList}
                              //showAdds={false}
                              onAddCard={onAddCard}
                              setError={() => {
                                setError("card", {
                                  type: "custom",
                                  message: "please chose the card",
                                });
                              }}
                              error={
                                errors.card && (errors.card.message as string)
                              }
                              {...field}
                            />
                          )}
                        />
                        <p
                          className="font-manrope text-[20px] font-medium text-primary mt-4"
                          ref={phoneRef}
                        >
                          {String(
                            L.translate("WithdrawFiat.enter_amount_with_index")
                          )}
                        </p>
                        <Controller
                          name="amount"
                          control={control}
                          render={({ field }) => (
                            <InputWithMax
                              label={String(L.translate("WithdrawFiat.amount"))}
                              placeholder={String(
                                L.translate("WithdrawFiat.enter_amount")
                              )}
                              className="w-full"
                              max_limit={amountLimits?.max || 0}
                              real_max_limit={maxAmount}
                              isDeposit={false}
                              showBalance={true}
                              currency={currencyList[currency]?.currency}
                              error={
                                errors.amount &&
                                (errors.amount.message as string)
                              }
                              {...field}
                            />
                          )}
                        />

                        <div className="form-block">
                          <div className="operation-info">
                            <span className="input-name">
                              {L.translate("DepositFiat.you_receive")}:
                            </span>
                            <span className="operation-info__sum">
                              {" "}
                              {(Number(recive) <= 0
                                ? 0
                                : roundTo(
                                    Number(
                                      convertExponentialToDecimal(
                                        Number(recive)
                                      )
                                    ),
                                    1
                                  )) || "0"}{" "}
                              {currencyItem?.currency?.toUpperCase()}
                            </span>
                            <div className="operation-info">
                              <div className="operation-info__line operation-info__line--flex ">
                                <span className="operation-info__label">
                                  {" "}
                                  {L.translate("DepositFiat.transaction_fee")}:
                                </span>
                                <span className="operation-info__value operation-info__value--type2">
                                  {!amount ? "0" : fee}{" "}
                                  {currencyItem?.currency?.toUpperCase()}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {payType === PAYMENT_OPTIONS.Paypal && (
                      <>
                        {" "}
                        <p
                          className="font-manrope text-[20px] font-medium text-primary mt-4"
                          ref={phoneRef}
                        >
                          {String(L.translate("WithdrawFiat.withdraw_info"))}
                        </p>
                        <Controller
                          name="variantId"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              label={String(
                                L.translate("WithdrawFiat.variant_id")
                              )}
                              placeholder={String(
                                L.translate("WithdrawFiat.choose_variant")
                              )}
                              className="w-full"
                              data={withdrawVariants}
                              error={
                                errors.variantId &&
                                (errors.variantId.message as string)
                              }
                              {...field}
                            />
                          )}
                        />
                        {makePaypalVariantField()}
                        <Controller
                          name="amount"
                          control={control}
                          render={({ field }) => (
                            <InputWithMax
                              label={String(L.translate("WithdrawFiat.amount"))}
                              placeholder={String(
                                L.translate("WithdrawFiat.enter_amount")
                              )}
                              className="w-full"
                              max_limit={amountLimits?.max || 0}
                              real_max_limit={maxAmount}
                              isDeposit={false}
                              showBalance={true}
                              currency={currencyList[currency]?.currency}
                              error={
                                errors.amount &&
                                (errors.amount.message as string)
                              }
                              {...field}
                            />
                          )}
                        />
                      </>
                    )}
                    {/* {payType === "bank_transfer" && (
                    <div>
                      <Controller
                        name="ibanIdx"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            label="IBAN"
                            placeholder="Choose bank"
                            className="w-full"
                            data={ibanAccounts}
                            displayValue={(item) => (
                              <>
                                <span className="mr-[0.5rem]">
                                  {item?.bank_name}
                                </span>
                                <span className="text-secondary">
                                  IBAN: {item?.iban}
                                </span>
                              </>
                            )}
                            error={
                              errors.ibanIdx &&
                              (errors.ibanIdx.message as string)
                            }
                            {...field}
                          />
                        )}
                      />
                      <a
                        onClick={onAddAccount}
                        className="text-[14px] font-light text-secondary leading-[24px] font-manrope underline hover:text-primary cursor-pointer"
                      >
                        {String(
                          L.translate("Account.PaymentMethods.add_new_bank")
                        )}
                      </a>
                    </div>
                  )} */}

                    {payType === PAYMENT_OPTIONS.Bank ? (
                      <>
                        <p
                          className="font-manrope text-[20px] font-medium text-primary mt-4"
                          ref={phoneRef}
                        >
                          {String(
                            L.translate("WithdrawFiat.enter_amount_with_index")
                          )}
                        </p>
                        <Controller
                          name="amount"
                          control={control}
                          render={({ field }) => (
                            <InputWithMax
                              label={String(L.translate("WithdrawFiat.amount"))}
                              placeholder={String(
                                L.translate("WithdrawFiat.enter_amount")
                              )}
                              className="w-full"
                              max_limit={amountLimits?.max || 0}
                              real_max_limit={maxAmount}
                              isDeposit={false}
                              showBalance={true}
                              currency={currencyList[currency]?.currency}
                              error={
                                errors.amount &&
                                (errors.amount.message as string)
                              }
                              {...field}
                            />
                          )}
                        />
                        <div className="form-block">
                          <div className="operation-info">
                            <span className="input-name">
                              {L.translate("DepositFiat.you_receive")}:
                            </span>
                            <span className="operation-info__sum">
                              {" "}
                              {(Number(recive) <= 0
                                ? 0
                                : roundTo(
                                    Number(
                                      convertExponentialToDecimal(
                                        Number(recive)
                                      )
                                    ),
                                    1
                                  )) || "0"}{" "}
                              {currencyItem?.currency?.toUpperCase()}
                            </span>
                            <div className="operation-info">
                              <div className="operation-info__line operation-info__line--flex ">
                                <span className="operation-info__label">
                                  {" "}
                                  {L.translate("DepositFiat.transaction_fee")}:
                                </span>
                                <span className="operation-info__value operation-info__value--type2">
                                  {!amount ? "0" : fee}{" "}
                                  {currencyItem?.currency?.toUpperCase()}
                                </span>
                              </div>
                              <div className="operation-info__line operation-info__line--flex operation-info__line--mob-column">
                                <span className="operation-info__label">
                                  {L.translate("DepositFiat.payment_method")}:
                                </span>
                                <span className="operation-payment operation-payment--full">
                                  <span className="operation-payment__name operation-payment__name--type2">
                                    {selectedBank?.bank_name}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => setStep(1)}
                                    className="d-flex operation-payment__link"
                                  >
                                    {L.translate("DepositFiat.change_bank")}
                                    <span className="d-flex operation-payment__link-arrow">
                                      <svg
                                        width="12"
                                        height="10"
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M11.166 5.18282L1.16602 5.18282"
                                          stroke="#232800"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.13281 9.19911L11.1661 5.18311L7.13281 1.16644"
                                          stroke="#232800"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {payType === PAYMENT_OPTIONS.Paypal && (
                      <div className="flex flex-col gap-2 mt-2 mb-2">
                        <p className="font-manrope text-[14px] font-light text-secondary">
                          {String(L.translate("Deposit.you_receive"))}
                        </p>
                        <p className="font-manrope text-[24px] font-medium text-primary">
                          {`${
                            amount > 0
                              ? makeNumberWithFloatingDigits(amount - fee, 2)
                              : makeNumberWithFloatingDigits(0, 2)
                          } ${currencyList[currency].currency.toUpperCase()}`}
                        </p>
                        <div className="flex flex-row gap-4 mt-4">
                          <p className="font-manrope text-[14px] font-light text-secondary">
                            {`${String(
                              L.translate("WithdrawFiat.transaction_fee")
                            )}:`}
                          </p>
                          <p className="font-manrope text-[14px] font-light text-secondary">
                            {`${makeNumberWithFloatingDigits(
                              fee,
                              2
                            )} ${currencyList[
                              currency
                            ].currency.toUpperCase()}`}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <p className="font-manrope text-[20px] font-medium text-secondary py-8">
                    {String(L.translate("DepositFiat.message"))}
                  </p>
                )}

                <div className="flex flex-row items-center justify-center">
                  <Button
                    variant="validate"
                    type="submit"
                    className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] w-full"
                    disabled={!isValid || isLoading || amount - fee <= 0}
                    isLoading={isLoadingSettings}
                  >
                    {String(L.translate("DepositFiat.continue"))}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WithdrawPage;
