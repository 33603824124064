import { ConfigValue } from "../config";
import Cookies from "js-cookie";
import { TwoAuthStatus, User } from "./types";
import CryptoJS from "crypto-js";

export const AUTH_TOKEN_KEY = ConfigValue.AUTH_TOKEN_KEY;
export const AUTH_USER_INFO = ConfigValue.AUTH_USER_INFO;
export const AUTH_USER_GOOGLE_2FA = ConfigValue.AUTH_USER_GOOGLE_2FA;
export const AUTH_USER_EMAIL_2FA = ConfigValue.AUTH_USER_PHONE_2FA;
export const AUTH_USER_PHONE_2FA = ConfigValue.AUTH_USER_EMAIL_2FA;
export const AUTH_USER_TELEPHONE_2FA = ConfigValue.AUTH_USER_TELEPHONE_2FA;

export const getAuthToken = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get(AUTH_TOKEN_KEY);
};

export function setAuthToken(token: string) {
  Cookies.set(AUTH_TOKEN_KEY, token, { expires: 1 });
}

export function removeAuthToken() {
  Cookies.remove(AUTH_TOKEN_KEY);
}

export function checkHasAuthToken() {
  const token = Cookies.get(AUTH_TOKEN_KEY);
  if (!token) return false;
  return true;
}

export const getAuthUserInfo = () => {
  if (typeof window === undefined) {
    return null;
  }

  const value = Cookies.get(AUTH_USER_INFO);
  if (value) {
    const bytes = CryptoJS.AES.decrypt(value, ConfigValue.CRYPTO_SECRET_PASS);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return null;
  }
};

export function setAuthUserInfo(info: User, password: string) {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify({ ...info, password }),
    ConfigValue.CRYPTO_SECRET_PASS
  ).toString();

  Cookies.set(AUTH_USER_INFO, data, {
    expires: 1,
  });
}

export function removeAuthUserInfo() {
  Cookies.remove(AUTH_USER_INFO);
}

export const getAuthUser2FAInfo = () => {
  if (typeof window === undefined) {
    return {
      phone: "+0000",
      google2fa_enabled: 0,
      email_2fa_enabled: 0,
      phone_2fa_enabled: 0,
    };
  }

  return {
    phone: Cookies.get(AUTH_USER_TELEPHONE_2FA)
      ? String(Cookies.get(AUTH_USER_TELEPHONE_2FA))
      : "+0000",
    google2fa_enabled: Cookies.get(AUTH_USER_GOOGLE_2FA)
      ? Number(Cookies.get(AUTH_USER_GOOGLE_2FA))
      : 0,
    email_2fa_enabled: Cookies.get(AUTH_USER_EMAIL_2FA)
      ? Number(Cookies.get(AUTH_USER_EMAIL_2FA))
      : 0,
    phone_2fa_enabled: Cookies.get(AUTH_USER_PHONE_2FA)
      ? Number(Cookies.get(AUTH_USER_PHONE_2FA))
      : 0,
  };
};

export function setAuthUser2FAInfo(info: TwoAuthStatus) {
  Cookies.set(AUTH_USER_TELEPHONE_2FA, `${info.phone}`, { expires: 1 });
  Cookies.set(AUTH_USER_GOOGLE_2FA, `${info.google2fa_enabled}`, {
    expires: 1,
  });
  Cookies.set(AUTH_USER_EMAIL_2FA, `${info.email_2fa_enabled}`, { expires: 1 });
  Cookies.set(AUTH_USER_PHONE_2FA, `${info.phone_2fa_enabled}`, { expires: 1 });
}

export function removeAuthUser2FAInfo() {
  Cookies.remove(AUTH_USER_TELEPHONE_2FA);
  Cookies.remove(AUTH_USER_GOOGLE_2FA);
  Cookies.remove(AUTH_USER_EMAIL_2FA);
  Cookies.remove(AUTH_USER_PHONE_2FA);
}

export function setWithdrawalAmount(amount: number) {
  Cookies.set("withdrawal_amount", `${amount}`, { expires: 1 });
}

export function getWithdrawalAmount() {
  const amount = Cookies.get("withdrawal_amount");
  if (!amount) return 0;
  return Number(amount);
}
