import MethodsItem from "./methods-item";
import KwikPayImg from "src/assets/images/kwikPay.png";
import routes from "../../config/routes";
import { useNavigate } from "react-router-dom";
import L from "i18n-react";
import { Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { User, UserClient } from "../../api/types";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import GlobalContext from "../../context/global-context";
import { useModalAction } from "../modal-views/context";
import { removeTooOldValues } from "oblivious-set/src/index";

const Methods = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [userClient, setUserClient] = useState<UserClient | null>(null);
  const { openModal, closeModal } = useModalAction();

  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);

  const { mutate: getUserData, isLoading } = useMutation(client.users.me, {
    onSuccess: async (data) => {
      setUser(data.user);
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: getUserClientData } = useMutation(client.users.clientMe, {
    onSuccess: async (data) => {
      setUserClient(data.client);
    },
    onError: (error: any) => {
      console.error("Error fetching user client data:", error);
    },
  });

  const handleToggle = async (method: any) => {
    try {
      if (userClient === null) {
        openModal("ADD_KWIKPAY_CLIENT", {
          type: "save",
        });
      } else {
        navigate(method);
      }
    } catch (error) {
      console.error("Error handling toggle:", error);
    }
  };

  useEffect(() => {
    getUserClientData();
  }, []);

  return (
    <>
      <p className="text-[#7F8172] text-base font-medium leading-[24px]">
        {String(L.translate("FastTransfers.methodTxt"))}
      </p>
      <div className="relative mt-[30px] flex gap-[30px]">
        <MethodsItem
          handleToggle={handleToggle}
          src={KwikPayImg}
          title={String(L.translate("FastTransfers.KwikPay.title"))}
        />
        <MethodsItem
          src={""}
          title={String(L.translate("FastTransfers.Other.title"))}
        />
      </div>
    </>
  );
};

export default Methods;
