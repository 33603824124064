import React, { FC, useContext, useState, useEffect, useCallback } from "react";
import Input from "../../components/ui/input";
import Password from "../../components/ui/password";
import Button from "../../components/ui/button";
import CheckBox from "../../components/ui/checkbox";
import AccSwitcher from "../../components/ui/acc-switcher";
import L from "i18n-react";
import { motion } from "framer-motion";
import { fadeInBottom } from "../../lib/framer-motion/fade-in-bottom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Navigate } from "react-router-dom";
import routes from "../../config/routes";
import useAuth from "../../hooks/use-auth";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import { useModalAction } from "src/components/modal-views/context";
import { ConfigValue } from "src/config";
import GlobalContext from "src/context/global-context";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { ResetIcon } from "../../components/icons/reset-icon";

const CAPTCHA = "6LcJxEYqAAAAAOqLShLydrGCTpUjS-ODkkXMXcVb";

const SignUpPage: FC = () => {
  const [val, setVal] = useState();

  const { openModal } = useModalAction();

  const schema = yup
    .object({
      email: yup
        .string()
        .required(String(L.translate("Errors.google2fa")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          String(L.translate("Errors.email_format"))
        ),
      password: yup
        .string()
        .required(String(L.translate("Errors.google2fa")))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          String(L.translate("Errors.password_validation"))
        ),
      tos: yup
        .bool()
        .required(String(L.translate("Errors.google2fa")))
        .isTrue(),
      cp: yup
        .bool()
        .required(String(L.translate("Errors.google2fa")))
        .isTrue(),
      captcha: yup
        .string()
        .required(String(L.translate("Errors.google2fa")))
        .min(3),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const { setAlertText, setIsAlertOpened, setAlertTitle } =
    useContext(GlobalContext);
  const [captchaValue, setCaptchaValue] = useState("");
  const [resetpCatpcha, setResetpCatpcha] = useState(false);
  const [isBuisiness, setIsBuisiness] = useState(false);

  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const [curPassword, curEmail, tos, cp] = watch([
    "password",
    "email",
    "tos",
    "cp",
  ]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!recaptchaRef.current) {
      console.error("reCAPTCHA ref не ініціалізовано");
      return;
    }

    const token = await recaptchaRef.current?.execute();
    if (String(token)) {
      setCaptchaValue(String(token));
      return token;
      // Додайте вашу логіку для перевірки капчі та авторизації після отримання токену
    } else {
      console.error("Не вдалося отримати токен від reCAPTCHA");
    }
  }, [recaptchaRef]);

  const handleGenerate = async () => {
    await handleReCaptchaVerify();
  };

  useEffect(() => {
    if (curPassword && curEmail && tos && cp) {
      if (!captchaValue) {
        handleGenerate();
        return;
      }
    }
  }, [curPassword, curEmail, tos, cp]);

  const { mutate: signup, isLoading } = useMutation(client.users.register, {
    onSuccess: (data) => {
      console.log(data);
      openModal("EMAIL_VERIFICATION", {
        type: "email",
        token: data.email_confirm_token,
        email: data.user.email,
        title: String(L.translate("ErrorMessage.crate_auth")),
      });
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));

      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          if (
            error.response.data.errors &&
            error.response.data.errors[0] === "registration_error"
          ) {
            setAlertText(String(L.translate("Errors.registration_error")));
            setIsAlertOpened(true);
            return;
          } else if (
            error.response.data.errors.password &&
            error.response.data.errors.password[0] ===
              "the_password_must_not_be_greater_than25_characters"
          ) {
            setAlertText(String(L.translate("Errors.password_max_length")));
            setIsAlertOpened(true);
            return;
          } else if (
            error.response.data.errors.email &&
            error.response.data.errors.email[0] ===
              "the_email_has_already_been_taken"
          ) {
            setAlertText(
              String(L.translate("Errors.the_email_has_already_been_taken"))
            );
            setIsAlertOpened(true);
            return;
          }

          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  const handleChange = (value: string | null) => {
    console.log("Captcha value:", value);
    setCaptchaValue(value ? value : "");
    setValue("captcha", value ? value : "", { shouldValidate: true });

    //this.setState({ value });
    // if value is null recaptcha expired
    //if (value === null) this.setState({ expired: "true" });
  };

  const asyncScriptOnLoad = () => {
    console.log("scriptLoad - reCaptcha Ref-", recaptchaRef);
  };

  const resetCaptcha = () => {
    recaptchaRef.current?.reset();
  };

  const { isAuthorized } = useAuth();
  if (isAuthorized) return <Navigate to={routes.settings} />;

  const onSubmit = (data: FormData) => {
    console.log(data);
    signup({ ...data, is_business: isBuisiness ? 1 : 0 });
  };

  return (
    <motion.div
      variants={fadeInBottom()}
      className="stify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto">
        <form
          className="flex h-full w-full sm:w-[421px] py-8 flex-col px-4 sm:px-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <AccSwitcher value={isBuisiness} onChange={setIsBuisiness} />
          <div className="flex flex-col gap-2 sm:gap-0">
            <img
              alt="fastoo"
              src={"/assets/logo.png"}
              className="w-24 h-24 object-contain m-auto sm:hidden"
            />
            <p className="text-[34px] font-medium font-manrope text-primary text-center sm:text-center">
              {String(L.translate("Forms.RegistrForm.registr_form_title"))}
            </p>
            <p className="text-[18px] font-light font-manrope text-secondary text-center sm:text-center">
              {String(L.translate("Forms.RegistrForm.registr_form_subtitle"))}
            </p>
          </div>
          <div className="flex flex-col mt-10 gap-4">
            <Input
              id="new-email"
              autoComplete="off"
              value={val}
              label={String(
                L.translate("Forms.RegistrForm.registr_form_field_email")
              )}
              placeholder={String(
                L.translate("Forms.LoginForm.placeholder_email")
              )}
              className="w-full"
              {...register("email")}
              onChange={(e: any) => {
                setVal(e.target.value.trim());
              }}
              error={errors.email && (errors.email.message as string)}
            />
            <Password
              label={String(
                L.translate("Forms.RegistrForm.registr_form_field_password")
              )}
              autoComplete="off"
              placeholder={String(
                L.translate("Forms.LoginForm.placeholder_password")
              )}
              className="w-full"
              {...register("password")}
              error={errors.password && (errors.password.message as string)}
            />
          </div>
          <div className="flex flex-row mt-10 gap-2">
            <CheckBox {...register("tos")} />
            <span className="font-normal font-manrope text-[14px] text-primary">
              {String(
                L.translate("Forms.RegistrForm.registr_form_agree_terms")
              )}
              <a
                target="_blank"
                href={routes.userAgreement}
                className="ml-1 mr-1 text-[14px] underline font-normal text-secondary hover:text-light-600 font-manrope"
                rel="noreferrer"
              >
                {String(
                  L.translate(
                    "Forms.RegistrForm.registr_form_agree_terms_conditions"
                  )
                )}
              </a>
              {String(
                L.translate("Forms.RegistrForm.registr_form_agree_terms_and")
              )}
              <a
                href={routes.privacyPolicy}
                target="_blank"
                className="ml-1 text-[14px] underline font-normal text-secondary hover:text-light-600 font-manrope"
                rel="noreferrer"
              >
                {String(
                  L.translate(
                    "Forms.RegistrForm.registr_form_agree_terms_privat"
                  )
                )}
              </a>
            </span>
          </div>
          <div className="flex flex-row mt-4 gap-2">
            <CheckBox {...register("cp")} />
            <span className="font-normal font-manrope text-[14px] text-primary">
              {String(
                L.translate("Forms.RegistrForm.registr_form_agree_width")
              )}
              <a
                target="_blank"
                href={routes.privacyCookies}
                className="ml-1 text-[14px] underline font-normal text-secondary hover:text-light-600 font-manrope"
                rel="noreferrer"
              >
                {String(
                  L.translate(
                    "Forms.RegistrForm.registr_form_agree_width_cookie"
                  )
                )}
              </a>
            </span>
          </div>
          <div className="flex flex-col mt-10">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={CAPTCHA}
              className="inline-block"
              size="invisible"
              onChange={(e) => handleChange(e)}
              asyncScriptOnLoad={asyncScriptOnLoad}
            />
            {/* {resetpCatpcha && (
              <div
                className="flex items-center cursor-pointer"
                onClick={() => resetCaptcha()}
              >
                <ResetIcon className="w-[20px] h-[20px] text-secondary" />
                <Button>
                  {String(L.translate("Forms.LoginForm.reset_captcha"))}
                </Button>
              </div>
            )}

            {errors.captcha?.message && (
              <span
                role="alert"
                className="block pt-2 text-[16px] text-[#F44336] font-manrope"
              >
                {errors.captcha?.message}
              </span>
            )} */}
          </div>
          <div className="flex flex-col gap-8 mt-10">
            <Button
              variant="validate"
              type="submit"
              className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px]"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
            >
              {String(L.translate("Forms.RegistrForm.registr_form_submit_btn"))}
            </Button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default SignUpPage;
