import { FC, useContext, Fragment, useState, useEffect } from "react";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import TransactionDepositWithdrawalList from "src/components/transactions/deposit-withdrawal-list";
import KwickpayHistory from "src/components/transactions/kwickpay-history";
import TransactionHistoryList from "src/components/transactions/history-list";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import {
  getTransactionsTabIndex,
  setTransactionsTabIndex,
} from "src/lib/utils";

const TransactionsPage: FC = () => {
  const tabLists = [
    `${String(L.translate("TransactionHistory.title_btn"))}`,
    // `${String(L.translate("KwickpayHistory.tab"))}`,
    //`${String(L.translate("Transfer.title"))}`,
  ];
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    getTransactionsTabIndex() ? Number(getTransactionsTabIndex()) : 0
  );
  const isMounted = useIsMounted();
  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const saveSelectedTabIndex = (tabIdx: number) => {
    setSelectedTabIndex(tabIdx);
    setTransactionsTabIndex(tabIdx);
  };

  const makeTabList = () => {
    const tabListsUI = tabLists.map((item, index) => (
      <Tab
        key={`tabs_${index}`}
        className={({ selected }) =>
          classNames(
            "font-medium text-[16px] relative px-8 py-4 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-brand before:transition-all before:duration-300 before:ease-in-out",
            "ring-transparent ring-opacity-60 ring-offset-0 ring-offset-transparent focus:outline-none focus:ring-0",
            selected
              ? "text-primary bg-white rounded-t-[8px]"
              : "text-secondary bg-transparent"
          )
        }
      >
        {item}
      </Tab>
    ));
    return <Tab.List>{tabListsUI}</Tab.List>;
  };
  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col py-2 px-4 sm:p-4 sm:pr-11">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          {String(L.translate("TransactionHistory.title"))}
        </p>
        <div className="flex flex-col mt-2 sm:mt-4">
          <Tab.Group
            selectedIndex={selectedTabIndex}
            onChange={(tabIdx: number) => saveSelectedTabIndex(tabIdx)}
          >
            {makeTabList()}
            <Tab.Panels>
              <Tab.Panel>
                <TransactionDepositWithdrawalList />
              </Tab.Panel>

              <Tab.Panel>
                <KwickpayHistory />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};

export default TransactionsPage;
