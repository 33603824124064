import React, { FC, useState, useEffect, useContext } from "react";
import { fadeInBottom } from "src/lib/framer-motion/fade-in-bottom";
import { motion } from "framer-motion";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";

const InternalAMLPolicyPage: FC = () => {
  const { language } = useContext(GlobalContext);
  console.log("current language is ", language);

  return (
    <motion.div
      variants={fadeInBottom()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto p-4 sm:p-11 aml-content">
        <div className="flex pb-[180px] font-manrope text-primary">
          <div className="flex flex-col gap-4">
            <h3 className="text-[25px] sm:text-[36px] font-bold text-primary">
              {String(L.translate("AMLPolicy.title"))}
            </h3>
            <div className="flex flex-col gap-2">
              <h2 className="font-manrope text-[16px] sm:text-[22px] font-medium text-primary">
                <span className="mr-3">1.</span>{" "}
                {String(L.translate("AMLPolicy.descr"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">1.1</span>{" "}
                    {String(L.translate("AMLPolicy.descr_1"))}
                  </p>
                </li>
                <li>
                  {" "}
                  <p className="font-manrope font-medium text-primary">
                    <span className="mr-3">1.1</span>
                    {String(L.translate("AMLPolicy.descr_1_1"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">1.2</span>
                    {String(L.translate("AMLPolicy.descr_1_2"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">1.3</span>{" "}
                    {String(L.translate("AMLPolicy.descr_1_3"))}
                  </p>
                </li>
                <li>
                  {" "}
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">1.4</span>{" "}
                    {String(L.translate("AMLPolicy.descr_1_4"))}
                  </p>
                </li>
                <li>
                  {" "}
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">1.5</span>{" "}
                    {String(L.translate("AMLPolicy.descr_1_5"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">1.6</span>
                    {String(L.translate("AMLPolicy.descr_1_6"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">1.7</span>
                    {String(L.translate("AMLPolicy.descr_1_7"))}
                  </p>
                </li>
              </ul>
              <p className="font-manrope text-[16px] font-medium text-primary">
                &nbsp;
              </p>
              <h2 className="font-manrope text-[16px] sm:text-[22px] font-medium text-primary">
                <span className="mr-3">2.</span>{" "}
                {String(L.translate("AMLPolicy.descr_2"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <p className="font-manrope font-medium text-primary">
                    <span className="mr-3">2.1</span>{" "}
                    {String(L.translate("AMLPolicy.descr_2_1"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">2.2</span>{" "}
                    {String(L.translate("AMLPolicy.descr_2_2"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">2.4</span>{" "}
                    {String(L.translate("AMLPolicy.descr_2_3"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">2.5</span>{" "}
                    {String(L.translate("AMLPolicy.descr_2_4"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">2.6</span>{" "}
                    {String(L.translate("AMLPolicy.descr_2_5"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">2.7</span>{" "}
                    {String(L.translate("AMLPolicy.descr_2_6"))}
                  </p>
                </li>
              </ul>
              <p className="font-manrope text-[14px] sm:text-[16px] font-medium text-primary">
                &nbsp;
              </p>
              <h2 className="font-manrope text-[16px] sm:text-[22px] font-medium text-primary">
                <span className="mr-3">3.</span>
                {String(L.translate("AMLPolicy.descr_3"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <p className="font-manrope ] font-medium text-primary">
                    <span className="mr-3">3.1</span>
                    {String(L.translate("AMLPolicy.descr_3_1"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">3.2</span>
                    {String(L.translate("AMLPolicy.descr_3_2"))}
                  </p>
                </li>
                <ul>
                  <li>
                    <p className="font-manrope  font-medium text-primary">
                      <span className="mr-3">3.2.1</span>
                      {String(L.translate("AMLPolicy.descr_3_3"))}
                    </p>
                  </li>
                  <li>
                    <p className="font-manrope  font-medium text-primary">
                      <span className="mr-3">3.2.2</span>
                      {String(L.translate("AMLPolicy.descr_3_4"))}
                    </p>
                  </li>{" "}
                  <li>
                    <p className="font-manrope  font-medium text-primary">
                      <span className="mr-3">3.2.3</span>{" "}
                      {String(L.translate("AMLPolicy.descr_3_5"))}
                    </p>
                  </li>
                  <li>
                    <p className="font-manrope  font-medium text-primary">
                      <span className="mr-3">3.2.4</span>{" "}
                      {String(L.translate("AMLPolicy.descr_3_6"))}
                    </p>
                  </li>
                  <li>
                    <p className="font-manrope font-medium text-primary">
                      <span className="mr-3">3.2.5</span>{" "}
                      {String(L.translate("AMLPolicy.descr_3_7"))}
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p className="font-manrope  font-medium text-primary">
                      <span className="mr-3">3.2.6</span>
                      {String(L.translate("AMLPolicy.descr_3_8"))}
                    </p>
                  </li>
                </ul>

                <li>
                  {" "}
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">3.3</span>{" "}
                    {String(L.translate("AMLPolicy.descr_3_9"))}
                  </p>
                </li>
                <li>
                  <p className="font-manrope  font-medium text-primary">
                    <span className="mr-3">3.4</span>
                    {String(L.translate("AMLPolicy.descr_3_10"))}
                  </p>
                </li>
              </ul>
              <p className="font-manrope text-[14px] sm:text-[16px] font-medium text-primary">
                &nbsp;
              </p>
              <h2 className="font-manrope text-[16px] sm:text-[22px] font-medium text-primary">
                <span className="mr-3">4.</span>{" "}
                {String(L.translate("AMLPolicy.descr_4"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  {" "}
                  <span className="mr-3">4.1</span>
                  {String(L.translate("AMLPolicy.descr_4_1"))}
                </li>
                <ul>
                  <li>
                    <span className="mr-3">4.1.1</span>
                    {String(L.translate("AMLPolicy.descr_4_2"))}
                  </li>

                  <li>
                    <span className="mr-3">4.1.2</span>
                    {String(L.translate("AMLPolicy.descr_4_3"))}
                  </li>
                  <li>
                    <span className="mr-3">4.1.3</span>
                    {String(L.translate("AMLPolicy.descr_4_4"))}
                  </li>

                  <li>
                    <span className="mr-3">4.1.4</span>
                    {String(L.translate("AMLPolicy.descr_4_5"))}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">4.2</span>
                  {String(L.translate("AMLPolicy.descr_4_6"))}
                </li>
                <li>
                  <span className="mr-3">4.3</span>
                  {String(L.translate("AMLPolicy.descr_4_7"))}
                </li>
                <li>
                  <span className="mr-3">4.4</span>
                  {String(L.translate("AMLPolicy.descr_4_8"))}
                </li>
                <ul>
                  {" "}
                  <li>
                    <span className="mr-3">4.4.1</span>
                    {String(L.translate("AMLPolicy.descr_4_9"))}
                  </li>
                  <li>
                    <span className="mr-3">4.4.2</span>
                    {String(L.translate("AMLPolicy.descr_4_10"))}
                  </li>
                  <li>
                    <span className="mr-3">4.4.3</span>
                    {String(L.translate("AMLPolicy.descr_4_11"))}
                  </li>
                  <li>
                    <span className="mr-3">4.4.4</span>
                    {String(L.translate("AMLPolicy.descr_4_12"))}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">4.5</span>
                  {String(L.translate("AMLPolicy.descr_4_13"))}
                </li>
                <li>
                  <span className="mr-3">4.6</span>
                  {String(L.translate("AMLPolicy.descr_4_14"))}
                </li>
                <li>
                  <span className="mr-3">4.7</span>
                  {String(L.translate("AMLPolicy.descr_4_15"))}
                </li>
                <li>
                  <span className="mr-3">4.8</span>
                  {String(L.translate("AMLPolicy.descr_4_16"))}
                </li>
                <li>
                  <span className="mr-3">4.9</span>
                  {String(L.translate("AMLPolicy.descr_4_17"))}
                </li>
                <ul>
                  <li>
                    {" "}
                    <span className="mr-3">4.9.1</span>
                    {String(L.translate("AMLPolicy.descr_4_18"))}
                  </li>
                  <li>
                    {" "}
                    <span className="mr-3">4.9.2</span>
                    {String(L.translate("AMLPolicy.descr_4_19"))}
                  </li>
                  <li>
                    {" "}
                    <span className="mr-3">4.9.3</span>
                    {String(L.translate("AMLPolicy.descr_4_20"))}
                  </li>
                  <li>
                    {" "}
                    <span className="mr-3">4.9.4</span>
                    {String(L.translate("AMLPolicy.descr_4_21"))}
                  </li>{" "}
                </ul>
                <li>
                  <span className="mr-3">4.10</span>
                  {String(L.translate("AMLPolicy.descr_4_22"))}
                </li>
                <ul>
                  {" "}
                  <li>
                    {" "}
                    <span className="mr-3">4.10.1</span>
                    {String(L.translate("AMLPolicy.descr_4_23"))}
                  </li>
                  <li>
                    <span className="mr-3">4.10.2</span>
                    {String(L.translate("AMLPolicy.descr_4_24"))}
                  </li>
                  <li>
                    <span className="mr-3">4.10.3</span>
                    {String(L.translate("AMLPolicy.descr_4_25"))}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">4.11</span>
                  {String(L.translate("AMLPolicy.descr_4_26"))}
                </li>
                <ul>
                  <li>
                    <span className="mr-3">4.11.1</span>
                    {String(L.translate("AMLPolicy.descr_4_27"))}
                  </li>{" "}
                </ul>
                <li>
                  <span className="mr-3">4.12</span>
                  {String(L.translate("AMLPolicy.descr_4_28"))}
                </li>
              </ul>
              <p className="font-manrope text-[16px] font-medium text-primary">
                &nbsp;
              </p>
              <h2 className="font-manrope text-[16px] sm:text-[22px] font-medium text-primary">
                <span className="mr-3">5.</span>
                {String(L.translate("AMLPolicy.par_content_1"))}
              </h2>
              <ol className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">5.1</span>
                  {String(L.translate("AMLPolicy.par_content_1_1"))}
                </li>
                <li>
                  <span className="mr-3">5.2</span>
                  {String(L.translate("AMLPolicy.par_content_1_2"))}
                </li>
                <li>
                  <span className="mr-3">5.3</span>
                  {String(L.translate("AMLPolicy.par_content_1_3"))}
                </li>
                <ul>
                  <li>
                    <span className="mr-3">5.3.1</span>
                    {String(L.translate("AMLPolicy.par_content_1_4"))}
                  </li>
                  <li>
                    <span className="mr-3">5.3.2</span>
                    {String(L.translate("AMLPolicy.par_content_1_5"))}
                  </li>
                  <li>
                    <span className="mr-3">5.3.3</span>
                    {String(L.translate("AMLPolicy.par_content_1_6"))}
                  </li>
                  <li>
                    <span className="mr-3">5.3.4</span>
                    {String(L.translate("AMLPolicy.par_content_1_7"))}
                  </li>
                  <li>
                    <span className="mr-3">5.3.5</span>
                    {String(L.translate("AMLPolicy.par_content_1_8"))}
                  </li>{" "}
                </ul>
                <li>
                  <span className="mr-3">5.4</span>
                  {String(L.translate("AMLPolicy.par_content_1_9"))}
                </li>
                <li>
                  <span className="mr-3">5.5</span>
                  {String(L.translate("AMLPolicy.par_content_1_10"))}
                </li>

                <li>
                  <span className="mr-3">5.6</span>
                  {String(L.translate("AMLPolicy.par_content_1_11"))}
                </li>
                <li>
                  <span className="mr-3">5.7</span>
                  {String(L.translate("AMLPolicy.par_content_1_12"))}
                </li>
                <li>
                  <span className="mr-3">5.8</span>
                  {String(L.translate("AMLPolicy.par_content_1_13"))}
                </li>

                <li>
                  <span className="mr-3">5.9</span>
                  {String(L.translate("AMLPolicy.par_content_1_14"))}
                </li>
                <ul>
                  <li>
                    <span className="mr-3">5.9.1</span>
                    {String(L.translate("AMLPolicy.par_content_1_15"))}
                  </li>
                  <li>
                    <span className="mr-3">5.9.2</span>
                    {String(L.translate("AMLPolicy.par_content_1_16"))}
                  </li>
                  <li>
                    <span className="mr-3">5.9.3</span>
                    {String(L.translate("AMLPolicy.par_content_1_17"))}
                  </li>
                </ul>
              </ol>
              <ol start={16}>
                <li>
                  <span className="mr-3">5.10</span>
                  {String(L.translate("AMLPolicy.par_content_1_18"))}
                </li>
              </ol>
              <ol start={17}>
                <li>
                  <span className="mr-3">5.11</span>
                  {String(L.translate("AMLPolicy.par_content_1_19"))}
                </li>
              </ol>
              <p className="font-manrope text-[16px] font-medium text-primary">
                &nbsp;
              </p>
              <h2 className="font-manrope text-[16px] sm:text-[22px] font-medium text-primary">
                <span className="mr-3">6.</span>
                {String(L.translate("AMLPolicy.par_content_2"))}
              </h2>
              <ol className=" mb-[22px]">
                <li>
                  <span className="mr-3">6.1</span>
                  {String(L.translate("AMLPolicy.par_content_2_1"))}
                </li>
                <li>
                  <span className="mr-3">6.2</span>
                  {String(L.translate("AMLPolicy.par_content_2_2"))}
                </li>
                <li>
                  <span className="mr-3">6.3</span>
                  {String(L.translate("AMLPolicy.par_content_2_3"))}
                </li>
                <li>
                  <span className="mr-3">6.4</span>
                  {String(L.translate("AMLPolicy.par_content_2_4"))}
                </li>
                <li>
                  <span className="mr-3">6.5</span>
                  {String(L.translate("AMLPolicy.par_content_2_5"))}
                </li>
                <ul>
                  <li>
                    <span className="mr-3">6.5.1</span>
                    {String(L.translate("AMLPolicy.par_content_2_6"))}
                  </li>
                  <li>
                    <span className="mr-3">6.5.2</span>
                    {String(L.translate("AMLPolicy.par_content_2_7"))}
                  </li>
                  <li>
                    <span className="mr-3">6.5.3</span>
                    {String(L.translate("AMLPolicy.par_content_2_8"))}
                  </li>
                  <li>
                    <span className="mr-3">6.5.4</span>
                    {String(L.translate("AMLPolicy.par_content_2_9"))}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">6.6</span>
                  {String(L.translate("AMLPolicy.par_content_2_10"))}
                </li>
                <li>
                  <span className="mr-3">6.7</span>
                  {String(L.translate("AMLPolicy.par_content_2_11"))}
                </li>
              </ol>
              <p className="font-manrope text-[16px] font-medium text-primary">
                &nbsp;
              </p>
              <h2 className="font-manrope text-[16px] sm:text-[22px] font-medium text-primary">
                <span className="mr-3">7.</span>
                {String(L.translate("AMLPolicy.par_content_3"))}
              </h2>
              <ol className="mb-[22px] flex flex-col gap-1">
                <li>
                  <span className="mr-3">7.1</span>
                  {String(L.translate("AMLPolicy.par_content_3_1"))}
                </li>
                <ul className=" text-sm flex flex-col gap-1">
                  <li>
                    <span className="mr-3">7.1.1</span>
                    {String(L.translate("AMLPolicy.par_content_3_2"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.2</span>
                    {String(L.translate("AMLPolicy.par_content_3_3"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.3</span>
                    {String(L.translate("AMLPolicy.par_content_3_4"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.4</span>
                    {String(L.translate("AMLPolicy.par_content_3_5"))}
                  </li>
                </ul>
                <li>
                  {" "}
                  <span className="mr-3">7.2</span>
                  {String(L.translate("AMLPolicy.par_content_3_6"))}
                </li>
                <ul className=" text-sm flex flex-col gap-1">
                  <li>
                    {" "}
                    <span className="mr-3">7.2.1</span>
                    {String(L.translate("AMLPolicy.par_content_3_7"))}
                  </li>{" "}
                  <li>
                    {" "}
                    <span className="mr-3">7.2.2</span>
                    {String(L.translate("AMLPolicy.par_content_3_8"))}
                  </li>
                </ul>

                <li>
                  <span className="mr-3">7.3</span>
                  {String(L.translate("AMLPolicy.par_content_3_9"))}
                </li>
                <ul className=" text-sm flex flex-col gap-1">
                  <li>
                    <span className="mr-3">7.3.1</span>
                    {String(L.translate("AMLPolicy.par_content_3_10"))}
                  </li>
                  <li>
                    <span className="mr-3">7.3.2</span>
                    {String(L.translate("AMLPolicy.par_content_3_11"))}
                  </li>
                  <li>
                    <span className="mr-3">7.3.3</span>
                    {String(L.translate("AMLPolicy.par_content_3_12"))}
                  </li>{" "}
                </ul>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default InternalAMLPolicyPage;
