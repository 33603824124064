import React, { FC, useState, useEffect, useContext } from "react";
import { fadeInBottom } from "src/lib/framer-motion/fade-in-bottom";
import { motion } from "framer-motion";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";

const PrivacyPolicyPage: FC = () => {
  const { language } = useContext(GlobalContext);
  console.log("current language is ", language);

  return (
    <motion.div
      variants={fadeInBottom()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center justify-center m-auto p-4 sm:p-11 static-content-type2  aml-content">
        <div className="flex pb-[180px] font-manrope text-primary">
          <div className="flex flex-col gap-4">
            <h3 className="text-[25px] sm:text-[36px] font-bold text-primary">
              {String(L.translate("PrivacyPolicy.title"))}
            </h3>
            <div
              className="text-[14px] sm:text-[16px]"
              style={{ textAlign: "right", marginBottom: "40px" }}
            >
              <p>{L.translate("PrivacyPolicy.title_desc.text_1")} </p>
              <p>{L.translate("PrivacyPolicy.title_desc.text_2")} </p>
              <p>{L.translate("PrivacyPolicy.title_desc.text_3")} </p>
              <p>{L.translate("PrivacyPolicy.title_desc.text_4")} </p>
              <p>
                <a
                  className="underline"
                  href="http://www.fastoo.com/"
                  target="_blank"
                >
                  www.fastoo.com
                </a>{" "}
                <a
                  className="underline"
                  href="http://mail.google.com/mail/?view=cm&fs=1&to=info@fastoo.com"
                  target="_blank"
                >
                  info@fastoo.com
                </a>{" "}
                <a
                  className="underline"
                  href="http://mail.google.com/mail/?view=cm&fs=1&to=support@fastoo.com"
                  target="_blank"
                >
                  support@fastoo.com
                </a>
              </p>
              {/* 					
						<a href="mailto:support@bitanica.com">support@bitanica.com </a> */}
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="font-manrope text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_1"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">1.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_1"))}
                </li>
                <li>
                  <span className="mr-3">1.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_1_2"))}
                </li>
                <li>
                  <span className="mr-3">1.3</span>{" "}
                  {String(L.translate("PrivacyPolicy.par_content_1_3"))}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_2"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">2.1</span>
                  {String(L.translate("PrivacyPolicy.par_con_2_0"))}
                </li>
                <li>
                  <span className="mr-3">2.2</span>
                  {String(L.translate("PrivacyPolicy.par_con_2_1"))}
                </li>
                <li>
                  <span className="mr-3">2.3</span>
                  {String(L.translate("PrivacyPolicy.par_con_2_2"))}
                </li>
                <li>
                  <span className="mr-3">2.4</span>
                  {String(L.translate("PrivacyPolicy.par_con_2_3"))}
                </li>
                <li>
                  <span className="mr-3">2.5</span>
                  {String(L.translate("PrivacyPolicy.par_con_2_4"))}
                </li>
                <li>
                  <span className="mr-3">2.6</span>
                  {String(L.translate("PrivacyPolicy.par_con_2_5"))}
                </li>
                <li>
                  <span className="mr-3">2.7</span>
                  {String(L.translate("PrivacyPolicy.par_con_2_6"))}
                </li>
                <li>
                  <span className="mr-3">2.8</span>
                  {String(L.translate("PrivacyPolicy.par_con_2_7"))}
                </li>
              </ul>

              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_3"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">3.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_3"))}
                </li>
                <li>
                  <span className="mr-3">3.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_3_1"))}
                </li>
                <li>
                  <span className="mr-3">3.3</span>
                  {String(L.translate("PrivacyPolicy.par_content_3_2"))}
                </li>
                <li>
                  <span className="mr-3">3.4</span>
                  {String(L.translate("PrivacyPolicy.par_content_3_3"))}
                </li>
                <li>
                  <span className="mr-3">3.5</span>
                  {String(L.translate("PrivacyPolicy.par_content_3_4"))}
                </li>
                <li>
                  <span className="mr-3">3.6</span>
                  {String(L.translate("PrivacyPolicy.par_content_3_5"))}
                </li>
                <ul>
                  <li>
                    {String(L.translate("PrivacyPolicy.par_content_3_6"))}
                  </li>
                  <li>
                    {String(L.translate("PrivacyPolicy.par_content_3_7"))}
                  </li>
                  <li>
                    {String(L.translate("PrivacyPolicy.par_content_3_8"))}
                  </li>
                </ul>
                <li>
                  {" "}
                  <span className="mr-3">3.7</span>
                  {String(L.translate("PrivacyPolicy.par_content_3_9"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">3.8</span>
                  {String(L.translate("PrivacyPolicy.par_content_3_10"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">3.9</span>
                  {String(L.translate("PrivacyPolicy.par_content_3_11"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">3.10</span>
                  {String(L.translate("PrivacyPolicy.par_content_3_12"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">3.11</span>
                  {String(L.translate("PrivacyPolicy.par_content_3_13"))}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_4"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  {" "}
                  <span className="mr-3">4.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_4"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">4.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_4_1"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">4.3</span>
                  {String(L.translate("PrivacyPolicy.par_content_4_2"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">4.4</span>
                  {String(L.translate("PrivacyPolicy.par_content_4_3"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">4.5</span>
                  {String(L.translate("PrivacyPolicy.par_content_4_4"))}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_5"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">5.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_5"))}
                </li>
                <li>
                  <span className="mr-3">5.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_5_1"))}
                </li>
                <li>
                  <span className="mr-3">5.3</span>
                  {String(L.translate("PrivacyPolicy.par_content_5_2"))}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_6"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">6.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_6"))}
                </li>
                <li>
                  <span className="mr-3">6.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_6_1"))}
                </li>
                <li>
                  <span className="mr-3">6.3</span>
                  {String(L.translate("PrivacyPolicy.par_content_6_2"))}
                </li>
                <li>
                  <span className="mr-3">6.4</span>
                  {String(L.translate("PrivacyPolicy.par_content_6_3"))}
                </li>
                <li>
                  <span className="mr-3">6.5</span>
                  {String(L.translate("PrivacyPolicy.par_content_6_4"))}
                </li>
                <li>
                  <span className="mr-3">6.6</span>
                  {String(L.translate("PrivacyPolicy.par_content_6_5"))}
                </li>
                <li>
                  <span className="mr-3">6.7</span>
                  {String(L.translate("PrivacyPolicy.par_content_6_6"))}
                </li>
                <li>
                  <span className="mr-3">6.8</span>
                  {String(L.translate("PrivacyPolicy.par_content_6_7"))}
                </li>
                <li>
                  <span className="mr-3">6.9</span>
                  {String(L.translate("PrivacyPolicy.par_content_6_8"))}
                </li>
                <li>
                  <span className="mr-3">6.10</span>
                  {String(L.translate("PrivacyPolicy.par_content_6_9"))}
                </li>
                <li>
                  <span className="mr-3">6.11</span>
                  {String(L.translate("PrivacyPolicy.par_content_6_10"))}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_7"))}
              </h2>

              <ul className="text-[14px] sm:text-[16px]">
                {" "}
                <li>
                  <span className="mr-3">7.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_7"))}
                </li>
                <ul>
                  {" "}
                  <li>
                    <span className="mr-3">7.1.1</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_1"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.2</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_2"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.3</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_3"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.4</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_4"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.5</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_5"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.6</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_6"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.7</span>{" "}
                    {String(L.translate("PrivacyPolicy.par_content_7_7"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.8</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_8"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.9</span>{" "}
                    {String(L.translate("PrivacyPolicy.par_content_7_9"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.10</span>{" "}
                    {String(L.translate("PrivacyPolicy.par_content_7_10"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.11</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_11"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.12</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_12"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.13</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_13"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.14</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_14"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.15</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_15"))}
                  </li>
                  <li>
                    <span className="mr-3">7.1.16</span>
                    {String(L.translate("PrivacyPolicy.par_content_7_16"))}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">7.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_7_17"))}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_8"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">8.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_8"))}
                </li>
                <li>
                  <span className="mr-3">8.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_8_1"))}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_9"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  {" "}
                  <span className="mr-3">9.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_9"))}
                </li>
                <ul>
                  {" "}
                  <li>
                    <span className="mr-3">9.1.1</span>
                    {String(L.translate("PrivacyPolicy.par_content_9_1"))}
                  </li>
                  <li>
                    <span className="mr-3">9.1.2</span>
                    {String(L.translate("PrivacyPolicy.par_content_9_2"))}
                  </li>
                  <li>
                    <span className="mr-3">9.1.3</span>
                    {String(L.translate("PrivacyPolicy.par_content_9_3"))}
                  </li>
                  <ul>
                    {" "}
                    <li>
                      {String(L.translate("PrivacyPolicy.par_content_9_4"))}
                    </li>
                    <li>
                      {String(L.translate("PrivacyPolicy.par_content_9_5"))}
                    </li>
                    <li>
                      {String(L.translate("PrivacyPolicy.par_content_9_6"))}
                    </li>
                  </ul>
                  <li>
                    <span className="mr-3">9.1.4</span>
                    {String(L.translate("PrivacyPolicy.par_content_9_7"))}
                  </li>
                  <li>
                    <span className="mr-3">9.1.5</span>
                    {String(L.translate("PrivacyPolicy.par_content_9_8"))}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">9.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_9_9"))}
                </li>
                <ul>
                  <li>
                    <span className="mr-3">9.2.1</span>
                    {String(L.translate("PrivacyPolicy.par_content_9_10"))}
                  </li>
                  <li>
                    <span className="mr-3">9.2.2</span>{" "}
                    {String(L.translate("PrivacyPolicy.par_content_9_11"))}
                  </li>
                  <li>
                    <span className="mr-3">9.2.3</span>
                    {String(L.translate("PrivacyPolicy.par_content_9_12"))}
                  </li>
                  <li>
                    <span className="mr-3">9.2.4</span>
                    {String(L.translate("PrivacyPolicy.par_content_9_13"))}
                  </li>
                  <li>
                    <span className="mr-3">9.2.5</span>
                    {String(L.translate("PrivacyPolicy.par_content_9_14"))}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">9.3</span>
                  {String(L.translate("PrivacyPolicy.par_content_9_15"))}
                </li>
                <li>
                  <span className="mr-3">9.4</span>
                  {String(L.translate("PrivacyPolicy.par_content_9_16"))}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_10"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">10.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_10"))}
                </li>
                <li>
                  <span className="mr-3">10.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_10_1"))}
                </li>
                <li>
                  <span className="mr-3">10.3</span>
                  {String(L.translate("PrivacyPolicy.par_content_10_2"))}
                  {/* &nbsp;
                <a className="underline" href="mailto:support@fastoo.com">
                  support@fastoo.com.
                </a> */}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_11"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  <span className="mr-3">11.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_11"))}
                </li>
                <li>
                  <span className="mr-3">11.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_11_1"))}
                </li>
                <li>
                  <span className="mr-3">11.3</span>
                  {String(L.translate("PrivacyPolicy.par_content_11_2"))}
                </li>
              </ul>
              <h2 className="font-manrope mt-2 text-[16px] sm:text-[22px] font-medium text-primary">
                {String(L.translate("PrivacyPolicy.paragraph_12"))}
              </h2>
              <ul className="text-[14px] sm:text-[16px]">
                <li>
                  {" "}
                  <span className="mr-3">12.1</span>
                  {String(L.translate("PrivacyPolicy.par_content_12"))}
                </li>
                <li>
                  {" "}
                  <span className="mr-3">12.2</span>
                  {String(L.translate("PrivacyPolicy.par_content_12_1"))}
                </li>
                <ul>
                  <li>
                    <span className="mr-3">12.2.1</span>
                    {String(L.translate("PrivacyPolicy.par_content_12_2"))}
                  </li>
                  <li>
                    <span className="mr-3">12.2.2</span>
                    {String(L.translate("PrivacyPolicy.par_content_12_3"))}
                  </li>
                  <li>
                    <span className="mr-3">12.2.3</span>
                    {String(L.translate("PrivacyPolicy.par_content_12_4"))}
                  </li>
                  <li>
                    <span className="mr-3">12.2.4</span>
                    {String(L.translate("PrivacyPolicy.par_content_12_5"))}
                  </li>
                  <li>
                    <span className="mr-3">12.2.5</span>
                    {String(L.translate("PrivacyPolicy.par_content_12_6"))}
                  </li>
                  <li>
                    <span className="mr-3">12.2.6</span>
                    {String(L.translate("PrivacyPolicy.par_content_12_7"))}
                  </li>
                  <li>
                    <span className="mr-3">12.2.7</span>
                    {String(L.translate("PrivacyPolicy.par_content_12_8"))}
                  </li>
                  <li>
                    <span className="mr-3">12.2.8</span>
                    {String(L.translate("PrivacyPolicy.par_content_12_9"))}
                  </li>
                </ul>
                <li>
                  <span className="mr-3">12.3</span>
                  {String(L.translate("PrivacyPolicy.par_content_12_10"))}
                </li>
                <li>
                  <span className="mr-3">12.4</span>
                  {String(L.translate("PrivacyPolicy.par_content_12_11"))}
                </li>
                <li>
                  <span className="mr-3">12.5</span>{" "}
                  {String(L.translate("PrivacyPolicy.par_content_12_12"))}
                </li>
                <li>
                  <span className="mr-3">12.6</span>
                  {String(L.translate("PrivacyPolicy.par_content_12_13"))}
                </li>
                <li>
                  <span className="mr-3">12.7</span>
                  {String(L.translate("PrivacyPolicy.par_content_12_14"))}
                </li>
                <li>
                  <span className="mr-3">12.8</span>
                  {String(L.translate("PrivacyPolicy.par_content_12_15"))}
                </li>
                <li>
                  <span className="mr-3">12.9</span>
                  {String(L.translate("PrivacyPolicy.par_content_12_16"))}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PrivacyPolicyPage;
