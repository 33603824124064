import React from "react";

export type MODAL_VIEWS =
  | "TRANSFER"
  | "ACTION_SUCCESS"
  | "EMAIL_VERIFICATION"
  | "RESET_2FA"
  | "TRANSFER_CONFIRM"
  | "BANK_DETAILS"
  | "WITHDRAWAL_BANK_CONFIRMATION"
  | "WITHDRAWAL_SECURITY_VERIFICATION"
  | "WITHDRAWAL_PAYPAL_CONFIRMATION"
  | "WITHDRAWAL_CARD_CONFIRMATION"
  | "WITHDRAWAL_VERIFY_CVV"
  | "DEPOSIT_CARD_CONFIRMATION"
  | "DEPOSIT_PAYPAL_CONFIRMATION"
  | "DEPOSIT_BANK_CONFIRMATION"
  | "DEPOSIT_VERIFY_CVV"
  | "ADD_PAYMENT_CARD"
  | "ADD_IBAN_ACCOUNT"
  | "DELETE_CARD_CONFIRM"
  | "DELETE_IBAN_CONFIRM"
  | "VERIFY_CARD_CONFIRM"
  | "ADD_PHONE_NUMBER"
  | "DELETE_TRANSACTION_CONFIRM"
  | "FAST_TRANSFER_TERM_CONDITION"
  | "ADD_KWIKPAY_CLIENT"
  | "FAST_TRANSFER_TERM_CONDITION_REJECT"
  | "WITHDRAWAL_NOT_AVAILABLE"
  | "KYC_CONTINUE"
  | "CHOOSE_NEW_PAYMENT_METHOD"
  | "WITHDRAWAL_SUBMITTED";

interface State {
  view: MODAL_VIEWS | undefined;
  data?: any;
  isOpen: boolean;
}
type Action =
  | { type: "open"; view: MODAL_VIEWS; payload?: any }
  | { type: "close" };

const initialState: State = {
  view: undefined,
  isOpen: false,
  data: null,
};

function modalReducer(state: State, action: Action): State {
  switch (action.type) {
    case "open":
      return {
        ...state,
        view: action.view,
        data: action.payload,
        isOpen: true,
      };
    case "close":
      return {
        ...state,
        view: undefined,
        data: null,
        isOpen: false,
      };
    default:
      throw new Error("Unknown Modal Action!");
  }
}

const ModalStateContext = React.createContext<State>(initialState);
ModalStateContext.displayName = "ModalStateContext";
const ModalActionContext = React.createContext<
  React.Dispatch<Action> | undefined
>(undefined);
ModalActionContext.displayName = "ModalActionContext";

export default function ModalProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [state, dispatch] = React.useReducer(modalReducer, initialState);
  return (
    <ModalStateContext.Provider value={state}>
      <ModalActionContext.Provider value={dispatch}>
        {children}
      </ModalActionContext.Provider>
    </ModalStateContext.Provider>
  );
}

export function useModalState() {
  const context = React.useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error(`useModalState must be used within a ModalProvider`);
  }
  return context;
}

export function useModalAction() {
  const dispatch = React.useContext(ModalActionContext);
  if (dispatch === undefined) {
    throw new Error(`useModalAction must be used within a ModalProvider`);
  }
  return {
    openModal(view: MODAL_VIEWS, payload?: unknown) {
      dispatch({ type: "open", view, payload });
    },
    closeModal() {
      dispatch({ type: "close" });
    },
  };
}
