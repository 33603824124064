import { FC, useEffect, useRef, useState } from "react";
import { QuestionIcon } from "../icons/question-icon";
import { CloseIcon } from "../icons/close-icon";
import {
  APISomethingWrongMsg,
  BlockMsg,
  NetworkDisconnectMsg,
} from "src/lib/constants";
import L from "i18n-react";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";

interface AlertProps {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
  text: string;
  title: string;
  duration?: number;
}

const Alert: FC<AlertProps> = ({
  isOpened,
  setIsOpened,
  text,
  title,
  duration = 5000,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isOpened) {
      timeoutId = setTimeout(() => {
        setIsOpened(false);
      }, duration);
    }
    return () => clearTimeout(timeoutId);
  }, [isOpened]);

  /*
  const makeTitle = () => {
    if (text === NetworkDisconnectMsg || text === APISomethingWrongMsg) {
      return "Error";
    } else {
      if (text.substring(0, 5) === "error") {
        return "Error";
      } else {
        return "Information";
      }
    }
  };
  */

  const makeText = () => {
    return text === "Unauthenticated"
      ? String(L.translate("Errors.unauthenticated"))
      : text;
    /*
    if (text.substring(0, 5) === "error") {
      return text.slice(6);
    } else {
      return text;
    }
    */
  };

  return (
    <div
      ref={containerRef}
      className={`fixed top-2 right-2 sm:top-4 sm:right-4 rounded-[22px] flex flex-row items-center justify-center shadow-card ${
        text === String(L.translate("GlobalErrors.Block"))
          ? "bg-error"
          : "bg-active"
      } z-[9999] w-[calc(100vw-40px)] max-w-[350px] scale-100 text-dark dark:text-light font-manrope transition-all duration-300 ${
        isOpened ? "translate-x-0" : "translate-x-[400px]"
      }`}
    >
      <div className="flex items-center justify-center w-[87px] rounded-l-[22px]">
        <QuestionIcon
          className={`w-[34px] h-[34px] ${
            text === String(L.translate("GlobalErrors.Block"))
              ? "text-white"
              : "text-primary"
          }`}
        />
      </div>
      <div className="relative flex flex-col items-center justify-center gap-2 w-full px-4 py-4 bg-white h-full rounded-r-[22px]">
        <p
          className={`${
            text === String(L.translate("Errors.the_totp_field_is_required"))
              ? "hidden"
              : "block"
          } font-manrope text-[20px] font-medium text-primary w-full text-left pr-6`}
        >
          {text === "Unauthenticated"
            ? String(L.translate("Alert.Error"))
            : title}
        </p>
        <p className="font-manrope text-[14px] font-medium text-[#777E91] w-full text-left pr-6">
          {/* break-all*/ makeText()}
        </p>
        <button
          className="absolute right-0 top-[12px] w-[32px] h-[32px]"
          onClick={() => setIsOpened(false)}
        >
          <CloseIcon className="w-[14px] h-[14px]" />
        </button>
      </div>
    </div>
  );
};

export default Alert;
