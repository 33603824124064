import { FC, useContext, useEffect, useState, useRef } from "react";
import L from "i18n-react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import { User } from "../../api/types";
import Button from "../../components/ui/button";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { TwoFactorAuthIcon } from "src/components/icons/2fa-icon";
import TwoAuthSettingsCard from "src/components/2fa/card";
import TwoAuthSettingsConfiguration, {
  ConfigurationItem,
} from "src/components/2fa/configuration";
import { Spin } from "antd";
import useAuth from "src/hooks/use-auth";
import { APISomethingWrongMsg, NetworkDisconnectMsg } from "src/lib/constants";

const TwoFactorAuthSettingsPage: FC = () => {
  const [showConfiguration, setShowConfiguration] = useState(false);

  const codeRef = useRef<ConfigurationItem>(null);

  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [userData, setUserData] = useState<User | null>(null);

  const [is2FAEnabled, set2FAEnabled] = useState(0);
  const [secretKey, setSecretKey] = useState("");
  const [qrImage, setQRImage] = useState("");

  const onEnable2FA = () => {
    if (!showConfiguration) {
      if (is2FAEnabled) {
        setShowConfiguration(!showConfiguration);
      } else {
        generateSecretKey();
      }
    } else {
      setShowConfiguration(!showConfiguration);
    }
  };

  useEffect(() => {
    isMounted && getSettings();
  }, [isMounted]);

  const { mutate: getSettings, isLoading } = useMutation(client.users.me, {
    onSuccess: (data) => {
      console.log(data);
      setUserData(data.user);
      set2FAEnabled(data.user.google2fa_enabled);
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));

      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  const onCallbackProcess = () => {
    getSettings();
  };

  const { mutate: generateSecretKey, isLoading: isLoadingSecretKey } =
    useMutation(client.google2FA.generateKey, {
      onSuccess: (data) => {
        console.log(data);
        setSecretKey(data.secret);
        setQRImage(data.QR_Image);
        setShowConfiguration(!showConfiguration);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (
            error.response.data.errors &&
            error.response.data.errors[0] === "can_not_disable_last_2fa"
          ) {
            setAlertText(
              "You can't turn OFF all options. At least one should be turned ON"
            );

            setIsAlertOpened(true);
            return;
          }
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col py-2 px-4 sm:p-4 mb-4 sm:pr-11">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          {String(
            L.translate("Account.TwoFactorAuth.two_factor_auth_page_title")
          )}
        </p>

        <div className="w-full flex flex-col relative">
          {isLoading && (
            <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
          )}
          <div className="flex flex-col bg-white rounded-[12px] mt-2 sm:mt-4 p-4 gap-4">
            <div className="w-full flex flex-row gap-2 items-center">
              <TwoFactorAuthIcon className="w-[24px] h-[24px] text-primary" />
              <p className="font-manrope text-[18px] font-medium text-primary leading-[24px]">
                {String(
                  L.translate(
                    "Account.TwoFactorAuth.two_factor_auth_page_title"
                  )
                )}
              </p>
            </div>
            <div className="w-full">
              <hr className="h-[1px] border-0 bg-[#E6E8EC] opacity-50 dark:opacity-50" />
            </div>

            <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
              <p className="font-manrope text-secondary text-[16px] font-medium leading-[24px]">
                {String(L.translate("Account.TwoFactorAuth.for_security"))}
              </p>
              <Button
                variant="solid"
                onClick={onEnable2FA}
                className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] w-full bg-active hover:bg-main sm:max-w-[215px]"
                isLoading={isLoadingSecretKey}
              >
                {`${
                  is2FAEnabled
                    ? String(L.translate("Account.TwoFactorAuth.disabled"))
                    : String(L.translate("Account.TwoFactorAuth.enable"))
                } 2FA`}
              </Button>
            </div>
            {showConfiguration && (
              <TwoAuthSettingsConfiguration
                isEnabled={is2FAEnabled}
                secretKey={secretKey}
                qrCode={qrImage}
                ref={codeRef}
                set2FAEnabled={set2FAEnabled}
                setShowConfiguration={setShowConfiguration}
              />
            )}
          </div>

          <TwoAuthSettingsCard
            type="phone"
            sender={userData?.data.phone}
            initialValue={userData?.phone_2fa_enabled ?? 0}
            onCallback={onCallbackProcess}
          />
          <TwoAuthSettingsCard
            type="email"
            sender={userData?.email}
            initialValue={userData?.email_2fa_enabled ?? 0}
            onCallback={onCallbackProcess}
          />
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuthSettingsPage;
