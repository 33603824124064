export const AppStoreIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#000" rx="5"></rect>
      <g clipPath="url(#clip0_8607_1190)">
        <path
          fill="#fff"
          d="M23.16 20.586a10.88 10.88 0 01-1.075 1.933c-.566.807-1.029 1.365-1.385 1.674-.553.509-1.146.77-1.78.784-.456 0-1.006-.13-1.645-.392-.642-.262-1.231-.392-1.77-.392-.566 0-1.172.13-1.82.392-.65.263-1.174.4-1.574.413-.608.026-1.215-.242-1.82-.805-.387-.337-.87-.914-1.449-1.733-.621-.874-1.132-1.887-1.532-3.043-.429-1.248-.643-2.457-.643-3.627 0-1.34.29-2.496.87-3.465a5.102 5.102 0 011.821-1.842 4.9 4.9 0 012.463-.695c.483 0 1.117.15 1.904.443.786.295 1.29.444 1.511.444.165 0 .726-.175 1.675-.523.898-.323 1.656-.457 2.277-.404 1.683.135 2.947.799 3.788 1.994-1.505.912-2.25 2.189-2.235 3.827.014 1.276.477 2.338 1.387 3.181.412.392.873.694 1.385.909-.111.322-.228.63-.353.927zM19.3 5.4c0 1-.365 1.934-1.093 2.799-.879 1.027-1.942 1.621-3.095 1.527a3.112 3.112 0 01-.023-.379c0-.96.418-1.987 1.16-2.828.37-.425.842-.779 1.414-1.061.57-.278 1.11-.432 1.617-.458.015.134.02.267.02.4z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_8607_1190">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(5 5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
