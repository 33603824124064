const routes = {
  home: "/",
  login: "/login",
  register: "/register",
  emailConfirmation: "/email-confirm",
  forgot: "/forgot-password",
  createNewPassword: "/create-new-password",
  login2FA: "/login-2fa",
  balance: "/balance",
  fastTransfers: "/fast-transfers",
  fastTransfersTermAndCondition: "/transfer-term-condition",
  fastTransferKwikPay: "/start-kwikpay",
  fastTransferKwikPayMethods: "/kwikpay-methods/:id",
  transactionHistory: "/transactions",
  settings: "/general-settings",
  twoFactorAuth: "/2fa-settings",
  notifications: "/notifications",
  paymentMethod: "/payment-method",
  deposit: "/balance/deposit",
  withdraw: "/balance/withdraw",
  withdrawResult: "/balance/withdraw/result",
  depositResult: "/balance/deposit/result",
  depositSuccess: "/deposit-success",
  depositFail: "/deposit-fail",
  depositCardSuccess: "/deposit-card-success",
  verifyCardResult: "/withdraw-fiat",
  proceed: "/balance/proceed",
  offerConfirm: "/balance/offer-confirm",
  logout: "/logout",
  privacyPolicy: "/privacy-policy",
  userAgreement: "/user-agreement",
  internalAMLPolicy: "/internal-aml-policy",
  privacyCookies: "/cookies-policy",
  kyc: "/kyc",
  kycBuisiness: "/kyc-buisiness",
  prices: "/prices",
  faq: "/faq",
  aboutUs: "/about-us",
  contactUs: "/Contact-us",
};
export default routes;
