import { FC, useContext } from "react";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";
import FaqItem from "../../components/faq/faq-item";

const FAQ: FC = () => {
  const { language } = useContext(GlobalContext);
  return (
    <div className="flex-1 pt-[130px] pb-[200px] font-manrope">
      <div className="w-[1022px] max-w-[100%] mx-auto px-[16px]">
        <h2 className="mb-[10px] text-[34px] font-medium leading-[1.4]">
          {L.translate("FAQ.title")}
        </h2>
        <div className="mt-[45px] max-w-[100%]">
          {Array.from(Array(18).keys())
            .map((i) => i + 1)
            .map((item) => {
              let details;
              if (item === 7) {
                details = 6;
              }
              if (item === 15 || item === 16) {
                details = 3;
              }
              if (item === 17 && language === "GE") {
                details = 4;
              }
              return <FaqItem key={item} number={item} details={details} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
