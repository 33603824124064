import type {
  User,
  LoginUserInput,
  RegisterUserInput,
  AuthResponse,
  RegisterResponse,
  ChangePasswordInput,
  AuthChangePasswordInput,
  UserResponse,
  StatusResponse,
  EmailConfirmationInput,
  CountryResponse,
  StartKYCInput,
  LoginUserCheckInput,
  IUserKycInput,
  IBuisinessKycInput,
  KYCResponse,
  WalletResponse,
  BankCard,
  BankCardInput,
  BankCardEditInput,
  DailyAmountResponse,
  DepositFeeResponse,
  WithdrawalFeeResponse,
  PaymentPlatformRequisitesResponse,
  TransactionHistoryInput,
  KwickpayHistoryInput,
  PaymentHistoryResponse,
  TransferHistoryResponse,
  PaypalDepositInput,
  PaypalDepositResponse,
  PaypalWithdrawalInput,
  PaypalWithdrawalResponse,
  BankDepositInput,
  BankDepositResponse,
  BankWithdrawalInput,
  BankWithdrawalResponse,
  NotificationSettings,
  Google2FASecretKeyResponse,
  SetPhoneInput,
  OnlineCardDepositInput,
  CardProceedResponse,
  BankCardStatusResponse,
  VerifyBankCardInput,
  SavedCardDepositInput,
  SavedCardWithdrawalInput,
  TransferNumberInput,
  TransferInBalanceInput,
  TransferKwikPayInBalanceResponse,
  TransferKwikPayResponse,
  FastTransferBankAccount,
  FastTransferBankAccountInput,
  FastTransferKwikPayInput,
  FastTransferKwikPay,
  FiatLimitation,
  IbanAccount,
  CreateIbanInput,
  UserClientResponse,
  UserClientInput,
  CountryNameInput,
  SetReset2FAInput,
  CountryItemResponse,
  AddKwikClientResponse,
  IGetOurPricesInput,
  TOurPrices,
  SetPhoneLoginInput,
  SetEmailLoginInput,
  SetEmailCheckLoginInput,
  SetSmsCheckLoginInput,
} from "./types";
import { API_ENDPOINTS } from "./endpoints";
import { HttpClient } from "./http-client";

class Client {
  users = {
    me: () => HttpClient.get<UserResponse>(API_ENDPOINTS.USERS_ME),
    clientMe: () =>
      HttpClient.get<UserClientResponse>(API_ENDPOINTS.USERS_CLIENT_ME),
    addKwikpayClient: (input: UserClientInput) =>
      HttpClient.post<AddKwikClientResponse>(
        API_ENDPOINTS.ADD_NEW_USER_KWIKPAY_CLIENT,
        input
      ),
    login: (input: LoginUserInput) =>
      HttpClient.post<any>(API_ENDPOINTS.USERS_LOGIN, input),
    register: (input: RegisterUserInput) =>
      HttpClient.post<RegisterResponse>(API_ENDPOINTS.USERS_REGISTER, input),
    forgotPassword: (input: { email: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.FORGOT_PASSWORD, input),
    resetToken: (input: { token: string; email: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.RESET_TOKEN, input),
    resendEmail: (input: { email: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.RESEND_EMAIL, input),
    emailConfirmation: (input: EmailConfirmationInput) =>
      HttpClient.put<StatusResponse>(API_ENDPOINTS.EMAIL_CONFIRMATION, input),
    changePassword: (input: ChangePasswordInput) =>
      HttpClient.put<User>(API_ENDPOINTS.CHANGE_PASSWORD, input),
    authChangePassword: (input: AuthChangePasswordInput) =>
      HttpClient.put<User>(API_ENDPOINTS.AUTH_CHANGE_PASSWORD, input),
    logout: () =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.USERS_LOGOUT, {}),
    confirmTermCondition: () =>
      HttpClient.post<User>(API_ENDPOINTS.CONFIRM_TERM_CONDITION, {}),
    active2FA: (input: LoginUserCheckInput) =>
      HttpClient.post<AuthResponse>(API_ENDPOINTS.USERS_CHECK_2FA, input),
    sendEmailCodeLogin: (input: SetEmailLoginInput) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.SEND_EMAIL_CODE_LOGIN,
        input
      ),
    sendSmsCodeLogin: (input: SetPhoneLoginInput) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.SEND_SMS_CODE_LOGIN, input),
    checkEmailCode: (input: SetEmailCheckLoginInput) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.SEND_EMAIL_CHECK_CODE_LOGIN,
        input
      ),
    checkSmsCode: (input: SetSmsCheckLoginInput) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.SEND_SMS_CHECK_CODE_LOGIN,
        input
      ),
    reset2FA: (input: SetReset2FAInput) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.RESET_2FA, input),
  };

  settings = {
    setPhone: (input: SetPhoneInput) =>
      HttpClient.post<User>(API_ENDPOINTS.SET_PHONE, input),
    sendEmailCode: (input: { email: string; event: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.SEND_EMAIL_CODE, input),
    sendPhoneCode: (input: { phone: string; event: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.SEND_PHONE_CODE, input),
  };

  fastTransfersKwikPay = {
    getTransferInfo: (input: TransferNumberInput) =>
      HttpClient.post<TransferKwikPayResponse>(
        API_ENDPOINTS.FAST_TRANSFER_KWIKPAY_SENDERS,
        input
      ),
    getTransferInBalance: (input: TransferInBalanceInput) =>
      HttpClient.post<TransferKwikPayInBalanceResponse>(
        API_ENDPOINTS.GET_TRANSFER_KWIKPAY_IN_BALANCE,
        input
      ),
    getCountryByName: (input: CountryNameInput) =>
      HttpClient.post<CountryItemResponse>(
        API_ENDPOINTS.GET_COUNTRY_BY_NAME,
        input
      ),
    getTransferInfoById: (input: any) =>
      HttpClient.post<FastTransferKwikPay>(
        API_ENDPOINTS.GET_KWIKPAY_DATA_BY_ID,
        input
      ),
    all: () =>
      HttpClient.get<FastTransferBankAccount[]>(
        API_ENDPOINTS.GET_FAST_BANK_ACCOUNTS
      ),
    add: (input: FastTransferBankAccountInput) =>
      HttpClient.post<FastTransferBankAccount>(
        API_ENDPOINTS.ADD_NEW_FAST_BANK_ACCOUNTS,
        input
      ),
    saveTransfer: (input: FastTransferKwikPayInput) =>
      HttpClient.post<FastTransferKwikPay>(
        API_ENDPOINTS.FAST_TRANSFER_KWIKPAY_CHECK,
        input
      ),
  };

  notification = {
    get: () =>
      HttpClient.get<NotificationSettings>(API_ENDPOINTS.NOTIFICATIONS),
    update: (input: NotificationSettings) =>
      HttpClient.put<NotificationSettings>(API_ENDPOINTS.NOTIFICATIONS, input),
  };

  google2FA = {
    generateKey: () =>
      HttpClient.get<Google2FASecretKeyResponse>(
        API_ENDPOINTS.GOOGLE_2FA_GENERATE_KEY
      ),
    enable: (input: { google2fa_enabled: boolean; totp: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.GOOGLE_2FA_ENABLE, input),
    disable: (input: { totp: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.GOOGLE_2FA_DISABLE, input),
  };

  twoAuthFactor = {
    check: () =>
      HttpClient.get<StatusResponse>(API_ENDPOINTS.CHECK_2FA_ENABLED),
    enableEmail: () =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.ENABLE_EMAIL_2FA, {}),
    disableEmail: () =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.DISABLE_EMAIL_2FA, {}),
    enablePhone: () =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.ENABLE_PHONE_2FA, {}),
    disablePhone: () =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.DISABLE_PHONE_2FA, {}),
  };

  kyc = {
    getCountries: () =>
      HttpClient.get<CountryResponse[]>(API_ENDPOINTS.GET_COUNTRIES),
    start: (input: IUserKycInput) =>
      HttpClient.post<KYCResponse>(API_ENDPOINTS.START_KYC, input),
    buisinessKycStart: (input: IBuisinessKycInput) =>
      HttpClient.post<KYCResponse>(API_ENDPOINTS.KYC_BUISINESS_START, input),
  };

  balance = {
    getWallets: () => HttpClient.get<WalletResponse>(API_ENDPOINTS.WALLETS),
  };

  bankCards = {
    all: () => HttpClient.get<BankCard[]>(API_ENDPOINTS.GET_BANK_CARDS),
    add: (input: BankCardInput) =>
      HttpClient.post<BankCard>(API_ENDPOINTS.ADD_NEW_BANK_CARD, input),
    update: (input: BankCardEditInput) =>
      HttpClient.put<BankCard>(API_ENDPOINTS.UPDATE_BANK_CARD, input),
    delete: (id: number) =>
      HttpClient.delete<StatusResponse>(
        `${API_ENDPOINTS.GET_BANK_CARDS}/${id}`
      ),
  };

  iban = {
    all: () => HttpClient.get<IbanAccount[]>(API_ENDPOINTS.GET_IBAN_ACCOUNTS),
    add: (input: CreateIbanInput) =>
      HttpClient.post<IbanAccount>(API_ENDPOINTS.ADD_IBAN_ACCOUNT, input),
    delete: (id: number) =>
      HttpClient.delete<StatusResponse>(
        `${API_ENDPOINTS.GET_IBAN_ACCOUNTS}/${id}`
      ),
  };

  transactions = {
    getPaymentHistory: (input: TransactionHistoryInput) =>
      HttpClient.get<PaymentHistoryResponse>(
        API_ENDPOINTS.PAYMENT_HISTORY,
        input
      ),
    getTransferHistory: (input: TransactionHistoryInput) =>
      HttpClient.get<TransferHistoryResponse>(
        API_ENDPOINTS.TRANSFER_HISTORY,
        input
      ),
    delete: (id: number) =>
      HttpClient.delete<StatusResponse>(
        `${API_ENDPOINTS.DELETE_BANK_TRANSFER}/${id}`
      ),
    getTransfers: (input: KwickpayHistoryInput) =>
      HttpClient.get<any>(API_ENDPOINTS.KWICKPAY_TRANSACTIONS, input),
  };

  payment = {
    calculateDailyDepositAmount: (input: {
      asset_code: string;
      channel_name: string;
      card_number?: string;
      swift?: string;
    }) =>
      HttpClient.post<DailyAmountResponse>(
        API_ENDPOINTS.CALCULATE_DAILY_DEPOSIT_AMOUNT,
        input
      ),
    calculateDailyWithdrawalAmountOld: (input: { asset_code: string }) =>
      HttpClient.post<DailyAmountResponse>(
        API_ENDPOINTS.CALCULATE_DAILY_WITHDRAWAL_AMOUNT_OLD,
        input
      ),
    calculateDailyWithdrawalAmount: (input: {
      asset_code: string;
      channel_name: string;
      card_number?: string;
      swift?: string;
    }) =>
      HttpClient.post<DailyAmountResponse>(
        API_ENDPOINTS.CALCULATE_DAILY_WITHDRAWAL_AMOUNT,
        input
      ),
    getPaymentDepositFee: (input: {
      asset_id: number;
      amount: number;
      channel_name: string;
      card_number?: string;
      swift?: string;
    }) =>
      HttpClient.get<DepositFeeResponse>(
        API_ENDPOINTS.GET_PAYMENT_DEPOSIT_FEE,
        input
      ),
    getPaymentWithdrawalFeeOld: (input: { asset_id: number; amount: number }) =>
      HttpClient.get<WithdrawalFeeResponse>(
        API_ENDPOINTS.GET_PAYMENT_WITHDRWAL_FEE_OLD,
        input
      ),
    getPaymentWithdrawalFee: (input: {
      asset_id: number;
      amount: number;
      channel_name: string;
      card_number?: string;
      swift?: string;
    }) =>
      HttpClient.get<WithdrawalFeeResponse>(
        API_ENDPOINTS.GET_PAYMENT_WITHDRWAL_FEE,
        input
      ),
    getPaymentPlatformRequisites: (input: {
      asset_id: number;
      // invoice_id?: number;
    }) =>
      HttpClient.get<PaymentPlatformRequisitesResponse>(
        API_ENDPOINTS.GET_PAYMENT_PLATFORM_REQUISITES,
        input
      ).then((data: any) => data),
    // ).then((data: any) => ({ ...data, invoice_id: input.invoice_id })),

    getAvailablePaymentMethods: (input: { code: string; type: string }) =>
      HttpClient.get<string[]>(
        API_ENDPOINTS.GET_AVAILABLE_PAYMENT_METHODS,
        input
      ),
    createExternalTransfer: (input: { asset_id: string; amount: number }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.CREATE_EXTERNAL_TRANSFER,
        input
      ),
    getLimitations: () =>
      HttpClient.get<FiatLimitation[]>(API_ENDPOINTS.GET_LIMITATIONS),
  };

  paypal = {
    deposit: (input: PaypalDepositInput) =>
      HttpClient.post<PaypalDepositResponse>(
        API_ENDPOINTS.PAYPAL_NEW_DEPOSIT,
        input
      ),
    withdrawal: (input: PaypalWithdrawalInput) =>
      HttpClient.post<PaypalWithdrawalResponse>(
        API_ENDPOINTS.PAYPAL_CREATE_WITHDRAWAL,
        input
      ),
  };

  bankTransfer = {
    deposit: (input: BankDepositInput) =>
      HttpClient.post<BankDepositResponse>(
        API_ENDPOINTS.BANK_TRANSFER_DEPOSIT,
        input
      ),
    withdrawal: (input: BankWithdrawalInput) =>
      HttpClient.post<BankWithdrawalResponse>(
        API_ENDPOINTS.BANK_TRANSFER_WITHDRAWAL,
        input
      ),
  };

  cartuBank = {
    depositWithCardOnline: (input: OnlineCardDepositInput) =>
      HttpClient.post<CardProceedResponse>(
        API_ENDPOINTS.CARD_ONLINE_DEPOSIT,
        input
      ),
    depositWithCardSaved: (input: SavedCardDepositInput) =>
      HttpClient.post<CardProceedResponse>(
        API_ENDPOINTS.CARD_SAVED_DESPOIT,
        input
      ),
    withdrawalWithCardSaved: (input: SavedCardWithdrawalInput) =>
      HttpClient.post<CardProceedResponse>(
        API_ENDPOINTS.CARD_SAVED_WITHDRAWAL,
        input
      ),
    verifyBankCard: (input: VerifyBankCardInput) =>
      HttpClient.post<CardProceedResponse>(
        API_ENDPOINTS.VERIFY_BANK_CARD,
        input
      ),
    getBankCardStatus: (input: { card_id: number }) =>
      HttpClient.get<BankCardStatusResponse>(
        API_ENDPOINTS.GET_BANK_CARD_STATUS,
        input
      ),
  };
  ourPrices = {
    all: (input: IGetOurPricesInput) =>
      HttpClient.get<TOurPrices>(API_ENDPOINTS.GET_OUR_PRICES, input),
  };
}

export default new Client();
