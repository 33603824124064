export const LinkedinIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#0A66C2" rx="5"></rect>
      <g clipPath="url(#clip0_8607_1183)">
        <rect width="20" height="20" x="5" y="5" fill="#0A66C2" rx="2"></rect>
        <path
          fill="#fff"
          d="M23.524 5H6.476A1.476 1.476 0 005 6.476v17.048A1.476 1.476 0 006.476 25h17.048A1.476 1.476 0 0025 23.524V6.476A1.476 1.476 0 0023.524 5zM10.96 22.038H7.954v-9.552h3.007v9.552zM9.456 11.162a1.726 1.726 0 111.736-1.725 1.7 1.7 0 01-1.736 1.726zm12.588 10.884H19.04v-5.218c0-1.54-.654-2.014-1.499-2.014-.891 0-1.766.672-1.766 2.053v5.179h-3.007v-9.553h2.891v1.324h.04c.29-.588 1.306-1.592 2.858-1.592 1.677 0 3.49.996 3.49 3.912l-.002 5.909z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_8607_1183">
          <rect width="20" height="20" x="5" y="5" fill="#fff" rx="2"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};
