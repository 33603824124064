export const AccountIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.12 12.1057C12.05 12.0966 11.96 12.0966 11.88 12.1057C10.12 12.0507 8.71997 10.7307 8.71997 9.10823C8.71997 7.44906 10.18 6.10156 12 6.10156C13.81 6.10156 15.28 7.44906 15.28 9.10823C15.27 10.7307 13.88 12.0507 12.12 12.1057Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.74 18.1553C16.96 19.6495 14.6 20.557 12 20.557C9.40001 20.557 7.04001 19.6495 5.26001 18.1553C5.36001 17.2936 5.96001 16.4503 7.03001 15.7903C9.77001 14.122 14.25 14.122 16.97 15.7903C18.04 16.4503 18.64 17.2936 18.74 18.1553Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20.557C17.5228 20.557 22 16.4529 22 11.3903C22 6.32769 17.5228 2.22363 12 2.22363C6.47715 2.22363 2 6.32769 2 11.3903C2 16.4529 6.47715 20.557 12 20.557Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
