import React, { FC, useState, useEffect, useContext } from "react";
import L from "i18n-react";
import { motion } from "framer-motion";
import { fadeInBottom } from "src/lib/framer-motion/fade-in-bottom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAuth from "../../hooks/use-auth";
import routes from "../../config/routes";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useModalAction } from "src/components/modal-views/context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import {
  APISomethingWrongMsg,
  NetworkDisconnectMsg,
  SUCCESS_MODAL_TYPE,
} from "src/lib/constants";
import GlobalContext from "src/context/global-context";
import { MailboxIcon } from "../../components/icons/mailbox-icon";
import { Spin } from "antd";
import { getErrorMessage } from "src/lib/utils";
import Button from "src/components/ui/button";

const EmailConfirmationPage: FC = () => {
  const { openModal } = useModalAction();
  const [searchParams] = useSearchParams();
  const isMounted = useIsMounted();
  const { setAlertText, setAlertTitle, setIsAlertOpened } =
    useContext(GlobalContext);
  const [isSentRequest, setSentRequest] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const navigate = useNavigate();

  const email = searchParams.get("email") ?? "";

  const schema = yup
    .object({
      token: yup.string().required(`${String(L.translate("Errors.fieldErr"))}`),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isMounted) return;

    const emailToken = searchParams.get("token") ?? "";
    const emailTimestamp = searchParams.get("timestamp");

    emailConfirmation({
      token: emailToken,
      timestamp: Date.now(),
      email: email,
    });
  }, [isMounted]);

  const { mutate: emailConfirmation, isLoading } = useMutation(
    client.users.emailConfirmation,
    {
      onSuccess: (data) => {
        openModal("ACTION_SUCCESS", {
          type: SUCCESS_MODAL_TYPE.EmailVerification,
        });
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
          setIsAlertOpened(true);
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "this_is_the_same_password"
            ) {
              setAlertText(
                String(L.translate("Errors.this_is_the_same_password"))
              );
              setIsAlertOpened(true);
              return;
            }
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "email_already_confirmed"
            ) {
              setAlertText(
                String(L.translate("Errors.email_already_confirmed"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors.token[0] ===
                "the_selected_token_is_invalid"
            ) {
              setAlertText(
                String(L.translate("Errors.the_selected_token_is_invalid"))
              );
              setIsAlertOpened(true);
              setTokenExpired(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "error_incorrect_old_password"
            ) {
              setAlertText(
                String(L.translate("Errors.error_incorrect_old_password"))
              );
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        /*else {
          openModal("EMAIL_VERIFICATION", {
            type: "email_expired",
            token: searchParams.get("token") ?? "",
            title: "Your verification link has expired.",
          });
        }
        */
      },
    }
  );

  const { mutate: resetToken, isLoading: isLoadingReset } = useMutation(
    client.users.resetToken,
    {
      onSuccess: (data) => {
        setSentRequest(true);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "email_already_confirmed"
            ) {
              setAlertText(
                String(L.translate("Errors.email_already_confirmed"))
              );
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors &&
              error.response.data.errors[0] === "token_expired"
            ) {
              setAlertText(String(L.translate("Errors.token_expired")));
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onProcess = () => {
    if (isSentRequest) {
      return;
    }

    const emailToken = searchParams.get("token") ?? "";
    resetToken({ token: emailToken, email: email });
  };

  const onBack = () => {
    navigate(routes.login);
  };

  const { isAuthorized } = useAuth();
  if (isAuthorized) return <Navigate to={routes.settings} />;

  return (
    <motion.div
      variants={fadeInBottom()}
      className="stify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full h-[412px] justify-center m-auto relative">
        {isLoading ? (
          <Spin className="absolute inset-0 z-40 flex items-center justify-center bg-opacity-80 backdrop-blur-sm" />
        ) : (
          <div className="flex flex-col items-center justify-center w-full sm:w-[420px] px-4 bg-white gap-4">
            <MailboxIcon className="w-[120px] h-[120px]" />
            <p className="font-manrope text-[20px] font-bold text-primary text-center">
              {tokenExpired
                ? String(L.translate("KYC.ip_verificatoion"))
                : String(
                    L.translate(
                      "EmailConfirm.EmailConfirmSuccess.email_confirm_completed"
                    )
                  )}
            </p>
            {tokenExpired ? (
              <Button
                variant="solid"
                className={`${
                  isSentRequest ? "bg-[#BCBEAD]" : "bg-active "
                }w-full font-manrope text-[14px] font-medium leading-[24px] h-[60px] rounded-[12px] hover:bg-[#BCBEAD]`}
                onClick={onProcess}
                disabled={isSentRequest}
                isLoading={isLoadingReset}
              >
                {isSentRequest
                  ? String(
                      L.translate(
                        "EmailConfirm.Verification.sent_verification_link"
                      )
                    )
                  : String(
                      L.translate(
                        "EmailConfirm.Verification.resend_verification_link"
                      )
                    )}
              </Button>
            ) : (
              <Button
                variant="solid"
                className={`bg-active w-full font-manrope text-[14px] font-medium leading-[24px] h-[60px] rounded-[12px] hover:bg-[#BCBEAD]`}
                onClick={onBack}
              >
                {String(
                  L.translate(
                    "EmailConfirm.EmailConfirmSuccess.email_confirm_success_btn"
                  )
                )}
              </Button>
            )}
            {tokenExpired ? (
              <div className="flex items-center">
                <span className="text-primary opacity-50">
                  {`${String(L.translate("Buttons.back_to"))}`}{" "}
                </span>{" "}
                <Button
                  variant="text"
                  className="font-manrope inline-block text-[14px] font-medium leading-[24px] h-[30px] hover:opacity-50 underline"
                  onClick={onBack}
                >
                  &nbsp;
                  {`${String(L.translate("Buttons.to_sing"))}`}
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default EmailConfirmationPage;
