import L from "i18n-react";
import { AppStoreIcon } from "src/components/icons/app-store-icon";
import { GoogleStoreIcon } from "src/components/icons/google-store-icon";
import { FacebookIcon } from "src/components/icons/facebook-icon";
import { LinkedinIcon } from "src/components/icons/linkedin-icon";
import routes from "src/config/routes";
import { Collapse, CollapseProps } from "antd";
import BankLogo from "src/components/icons/bankLogo";
import { CaretRightOutlined } from "@ant-design/icons";
export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: String(L.translate("Footer.information")),
      children: (
        <div className="flex flex-col gap-4">
          <div>
            <a
              href={routes.aboutUs}
              target="_blank"
              className="block w-full text-[12px] text-primary hover:text-secondary"
            >
              {String(L.translate("Footer.about_us"))}
            </a>
          </div>
          <div>
            <a
              href={routes.contactUs}
              target="_blank"
              className="block w-full text-[12px] text-primary hover:text-secondary"
            >
              {String(L.translate("Footer.contact_us"))}
            </a>
          </div>
          <div>
            <a
              href={routes.prices}
              target="_blank"
              className="block w-full text-[12px] text-primary hover:text-secondary"
            >
              {String(L.translate("Footer.prices"))}
            </a>
          </div>
          <div>
            <a
              href={routes.faq}
              target="_blank"
              className="block w-full text-[12px] text-primary hover:text-secondary"
            >
              {String(L.translate("Footer.faq"))}
            </a>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: String(L.translate("Footer.legal")),
      children: (
        <div className="flex flex-col gap-4">
          <div>
            <a
              href={routes.userAgreement}
              target="_blank"
              className="block w-full text-[12px] text-primary hover:text-secondary"
            >
              {String(L.translate("Footer.agreement"))}
            </a>
          </div>
          <div>
            <a
              href={routes.internalAMLPolicy}
              target="_blank"
              className="block w-full text-[12px] text-primary hover:text-secondary"
            >
              {String(L.translate("Footer.police"))}
            </a>
          </div>
          <div>
            <a
              href={routes.privacyPolicy}
              target="_blank"
              className="block w-full text-[12px] text-primary hover:text-secondary"
            >
              {String(L.translate("Footer.policy"))}
            </a>
          </div>
          <div>
            <a
              href={routes.privacyCookies}
              target="_blank"
              className="block w-full text-[12px] text-primary hover:text-secondary"
            >
              {String(L.translate("Footer.cookie"))}
            </a>
          </div>
        </div>
      ),
    },
  ];
  return (
    <footer
      className="w-full 
    border-t border-light-300 dark:border-dark-300 z-20 bg-white font-manrope "
    >
      <div className="flex flex-col gap-0">
        <div
          className={`flex flex-col gap-10 md:flex-row px-8 sm:justify-between sm:px-20 py-9`}
        >
          <div className="hidden  sm:flex justify-between gap-10 lg:gap-20 md:justify-center font-manrope text-xs font-bold">
            <div className="flex flex-col gap-4">
              <div className="text-[#7F8172]">
                {String(L.translate("Footer.information"))}
              </div>
              <div className="flex flex-col gap-4">
                <div>
                  <a
                    href={routes.aboutUs}
                    target="_blank"
                    className="text-[12px] text-primary hover:text-secondary"
                  >
                    {String(L.translate("Footer.about_us"))}
                  </a>
                </div>
                <div>
                  <a
                    href={routes.contactUs}
                    target="_blank"
                    className="text-[12px] text-primary hover:text-secondary"
                  >
                    {String(L.translate("Footer.contact_us"))}
                  </a>
                </div>
                <div>
                  <a
                    href={routes.prices}
                    target="_blank"
                    className="text-[12px] text-primary hover:text-secondary"
                  >
                    {String(L.translate("Footer.prices"))}
                  </a>
                </div>
                <div>
                  <a
                    href={routes.faq}
                    target="_blank"
                    className="text-[12px] text-primary hover:text-secondary"
                  >
                    {String(L.translate("Footer.faq"))}
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-[#7F8172]">
                {String(L.translate("Footer.legal"))}
              </div>
              <div className="flex flex-col gap-4">
                <div>
                  <a
                    href={routes.userAgreement}
                    target="_blank"
                    className="text-[12px] text-primary hover:text-secondary"
                  >
                    {String(L.translate("Footer.agreement"))}
                  </a>
                </div>
                <div>
                  <a
                    href={routes.internalAMLPolicy}
                    target="_blank"
                    className="text-[12px] text-primary hover:text-secondary"
                  >
                    {String(L.translate("Footer.police"))}
                  </a>
                </div>
                <div>
                  <a
                    href={routes.privacyPolicy}
                    target="_blank"
                    className="text-[12px] text-primary hover:text-secondary"
                  >
                    {String(L.translate("Footer.policy"))}
                  </a>
                </div>
                <div>
                  <a
                    href={routes.privacyCookies}
                    target="_blank"
                    className="text-[12px] text-primary hover:text-secondary"
                  >
                    {String(L.translate("Footer.cookie"))}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Collapse
            items={items}
            ghost
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 270 : 90} />
            )}
            className="block sm:hidden"
          />
          <div
            className={`flex flex-col sm:flex-row justify-center items-center
              sm:justify-between sm:gap-10 lg:gap-20 md:justify-center
            `}
          >
            <div
              className={`flex flex-col gap-2 font-manrope items-center sm:items-start
              `}
            >
              <div className="text-xs font-bold text-[#7F8172]">
                {String(L.translate("Footer.provider"))}
              </div>
              <div
                className={`flex flex-col gap-2 text-primary text-[10px] font-semibold items-center sm:items-start
                }`}
              >
                <div>{String(L.translate("Footer.ltdname"))}</div>
                <div>{String(L.translate("Footer.regNumber"))} : 0097-2904</div>
                <div>
                  {String(L.translate("Footer.granted"))}:
                  {String(L.translate("Footer.bankName"))}
                </div>
              </div>
              <div>
                <BankLogo />
              </div>
              <div className="text-[10px] font-medium text-[#027DF2]">
                <a
                  href="https://nbg.gov.ge/payment-system/providers"
                  target="_blank"
                >
                  {String(L.translate("Footer.check"))}
                </a>
              </div>
            </div>
            <div className="flex gap-2 pt-8">
              <a
                href="https://www.linkedin.com/company/fastoo-georgia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon />
              </a>
              <a
                href="https://www.facebook.com/fastoo.georgia?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://apps.apple.com/us/app/fastoo-new-payment-system/id6475793330"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppStoreIcon />
              </a>
              <GoogleStoreIcon />
            </div>
          </div>
        </div>
        <div className="w-full border border-solid border-[#E6E8EC]"></div>
        <div
          className={`flex flex-col sm:flex-row gap-3 sm:gap-8
          px-20 py-9 font-manrope items-center `}
        >
          <div className="text-primary text-lg font-bold">
            {String(L.translate("Footer.logo"))}
          </div>
          <div className="text-xs font-normal text-primary">
            © Copyright 2021 - {year}{" "}
            {String(L.translate("Footer.all_rights_reserved"))}
          </div>
        </div>
      </div>
    </footer>
  );
}
