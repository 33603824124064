import { FC, useRef } from "react";
import L from "i18n-react";
import ToggleSwitch, { SwitchElement } from "src/components/ui/switch";

type NotificationCardProps = {
  type: string; //sms & email
  value: boolean;
  onValueChanged: any;
};

const NotificationCard: FC<NotificationCardProps> = ({
  type,
  value,
  onValueChanged,
}) => {
  const switchRef = useRef<SwitchElement>(null);

  return (
    <div className="w-full flex flex-col gap-4 mt-2">
      <div className="flex flex-row items-center justify-between">
        <p className="font-manrope text-[18px] font-medium text-primary leading-[24px]">
          {`${
            type === "email"
              ? String(L.translate("Account.Notifications.email"))
              : String(L.translate("Account.Notifications.sms"))
          } ${String(
            L.translate("Account.Notifications.notifications_small")
          )}`}
        </p>
        <ToggleSwitch
          type={type}
          ref={switchRef}
          value={value}
          onValueChanged={onValueChanged}
        />
      </div>
      <p className="font-manrope text-[14px] font-medium text-secondary leading-[21px] w-full sm:w-[372px]">
        {L.translate("Account.Notifications.email_notify_text", {
          typeN: type,
        })}
      </p>
    </div>
  );
};

export default NotificationCard;
