export const EmailVerificationIcon: React.FC<React.SVGAttributes<{}>> = (
  props
) => {
  return (
    <svg fill="none" viewBox="0 0 24 25" stroke="currentColor" {...props}>
      <path
        d="M22 11V16C22 19.5 20 21 17 21H7C4 21 2 19.5 2 16V9C2 5.5 4 4 7 4H14"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        d="M7 9.5L10.13 12C11.16 12.82 12.85 12.82 13.88 12L15.06 11.06"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        d="M19.5 8.5C20.8807 8.5 22 7.38071 22 6C22 4.61929 20.8807 3.5 19.5 3.5C18.1193 3.5 17 4.61929 17 6C17 7.38071 18.1193 8.5 19.5 8.5Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </svg>
  );
};
