export default function BankTransferIcon() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3392 1.97102L19.5892 5.27099C19.91 5.39933 20.1667 5.78432 20.1667 6.12349V9.16683C20.1667 9.67099 19.7542 10.0835 19.25 10.0835H2.75C2.24584 10.0835 1.83334 9.67099 1.83334 9.16683V6.12349C1.83334 5.78432 2.09001 5.39933 2.41084 5.27099L10.6608 1.97102C10.8442 1.89768 11.1558 1.89768 11.3392 1.97102Z"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.1667 20.1667H1.83334V17.4167C1.83334 16.9125 2.24584 16.5 2.75 16.5H19.25C19.7542 16.5 20.1667 16.9125 20.1667 17.4167V20.1667Z"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.66666 16.4997V10.083"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.33334 16.4997V10.083"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 16.4997V10.083"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.6667 16.4997V10.083"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.3333 16.4997V10.083"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.916664 20.167H21.0833"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 7.79199C11.7594 7.79199 12.375 7.17638 12.375 6.41699C12.375 5.6576 11.7594 5.04199 11 5.04199C10.2406 5.04199 9.625 5.6576 9.625 6.41699C9.625 7.17638 10.2406 7.79199 11 7.79199Z"
          stroke="#232800"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}
