import { FC, useContext, useEffect, useState } from "react";
import { KwickpayHistoryInput, KwickpayHistoryResponse } from "src/api/types";
import L from "i18n-react";
import { getErrorMessage, capitalizeFirstLetter } from "src/lib/utils";
import { LIST_PAGE_SIZE, KwickpayTransferType } from "src/lib/constants";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import moment from "moment";
import "react-swipeable-list/dist/styles.css";

type TransactionDepositWithdrawalListProps = {};

const TransactionDepositWithdrawalList: FC<
  TransactionDepositWithdrawalListProps
> = ({}) => {
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<KwickpayTransferType[]>([]);
  const [searchParams, setSearchParams] = useState<KwickpayHistoryInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });

  const [total, setTotal] = useState(0);

  useEffect(() => {
    isMounted &&
      getList({
        ...searchParams,
      });
  }, [isMounted, searchParams.current_page]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({ ...searchParams, current_page: page });
  };

  const { mutate: getList, isLoading } = useMutation(
    client.transactions.getTransfers,
    {
      onSuccess: (data: KwickpayHistoryResponse) => {
        console.log(data);
        setTotal(data.total);

        setDataSource(
          data.data.map((item) => ({
            key: item.id,
            id: item.id,
            amount: item.amount,
            currency: item.currency,
            sender_name: item.sender_name,
            sender_country: item.sender_country,
            status: item.status,
            created_at: item.created_at,
            updated_at: item.updated_at,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const columns: ColumnsType<KwickpayTransferType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("KwickpayHistory.id")}
        </p>
      ),
      dataIndex: "id",
      className: "w-auto",
      key: "id",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("KwickpayHistory.indicated_amount")}
        </p>
      ),
      dataIndex: "indicated_amount",
      className: "w-auto",
      key: "indicated_amount",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("KwickpayHistory.fee")}
        </p>
      ),
      dataIndex: "fee",
      className: "w-auto",
      key: "fee",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("KwickpayHistory.amount")}
        </p>
      ),
      dataIndex: "amount",
      className: "w-auto",
      key: "amount",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("KwickpayHistory.currency")}
        </p>
      ),
      dataIndex: "currency",
      className: "w-auto",
      key: "currency",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("KwickpayHistory.sender_name")}
        </p>
      ),
      dataIndex: "sender_name",
      className: "w-auto",
      key: "sender_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("KwickpayHistory.sender_country")}
        </p>
      ),
      dataIndex: "sender_country",
      className: "w-auto",
      key: "sender_country",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("KwickpayHistory.status")}
        </p>
      ),
      dataIndex: "status",
      className: "w-auto",
      key: "status",
      render: (value) => (
        <div
          className={`bg-[${
            value === "sent_to_iban" ? "#7F8172" : "#E6E8EC"
          }] inline-block py-[4px] px-[12px] rounded-[10px]`}
        >
          <p
            className={`font-manrope ${
              value === "sent_to_iban" ? "text-[#fff]" : "text-primary"
            } text-[12px] font-medium leading-none`}
          >
            {value && capitalizeFirstLetter(value.replaceAll("_", " "))}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("KwickpayHistory.created")}
        </p>
      ),
      dataIndex: "created_at",
      className: "w-auto",
      key: "created_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {moment(value).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("KwickpayHistory.updated")}
        </p>
      ),
      dataIndex: "updated_at",
      className: "w-auto",
      key: "updated_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {moment(value).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<KwickpayTransferType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          {L.translate("TransactionHistory.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-row gap-2 justify-between">
            <div className="w-full flex flex-col items-start gap-2">
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  {L.translate("KwickpayHistory.id")}
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.id}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  {L.translate("KwickpayHistory.indicated_amount")}
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.indicated_amount || "---"}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  {L.translate("KwickpayHistory.fee")}
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.fee || "---"}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  {L.translate("KwickpayHistory.amount")}
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.amount || "---"}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  {L.translate("KwickpayHistory.currency")}
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.currency || "---"}
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-2">
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  {L.translate("KwickpayHistory.sender_name")}
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.sender_name || "---"}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  {L.translate("KwickpayHistory.sender_country")}
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {selData.sender_country || "---"}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  {L.translate("KwickpayHistory.status")}
                </p>
                <div
                  className={`bg-[${
                    selData.status === "sent_to_iban" ? "#7F8172" : "#E6E8EC"
                  }] inline-block py-[4px] px-[12px] rounded-[10px]`}
                >
                  <p
                    className={`font-manrope ${
                      selData.status === "sent_to_iban"
                        ? "text-[#fff]"
                        : "text-primary"
                    } text-[12px] font-medium leading-none`}
                  >
                    {selData.status &&
                      capitalizeFirstLetter(
                        selData.status.replaceAll("_", " ")
                      )}
                  </p>
                </div>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  {L.translate("KwickpayHistory.created")}
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {moment(selData.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </p>
              </div>
              <div className="py-[4px]">
                <p className="text-secondary text-[13px] font-manrope font-medium leading-none pb-[3px]">
                  {L.translate("KwickpayHistory.updated")}
                </p>
                <p className="text-primary text-[13px] font-manrope font-medium">
                  {moment(selData.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                </p>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white rounded-b-[12px] w-full rounded-tr-[12px]">
      <div className="flex flex-col w-full p-4 gap-4">
        <Table
          loading={isLoading}
          showSorterTooltip={false}
          columns={isMobile ? mobileColumns : columns}
          dataSource={dataSource}
          pagination={{
            total,
            onChange: onPageChange,
            position: ["bottomCenter"],
            defaultPageSize: LIST_PAGE_SIZE,
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
};

export default TransactionDepositWithdrawalList;
