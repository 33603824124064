// @TODO: Переписать на Tailwind.css
import { FC } from "react";
import L from "i18n-react";

// Styles
const itemStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  borderRadius: "11px",
  fontSize: "14px",
  fontWeight: 400,
  color: "var(--text-color-main)",
};

const styles = {
  accSwitcher: {
    display: "flex",
    width: "208px",
    height: "34px",
    margin: "0 auto 24px auto",
    borderRadius: "11px",
    border: "1px solid #232800",
    item: itemStyles,
    itemActive: {
      ...itemStyles,
      background: "#232800",
      color: "#fff",
    },
  },
};
// ---------

interface IAccSwitcher {
  value: boolean;
  onChange: (value: boolean) => void;
}

const AccSwitcher: FC<IAccSwitcher> = ({ value, onChange }) => {
  const handleClick = () => {
    onChange(!value);
  };

  return (
    <div style={styles.accSwitcher}>
      <button
        style={value ? styles.accSwitcher.item : styles.accSwitcher.itemActive}
        type="button"
        onClick={handleClick}
      >
        {String(L.translate("Forms.RegistrForm.registr_form__type_private"))}
      </button>
      <button
        style={value ? styles.accSwitcher.itemActive : styles.accSwitcher.item}
        type="button"
        onClick={handleClick}
      >
        {String(L.translate("Forms.RegistrForm.registr_form__type_business"))}
      </button>
    </div>
  );
};

AccSwitcher.displayName = "Button";
export default AccSwitcher;
