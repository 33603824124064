export const API_ENDPOINTS = {
  USERS_LOGIN: "/login",
  USERS_REGISTER: "/register",
  FORGOT_PASSWORD: "/password_reset",
  RESET_TOKEN: "/reset-token",
  RESEND_EMAIL: "/resend-register",
  RESET_2FA: "/2fa/reset",
  CHANGE_PASSWORD: "/user/password",
  USERS_CHECK_2FA: "/active-2fa",
  SEND_EMAIL_CODE_LOGIN: "/send_email",
  SEND_SMS_CODE_LOGIN: "/send_sms",
  SEND_EMAIL_CHECK_CODE_LOGIN: "/check-email-2fa",
  SEND_SMS_CHECK_CODE_LOGIN: "/check-sms-2fa",
  USERS_ME: "/settings",
  USERS_CLIENT_ME: "/settings/user-client",
  ADD_NEW_USER_KWIKPAY_CLIENT: "/settings/add-user-client",
  AUTH_CHANGE_PASSWORD: "/settings/change_password",
  EMAIL_CONFIRMATION: "/email_confirmation",
  USERS_LOGOUT: "/logout",
  GET_COUNTRIES: "/countries",
  SEND_EMAIL_CODE: "/send_email_auth",
  SEND_PHONE_CODE: "/send_sms_auth",
  SET_PHONE: "/settings/set_phone",
  NOTIFICATIONS: "/settings/notification_settings",
  GOOGLE_2FA_GENERATE_KEY: "/settings/2fa/generate_secret_key",
  GOOGLE_2FA_ENABLE: "/settings/2fa/enable",
  CONFIRM_TERM_CONDITION: "settings/confirm-term-condition",
  GOOGLE_2FA_DISABLE: "/settings/2fa/disable",
  CHECK_2FA_ENABLED: "/settings/2fa/check_enabled",
  ENABLE_EMAIL_2FA: "/settings/2fa/email_check_enable",
  DISABLE_EMAIL_2FA: "/settings/2fa/email_check_disable",
  ENABLE_PHONE_2FA: "/settings/2fa/phone_check_enable",
  DISABLE_PHONE_2FA: "/settings/2fa/phone_check_disable",
  START_KYC: "/kyc/start",
  WALLETS: "/wallets",
  GET_BANK_CARDS: "/user/bank_card",
  ADD_NEW_BANK_CARD: "/user/bank_card/create",
  UPDATE_BANK_CARD: "/user/bank_card/edit",
  CALCULATE_DAILY_DEPOSIT_AMOUNT: "/payment/new_calculate_daily_deposit",
  CALCULATE_DAILY_WITHDRAWAL_AMOUNT_OLD:
    "/payment/calculate_withdrawal_request",
  CALCULATE_DAILY_WITHDRAWAL_AMOUNT:
    "/payment/new_calculate_withdrawal_request",
  GET_PAYMENT_DEPOSIT_FEE: "/payment/new_deposit_fee",
  GET_PAYMENT_WITHDRWAL_FEE_OLD: "/payment/withdrawal_fee",
  GET_PAYMENT_WITHDRWAL_FEE: "/payment/new_withdrawal_fee",
  GET_PAYMENT_PLATFORM_REQUISITES: "/payment/platform_requisites",
  GET_AVAILABLE_PAYMENT_METHODS: "/payment/available",
  CREATE_EXTERNAL_TRANSFER: "/payment/transfer",
  PAYMENT_HISTORY: "/payment/history",
  TRANSFER_HISTORY: "/transfers",
  DELETE_BANK_TRANSFER: "/payment/bank_transfer/deposit",
  PAYPAL_NEW_DEPOSIT: "/payment/paypal/deposit/new",
  PAYPAL_CREATE_WITHDRAWAL: "/payment/paypal/withdraw/new",
  BANK_TRANSFER_DEPOSIT: "/payment/bank_transfer/deposit",
  BANK_TRANSFER_WITHDRAWAL: "/payment/bank_transfer/withdrawal",
  CARD_ONLINE_DEPOSIT: "/payment/cartubank/deposit/card-online",
  CARD_SAVED_DESPOIT: "/payment/cartubank/deposit/card-saved",
  CARD_SAVED_WITHDRAWAL: "/payment/cartubank/withdrawal/card-saved",
  VERIFY_BANK_CARD: "/payment/cartubank/withdrawal/authorize-card",
  GET_BANK_CARD_STATUS: "/payment/cartubank/withdrawal/card-status",
  FAST_TRANSFER_KWIKPAY_SENDERS: "/kwikpay-senders",
  GET_TRANSFER_KWIKPAY_IN_BALANCE: "/get-kwikpay-transfer-balance",
  GET_COUNTRY_BY_NAME: "/get-country-by-name",
  GET_KWIKPAY_DATA_BY_ID: "/get-kwikpay-data-by-id",
  FAST_TRANSFER_KWIKPAY_CHECK: "/check-kwikpay-transfer",
  GET_FAST_BANK_ACCOUNTS: "/user/fast-transfer/bank-accounts",
  ADD_NEW_FAST_BANK_ACCOUNTS: "/user/fast-transfer/bank-accounts/create",
  GET_LIMITATIONS: "/payment/limitations",
  KYC_BUISINESS_START: "/business-kyc/start",
  GET_IBAN_ACCOUNTS: "/iban-accounts",
  ADD_IBAN_ACCOUNT: "/iban-accounts/create",
  GET_OUR_PRICES: "/our-prices",
  KWICKPAY_TRANSACTIONS: "/kwikpay-transactions",
};
