export const FastTransfersIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M18.7918 10.359V6.45399C18.7918 2.75983 17.9302 1.83398 14.4652 1.83398H7.53516C4.07016 1.83398 3.2085 2.75983 3.2085 6.45399V16.7756C3.2085 19.214 4.54684 19.7915 6.16934 18.0498L6.17849 18.0406C6.93015 17.2431 8.07599 17.3073 8.72682 18.1781L9.65266 19.4156"
        stroke="#7F8172"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3335 6.41602H14.6668"
        stroke="#7F8172"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 10.084H13.75"
        stroke="#7F8172"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6933 13.5389L13.4483 16.7839C13.3199 16.9123 13.2008 17.1506 13.1733 17.3248L12.9991 18.5623C12.9349 19.0114 13.2466 19.3231 13.6958 19.2589L14.9333 19.0848C15.1074 19.0573 15.3549 18.9381 15.4741 18.8098L18.7191 15.5648C19.2783 15.0056 19.5441 14.3548 18.7191 13.5298C17.9033 12.714 17.2524 12.9798 16.6933 13.5389Z"
        stroke="#7F8172"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2241 14.0059C16.4991 14.9959 17.2691 15.7659 18.2591 16.0409"
        stroke="#7F8172"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
