export const GoogleStoreIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#000" rx="5"></rect>
      <path
        fill="url(#paint0_linear_8607_1193)"
        d="M6.356 5.273c-.233.234-.31.622-.31 1.088v17.171c0 .466.155.855.388 1.088l.078.077 9.634-9.634v-.155l-9.79-9.635z"
      ></path>
      <path
        fill="url(#paint1_linear_8607_1193)"
        d="M19.254 18.327l-3.186-3.186V14.908l3.186-3.185.077.077 3.808 2.176c1.087.621 1.087 1.631 0 2.253l-3.885 2.098z"
      ></path>
      <path
        fill="url(#paint2_linear_8607_1193)"
        d="M19.332 18.252l-3.264-3.264L6.356 24.7c.389.389.933.389 1.632.078l11.344-6.526z"
      ></path>
      <path
        fill="url(#paint3_linear_8607_1193)"
        d="M19.332 11.724L7.988 5.274c-.7-.388-1.243-.31-1.632.079l9.712 9.634 3.264-3.263z"
      ></path>
      <path
        fill="#000"
        d="M19.254 18.172l-11.266 6.37c-.622.39-1.166.312-1.554 0l-.078.079.078.077c.388.311.932.389 1.554 0l11.266-6.526z"
        opacity="0.2"
      ></path>
      <path
        fill="#000"
        d="M6.356 24.545c-.233-.233-.31-.622-.31-1.088v.078c0 .466.155.854.388 1.087v-.077h-.078zM23.139 15.998l-3.885 2.176.078.078 3.807-2.176c.544-.31.777-.7.777-1.088 0 .389-.311.7-.777 1.01z"
        opacity="0.12"
      ></path>
      <path
        fill="#fff"
        d="M7.988 5.352l15.15 8.624c.467.31.778.622.778 1.01 0-.388-.233-.777-.777-1.088L7.988 5.274C6.9 4.653 6.046 5.12 6.046 6.362v.078c0-1.166.854-1.71 1.942-1.088z"
        opacity="0.25"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_8607_1193"
          x1="15.214"
          x2="2.174"
          y1="6.213"
          y2="19.253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF"></stop>
          <stop offset="0.007" stopColor="#00A1FF"></stop>
          <stop offset="0.26" stopColor="#00BEFF"></stop>
          <stop offset="0.512" stopColor="#00D2FF"></stop>
          <stop offset="0.76" stopColor="#00DFFF"></stop>
          <stop offset="1" stopColor="#00E3FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_8607_1193"
          x1="24.564"
          x2="5.764"
          y1="14.987"
          y2="14.987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000"></stop>
          <stop offset="0.409" stopColor="#FFBD00"></stop>
          <stop offset="0.775" stopColor="orange"></stop>
          <stop offset="1" stopColor="#FF9C00"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_8607_1193"
          x1="17.566"
          x2="-0.117"
          y1="16.772"
          y2="34.455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44"></stop>
          <stop offset="1" stopColor="#C31162"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_8607_1193"
          x1="3.946"
          x2="11.842"
          y1="-0.415"
          y2="7.481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071"></stop>
          <stop offset="0.069" stopColor="#2DA771"></stop>
          <stop offset="0.476" stopColor="#15CF74"></stop>
          <stop offset="0.801" stopColor="#06E775"></stop>
          <stop offset="1" stopColor="#00F076"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
