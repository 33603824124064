import { useContext, useEffect, useState } from "react";
import cn from "classnames";
import L from "i18n-react";
import { motion } from "framer-motion";
import Header from "../layouts/_header";
import Sidebar from "../layouts/_layout_sidebar";
import Footer from "../layouts/_footer";
import { useBreakpoint } from "../lib/hooks/use-breakpoint";
import { useIsMounted } from "../lib/hooks/use-is-mounted";
import useAuth from "../hooks/use-auth";
import { useLocation, useNavigate } from "react-router-dom";
import { VerificationBanner } from "../components/kyc/verification-banner";
import { BackIcon } from "../components/icons/back-icon";
import routes from "../config/routes";
import Button from "../components/ui/button";
import useKYCStatus from "../hooks/use-kyc-status";
import Alert from "../components/ui/alert";
import GlobalContext from "../context/global-context";

export default function LayoutKyc({ children }: React.PropsWithChildren<{}>) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  let [collapse, setCollapse] = useState(false);
  const { isAuthorized } = useAuth();
  const { isNotVerified } = useKYCStatus();

  const { isAlertOpened, setIsAlertOpened, alertText, alertTitle } =
    useContext(GlobalContext);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthorized) {
      setCollapse(false);
    }
  }, [isAuthorized]);

  function updateWindowSize() {
    setIsMobile(false);
    if (window.innerWidth >= 640) {
      setCollapse(false);
    } else {
      setIsMobile(true);
    }
  }

  function toggleSidebar() {
    setCollapse((prev) => !prev);
  }

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className="scrollbar-hide flex min-h-screen w-full flex-col bg-light dark:bg-dark-100"
    >
      <Header isCollapse={collapse} />
      <main className="kyc flex flex-col">
        <section className="pt-16 pl-0 pr-0 pb-24">
          <div className="container max-w-full pt-2 pb-2 pl-4 pr-4 mt-0 mb-0 ml-auto mr-auto">
            <Button
              variant="outline"
              type="submit"
              className="rounded-[10px] text-[15px] font-regular"
              onClick={() => navigate(routes.settings)}
            >
              <span className="btn-icon">
                <BackIcon className="w-[15px] h-[20px] text-primary" />
              </span>
              {L.translate("KYC.back_btn")}
            </Button>
            <div>{children}</div>
          </div>
        </section>
      </main>
      <Footer />
      <Alert
        isOpened={isAlertOpened}
        setIsOpened={setIsAlertOpened}
        text={alertText}
        title={alertTitle}
      />
    </motion.div>
  );
}
