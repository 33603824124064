// TODO: Should be rewriten to Tailwind CSS
import L from "i18n-react";
import { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import classNames from "classnames";
import { getNumberOfDays } from "src/lib/utils";
import { CountryResponse } from "../../../api/types";
import months from "../../../assets/data/months.json";
import Button from "../../ui/button";
import Dropdown from "../../ui/dropdown";
import Input from "../../ui/input";
import Steps from "../kyc-steps";
import { ReactPhone } from "../../ui/phone-input";
import { companyTypes } from "./data";

export type TFinalData = Omit<
  FormData,
  | "company_type"
  | "reg_date"
  | "reg_month"
  | "reg_year"
  | "director_tin"
  | "tax_code"
> & {
  company_type: string;
  director_tin: string;
  tax_code: string;
  registration_date: string;
};

interface IKycBuisinessFormStep1 {
  onSubmit: (state: TFinalData) => void;
}

const schema = yup
  .object({
    company_name: yup
      .string()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    company_type: yup
      .string()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    tax_code: yup
      .number()
      .typeError("Tax Code must be a number")
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    reg_date: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`)
      .min(0),
    reg_month: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`)
      .min(0),
    reg_year: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`)
      .min(0),
    legal_address: yup
      .string()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    real_address: yup
      .string()
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    phone: yup
      .string()
      .matches(
        /[1-9]{1}[\d]{9,13}$/,
        "The phone  number must be (000) 000 00 00"
      )
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    director_first_name: yup
      .string()
      .min(2, `Director's First Name must be at least 2 characters`)
      .max(256)
      .matches(/^[^\d]+$/, "Must be only letter")
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    director_last_name: yup
      .string()
      .min(2, `Director Last Name must be at least 2 characters`)
      .max(256)
      .matches(/^[^\d]+$/, "Must be only letter")
      .required(`${String(L.translate("Errors.fieldErr"))}`),
    director_tin: yup
      .number()
      .required(`${String(L.translate("Errors.fieldErr"))}`)
      .typeError("Director Tin must be a number"),
  })
  .required();
export type FormData = yup.InferType<typeof schema>;

export default function KycBuisinessFormStep1({
  onSubmit,
}: IKycBuisinessFormStep1) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      reg_date: -1,
      reg_month: -1,
      reg_year: -1,
      // company_type: "",
    },
  });

  const phoneRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const monthIdx = watch("reg_month");
  const yearIdx = watch("reg_year");
  const dayIdx = watch("reg_date");
  const watchCompanyType = watch("company_type");

  const [days, setDays] = useState<CountryResponse[]>([]);
  const [years, setYears] = useState<CountryResponse[]>([]);

  const selectedMonth = months[monthIdx];
  const selectedDay = days[dayIdx];
  const selectedYear = years[yearIdx];

  const [phoneDivWidth, setPhoneDivWidth] = useState(0);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    const width = phoneRef.current ? phoneRef.current.offsetWidth : 0;
    setPhoneDivWidth(width);

    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  useEffect(() => {
    let tempYears = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 100; i <= currentYear; i++) {
      tempYears.push({
        id: i - currentYear + 100,
        name: `${i}`,
        code: `${i}`,
        active: 1,
      });
    }
    setYears(tempYears);
    // loadCountries();
  }, []);

  useEffect(() => {
    const daysAmount = getNumberOfDays(
      +selectedMonth?.code,
      +selectedYear?.code || undefined
    );
    let tempDays = [];
    for (let i = 1; i <= daysAmount; i++) {
      tempDays.push({ id: i, name: `${i}`, code: `${i}`, active: 1 });
    }
    if (dayIdx > tempDays.length - 1) {
      resetField("reg_date");
    }
    setDays(tempDays);
  }, [selectedMonth, selectedYear]);

  const makePhoneWithCode = () => {
    return (
      <div className="flex flex-row w-full justify-between gap-4">
        <div className="flex flex-col w-full" ref={phoneRef}>
          <span className="block cursor-pointer pb-2.5 font-normal text-secondary font-manrope text-[16px]">
            <span className="text-[16px] text-[#F44336] font-manrope">* </span>
            {String(L.translate("WithdrawFiat.phone"))}
          </span>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <ReactPhone
                dropdownStyle={{ width: phoneDivWidth }}
                inputClass={classNames(
                  "!w-full !border !border-border !ring-[0.5px] !ring-light-500 !focus:ring-[0.5px] !rounded-[12px]",
                  errors.phone
                    ? "focus:!border-[#F44336] focus:!ring-[#F44336]"
                    : "focus:!border-active focus:!ring-active"
                )}
                country="us"
                {...field}
              />
            )}
          />

          {errors.phone?.message && (
            <span
              role="alert"
              className="block pt-2 text-[16px] text-[#F44336] font-manrope"
            >
              {errors.phone?.message}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <form
      className="h-full w-full flex flex-col gap-4"
      onSubmit={handleSubmit((data: FormData) => {
        console.log(data);
        const { reg_date, reg_month, reg_year, ...rest } = data;
        const dayFormatted =
          selectedDay?.code &&
          (selectedDay?.code.length === 1
            ? `0${selectedDay?.code}`
            : selectedDay?.code);
        onSubmit({
          // ...rest,
          ...data,
          company_type:
            companyTypes
              .find((item) => item.id === parseInt(data.company_type))
              ?.name.toLowerCase() || "",
          director_tin: data.director_tin.toString(),
          tax_code: data.tax_code.toString(),
          phone: "+" + data.phone,
          registration_date: `${selectedYear.code}-${selectedMonth.code}-${dayFormatted}`,
        });
      })}
    >
      <div className="w-full flex flex-col gap-8 mt-2">
        <Input
          label={String(L.translate("KYC.company_name"))}
          placeholder={String(L.translate("KYC.enter_company_name"))}
          className="w-full"
          {...register("company_name")}
          required
          error={String(L.translate(errors.company_name?.message || ""))}
        />
        <Controller
          name="company_type"
          control={control}
          render={({ field }) => (
            <Dropdown
              label={String(L.translate("KYC.company_type"))}
              placeholder={String(L.translate("KYC.enter_company_type"))}
              className="w-full"
              data={companyTypes}
              required
              error={String(L.translate(errors.company_type?.message || ""))}
              {...field}
            />
          )}
        />
        <Input
          label={String(L.translate("KYC.tax_code"))}
          placeholder={String(L.translate("KYC.enter_tax_code"))}
          className="w-full"
          {...register("tax_code")}
          required
          error={String(L.translate(errors.tax_code?.message || ""))}
        />
        {/* <Input
          label={String(L.translate("KYC.tax_code"))}
          placeholder={String(L.translate("KYC.enter_tax_code"))}
          className="w-full"
          required
        /> */}
        <div className="flex flex-col gap-2">
          <label>
            {" "}
            <span className="block text-[16px] font-manrope font-medium text-secondary relative">
              <span className="text-[16px] text-[#F44336] font-manrope">
                *{" "}
              </span>
              {String(L.translate("KYC.registration_date"))}
            </span>
          </label>
          <div className="grid grid-cols-3 gap-4">
            <Controller
              name="reg_date"
              control={control}
              render={({ field }) => (
                <Dropdown
                  label={String(L.translate("Account.AccountSettings.date"))}
                  placeholder={
                    isMobile
                      ? String(L.translate("KYC.date"))
                      : String(L.translate("KYC.registration_date"))
                  }
                  className="w-full"
                  data={days}
                  required
                  error={String(L.translate(errors.reg_date?.message || ""))}
                  {...field}
                />
              )}
            />

            <Controller
              name="reg_month"
              control={control}
              render={({ field }) => (
                <Dropdown
                  label={String(L.translate("Account.AccountSettings.month"))}
                  placeholder={
                    isMobile
                      ? String(L.translate("KYC.month"))
                      : String(L.translate("KYC.registration_month"))
                  }
                  className="w-full"
                  data={months}
                  required
                  error={String(L.translate(errors.reg_month?.message || ""))}
                  {...field}
                />
              )}
            />
            <Controller
              name="reg_year"
              control={control}
              render={({ field }) => (
                <Dropdown
                  label={String(L.translate("Account.AccountSettings.year"))}
                  placeholder={
                    isMobile
                      ? String(L.translate("KYC.year"))
                      : String(L.translate("KYC.registration_year"))
                  }
                  className="w-full"
                  data={years}
                  required
                  error={String(L.translate(errors.reg_year?.message || ""))}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <Input
          label={String(L.translate("KYC.legal_address"))}
          placeholder={String(L.translate("KYC.enter_legal_address"))}
          className="w-full"
          {...register("legal_address")}
          required
          error={String(L.translate(errors.legal_address?.message || ""))}
        />
        <Input
          label={String(L.translate("KYC.actual_address"))}
          placeholder={String(L.translate("KYC.enter_actual_address"))}
          className="w-full"
          {...register("real_address")}
          required
          error={String(L.translate(errors.real_address?.message || ""))}
        />
        {makePhoneWithCode()}
        <Input
          label={String(L.translate("KYC.director_first_name"))}
          placeholder={String(L.translate("KYC.enter_first_name"))}
          className="w-full"
          {...register("director_first_name")}
          required
          error={String(L.translate(errors.director_first_name?.message || ""))}
        />
        <Input
          label={String(L.translate("KYC.director_last_name"))}
          placeholder={String(L.translate("KYC.enter_last_name"))}
          className="w-full"
          {...register("director_last_name")}
          required
          error={String(L.translate(errors.director_last_name?.message || ""))}
        />
        <Input
          label={String(L.translate("KYC.director_tin"))}
          placeholder={String(L.translate("KYC.enter_tin"))}
          className="w-full"
          {...register("director_tin")}
          required
          error={String(L.translate(errors.director_tin?.message || ""))}
        />
      </div>
      <div className="flex flex-row items-center justify-center mt-4">
        <Button
          type="submit"
          variant="validate"
          className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] w-full h-[64px]"
          disabled={!isValid}
        >
          {L.translate("KYC.next")}
        </Button>
      </div>
    </form>
  );
}
