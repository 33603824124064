import { FC, useContext, useEffect } from "react";
import L from "i18n-react";
import { LockIcon } from "../icons/lock-icon";
import Password from "../ui/password";
import Button from "../ui/button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import client from "../../api";
import { getCurrencyInfo, getErrorMessage } from "../../lib/utils";
import {
  APISomethingWrongMsg,
  NetworkDisconnectMsg,
  SUCCESS_MODAL_TYPE,
} from "src/lib/constants";
import { useModalAction } from "../modal-views/context";
import GlobalContext from "src/context/global-context";
import useAuth from "src/hooks/use-auth";

const ChangePasswordCard = () => {
  const schema = yup
    .object({
      cur_password: yup
        .string()
        .required(String(L.translate("Errors.password_validation")))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          String(L.translate("Errors.fieldErr"))
        ),
      old_password: yup
        .string()
        .required(String(L.translate("Errors.fieldErr")))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          String(L.translate("Errors.password_validation"))
        )
        .oneOf(
          [yup.ref("cur_password")],
          String(L.translate("Errors.pass_match"))
        ),

      password: yup
        .string()
        .required(String(L.translate("Errors.fieldErr")))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          String(L.translate("Errors.password_validation"))
        ),
      confirmPassword: yup
        .string()
        .required(String(L.translate("Errors.fieldErr")))
        .oneOf([yup.ref("password")], String(L.translate("Errors.pass_match"))),
    })
    .required();

  type FormData = yup.InferType<typeof schema>;

  const { openModal } = useModalAction();
  const { setAlertText, setIsAlertOpened, setAlertTitle } =
    useContext(GlobalContext);
  const { getCurrrentUser, setCurrentUser } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      cur_password: getCurrrentUser().password ?? "",
      old_password: "",
    },
  });

  const newPassword = watch("password");
  const { mutate: changePassword, isLoading } = useMutation(
    client.users.authChangePassword,
    {
      onSuccess: (data) => {
        setCurrentUser(getCurrrentUser(), newPassword);

        openModal("ACTION_SUCCESS", {
          type: SUCCESS_MODAL_TYPE.ChangePassword,
        });
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "token_incorrect"
            ) {
              setAlertText(String(L.translate("Errors.token_incorrect")));
              setIsAlertOpened(true);
              return;
            } else if (
              error.response.data.errors.password &&
              error.response.data.errors.password[0] ===
                "the_password_must_not_be_greater_than25_characters"
            ) {
              setAlertText(String(L.translate("Errors.password_max_length")));
              setIsAlertOpened(true);
              return;
            }

            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onSubmit = (data: FormData) => {
    changePassword(data);
  };

  return (
    <form
      className="flex flex-col w-full md:w-[45%] bg-white rounded-[12px] p-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-row items-center w-full border-b border-gray border-opacity-50 gap-2 pb-4">
        <LockIcon className="w-[20px] h-[20px] text-primary " />
        <p className="text-primary font-manrope text-[18px]  leading-[28px] font-medium">
          {L.translate("Settings.change_pass_form_title")}
        </p>
      </div>
      <div className="flex flex-col w-full pt-3">
        <Password
          label={String(L.translate("Settings.change_you_title"))}
          className="w-full"
          {...register("old_password")}
          placeholder={String(
            L.translate("Forms.LoginForm.placeholder_password")
          )}
          error={errors.old_password && (errors.old_password.message as string)}
        />
        <Password
          label={String(L.translate("Settings.change_pass_form_title"))}
          className="w-full mt-8"
          {...register("password")}
          placeholder={String(
            L.translate("Forms.LoginForm.placeholder_password")
          )}
          error={errors.password && (errors.password.message as string)}
        />
        <Password
          label=""
          className="w-full mt-4"
          {...register("confirmPassword")}
          placeholder={String(
            L.translate("Forms.LoginForm.placeholder_password")
          )}
          error={
            errors.confirmPassword && (errors.confirmPassword.message as string)
          }
        />
        <Button
          variant="validate"
          type="submit"
          className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] mt-4"
          disabled={!isValid || isLoading}
          isLoading={isLoading}
        >
          {L.translate("Settings.change_pass_form_title")}
        </Button>
      </div>
    </form>
  );
};

export default ChangePasswordCard;
