import {
  FC,
  useContext,
  useEffect,
  useState,
  useRef,
  forwardRef,
  Ref,
  useImperativeHandle,
} from "react";
import L from "i18n-react";
import Input from "../ui/input";
import { InfoIcon } from "../icons/info-icon";
import { AuthenticatorAppURL, getErrorMessage } from "src/lib/utils";
import Button from "../ui/button";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import useAuth from "src/hooks/use-auth";
export interface ConfigurationItem {
  getCode: () => string;
}

const TwoAuthSettingsConfiguration = forwardRef(
  (
    props: {
      secretKey: string;
      qrCode: string;
      isEnabled: number;
      set2FAEnabled: any;
      setShowConfiguration: any;
    },
    ref: Ref<ConfigurationItem>
  ) => {
    const textRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState("");
    const { setIsAlertOpened, setAlertText, setAlertTitle } =
      useContext(GlobalContext);
    const { getCurrent2FAInfo, setCurrent2FAInfo } = useAuth();

    const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (val.length > 6) {
        return;
      }

      const re = /^[0-9\b]+$/;
      if (val === "" || re.test(val)) {
        setValue(val);
      }
    };

    function getCode() {
      return value;
    }

    useImperativeHandle(ref, () => ({ getCode }));

    const goAuthenticatorApp = (url: string) => {
      window.open(url, "_blank");
    };

    const { mutate: enable2FA, isLoading: isLoadingEnable2FA } = useMutation(
      client.google2FA.enable,
      {
        onSuccess: (data) => {
          console.log(data);
          setCurrent2FAInfo({ ...getCurrent2FAInfo(), google2fa_enabled: 1 });
          props.set2FAEnabled(1);
          props.setShowConfiguration(false);
        },
        onError: (error: any) => {
          setAlertTitle(String(L.translate("Alert.Error")));

          if (error.code === "ERR_NETWORK") {
            setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
          } else {
            if (error.response) {
              if (
                error.response.data.errors &&
                error.response.data.errors[0] === "2fa_already_enabled"
              ) {
                setAlertText(String(L.translate("Errors.2fa_already_enabled")));
                setIsAlertOpened(true);
                return;
              } else if (
                error.response.data.errors &&
                error.response.data.errors[0] === "invalid_totp_code"
              ) {
                setAlertText(String(L.translate("Errors.invalid_totp_code")));
                setIsAlertOpened(true);
                return;
              }
              setAlertText(getErrorMessage(error.response.data));
            } else {
              setAlertText(
                String(L.translate("GlobalErrors.APISomethingWrong"))
              );
            }
          }
          setIsAlertOpened(true);
        },
      }
    );

    const { mutate: disable2FA, isLoading: isLoadingDisable2FA } = useMutation(
      client.google2FA.disable,
      {
        onSuccess: (data) => {
          console.log(data);
          setCurrent2FAInfo({ ...getCurrent2FAInfo(), google2fa_enabled: 0 });
          props.set2FAEnabled(0);
          props.setShowConfiguration(false);
        },
        onError: (error: any) => {
          setAlertTitle(String(L.translate("Alert.Error")));

          if (error.code === "ERR_NETWORK") {
            setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
          } else {
            if (error.response) {
              if (
                error.response.data.errors &&
                error.response.data.errors[0] === "can_not_disable_last_2fa"
              ) {
                setAlertText(
                  "You can't turn OFF all options. At least one should be turned ON"
                );

                setIsAlertOpened(true);
                return;
              }
              if (
                error.response.data.errors &&
                error.response.data.errors[0] === "2fa_already_disabled"
              ) {
                setAlertText(
                  String(L.translate("Errors.2fa_already_disabled"))
                );
                setIsAlertOpened(true);
                return;
              } else if (
                error.response.data.errors &&
                error.response.data.errors[0] === "invalid_totp_code"
              ) {
                setAlertText(String(L.translate("Errors.invalid_totp_code")));
                setIsAlertOpened(true);
                return;
              }
              setAlertText(getErrorMessage(error.response.data));
            } else {
              setAlertText(
                String(L.translate("GlobalErrors.APISomethingWrong"))
              );
            }
          }
          setIsAlertOpened(true);
        },
      }
    );

    const onConfirm = () => {
      if (props.isEnabled) {
        disable2FA({ totp: value });
      } else {
        enable2FA({ google2fa_enabled: true, totp: value });
      }
    };

    return (
      <div className="flex flex-col gap-4 w-full md:w-[731px] md:px-4 md:py-8">
        {!props.isEnabled && (
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-row items-center gap-4">
              <div className="flex items-center justify-center bg-active w-8 h-8 rounded-full">
                <p className="font-manrope text-primary text-[14px] font-medium leading-[22px]">
                  1
                </p>
              </div>
              <p className="font-manrope text-primary text-[16px] font-medium leading-[24px]">
                {String(L.translate("Forms.Google2fa.download"))}
              </p>
            </div>
            <p className="font-manrope text-secondary text-[14px] font-light leading-[21px]">
              {String(L.translate("Forms.Google2fa.text"))}
            </p>
            <div className="flex flex-row gap-2 md:gap-8 items-center justify-between sm:justify-start">
              <button
                className="bg-google-play w-[50%] max-w-[136px] aspect-[136/40] sm:w-[136px] bg-cover hover:opacity-80"
                onClick={() => goAuthenticatorApp(AuthenticatorAppURL.android)}
              />
              <button
                className="bg-app-store w-[50%] max-w-[136px] aspect-[136/40] sm:w-[136px] bg-cover hover:opacity-80"
                onClick={() => goAuthenticatorApp(AuthenticatorAppURL.ios)}
              />
            </div>
            <div className="flex flex-row items-center gap-4 mt-4">
              <div className="flex items-center justify-center bg-active w-8 h-8 rounded-full">
                <p className="font-manrope text-primary text-[14px] font-medium leading-[22px]">
                  2
                </p>
              </div>
              <p className="font-manrope text-primary text-[16px] font-medium leading-[24px]">
                {String(L.translate("Forms.Google2fa.save_2fa_code"))}
              </p>
            </div>
            <p className="font-manrope text-secondary text-[14px] font-light leading-[21px]">
              {String(L.translate("Forms.Google2fa.please_backup"))}
            </p>
            <div className="flex flex-col md:flex-row gap-4 md:gap-8 mt-4 mb-4">
              <div className="max-h-[150px] h-full max-w-[150px] w-full md:m-auto">
                <img src={props.qrCode} />
              </div>
              <div className="flex flex-col w-full md:pr-10 gap-2">
                <p className="font-manrope text-secondary text-[16px] font-medium leading-[24px]">
                  {String(L.translate("Forms.Google2fa.backup_secret"))}
                </p>
                <Input
                  ref={textRef}
                  label=""
                  className="w-full"
                  value={props.secretKey}
                  inputClassName="text-success font-manrope text-[16px] font-medium !h-[58px]"
                />
                <div className="flex flex-row items-center gap-4 bg-error bg-opacity-10 p-4 rounded-[12px]">
                  <InfoIcon className="w-[18px] h-[18px] text-error flex-shrink-0" />
                  <p className="text-error font-manrope text-[14px] font-medium">
                    {String(L.translate("Forms.Google2fa.please_write"))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-row w-full items-end gap-2 sm:gap-4">
          <Input
            label={String(L.translate("Forms.Google2fa.google_2fa_form_title"))}
            className="w-full md:!w-[50%]"
            placeholder={String(
              L.translate("Base.six_code_from_google_authenticator")
            )}
            inputClassName="font-manrope text-[16px] font-medium !h-[58px]"
            value={value}
            onChange={onCodeChange}
            error={
              value.length > 0 && value.length < 6
                ? String(L.translate("Forms.Google2fa.valid_code"))
                : undefined
            }
          />
          <Button
            variant="validate"
            disabled={value.length >= 0 && value.length < 6}
            onClick={onConfirm}
            className={`${
              value.length > 0 && value.length < 6 ? "mb-[32px]" : "mb-0"
            } transition-none rounded-[12px] font-manrope text-[14px] font-bold leading-[24px] w-[118px] h-[58px]`}
            isLoading={isLoadingEnable2FA || isLoadingDisable2FA}
          >
            {String(L.translate("Forms.Google2fa.confirm"))}
          </Button>
        </div>
      </div>
    );
  }
);
TwoAuthSettingsConfiguration.displayName = "TwoAuthSettingsConfiguration";

export default TwoAuthSettingsConfiguration;
