export const TransactionHistoryIcon: React.FC<React.SVGAttributes<{}>> = (
  props
) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7378 2.76175H8.0848C6.0048 2.75375 4.2998 4.41175 4.2508 6.49075V17.2037C4.2048 19.3167 5.8798 21.0677 7.9928 21.1147C8.0238 21.1147 8.0538 21.1157 8.0848 21.1147H16.0738C18.1678 21.0297 19.8178 19.2997 19.8028 17.2037V8.03775L14.7378 2.76175Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M14.4751 2.75V5.659C14.4751 7.079 15.6231 8.23 17.0431 8.234H19.7981"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M14.2881 15.3584H8.88812"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M12.2432 11.6064H8.88721"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  );
};
