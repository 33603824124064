import { FC, useEffect, useRef, useState, useContext } from "react";
import Button from "../../components/ui/button";
import { useModalAction } from "../../components/modal-views/context";
import InputWithMax from "../../components/ui/input-max";
import DropdownCurrency from "../../components/ui/dropdown-currency";
import L from "i18n-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import RadioGroup from "../../components/ui/radio-group";
import Dropdown from "src/components/ui/dropdown";
import Input from "src/components/ui/input";
import { ReactPhone } from "src/components/ui/phone-input";
import classNames from "classnames";
import {
  getCurrencySymbol,
  getErrorMessage,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import {
  APISomethingWrongMsg,
  AssetDataType,
  NetworkDisconnectMsg,
  PAYMENT_OPTIONS,
  PAYMENT_TYPE,
} from "src/lib/constants";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "../../context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import DropdownCard from "src/components/ui/dropdown-card";
import { BankCard } from "src/api/types";
import { Spin } from "antd";
import useAuth from "src/hooks/use-auth";
import useKYCStatus from "src/hooks/use-kyc-status";
import { getWithdrawalAmount } from "src/api/token-util";

const WithdrawFiatPage: FC = () => {
  const schema = yup
    .object({
      currency: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`),
      payType: yup
        .string()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .min(0),
      card: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .min(0),
      min_amount: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .positive(),
      max_amount: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .positive(),
      amount: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .positive()
        .min(
          yup.ref("min_amount"),
          `${String(L.translate("Errors.small_amount_for_a_deposit"))}`
        )
        .max(
          yup.ref("max_amount"),
          `${String(L.translate("Errors.much_amount_for_a_deposit"))}`
        ),
      variantId: yup
        .number()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .min(0),
      paypalId: yup
        .string()
        .required(`${String(L.translate("Errors.fieldErr"))}`),
      email: yup
        .string()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          `${String(L.translate("Errors.email_format"))}`
        ),
      phone: yup.string().required(`${String(L.translate("Errors.fieldErr"))}`),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  const withdrawVariants = [
    `${String(L.translate("WithdrawFiat.paypal_id"))}`,
    `${String(L.translate("WithdrawFiat.email"))}`,
    `${String(L.translate("WithdrawFiat.phone"))}`,
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const phoneRef = useRef<HTMLDivElement>(null);
  const [phoneDivWidth, setPhoneDivWidth] = useState(0);
  const { setIsAlertOpened, setAlertText, setAlertTitle } =
    useContext(GlobalContext);
  const isMounted = useIsMounted();
  const { getCurrrentUser } = useAuth();
  const [searchParams] = useSearchParams();

  const { closeModal, openModal } = useModalAction();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [maxAmount, setMaxAmount] = useState(0);
  const [fee, setFee] = useState(0);

  const [currencyList, setCurrencyList] = useState<AssetDataType[]>([]);
  const [selectedCurrencyIdx, setSelectedCurrencyIdx] = useState(-1);
  const [fromCardVerification, setFromCardVerification] = useState(false);
  const [selectedCurrencyCode, setSelectedCurrenyCode] = useState("");
  const [selectedCardID, setSelectedCardID] = useState(-1);
  const [selectedPayType, setSelectedPayType] = useState("");

  const [payOptions, setPayOptions] = useState<string[]>([]);
  const [cardList, setCardList] = useState<BankCard[]>([]);

  const [amountLimit, setAmountLimit] = useState({ min: 0, max: 0 });
  const [dailyAmount, setDailyAmount] = useState(0);

  const [submitData, setSubmitData] = useState<any>({});
  const { isVerifed } = useKYCStatus();

  const { getCurrent2FAInfo } = useAuth();

  useEffect(() => {
    if (!isMounted) return;

    if (
      searchParams.get("currency") &&
      searchParams.get("card_id") &&
      searchParams.get("type")
    ) {
      setFromCardVerification(true);
      setSelectedCurrenyCode(searchParams.get("currency")!);
      setSelectedCardID(Number(searchParams.get("card_id")!));
      setSelectedPayType(searchParams.get("type")!);
    }

    setTimeout(() => {
      getWallets();
      /*
      if (getCurrent2FAInfo().google2fa_enabled && isVerifed) {
        getWallets();
      } else {
        openModal("WITHDRAWAL_NOT_AVAILABLE");
      }*/
    }, 300);
  }, [isMounted]);

  /*
  useEffect(() => {
    if (location.state) {
      setSelectedCurrencyIdx(location.state.selectedCurrencyIdx);
      setCurrencyList(location.state.currencies);
      setValue("currency", location.state.selectedCurrencyIdx);
    }
  }, [location.state]);
  */

  const {
    register,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      currency: selectedCurrencyIdx,
      payType: "",
      card: -1,
      amount: -1,
      min_amount: 1,
      max_amount: 1,
      variantId: 0,
    },
  });

  useEffect(() => {
    const width = phoneRef.current ? phoneRef.current.offsetWidth : 0;
    setPhoneDivWidth(width);
  }, [phoneRef.current]);

  const [currency, payType, variantId, amount] = watch([
    "currency",
    "payType",
    "variantId",
    "amount",
  ]);

  useEffect(() => {
    setMaxAmount(
      currency >= 0 && currencyList.length > 0
        ? currencyList[currency].amount
        : 0
    );
    setValue("amount", -1);

    currencyList.length > 0 &&
      currency >= 0 &&
      getAvailablePaymentMethods({
        code: currencyList[currency].currency,
        type: PAYMENT_TYPE.Withdrawal,
      });

    const isVIP = getCurrrentUser().is_vip ?? 0;
    setAmountLimit({
      min:
        isVIP === 1
          ? currencyList[currency].vip_withdraw_min
          : currencyList[currency].withdraw_min,
      max:
        isVIP === 1
          ? currencyList[currency].vip_withdraw_max
          : currencyList[currency].withdraw_max,
    });
    setValue(
      "min_amount",
      isVIP === 1
        ? currencyList[currency].vip_withdraw_min
        : currencyList[currency].withdraw_min
    );
    setValue(
      "max_amount",
      isVIP === 1
        ? currencyList[currency].vip_withdraw_max
        : currencyList[currency].withdraw_max
    );
    /*
      setTimeout(() => {
        getDailyAmount({ asset_code: currencyList[currency].currency });
      }, 3000);
      */
  }, [currency, currencyList]);

  useEffect(() => {
    setValue("card", payType === PAYMENT_OPTIONS.Card ? -1 : 0);
    setValue("amount", -1);
    setValue("paypalId", "");

    if (payType === PAYMENT_OPTIONS.Card) {
      setValue("paypalId", "***");
      setValue("email", "placeholder@gmail.com");
      setValue("phone", "***");

      getCards();
    } else if (payType === PAYMENT_OPTIONS.Bank) {
      setValue("paypalId", "***");
      setValue("email", "placeholder@gmail.com");
      setValue("phone", "***");
    } else if (payType === PAYMENT_OPTIONS.Paypal) {
      setValue("email", "");
      setValue("phone", "***");
    }
  }, [payType]);

  useEffect(() => {
    if (amount <= 0) {
      return;
    }

    const timeOutId = setTimeout(
      () => getFee({ asset_id: currencyList[currency].currency_id, amount }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [amount]);

  useEffect(() => {
    if (payType === PAYMENT_OPTIONS.Paypal) {
      setValue("paypalId", "***");
      setValue("email", "placeholder@gmail.com");
      setValue("phone", "***");

      if (variantId === 0) {
        setValue("paypalId", "");
      }

      if (variantId === 1) {
        setValue("email", "");
      }

      if (variantId === 2) {
        setValue("phone", "");
      }
    }

    setValue("amount", -1);
  }, [variantId]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    const width = phoneRef.current ? phoneRef.current.offsetWidth : 0;
    setPhoneDivWidth(width);

    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const gotNewCard = (cardData: any) => {
    console.log("got new card", cardData);

    /*
    const newCard = {
      id: -1,
      bank_name: cardData.bank_name,
      card_number: cardData.card_number,
      card_number_last4: cardData.card_number.substr(
        cardData.card_number.length - 4
      ),
      holder_name: cardData.holder_name,
      expiry_month: cardData.expiry_month,
      expiry_year: cardData.expiry_year,
    };
    */

    setCardList([...cardList, cardData]);
    setValue("card", [...cardList, cardData].length - 1);
  };

  const onAddCard = () => {
    console.log("added new card");
    openModal("ADD_PAYMENT_CARD", { type: "online", callback: gotNewCard });
  };

  const { mutate: getFee } = useMutation(
    client.payment.getPaymentWithdrawalFeeOld,
    {
      onSuccess: (data) => {
        console.log(data);
        setFee(data.withdrawal_fee);
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getDailyAmount, isLoading: isLoadingDailyAmount } =
    useMutation(client.payment.calculateDailyWithdrawalAmountOld, {
      onSuccess: (data) => {
        console.log("DAILY - ", data);
        setDailyAmount(data.residual_amount ?? 0);
        /*
        if (data.residual_amount) {
          setValue("max_amount", data.residual_amount);
        }
        */
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  const loadSelectedWallet = (
    wallets: AssetDataType[],
    walletIdx: number,
    isVIP: number
  ) => {
    setAmountLimit({
      min:
        isVIP === 1
          ? wallets[walletIdx].vip_withdraw_min
          : wallets[walletIdx].withdraw_min,
      max:
        isVIP === 1
          ? wallets[walletIdx].vip_withdraw_max
          : wallets[walletIdx].withdraw_max,
    });
    setValue(
      "min_amount",
      isVIP === 1
        ? wallets[walletIdx].vip_withdraw_min
        : wallets[walletIdx].withdraw_min
    );
    setValue(
      "max_amount",
      isVIP === 1
        ? wallets[walletIdx].vip_withdraw_max
        : wallets[walletIdx].withdraw_max
    );

    getAvailablePaymentMethods({
      code: wallets[walletIdx].currency,
      type: PAYMENT_TYPE.Withdrawal,
    });

    getDailyAmount({ asset_code: wallets[walletIdx].currency });
  };

  const { mutate: getWallets, isLoading } = useMutation(
    client.balance.getWallets,
    {
      onSuccess: (data) => {
        console.log(data);
        const dataSource = data.wallets.map((item, index) => ({
          key: index,
          wallet_id: item.id,
          currency: item.asset.code,
          amount: item.total,
          currency_id: item.asset.id,
          name: item.asset.name,
          img_path: item.asset.img_path,
          vip_deposit_max: item.asset.vip_deposit_max,
          vip_deposit_min: item.asset.vip_deposit_min,
          vip_withdraw_max: item.asset.vip_withdraw_max,
          vip_withdraw_min: item.asset.vip_withdraw_min,
          deposit_max: item.asset.deposit_max,
          deposit_min: item.asset.deposit_min,
          withdraw_max: item.asset.withdraw_max,
          withdraw_min: item.asset.withdraw_min,
          depositable: item.asset.depositable,
          withdrawable: item.asset.withdrawable,
        }));
        setCurrencyList(dataSource.filter((item) => item.withdrawable === 1));

        const isVIP = getCurrrentUser().is_vip ?? 0;

        if (dataSource.length > 0) {
          if (fromCardVerification) {
            const selectedWalletID = dataSource.findIndex(
              (item) => item.currency === selectedCurrencyCode
            );

            setSelectedCurrencyIdx(selectedWalletID);
            setValue("currency", selectedWalletID);

            loadSelectedWallet(dataSource, selectedWalletID, isVIP);
          } else {
            if (selectedCurrencyIdx < 0) {
              setSelectedCurrencyIdx(0);
              setValue("currency", 0);

              loadSelectedWallet(dataSource, 0, isVIP);
            } else {
              loadSelectedWallet(dataSource, selectedCurrencyIdx, isVIP);
            }
          }
        }
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const {
    mutate: getAvailablePaymentMethods,
    isLoading: isLoadingPaymentMethods,
  } = useMutation(client.payment.getAvailablePaymentMethods, {
    onSuccess: (data) => {
      console.log(data);
      if (fromCardVerification) {
        data.length > 0 && setValue("payType", "cartubank");
      } else {
        data.length > 0 && setValue("payType", data[0]);
      }
      setPayOptions(data);
    },
    onError: (error: any) => {
      setAlertTitle(String(L.translate("Alert.Error")));

      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: getCards, isLoading: isLoadingCards } = useMutation(
    client.bankCards.all,
    {
      onSuccess: (data) => {
        console.log(data);
        setCardList(data);
        if (fromCardVerification) {
          setFromCardVerification(false);
          setValue("amount", getWithdrawalAmount(), { shouldValidate: true });
          setValue(
            "card",
            data.findIndex((item) => item.id === selectedCardID)
          );
        }
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getSettings, isLoading: isLoadingSettings } = useMutation(
    client.users.me,
    {
      onSuccess: (data) => {
        if (payType === PAYMENT_OPTIONS.Bank) {
          openModal("BANK_DETAILS", { ...submitData, me: data.user });
        } else if (payType === PAYMENT_OPTIONS.Paypal) {
          openModal("WITHDRAWAL_PAYPAL_CONFIRMATION", {
            ...submitData,
            me: data.user,
          });
        } else if (payType === PAYMENT_OPTIONS.Card) {
          openModal("WITHDRAWAL_VERIFY_CVV", {
            ...submitData,
            payType,
            me: data.user,
          });
        }

        /*
        if (data.user.email_2fa_enabled && data.user.phone_2fa_enabled) {
          if (payType === PAYMENT_OPTIONS.Bank) {
            openModal("BANK_DETAILS", { ...submitData, me: data.user });
          } else if (payType === PAYMENT_OPTIONS.Paypal) {
            openModal("WITHDRAWAL_PAYPAL_CONFIRMATION", {
              ...submitData,
              me: data.user,
            });
          } else if (payType === PAYMENT_OPTIONS.Card) {
            openModal("WITHDRAWAL_VERIFY_CVV", {
              ...submitData,
              me: data.user,
            });
          }
        } else {
          setAlertText("You need to enable 2FA settings. Please check");
          setIsAlertOpened(true);
        }
        */
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onSubmit = (data: FormData) => {
    const asset_id = currencyList[data.currency].currency_id;

    if (data.payType === PAYMENT_OPTIONS.Bank) {
      setSubmitData({
        withdrawal_type: "bank",
        asset_id,
        amount: data.amount,
        currency: currencyList[data.currency].currency,
      });
    } else if (data.payType === PAYMENT_OPTIONS.Paypal) {
      let targetAddress = data.phone;
      let targetType = "phone";

      switch (data.variantId) {
        case 0:
          targetAddress = data.paypalId;
          targetType = "PAYPAL_ID";
          break;
        case 1:
          targetAddress = data.email;
          targetType = "EMAIL";
          break;
        case 2:
          targetAddress = data.phone;
          targetType = "PHONE";
          break;
      }

      setSubmitData({
        withdrawal_type: "paypal",
        asset_id,
        amount: data.amount,
        currency: currencyList[data.currency].currency,
        variant: withdrawVariants[data.variantId],
        targetAddress,
        targetType,
      });
    } else if (data.payType === PAYMENT_OPTIONS.Card) {
      const cardInfo = cardList[data.card];

      setSubmitData({
        withdrawal_type: "card",
        asset_id,
        holder_name: cardInfo.holder_name,
        amount: data.amount,
        card_no: cardList[data.card].card_number,
        currency: currencyList[currency].currency,
        card_info: cardInfo,
      });
    }

    setTimeout(() => getSettings(), 300);
  };

  const makePaypalVariantField = () => {
    if (variantId == 2) {
      return (
        <div className="flex flex-col w-full">
          <span className="block cursor-pointer pb-2.5 font-normal text-secondary font-manrope text-[16px]">
            {String(L.translate("WithdrawFiat.phone"))}
          </span>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <ReactPhone
                placeholder=""
                dropdownStyle={{ width: phoneDivWidth }}
                inputClass={classNames(
                  "!w-full !border !border-border !ring-[0.5px] !ring-light-500 !focus:ring-[0.5px] !rounded-[12px]",
                  errors.phone
                    ? "focus:!border-[#F44336] focus:!ring-[#F44336]"
                    : "focus:!border-active focus:!ring-active"
                )}
                country="us"
                {...field}
              />
            )}
          />

          {errors.phone?.message && (
            <span
              role="alert"
              className="block pt-2 text-[16px] text-[#F44336] font-manrope"
            >
              {errors.phone?.message}
            </span>
          )}
        </div>
      );
    } else if (variantId == 0) {
      return (
        <Input
          label={withdrawVariants[variantId]}
          className="w-full"
          {...register("paypalId")}
          error={errors.paypalId && (errors.paypalId.message as string)}
        />
      );
    } else {
      return (
        <Input
          label={withdrawVariants[variantId]}
          placeholder={String(L.translate("WithdrawFiat.enter_email"))}
          className="w-full"
          {...register("email")}
          error={errors.email && (errors.email.message as string)}
        />
      );
    }
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col py-2 px-4 sm:p-4 sm:pr-11">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          {String(L.translate("WithdrawFiat.title"))}
        </p>
        <div className="relative w-full flex flex-col md:flex-row gap-2 mt-2 sm:mt-4 bg-white rounded-[12px] items-center justify-center">
          <button
            onClick={() => navigate(-1)}
            className="back-step left-4 top-4 start ml-10 mt-6 mr-auto"
          >
            <span className="back-step__arrow">
              <svg
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.25 7.27393L16.25 7.27393"
                  stroke="#011E26"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M7.29883 13.2985L1.24883 7.27451L7.29883 1.24951"
                  stroke="#011E26"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
            <span className="back-step__text text-[20px]">Back </span>
          </button>{" "}
          {(isLoading || isLoadingPaymentMethods || isLoadingCards) && (
            <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
          )}
          <div className="flex flex-col w-full sm:w-[548px] p-4">
            <form
              className="h-full w-full flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <p className="font-manrope text-[20px] font-medium text-primary">
                1. {String(L.translate("WithdrawFiat.select_currency"))}
              </p>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <DropdownCurrency
                    label={String(
                      L.translate("WithdrawFiat.currency_to_withdraw")
                    )}
                    placeholder={String(L.translate("WithdrawFiat.currency"))}
                    className="w-full"
                    data={currencyList}
                    error={
                      errors.currency && (errors.currency.message as string)
                    }
                    {...field}
                  />
                )}
              />
              {payOptions.length > 0 ? (
                <div className="w-full flex flex-col gap-8">
                  <div className="w-full flex flex-row justify-between">
                    <Controller
                      name="payType"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          label={String(
                            L.translate("WithdrawFiat.withdraw_crypto")
                          )}
                          options={payOptions}
                          className="w-full"
                          error={
                            errors.payType && (errors.payType.message as string)
                          }
                          {...field}
                        />
                      )}
                    />
                    <div className="w-full flex flex-col items-start">
                      <p className="font-manrope text-[20px] text-primary font-medium">
                        {String(L.translate("WithdrawFiat.limits"))}
                      </p>
                      <p className="font-manrope text-[14px] text-secondary font-light mt-2">
                        {String(L.translate("WithdrawFiat.withdraw_min_max"))}
                      </p>
                      <p className="font-manrope text-[16px] text-primary font-medium">
                        {`${getCurrencySymbol(
                          currencyList[currency].currency,
                          amountLimit.min
                        )} / ${getCurrencySymbol(
                          currencyList[currency].currency,
                          amountLimit.max
                        )}`}
                      </p>
                    </div>
                  </div>
                  {payType === PAYMENT_OPTIONS.Card && (
                    <Controller
                      name="card"
                      control={control}
                      render={({ field }) => (
                        <DropdownCard
                          label={String(L.translate("Bank.choose_card"))}
                          placeholder={String(L.translate("Bank.choose_card"))}
                          className="w-full"
                          data={cardList}
                          //showAdds={false}
                          onAddCard={onAddCard}
                          error={errors.card && (errors.card.message as string)}
                          {...field}
                        />
                      )}
                    />
                  )}

                  <p
                    className="font-manrope text-[20px] font-medium text-primary mt-4"
                    ref={phoneRef}
                  >
                    {payType === PAYMENT_OPTIONS.Paypal
                      ? String(L.translate("WithdrawFiat.withdraw_info"))
                      : String(
                          L.translate("WithdrawFiat.enter_amount_with_index")
                        )}
                  </p>
                  {payType === PAYMENT_OPTIONS.Paypal && (
                    <>
                      <Controller
                        name="variantId"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            label={String(
                              L.translate("WithdrawFiat.variant_id")
                            )}
                            placeholder={String(
                              L.translate("WithdrawFiat.choose_variant")
                            )}
                            className="w-full"
                            data={withdrawVariants}
                            error={
                              errors.variantId &&
                              (errors.variantId.message as string)
                            }
                            {...field}
                          />
                        )}
                      />
                      {makePaypalVariantField()}
                    </>
                  )}
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field }) => (
                      <InputWithMax
                        label={String(L.translate("WithdrawFiat.amount"))}
                        placeholder={String(
                          L.translate("WithdrawFiat.enter_amount")
                        )}
                        className="w-full"
                        max_limit={amountLimit.max}
                        real_max_limit={maxAmount}
                        isDeposit={false}
                        showBalance={true}
                        currency={currencyList[currency].currency}
                        error={
                          errors.amount && (errors.amount.message as string)
                        }
                        {...field}
                      />
                    )}
                  />
                  {payType === PAYMENT_OPTIONS.Paypal && (
                    <div className="flex flex-col gap-2 mt-2 mb-2">
                      <p className="font-manrope text-[14px] font-light text-secondary">
                        {String(L.translate("Deposit.you_receive"))}
                      </p>
                      <p className="font-manrope text-[24px] font-medium text-primary">
                        {`${
                          amount > 0
                            ? makeNumberWithFloatingDigits(amount - fee, 2)
                            : makeNumberWithFloatingDigits(0, 2)
                        } ${currencyList[currency].currency.toUpperCase()}`}
                      </p>
                      <div className="flex flex-row gap-4 mt-4">
                        <p className="font-manrope text-[14px] font-light text-secondary">
                          {`${String(
                            L.translate("WithdrawFiat.transaction_fee")
                          )}:`}
                        </p>
                        <p className="font-manrope text-[14px] font-light text-secondary">
                          {`${makeNumberWithFloatingDigits(
                            fee,
                            2
                          )} ${currencyList[currency].currency.toUpperCase()}`}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <p className="font-manrope text-[20px] font-medium text-secondary py-8">
                  {String(L.translate("DepositFiat.message"))}
                </p>
              )}

              <div className="flex flex-row items-center justify-center">
                <Button
                  variant="validate"
                  type="submit"
                  className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] w-full"
                  disabled={!isValid || isLoading}
                  isLoading={isLoadingSettings}
                >
                  {String(L.translate("DepositFiat.continue"))}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawFiatPage;
