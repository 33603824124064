import React, { FC, useEffect, useState, useContext } from "react";
import Password from "../../components/ui/password";
import L from "i18n-react";
import Button from "../../components/ui/button";
import { BackIcon } from "../../components/icons/back-icon";
import { useNavigate, Navigate, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeInOut } from "src/lib/framer-motion/fade-in-out";
import useAuth from "../../hooks/use-auth";
import routes from "../../config/routes";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useModalAction } from "src/components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { SUCCESS_MODAL_TYPE, APISomethingWrongMsg } from "../../lib/constants";
import GlobalContext from "src/context/global-context";
import { getErrorMessage } from "src/lib/utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const CreateNewPasswordPage: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isMounted = useIsMounted();
  const [passToken, setPassToken] = useState("");
  const { openModal } = useModalAction();
  const { setAlertText, setAlertTitle, setIsAlertOpened } =
    useContext(GlobalContext);

  const schema = yup
    .object({
      password: yup
        .string()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          `${String(L.translate("Errors.password_validation"))}`
        ),
      confirmPassword: yup
        .string()
        .required(`${String(L.translate("Errors.fieldErr"))}`)
        .oneOf(
          [yup.ref("password")],
          `${String(L.translate("Errors.pass_match"))}`
        ),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isMounted) return;

    searchParams.forEach((value, key) => {
      setPassToken(key);
    });
  }, [isMounted]);

  const { mutate: changePassword, isLoading } = useMutation(
    client.users.changePassword,
    {
      onSuccess: (data) => {
        openModal("ACTION_SUCCESS", {
          type: SUCCESS_MODAL_TYPE.ForgotPassword,
        });
      },
      onError: (error: any) => {
        setAlertTitle(String(L.translate("Alert.Error")));

        if (error.code === "ERR_NETWORK") {
          setAlertText(
            String(L.translate("ChangePassForm.network_disconnect"))
          );

          setIsAlertOpened(true);
        } else {
          if (error.response) {
            if (
              error.response.data.errors &&
              error.response.data.errors[0] === "email_not_confirmed"
            ) {
              setAlertText(String(L.translate("Errors.email_not_confirmed")));
              setIsAlertOpened(true);
              return;
            }
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
          openModal("EMAIL_VERIFICATION", {
            type: "password_expired",
            title: `${String(
              L.translate("Forms.ChangePassForm.password_has_expired")
            )}`,
          });
        }
      },
    }
  );

  const { isAuthorized } = useAuth();
  if (isAuthorized) return <Navigate to={routes.balance} />;

  const onSubmit = (data: FormData) => {
    changePassword({ token: passToken, password: data.password });
  };

  return (
    <motion.div
      variants={fadeInOut()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto">
        <form
          className="flex h-full w-full sm:w-[421px] py-8 flex-col px-4 sm:px-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-2 sm:gap-0">
            <Button
              variant="icon"
              onClick={() => navigate(-1)}
              className="bg-[#F4F4F4] rounded-[5px] px-4 py-2 w-[101px] mb-10 hover:bg-light-600"
            >
              <BackIcon className="w-[20px] h-[20px] text-primary" />
              <p className="font-manrope text-[18px] font-medium text-primary">
                {String(L.translate("Forms.ChangePassForm.back"))}
              </p>
            </Button>
            <img
              src={"/assets/logo.png"}
              className="w-24 h-24 object-contain m-auto sm:hidden"
            />
            <p className="text-[34px] font-medium font-manrope text-primary text-center sm:text-left">
              {String(L.translate("CreatePassForm.title"))}
            </p>
            <p className="text-[18px] font-light font-manrope text-secondary text-center sm:text-left">
              {String(L.translate("CreatePassForm.description"))}
            </p>
          </div>
          <div className="flex flex-col mt-10 gap-4">
            <Password
              label={String(L.translate("CreatePassForm.password"))}
              className="w-full"
              {...register("password")}
              error={errors.password && (errors.password.message as string)}
            />
            <Password
              label={String(L.translate("CreatePassForm.confirm_password"))}
              className="w-full"
              {...register("confirmPassword")}
              error={
                errors.confirmPassword &&
                (errors.confirmPassword.message as string)
              }
            />
          </div>
          <div className="flex flex-col gap-8 mt-10">
            <Button
              variant="validate"
              type="submit"
              className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px] mt-4"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
            >
              {String(L.translate("CreatePassForm.confirm_button"))}
            </Button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default CreateNewPasswordPage;
